import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAlertModal } from 'redux/reducers/alert';

import beasyLightText from 'assets/images/beasy-light-text.png';
import beasyLightText2x from 'assets/images/beasy-light-text@2x.png';
import beasyLightText3x from 'assets/images/beasy-light-text@3x.png';

import { showGenericErrorToast } from 'components/AppToasts';
import Button, { ButtonColorScheme } from 'components/Button';
import { Modal } from 'components/Modal';
import { WebVersion } from 'components/NFTLogo';
import { Pin } from 'components/Pin';

import { usePin } from 'hooks/usePin';

import css from './index.module.css';

export const PinLock = () => {
  const dispatch = useDispatch();

  const pin = usePin();

  const [value, setValue] = useState<number[]>([]);

  useEffect(() => {
    const submit = async () => {
      try {
        const passcode = value.join('');
        await pin(passcode);

        dispatch(updateAlertModal({ isVisibleAlert: false }));
      } catch (error) {
        setValue([]);
        showGenericErrorToast('Incorrect passcode');
      }
    };

    if (value.length === 4) {
      submit().then();
    }
  }, [value, dispatch, pin]);

  const handleCancel = useCallback(() => {
    dispatch(updateAlertModal({ isVisibleAlert: false }));
  }, [dispatch]);

  return (
    <Modal className={css.modal}>
      <section className={css.container}>
        <div className={css.container__info}>
          <div className={css.content_img}>
            <img
              src={beasyLightText}
              alt="beasyLogo"
              srcSet={`${beasyLightText} 1x, ${beasyLightText2x} 2x, ${beasyLightText3x} 3x`}
            />
          </div>
          <div>
            <div className={css.title}>Enter the passcode</div>
            <div className={css.contents}>
              <Pin value={value} onChange={setValue} />
            </div>
          </div>
          <div>
            <Button className={css.cancelButton} color={ButtonColorScheme.SECONDARY} onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </div>
        <WebVersion />
      </section>
    </Modal>
  );
};
