import { AmountInput } from 'pages/transfers/AmountInput';
import { Balance } from 'pages/transfers/Balance';
import { SelectCurrencyModal } from 'pages/transfers/SelectCurrencyModal';
import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { updateAlertModal } from 'redux/reducers/alert';
import {
  closeCurrencyModal,
  openCurrencyModal,
  setAmount,
  setTransferCurrency,
  transferSelector,
} from 'redux/reducers/transfer';

import { Chevron } from 'assets/icons';

import Button, { ButtonColorScheme } from 'components/Button';

import { AlertType, Path } from 'constants/enumTypes';

import { useBalanceQuery } from 'hooks/useBalanceQuery';
import { useNetwork } from 'hooks/useNetwork';

import css from './index.module.css';

export const CryptoDesktopDetails = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { amount, isCurrencyModalOpen, coinName } = useAppSelector(transferSelector);

  const continueMutation = () => {
    navigate(generatePath(Path.TransferDesktopRecipientCrypto));
  };

  const { currentNetworkName, currentCoinCode, currentNetwork } = useNetwork();
  const balanceQuery = useBalanceQuery();

  const isBalanceInsufficient = amount !== null && balanceQuery.isSuccess && amount > balanceQuery.data;

  const handleChainSwitch = useCallback(() => {
    dispatch(updateAlertModal({ isVisibleAlert: true, alertType: AlertType.SwitchChain }));
  }, [dispatch]);

  return (
    <>
      <div className={css.bodyHeight}>
        <div className={css.mainItems}>
          <div className={css.containerHeader}>
            <h2 className={css.header}>How many tokens do you want to transfer?</h2>
          </div>
          <div className={css.cryptoInfo}>
            <div className={css.network} onClick={handleChainSwitch}>
              <label htmlFor="network">Current network</label>
              <input value={currentNetworkName} name="network" readOnly={true} />
              <Chevron />
            </div>

            <div className={css.network} onClick={() => dispatch(openCurrencyModal())}>
              <label htmlFor="currency">Pick currency</label>
              <input value={coinName} name="currency" readOnly={true} />
              <Chevron />
            </div>

            <div className={css.amount}>
              <label htmlFor="network">Enter amount</label>
              <AmountInput
                hasError={isBalanceInsufficient}
                value={amount ?? ''}
                onChange={e => dispatch(setAmount(Number(e.target.value)))}
              />
            </div>
          </div>
          <div className={css.cryptoBalance}>{coinName !== '' && <Balance currencyName={coinName} />}</div>
        </div>

        <div className={css.footerContainer}>
          <div className={css.cancelButton}>
            <Button colorScheme={ButtonColorScheme.SECONDARY}>Cancel</Button>
          </div>
          <div className={css.continueButton}>
            <div className={css.previousButton}>
              <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={() => navigate(-1)}>
                Previous Step
              </Button>
            </div>
            <div className={css.previousButton}>
              <Button onClick={continueMutation} disabled={isBalanceInsufficient ||  amount === null || amount === 0 || coinName === ''}>
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isCurrencyModalOpen && (
        <SelectCurrencyModal
          coinName={currentCoinCode}
          coinNameSelected={coinName}
          onSelect={coinName =>
            dispatch(setTransferCurrency({ coinName: coinName, wethAddress: currentNetwork?.wethAddress! }))
          }
          onClose={() => dispatch(closeCurrencyModal())}
        />
      )}
    </>
  );
};
