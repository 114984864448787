import React from 'react';

import chevronDown from 'assets/images/chevronDown.png';
import chevronDown2x from 'assets/images/chevronDown@2x.png';
import chevronDown3x from 'assets/images/chevronDown@3x.png';

import { useBalanceQuery } from 'hooks/useBalanceQuery';
import { useNetwork } from 'hooks/useNetwork';

import css from './index.module.css';

interface Props {
  onChainSwitch: () => void;
}

export const ChainInfo = ({ onChainSwitch }: Props) => {
  const { currentNetworkName, currentCoinCode } = useNetwork();
  const balanceQuery = useBalanceQuery();

  return (
    <div className={css.container}>
      <div className={css.chain} onClick={onChainSwitch}>
        <div className={css.name}>{currentNetworkName}</div>
        <img
          className={css.downIcon}
          src={chevronDown}
          alt="Choose network"
          srcSet={`${chevronDown} 1x, ${chevronDown2x} 2x, ${chevronDown3x} 3x`}
        />
      </div>

      {balanceQuery.isSuccess && !balanceQuery.isStale && (
        <div className={css.balance}>
          {balanceQuery.data.toFixed(4)} {currentCoinCode}
        </div>
      )}
    </div>
  );
};
