import React from 'react';

import css from './index.module.css';

interface Props {
  header: string;
  subHeader: string;
}

export const SubHeaderCard = ({ header, subHeader }: Props) => {
  return (
    <div>
      <h2 className={css.headerCard}>{header}</h2>
      <p className={css.subHeaderCard}>{subHeader}</p>
    </div>
  );
};
