import { NavigateFunction } from 'react-router-dom';

const StorageKey = 'redirectAfterPin';

export class RedirectAfterPinService {
  static init() {
    localStorage.setItem(StorageKey, window.location.pathname);
  }

  static redirectIfNecessary(navigate: NavigateFunction) {
    const pathname = localStorage.getItem(StorageKey);
    if (pathname) {
      navigate(pathname);
      localStorage.removeItem(StorageKey);
    }
  }
}
