import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/configStore';

import { retry } from 'redux/reducers/tx';
import { providerService } from 'services';
import { assert } from 'utils';

import Button, { ButtonColorScheme } from 'components/Button';
import { LandingHeader } from 'components/Header/LandingHeader';
import { TradeHistoryItem } from 'components/TradeHistory/TradeHistoryItem';
import { TextAreaInputWithCopy } from 'components/inputs/TextAreaInputWithCopy';

import { Path } from 'constants/enumTypes';

import { useTransferHistoryQuery } from 'hooks/useTransferHistory';

import { TransferType, TxStatus } from 'types';

import css from './index.module.css';
import { selectorIsLoading, updateFlags } from 'redux/reducers/flags';

export const TxDetailsPage = () => {
  const isLoading = useAppSelector(selectorIsLoading);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  

  const params = useParams();

  const [showDetails, setShowDetails] = useState(false);

  const txQuery = useQuery('tx', async () => {
    assert(params.hash, 'Hash is not defined');
    return await providerService.getTransaction(params.hash);
  });

  const transferHistoryQuery = useTransferHistoryQuery();

  useEffect(() => {
    if (!isLoading && transferHistoryQuery.isLoading) {
      dispatch(updateFlags({ isLoading: true }));
    }
    if (isLoading && !transferHistoryQuery.isLoading) {
      dispatch(updateFlags({ isLoading: false }));
    }
  }, [isLoading, transferHistoryQuery.isLoading, dispatch]);


  const transfer =
    transferHistoryQuery.isSuccess && txQuery.isSuccess
      ? transferHistoryQuery.data.find(
          item => item.txHash === txQuery.data?.hash && params.contractAddress === item.contractAddress,
        )
      : null;

  const isFirstUnsuccessfulTransfer = useMemo(() => {
    const unsuccessfulTransfer = transferHistoryQuery.data
      ?.filter(item => item.txStatus !== TxStatus.Success)
      .sort((a, b) => a.timestamp - b.timestamp);

    return unsuccessfulTransfer?.[0]?.txHash === params.hash;
  }, [transferHistoryQuery.data, params.hash]);

  const handlePolygonScanClick = useCallback(() => {
    const hash = txQuery.data?.hash;
    if (hash) {
      //TODO: SET EXPLORER URL ON API MESSAGES
      const url = `/tx/${hash}`;
      window.open(url, '_blank')?.focus();
    }
  }, [txQuery.data]);

  const handleRetryClick = useCallback(async () => {
    assert(params.hash, 'Hash is not defined');

    try {
      if (transfer?.uuid) {
        await dispatch(retry(transfer.uuid, params.hash));
        navigate(Path.Transfers);
      }
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, navigate, params.hash, transfer?.uuid]);

  return (
    <div className={css.container}>
      <div className={css.headerPage}>
        <LandingHeader isGoBack={true} />
        <h1 className="pageTitle">Transfer Details</h1>
      </div>

      <section className={css.contentContainer}>
        <div className={css.content}>
          <div className={css.transfer}>{transfer && <TradeHistoryItem transfer={transfer} />}</div>

          <div className={css.tokenInfo}>
            <div className={css.tokenId}>
              {transfer?.type === TransferType.Nft && (
                <TextAreaInputWithCopy label="Token ID" value={transfer.tokenId} successMessage="" />
              )}
            </div>

            <Button
              className={css.polygonScanButton}
              colorScheme={ButtonColorScheme.SECONDARY}
              disabled={!txQuery.isSuccess}
              onClick={handlePolygonScanClick}
            >
              Navigate to PolygonScan
            </Button>
          </div>

          <div>
            {txQuery.data && (
              <p className={css.txDetailsTitle} onClick={() => setShowDetails(s => !s)}>
                Show transaction details
              </p>
            )}

            {showDetails && txQuery.isSuccess && (
              <pre className={css.txDetails}>{JSON.stringify(txQuery.data, null, 2)}</pre>
            )}
          </div>
        </div>

        {isFirstUnsuccessfulTransfer && (
          <footer className={css.footer}>
            <Button className={css.retryButton} disabled={!txQuery.isSuccess} onClick={handleRetryClick}>
              Retry
            </Button>
          </footer>
        )}
      </section>
    </div>
  );
};
