/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import _m0 from "protobufjs/minimal";
import { Any } from "./google/protobuf/any";
import { Timestamp } from "./google/protobuf/timestamp";

export const protobufPackage = "alchemy";

export interface GetAllNftTransfersRes {
  transfers: GetAssetTransfersRes_Transfer[];
  pageKey: string;
}

export interface GetAllNftTransfersReq {
  contract: string;
  fromBlock?: number | undefined;
  toBlock?: number | undefined;
  maxCount: number;
  excludeZeroValue: boolean;
  pageKey: string;
}

export interface GetTokenMetadataRes {
  name: string;
  symbol: string;
  decimals: number;
  logo: string;
}

export interface GetTokenMetadataReq {
  contract: string;
}

export interface GetNFTMetadataRes {
  metadata: GetNFTMetadataRes_Metadata | undefined;
  tokenUri: GetNFTMetadataRes_TokenUri | undefined;
}

export interface GetNFTMetadataRes_Metadata {
  name: string;
  description: string;
  image: string;
  attributes: Any | undefined;
}

export interface GetNFTMetadataRes_TokenUri {
  raw: string;
  gateway: string;
}

export interface GetNFTMetadataReq {
  contractAddress: string;
  tokenId: string;
  tokenType: GetNFTMetadataReq_TokenType;
}

export enum GetNFTMetadataReq_TokenType {
  ERC721 = 0,
  ERC1155 = 1,
  UNRECOGNIZED = -1,
}

export interface GetWETHBalanceRes {
  tokenBalance: GetTokenBalancesRes_TokenBalance | undefined;
}

export interface GetWETHBalanceReq {
}

export interface GetLatestTransferRes {
  transfer: GetAssetTransfersRes_Transfer | undefined;
  block: GetLatestTransferRes_Block | undefined;
}

export interface GetLatestTransferRes_Block {
  number: number;
  timestamp: Date | undefined;
}

export interface GetLatestTransferReq {
  contractAddresses: string[];
  category: GetAssetTransfersReq_Category[];
  fromBlock: number;
}

export interface GetNFTsReq {
  pageKey: string;
  contractAddresses: string[];
  withMetadata: boolean;
  includeBeasy: boolean;
}

export interface GetNFTsRes {
  ownedNfts: GetNFTsRes_OwnedNft[];
  pageKey: string;
  totalCount: number;
  blockHash: string;
}

export interface GetNFTsRes_OwnedNft {
  contract: GetNFTsRes_OwnedNft_Contract | undefined;
  id: GetNFTsRes_OwnedNft_Id | undefined;
  title: string;
  description: string;
  tokenUri: GetNFTsRes_OwnedNft_TokenUri | undefined;
  media: GetNFTsRes_OwnedNft_TokenUri | undefined;
  metadata: GetNFTsRes_OwnedNft_Metadata | undefined;
  timeLastUpdated: string;
  balance: string;
}

export interface GetNFTsRes_OwnedNft_Contract {
  address: string;
}

export interface GetNFTsRes_OwnedNft_Id {
  tokenId: string;
  tokenMetadata: GetNFTsRes_OwnedNft_Id_TokenMetadata | undefined;
}

export interface GetNFTsRes_OwnedNft_Id_TokenMetadata {
  tokenType: string;
}

export interface GetNFTsRes_OwnedNft_TokenUri {
  raw: string;
  gateway: string;
}

export interface GetNFTsRes_OwnedNft_Metadata {
  image: string;
  externalUrl: string;
  backgroundColor: string;
  name: string;
  description: string;
  attributes: Any[];
  beasy: Any | undefined;
  coven: Any | undefined;
}

export interface GetTokenBalancesReq {
  contractAddresses: string[];
}

export interface GetTokenBalancesRes {
  tokenBalances: GetTokenBalancesRes_TokenBalance[];
}

export interface GetTokenBalancesRes_TokenBalance {
  contractAddress: string;
  tokenBalance: string;
  error: string;
}

export interface GetAssetTransfersReq {
  fromBlock?: number | undefined;
  toBlock?: number | undefined;
  contractAddresses: string[];
  maxCount: number;
  excludeZeroValue: boolean;
  category: GetAssetTransfersReq_Category[];
  pageKey: string;
  transferredTo: string;
}

export enum GetAssetTransfersReq_Category {
  TOKEN = 0,
  ERC20 = 1,
  ERC721 = 2,
  ERC1155 = 3,
  EXTERNAL = 4,
  UNRECOGNIZED = -1,
}

export interface GetAssetTransfersRes {
  transfers: GetAssetTransfersRes_Transfer[];
  pageKey: string;
}

export interface GetAssetTransfersRes_Transfer {
  category: GetAssetTransfersReq_Category;
  blockNum: number;
  from: string;
  to: string;
  value: string;
  erc721TokenId: string;
  tokenId: string;
  asset: string;
  hash: string;
  rawContract: GetAssetTransfersRes_Transfer_RawContract | undefined;
  erc1155Metadata: GetAssetTransfersRes_Transfer_Erc1155Metadata[];
  chainId: number;
}

export interface GetAssetTransfersRes_Transfer_RawContract {
  value: string;
  address: string;
  decimal: string;
}

export interface GetAssetTransfersRes_Transfer_Erc1155Metadata {
  tokenId: string;
  value: string;
}

function createBaseGetAllNftTransfersRes(): GetAllNftTransfersRes {
  return { transfers: [], pageKey: "" };
}

export const GetAllNftTransfersRes = {
  encode(message: GetAllNftTransfersRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.transfers) {
      GetAssetTransfersRes_Transfer.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pageKey !== "") {
      writer.uint32(18).string(message.pageKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllNftTransfersRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllNftTransfersRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transfers.push(GetAssetTransfersRes_Transfer.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pageKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetAllNftTransfersRes>, I>>(base?: I): GetAllNftTransfersRes {
    return GetAllNftTransfersRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAllNftTransfersRes>, I>>(object: I): GetAllNftTransfersRes {
    const message = createBaseGetAllNftTransfersRes();
    message.transfers = object.transfers?.map((e) => GetAssetTransfersRes_Transfer.fromPartial(e)) || [];
    message.pageKey = object.pageKey ?? "";
    return message;
  },
};

function createBaseGetAllNftTransfersReq(): GetAllNftTransfersReq {
  return { contract: "", fromBlock: undefined, toBlock: undefined, maxCount: 0, excludeZeroValue: false, pageKey: "" };
}

export const GetAllNftTransfersReq = {
  encode(message: GetAllNftTransfersReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contract !== "") {
      writer.uint32(10).string(message.contract);
    }
    if (message.fromBlock !== undefined) {
      writer.uint32(16).int32(message.fromBlock);
    }
    if (message.toBlock !== undefined) {
      writer.uint32(24).int32(message.toBlock);
    }
    if (message.maxCount !== 0) {
      writer.uint32(32).int32(message.maxCount);
    }
    if (message.excludeZeroValue === true) {
      writer.uint32(40).bool(message.excludeZeroValue);
    }
    if (message.pageKey !== "") {
      writer.uint32(50).string(message.pageKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAllNftTransfersReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAllNftTransfersReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.contract = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.fromBlock = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.toBlock = reader.int32();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.maxCount = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.excludeZeroValue = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.pageKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetAllNftTransfersReq>, I>>(base?: I): GetAllNftTransfersReq {
    return GetAllNftTransfersReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAllNftTransfersReq>, I>>(object: I): GetAllNftTransfersReq {
    const message = createBaseGetAllNftTransfersReq();
    message.contract = object.contract ?? "";
    message.fromBlock = object.fromBlock ?? undefined;
    message.toBlock = object.toBlock ?? undefined;
    message.maxCount = object.maxCount ?? 0;
    message.excludeZeroValue = object.excludeZeroValue ?? false;
    message.pageKey = object.pageKey ?? "";
    return message;
  },
};

function createBaseGetTokenMetadataRes(): GetTokenMetadataRes {
  return { name: "", symbol: "", decimals: 0, logo: "" };
}

export const GetTokenMetadataRes = {
  encode(message: GetTokenMetadataRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.symbol !== "") {
      writer.uint32(18).string(message.symbol);
    }
    if (message.decimals !== 0) {
      writer.uint32(24).int32(message.decimals);
    }
    if (message.logo !== "") {
      writer.uint32(34).string(message.logo);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTokenMetadataRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTokenMetadataRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.symbol = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.decimals = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.logo = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetTokenMetadataRes>, I>>(base?: I): GetTokenMetadataRes {
    return GetTokenMetadataRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTokenMetadataRes>, I>>(object: I): GetTokenMetadataRes {
    const message = createBaseGetTokenMetadataRes();
    message.name = object.name ?? "";
    message.symbol = object.symbol ?? "";
    message.decimals = object.decimals ?? 0;
    message.logo = object.logo ?? "";
    return message;
  },
};

function createBaseGetTokenMetadataReq(): GetTokenMetadataReq {
  return { contract: "" };
}

export const GetTokenMetadataReq = {
  encode(message: GetTokenMetadataReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contract !== "") {
      writer.uint32(10).string(message.contract);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTokenMetadataReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTokenMetadataReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.contract = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetTokenMetadataReq>, I>>(base?: I): GetTokenMetadataReq {
    return GetTokenMetadataReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTokenMetadataReq>, I>>(object: I): GetTokenMetadataReq {
    const message = createBaseGetTokenMetadataReq();
    message.contract = object.contract ?? "";
    return message;
  },
};

function createBaseGetNFTMetadataRes(): GetNFTMetadataRes {
  return { metadata: undefined, tokenUri: undefined };
}

export const GetNFTMetadataRes = {
  encode(message: GetNFTMetadataRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.metadata !== undefined) {
      GetNFTMetadataRes_Metadata.encode(message.metadata, writer.uint32(10).fork()).ldelim();
    }
    if (message.tokenUri !== undefined) {
      GetNFTMetadataRes_TokenUri.encode(message.tokenUri, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNFTMetadataRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNFTMetadataRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.metadata = GetNFTMetadataRes_Metadata.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tokenUri = GetNFTMetadataRes_TokenUri.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetNFTMetadataRes>, I>>(base?: I): GetNFTMetadataRes {
    return GetNFTMetadataRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNFTMetadataRes>, I>>(object: I): GetNFTMetadataRes {
    const message = createBaseGetNFTMetadataRes();
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? GetNFTMetadataRes_Metadata.fromPartial(object.metadata)
      : undefined;
    message.tokenUri = (object.tokenUri !== undefined && object.tokenUri !== null)
      ? GetNFTMetadataRes_TokenUri.fromPartial(object.tokenUri)
      : undefined;
    return message;
  },
};

function createBaseGetNFTMetadataRes_Metadata(): GetNFTMetadataRes_Metadata {
  return { name: "", description: "", image: "", attributes: undefined };
}

export const GetNFTMetadataRes_Metadata = {
  encode(message: GetNFTMetadataRes_Metadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.image !== "") {
      writer.uint32(26).string(message.image);
    }
    if (message.attributes !== undefined) {
      Any.encode(message.attributes, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNFTMetadataRes_Metadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNFTMetadataRes_Metadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.image = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.attributes = Any.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetNFTMetadataRes_Metadata>, I>>(base?: I): GetNFTMetadataRes_Metadata {
    return GetNFTMetadataRes_Metadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNFTMetadataRes_Metadata>, I>>(object: I): GetNFTMetadataRes_Metadata {
    const message = createBaseGetNFTMetadataRes_Metadata();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.image = object.image ?? "";
    message.attributes = (object.attributes !== undefined && object.attributes !== null)
      ? Any.fromPartial(object.attributes)
      : undefined;
    return message;
  },
};

function createBaseGetNFTMetadataRes_TokenUri(): GetNFTMetadataRes_TokenUri {
  return { raw: "", gateway: "" };
}

export const GetNFTMetadataRes_TokenUri = {
  encode(message: GetNFTMetadataRes_TokenUri, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.raw !== "") {
      writer.uint32(10).string(message.raw);
    }
    if (message.gateway !== "") {
      writer.uint32(18).string(message.gateway);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNFTMetadataRes_TokenUri {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNFTMetadataRes_TokenUri();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.raw = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.gateway = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetNFTMetadataRes_TokenUri>, I>>(base?: I): GetNFTMetadataRes_TokenUri {
    return GetNFTMetadataRes_TokenUri.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNFTMetadataRes_TokenUri>, I>>(object: I): GetNFTMetadataRes_TokenUri {
    const message = createBaseGetNFTMetadataRes_TokenUri();
    message.raw = object.raw ?? "";
    message.gateway = object.gateway ?? "";
    return message;
  },
};

function createBaseGetNFTMetadataReq(): GetNFTMetadataReq {
  return { contractAddress: "", tokenId: "", tokenType: 0 };
}

export const GetNFTMetadataReq = {
  encode(message: GetNFTMetadataReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractAddress !== "") {
      writer.uint32(10).string(message.contractAddress);
    }
    if (message.tokenId !== "") {
      writer.uint32(18).string(message.tokenId);
    }
    if (message.tokenType !== 0) {
      writer.uint32(24).int32(message.tokenType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNFTMetadataReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNFTMetadataReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.contractAddress = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tokenId = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.tokenType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetNFTMetadataReq>, I>>(base?: I): GetNFTMetadataReq {
    return GetNFTMetadataReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNFTMetadataReq>, I>>(object: I): GetNFTMetadataReq {
    const message = createBaseGetNFTMetadataReq();
    message.contractAddress = object.contractAddress ?? "";
    message.tokenId = object.tokenId ?? "";
    message.tokenType = object.tokenType ?? 0;
    return message;
  },
};

function createBaseGetWETHBalanceRes(): GetWETHBalanceRes {
  return { tokenBalance: undefined };
}

export const GetWETHBalanceRes = {
  encode(message: GetWETHBalanceRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tokenBalance !== undefined) {
      GetTokenBalancesRes_TokenBalance.encode(message.tokenBalance, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWETHBalanceRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWETHBalanceRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokenBalance = GetTokenBalancesRes_TokenBalance.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetWETHBalanceRes>, I>>(base?: I): GetWETHBalanceRes {
    return GetWETHBalanceRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetWETHBalanceRes>, I>>(object: I): GetWETHBalanceRes {
    const message = createBaseGetWETHBalanceRes();
    message.tokenBalance = (object.tokenBalance !== undefined && object.tokenBalance !== null)
      ? GetTokenBalancesRes_TokenBalance.fromPartial(object.tokenBalance)
      : undefined;
    return message;
  },
};

function createBaseGetWETHBalanceReq(): GetWETHBalanceReq {
  return {};
}

export const GetWETHBalanceReq = {
  encode(_: GetWETHBalanceReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetWETHBalanceReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetWETHBalanceReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetWETHBalanceReq>, I>>(base?: I): GetWETHBalanceReq {
    return GetWETHBalanceReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetWETHBalanceReq>, I>>(_: I): GetWETHBalanceReq {
    const message = createBaseGetWETHBalanceReq();
    return message;
  },
};

function createBaseGetLatestTransferRes(): GetLatestTransferRes {
  return { transfer: undefined, block: undefined };
}

export const GetLatestTransferRes = {
  encode(message: GetLatestTransferRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transfer !== undefined) {
      GetAssetTransfersRes_Transfer.encode(message.transfer, writer.uint32(10).fork()).ldelim();
    }
    if (message.block !== undefined) {
      GetLatestTransferRes_Block.encode(message.block, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLatestTransferRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLatestTransferRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transfer = GetAssetTransfersRes_Transfer.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.block = GetLatestTransferRes_Block.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetLatestTransferRes>, I>>(base?: I): GetLatestTransferRes {
    return GetLatestTransferRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLatestTransferRes>, I>>(object: I): GetLatestTransferRes {
    const message = createBaseGetLatestTransferRes();
    message.transfer = (object.transfer !== undefined && object.transfer !== null)
      ? GetAssetTransfersRes_Transfer.fromPartial(object.transfer)
      : undefined;
    message.block = (object.block !== undefined && object.block !== null)
      ? GetLatestTransferRes_Block.fromPartial(object.block)
      : undefined;
    return message;
  },
};

function createBaseGetLatestTransferRes_Block(): GetLatestTransferRes_Block {
  return { number: 0, timestamp: undefined };
}

export const GetLatestTransferRes_Block = {
  encode(message: GetLatestTransferRes_Block, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.number !== 0) {
      writer.uint32(8).int32(message.number);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLatestTransferRes_Block {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLatestTransferRes_Block();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.number = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetLatestTransferRes_Block>, I>>(base?: I): GetLatestTransferRes_Block {
    return GetLatestTransferRes_Block.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLatestTransferRes_Block>, I>>(object: I): GetLatestTransferRes_Block {
    const message = createBaseGetLatestTransferRes_Block();
    message.number = object.number ?? 0;
    message.timestamp = object.timestamp ?? undefined;
    return message;
  },
};

function createBaseGetLatestTransferReq(): GetLatestTransferReq {
  return { contractAddresses: [], category: [], fromBlock: 0 };
}

export const GetLatestTransferReq = {
  encode(message: GetLatestTransferReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.contractAddresses) {
      writer.uint32(10).string(v!);
    }
    writer.uint32(18).fork();
    for (const v of message.category) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.fromBlock !== 0) {
      writer.uint32(24).int32(message.fromBlock);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetLatestTransferReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetLatestTransferReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.contractAddresses.push(reader.string());
          continue;
        case 2:
          if (tag === 16) {
            message.category.push(reader.int32() as any);

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.category.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.fromBlock = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetLatestTransferReq>, I>>(base?: I): GetLatestTransferReq {
    return GetLatestTransferReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetLatestTransferReq>, I>>(object: I): GetLatestTransferReq {
    const message = createBaseGetLatestTransferReq();
    message.contractAddresses = object.contractAddresses?.map((e) => e) || [];
    message.category = object.category?.map((e) => e) || [];
    message.fromBlock = object.fromBlock ?? 0;
    return message;
  },
};

function createBaseGetNFTsReq(): GetNFTsReq {
  return { pageKey: "", contractAddresses: [], withMetadata: false, includeBeasy: false };
}

export const GetNFTsReq = {
  encode(message: GetNFTsReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pageKey !== "") {
      writer.uint32(10).string(message.pageKey);
    }
    for (const v of message.contractAddresses) {
      writer.uint32(18).string(v!);
    }
    if (message.withMetadata === true) {
      writer.uint32(24).bool(message.withMetadata);
    }
    if (message.includeBeasy === true) {
      writer.uint32(32).bool(message.includeBeasy);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNFTsReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNFTsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pageKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.contractAddresses.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.withMetadata = reader.bool();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.includeBeasy = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetNFTsReq>, I>>(base?: I): GetNFTsReq {
    return GetNFTsReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNFTsReq>, I>>(object: I): GetNFTsReq {
    const message = createBaseGetNFTsReq();
    message.pageKey = object.pageKey ?? "";
    message.contractAddresses = object.contractAddresses?.map((e) => e) || [];
    message.withMetadata = object.withMetadata ?? false;
    message.includeBeasy = object.includeBeasy ?? false;
    return message;
  },
};

function createBaseGetNFTsRes(): GetNFTsRes {
  return { ownedNfts: [], pageKey: "", totalCount: 0, blockHash: "" };
}

export const GetNFTsRes = {
  encode(message: GetNFTsRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ownedNfts) {
      GetNFTsRes_OwnedNft.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pageKey !== "") {
      writer.uint32(18).string(message.pageKey);
    }
    if (message.totalCount !== 0) {
      writer.uint32(24).int32(message.totalCount);
    }
    if (message.blockHash !== "") {
      writer.uint32(34).string(message.blockHash);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNFTsRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNFTsRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ownedNfts.push(GetNFTsRes_OwnedNft.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pageKey = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.totalCount = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.blockHash = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetNFTsRes>, I>>(base?: I): GetNFTsRes {
    return GetNFTsRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNFTsRes>, I>>(object: I): GetNFTsRes {
    const message = createBaseGetNFTsRes();
    message.ownedNfts = object.ownedNfts?.map((e) => GetNFTsRes_OwnedNft.fromPartial(e)) || [];
    message.pageKey = object.pageKey ?? "";
    message.totalCount = object.totalCount ?? 0;
    message.blockHash = object.blockHash ?? "";
    return message;
  },
};

function createBaseGetNFTsRes_OwnedNft(): GetNFTsRes_OwnedNft {
  return {
    contract: undefined,
    id: undefined,
    title: "",
    description: "",
    tokenUri: undefined,
    media: undefined,
    metadata: undefined,
    timeLastUpdated: "",
    balance: "",
  };
}

export const GetNFTsRes_OwnedNft = {
  encode(message: GetNFTsRes_OwnedNft, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contract !== undefined) {
      GetNFTsRes_OwnedNft_Contract.encode(message.contract, writer.uint32(10).fork()).ldelim();
    }
    if (message.id !== undefined) {
      GetNFTsRes_OwnedNft_Id.encode(message.id, writer.uint32(18).fork()).ldelim();
    }
    if (message.title !== "") {
      writer.uint32(26).string(message.title);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.tokenUri !== undefined) {
      GetNFTsRes_OwnedNft_TokenUri.encode(message.tokenUri, writer.uint32(42).fork()).ldelim();
    }
    if (message.media !== undefined) {
      GetNFTsRes_OwnedNft_TokenUri.encode(message.media, writer.uint32(50).fork()).ldelim();
    }
    if (message.metadata !== undefined) {
      GetNFTsRes_OwnedNft_Metadata.encode(message.metadata, writer.uint32(58).fork()).ldelim();
    }
    if (message.timeLastUpdated !== "") {
      writer.uint32(66).string(message.timeLastUpdated);
    }
    if (message.balance !== "") {
      writer.uint32(74).string(message.balance);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNFTsRes_OwnedNft {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNFTsRes_OwnedNft();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.contract = GetNFTsRes_OwnedNft_Contract.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.id = GetNFTsRes_OwnedNft_Id.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.title = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tokenUri = GetNFTsRes_OwnedNft_TokenUri.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.media = GetNFTsRes_OwnedNft_TokenUri.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.metadata = GetNFTsRes_OwnedNft_Metadata.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.timeLastUpdated = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.balance = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetNFTsRes_OwnedNft>, I>>(base?: I): GetNFTsRes_OwnedNft {
    return GetNFTsRes_OwnedNft.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNFTsRes_OwnedNft>, I>>(object: I): GetNFTsRes_OwnedNft {
    const message = createBaseGetNFTsRes_OwnedNft();
    message.contract = (object.contract !== undefined && object.contract !== null)
      ? GetNFTsRes_OwnedNft_Contract.fromPartial(object.contract)
      : undefined;
    message.id = (object.id !== undefined && object.id !== null)
      ? GetNFTsRes_OwnedNft_Id.fromPartial(object.id)
      : undefined;
    message.title = object.title ?? "";
    message.description = object.description ?? "";
    message.tokenUri = (object.tokenUri !== undefined && object.tokenUri !== null)
      ? GetNFTsRes_OwnedNft_TokenUri.fromPartial(object.tokenUri)
      : undefined;
    message.media = (object.media !== undefined && object.media !== null)
      ? GetNFTsRes_OwnedNft_TokenUri.fromPartial(object.media)
      : undefined;
    message.metadata = (object.metadata !== undefined && object.metadata !== null)
      ? GetNFTsRes_OwnedNft_Metadata.fromPartial(object.metadata)
      : undefined;
    message.timeLastUpdated = object.timeLastUpdated ?? "";
    message.balance = object.balance ?? "";
    return message;
  },
};

function createBaseGetNFTsRes_OwnedNft_Contract(): GetNFTsRes_OwnedNft_Contract {
  return { address: "" };
}

export const GetNFTsRes_OwnedNft_Contract = {
  encode(message: GetNFTsRes_OwnedNft_Contract, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNFTsRes_OwnedNft_Contract {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNFTsRes_OwnedNft_Contract();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetNFTsRes_OwnedNft_Contract>, I>>(base?: I): GetNFTsRes_OwnedNft_Contract {
    return GetNFTsRes_OwnedNft_Contract.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNFTsRes_OwnedNft_Contract>, I>>(object: I): GetNFTsRes_OwnedNft_Contract {
    const message = createBaseGetNFTsRes_OwnedNft_Contract();
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseGetNFTsRes_OwnedNft_Id(): GetNFTsRes_OwnedNft_Id {
  return { tokenId: "", tokenMetadata: undefined };
}

export const GetNFTsRes_OwnedNft_Id = {
  encode(message: GetNFTsRes_OwnedNft_Id, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tokenId !== "") {
      writer.uint32(10).string(message.tokenId);
    }
    if (message.tokenMetadata !== undefined) {
      GetNFTsRes_OwnedNft_Id_TokenMetadata.encode(message.tokenMetadata, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNFTsRes_OwnedNft_Id {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNFTsRes_OwnedNft_Id();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokenId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tokenMetadata = GetNFTsRes_OwnedNft_Id_TokenMetadata.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetNFTsRes_OwnedNft_Id>, I>>(base?: I): GetNFTsRes_OwnedNft_Id {
    return GetNFTsRes_OwnedNft_Id.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNFTsRes_OwnedNft_Id>, I>>(object: I): GetNFTsRes_OwnedNft_Id {
    const message = createBaseGetNFTsRes_OwnedNft_Id();
    message.tokenId = object.tokenId ?? "";
    message.tokenMetadata = (object.tokenMetadata !== undefined && object.tokenMetadata !== null)
      ? GetNFTsRes_OwnedNft_Id_TokenMetadata.fromPartial(object.tokenMetadata)
      : undefined;
    return message;
  },
};

function createBaseGetNFTsRes_OwnedNft_Id_TokenMetadata(): GetNFTsRes_OwnedNft_Id_TokenMetadata {
  return { tokenType: "" };
}

export const GetNFTsRes_OwnedNft_Id_TokenMetadata = {
  encode(message: GetNFTsRes_OwnedNft_Id_TokenMetadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tokenType !== "") {
      writer.uint32(10).string(message.tokenType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNFTsRes_OwnedNft_Id_TokenMetadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNFTsRes_OwnedNft_Id_TokenMetadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokenType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetNFTsRes_OwnedNft_Id_TokenMetadata>, I>>(
    base?: I,
  ): GetNFTsRes_OwnedNft_Id_TokenMetadata {
    return GetNFTsRes_OwnedNft_Id_TokenMetadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNFTsRes_OwnedNft_Id_TokenMetadata>, I>>(
    object: I,
  ): GetNFTsRes_OwnedNft_Id_TokenMetadata {
    const message = createBaseGetNFTsRes_OwnedNft_Id_TokenMetadata();
    message.tokenType = object.tokenType ?? "";
    return message;
  },
};

function createBaseGetNFTsRes_OwnedNft_TokenUri(): GetNFTsRes_OwnedNft_TokenUri {
  return { raw: "", gateway: "" };
}

export const GetNFTsRes_OwnedNft_TokenUri = {
  encode(message: GetNFTsRes_OwnedNft_TokenUri, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.raw !== "") {
      writer.uint32(10).string(message.raw);
    }
    if (message.gateway !== "") {
      writer.uint32(18).string(message.gateway);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNFTsRes_OwnedNft_TokenUri {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNFTsRes_OwnedNft_TokenUri();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.raw = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.gateway = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetNFTsRes_OwnedNft_TokenUri>, I>>(base?: I): GetNFTsRes_OwnedNft_TokenUri {
    return GetNFTsRes_OwnedNft_TokenUri.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNFTsRes_OwnedNft_TokenUri>, I>>(object: I): GetNFTsRes_OwnedNft_TokenUri {
    const message = createBaseGetNFTsRes_OwnedNft_TokenUri();
    message.raw = object.raw ?? "";
    message.gateway = object.gateway ?? "";
    return message;
  },
};

function createBaseGetNFTsRes_OwnedNft_Metadata(): GetNFTsRes_OwnedNft_Metadata {
  return {
    image: "",
    externalUrl: "",
    backgroundColor: "",
    name: "",
    description: "",
    attributes: [],
    beasy: undefined,
    coven: undefined,
  };
}

export const GetNFTsRes_OwnedNft_Metadata = {
  encode(message: GetNFTsRes_OwnedNft_Metadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.image !== "") {
      writer.uint32(10).string(message.image);
    }
    if (message.externalUrl !== "") {
      writer.uint32(18).string(message.externalUrl);
    }
    if (message.backgroundColor !== "") {
      writer.uint32(26).string(message.backgroundColor);
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(42).string(message.description);
    }
    for (const v of message.attributes) {
      Any.encode(v!, writer.uint32(50).fork()).ldelim();
    }
    if (message.beasy !== undefined) {
      Any.encode(message.beasy, writer.uint32(58).fork()).ldelim();
    }
    if (message.coven !== undefined) {
      Any.encode(message.coven, writer.uint32(66).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetNFTsRes_OwnedNft_Metadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetNFTsRes_OwnedNft_Metadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.image = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.externalUrl = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.backgroundColor = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.description = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.attributes.push(Any.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.beasy = Any.decode(reader, reader.uint32());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.coven = Any.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetNFTsRes_OwnedNft_Metadata>, I>>(base?: I): GetNFTsRes_OwnedNft_Metadata {
    return GetNFTsRes_OwnedNft_Metadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetNFTsRes_OwnedNft_Metadata>, I>>(object: I): GetNFTsRes_OwnedNft_Metadata {
    const message = createBaseGetNFTsRes_OwnedNft_Metadata();
    message.image = object.image ?? "";
    message.externalUrl = object.externalUrl ?? "";
    message.backgroundColor = object.backgroundColor ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.attributes = object.attributes?.map((e) => Any.fromPartial(e)) || [];
    message.beasy = (object.beasy !== undefined && object.beasy !== null) ? Any.fromPartial(object.beasy) : undefined;
    message.coven = (object.coven !== undefined && object.coven !== null) ? Any.fromPartial(object.coven) : undefined;
    return message;
  },
};

function createBaseGetTokenBalancesReq(): GetTokenBalancesReq {
  return { contractAddresses: [] };
}

export const GetTokenBalancesReq = {
  encode(message: GetTokenBalancesReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.contractAddresses) {
      writer.uint32(10).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTokenBalancesReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTokenBalancesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.contractAddresses.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetTokenBalancesReq>, I>>(base?: I): GetTokenBalancesReq {
    return GetTokenBalancesReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTokenBalancesReq>, I>>(object: I): GetTokenBalancesReq {
    const message = createBaseGetTokenBalancesReq();
    message.contractAddresses = object.contractAddresses?.map((e) => e) || [];
    return message;
  },
};

function createBaseGetTokenBalancesRes(): GetTokenBalancesRes {
  return { tokenBalances: [] };
}

export const GetTokenBalancesRes = {
  encode(message: GetTokenBalancesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.tokenBalances) {
      GetTokenBalancesRes_TokenBalance.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTokenBalancesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTokenBalancesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokenBalances.push(GetTokenBalancesRes_TokenBalance.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetTokenBalancesRes>, I>>(base?: I): GetTokenBalancesRes {
    return GetTokenBalancesRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTokenBalancesRes>, I>>(object: I): GetTokenBalancesRes {
    const message = createBaseGetTokenBalancesRes();
    message.tokenBalances = object.tokenBalances?.map((e) => GetTokenBalancesRes_TokenBalance.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetTokenBalancesRes_TokenBalance(): GetTokenBalancesRes_TokenBalance {
  return { contractAddress: "", tokenBalance: "", error: "" };
}

export const GetTokenBalancesRes_TokenBalance = {
  encode(message: GetTokenBalancesRes_TokenBalance, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.contractAddress !== "") {
      writer.uint32(10).string(message.contractAddress);
    }
    if (message.tokenBalance !== "") {
      writer.uint32(18).string(message.tokenBalance);
    }
    if (message.error !== "") {
      writer.uint32(26).string(message.error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetTokenBalancesRes_TokenBalance {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetTokenBalancesRes_TokenBalance();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.contractAddress = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.tokenBalance = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.error = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetTokenBalancesRes_TokenBalance>, I>>(
    base?: I,
  ): GetTokenBalancesRes_TokenBalance {
    return GetTokenBalancesRes_TokenBalance.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetTokenBalancesRes_TokenBalance>, I>>(
    object: I,
  ): GetTokenBalancesRes_TokenBalance {
    const message = createBaseGetTokenBalancesRes_TokenBalance();
    message.contractAddress = object.contractAddress ?? "";
    message.tokenBalance = object.tokenBalance ?? "";
    message.error = object.error ?? "";
    return message;
  },
};

function createBaseGetAssetTransfersReq(): GetAssetTransfersReq {
  return {
    fromBlock: undefined,
    toBlock: undefined,
    contractAddresses: [],
    maxCount: 0,
    excludeZeroValue: false,
    category: [],
    pageKey: "",
    transferredTo: "",
  };
}

export const GetAssetTransfersReq = {
  encode(message: GetAssetTransfersReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fromBlock !== undefined) {
      writer.uint32(8).int32(message.fromBlock);
    }
    if (message.toBlock !== undefined) {
      writer.uint32(16).int32(message.toBlock);
    }
    for (const v of message.contractAddresses) {
      writer.uint32(26).string(v!);
    }
    if (message.maxCount !== 0) {
      writer.uint32(32).int32(message.maxCount);
    }
    if (message.excludeZeroValue === true) {
      writer.uint32(40).bool(message.excludeZeroValue);
    }
    writer.uint32(50).fork();
    for (const v of message.category) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.pageKey !== "") {
      writer.uint32(58).string(message.pageKey);
    }
    if (message.transferredTo !== "") {
      writer.uint32(66).string(message.transferredTo);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAssetTransfersReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAssetTransfersReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.fromBlock = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.toBlock = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.contractAddresses.push(reader.string());
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.maxCount = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.excludeZeroValue = reader.bool();
          continue;
        case 6:
          if (tag === 48) {
            message.category.push(reader.int32() as any);

            continue;
          }

          if (tag === 50) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.category.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.pageKey = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.transferredTo = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetAssetTransfersReq>, I>>(base?: I): GetAssetTransfersReq {
    return GetAssetTransfersReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAssetTransfersReq>, I>>(object: I): GetAssetTransfersReq {
    const message = createBaseGetAssetTransfersReq();
    message.fromBlock = object.fromBlock ?? undefined;
    message.toBlock = object.toBlock ?? undefined;
    message.contractAddresses = object.contractAddresses?.map((e) => e) || [];
    message.maxCount = object.maxCount ?? 0;
    message.excludeZeroValue = object.excludeZeroValue ?? false;
    message.category = object.category?.map((e) => e) || [];
    message.pageKey = object.pageKey ?? "";
    message.transferredTo = object.transferredTo ?? "";
    return message;
  },
};

function createBaseGetAssetTransfersRes(): GetAssetTransfersRes {
  return { transfers: [], pageKey: "" };
}

export const GetAssetTransfersRes = {
  encode(message: GetAssetTransfersRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.transfers) {
      GetAssetTransfersRes_Transfer.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    if (message.pageKey !== "") {
      writer.uint32(18).string(message.pageKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAssetTransfersRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAssetTransfersRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transfers.push(GetAssetTransfersRes_Transfer.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.pageKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetAssetTransfersRes>, I>>(base?: I): GetAssetTransfersRes {
    return GetAssetTransfersRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAssetTransfersRes>, I>>(object: I): GetAssetTransfersRes {
    const message = createBaseGetAssetTransfersRes();
    message.transfers = object.transfers?.map((e) => GetAssetTransfersRes_Transfer.fromPartial(e)) || [];
    message.pageKey = object.pageKey ?? "";
    return message;
  },
};

function createBaseGetAssetTransfersRes_Transfer(): GetAssetTransfersRes_Transfer {
  return {
    category: 0,
    blockNum: 0,
    from: "",
    to: "",
    value: "",
    erc721TokenId: "",
    tokenId: "",
    asset: "",
    hash: "",
    rawContract: undefined,
    erc1155Metadata: [],
    chainId: 0,
  };
}

export const GetAssetTransfersRes_Transfer = {
  encode(message: GetAssetTransfersRes_Transfer, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.category !== 0) {
      writer.uint32(8).int32(message.category);
    }
    if (message.blockNum !== 0) {
      writer.uint32(16).int32(message.blockNum);
    }
    if (message.from !== "") {
      writer.uint32(26).string(message.from);
    }
    if (message.to !== "") {
      writer.uint32(34).string(message.to);
    }
    if (message.value !== "") {
      writer.uint32(42).string(message.value);
    }
    if (message.erc721TokenId !== "") {
      writer.uint32(50).string(message.erc721TokenId);
    }
    if (message.tokenId !== "") {
      writer.uint32(58).string(message.tokenId);
    }
    if (message.asset !== "") {
      writer.uint32(66).string(message.asset);
    }
    if (message.hash !== "") {
      writer.uint32(74).string(message.hash);
    }
    if (message.rawContract !== undefined) {
      GetAssetTransfersRes_Transfer_RawContract.encode(message.rawContract, writer.uint32(82).fork()).ldelim();
    }
    for (const v of message.erc1155Metadata) {
      GetAssetTransfersRes_Transfer_Erc1155Metadata.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    if (message.chainId !== 0) {
      writer.uint32(96).int32(message.chainId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAssetTransfersRes_Transfer {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAssetTransfersRes_Transfer();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.category = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.blockNum = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.from = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.to = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.value = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.erc721TokenId = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.tokenId = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.asset = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.hash = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.rawContract = GetAssetTransfersRes_Transfer_RawContract.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.erc1155Metadata.push(GetAssetTransfersRes_Transfer_Erc1155Metadata.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.chainId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetAssetTransfersRes_Transfer>, I>>(base?: I): GetAssetTransfersRes_Transfer {
    return GetAssetTransfersRes_Transfer.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAssetTransfersRes_Transfer>, I>>(
    object: I,
  ): GetAssetTransfersRes_Transfer {
    const message = createBaseGetAssetTransfersRes_Transfer();
    message.category = object.category ?? 0;
    message.blockNum = object.blockNum ?? 0;
    message.from = object.from ?? "";
    message.to = object.to ?? "";
    message.value = object.value ?? "";
    message.erc721TokenId = object.erc721TokenId ?? "";
    message.tokenId = object.tokenId ?? "";
    message.asset = object.asset ?? "";
    message.hash = object.hash ?? "";
    message.rawContract = (object.rawContract !== undefined && object.rawContract !== null)
      ? GetAssetTransfersRes_Transfer_RawContract.fromPartial(object.rawContract)
      : undefined;
    message.erc1155Metadata =
      object.erc1155Metadata?.map((e) => GetAssetTransfersRes_Transfer_Erc1155Metadata.fromPartial(e)) || [];
    message.chainId = object.chainId ?? 0;
    return message;
  },
};

function createBaseGetAssetTransfersRes_Transfer_RawContract(): GetAssetTransfersRes_Transfer_RawContract {
  return { value: "", address: "", decimal: "" };
}

export const GetAssetTransfersRes_Transfer_RawContract = {
  encode(message: GetAssetTransfersRes_Transfer_RawContract, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.value !== "") {
      writer.uint32(10).string(message.value);
    }
    if (message.address !== "") {
      writer.uint32(18).string(message.address);
    }
    if (message.decimal !== "") {
      writer.uint32(26).string(message.decimal);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAssetTransfersRes_Transfer_RawContract {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAssetTransfersRes_Transfer_RawContract();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.value = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.address = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.decimal = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetAssetTransfersRes_Transfer_RawContract>, I>>(
    base?: I,
  ): GetAssetTransfersRes_Transfer_RawContract {
    return GetAssetTransfersRes_Transfer_RawContract.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAssetTransfersRes_Transfer_RawContract>, I>>(
    object: I,
  ): GetAssetTransfersRes_Transfer_RawContract {
    const message = createBaseGetAssetTransfersRes_Transfer_RawContract();
    message.value = object.value ?? "";
    message.address = object.address ?? "";
    message.decimal = object.decimal ?? "";
    return message;
  },
};

function createBaseGetAssetTransfersRes_Transfer_Erc1155Metadata(): GetAssetTransfersRes_Transfer_Erc1155Metadata {
  return { tokenId: "", value: "" };
}

export const GetAssetTransfersRes_Transfer_Erc1155Metadata = {
  encode(message: GetAssetTransfersRes_Transfer_Erc1155Metadata, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tokenId !== "") {
      writer.uint32(10).string(message.tokenId);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAssetTransfersRes_Transfer_Erc1155Metadata {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAssetTransfersRes_Transfer_Erc1155Metadata();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokenId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetAssetTransfersRes_Transfer_Erc1155Metadata>, I>>(
    base?: I,
  ): GetAssetTransfersRes_Transfer_Erc1155Metadata {
    return GetAssetTransfersRes_Transfer_Erc1155Metadata.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAssetTransfersRes_Transfer_Erc1155Metadata>, I>>(
    object: I,
  ): GetAssetTransfersRes_Transfer_Erc1155Metadata {
    const message = createBaseGetAssetTransfersRes_Transfer_Erc1155Metadata();
    message.tokenId = object.tokenId ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

export interface AlchemyService {
  GetNFTs(request: DeepPartial<GetNFTsReq>, metadata?: grpc.Metadata): Promise<GetNFTsRes>;
  GetTokenBalances(request: DeepPartial<GetTokenBalancesReq>, metadata?: grpc.Metadata): Promise<GetTokenBalancesRes>;
  GetAssetTransfers(
    request: DeepPartial<GetAssetTransfersReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetAssetTransfersRes>;
  GetLatestTransfer(
    request: DeepPartial<GetLatestTransferReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetLatestTransferRes>;
  GetWETHBalance(request: DeepPartial<GetWETHBalanceReq>, metadata?: grpc.Metadata): Promise<GetWETHBalanceRes>;
  GetNFTMetadata(request: DeepPartial<GetNFTMetadataReq>, metadata?: grpc.Metadata): Promise<GetNFTMetadataRes>;
  GetTokenMetadata(request: DeepPartial<GetTokenMetadataReq>, metadata?: grpc.Metadata): Promise<GetTokenMetadataRes>;
  GetAllNftTransfers(
    request: DeepPartial<GetAllNftTransfersReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetAllNftTransfersRes>;
}

export class AlchemyServiceClientImpl implements AlchemyService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetNFTs = this.GetNFTs.bind(this);
    this.GetTokenBalances = this.GetTokenBalances.bind(this);
    this.GetAssetTransfers = this.GetAssetTransfers.bind(this);
    this.GetLatestTransfer = this.GetLatestTransfer.bind(this);
    this.GetWETHBalance = this.GetWETHBalance.bind(this);
    this.GetNFTMetadata = this.GetNFTMetadata.bind(this);
    this.GetTokenMetadata = this.GetTokenMetadata.bind(this);
    this.GetAllNftTransfers = this.GetAllNftTransfers.bind(this);
  }

  GetNFTs(request: DeepPartial<GetNFTsReq>, metadata?: grpc.Metadata): Promise<GetNFTsRes> {
    return this.rpc.unary(AlchemyServiceGetNFTsDesc, GetNFTsReq.fromPartial(request), metadata);
  }

  GetTokenBalances(request: DeepPartial<GetTokenBalancesReq>, metadata?: grpc.Metadata): Promise<GetTokenBalancesRes> {
    return this.rpc.unary(AlchemyServiceGetTokenBalancesDesc, GetTokenBalancesReq.fromPartial(request), metadata);
  }

  GetAssetTransfers(
    request: DeepPartial<GetAssetTransfersReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetAssetTransfersRes> {
    return this.rpc.unary(AlchemyServiceGetAssetTransfersDesc, GetAssetTransfersReq.fromPartial(request), metadata);
  }

  GetLatestTransfer(
    request: DeepPartial<GetLatestTransferReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetLatestTransferRes> {
    return this.rpc.unary(AlchemyServiceGetLatestTransferDesc, GetLatestTransferReq.fromPartial(request), metadata);
  }

  GetWETHBalance(request: DeepPartial<GetWETHBalanceReq>, metadata?: grpc.Metadata): Promise<GetWETHBalanceRes> {
    return this.rpc.unary(AlchemyServiceGetWETHBalanceDesc, GetWETHBalanceReq.fromPartial(request), metadata);
  }

  GetNFTMetadata(request: DeepPartial<GetNFTMetadataReq>, metadata?: grpc.Metadata): Promise<GetNFTMetadataRes> {
    return this.rpc.unary(AlchemyServiceGetNFTMetadataDesc, GetNFTMetadataReq.fromPartial(request), metadata);
  }

  GetTokenMetadata(request: DeepPartial<GetTokenMetadataReq>, metadata?: grpc.Metadata): Promise<GetTokenMetadataRes> {
    return this.rpc.unary(AlchemyServiceGetTokenMetadataDesc, GetTokenMetadataReq.fromPartial(request), metadata);
  }

  GetAllNftTransfers(
    request: DeepPartial<GetAllNftTransfersReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetAllNftTransfersRes> {
    return this.rpc.unary(AlchemyServiceGetAllNftTransfersDesc, GetAllNftTransfersReq.fromPartial(request), metadata);
  }
}

export const AlchemyServiceDesc = { serviceName: "alchemy.AlchemyService" };

export const AlchemyServiceGetNFTsDesc: UnaryMethodDefinitionish = {
  methodName: "GetNFTs",
  service: AlchemyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNFTsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetNFTsRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AlchemyServiceGetTokenBalancesDesc: UnaryMethodDefinitionish = {
  methodName: "GetTokenBalances",
  service: AlchemyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetTokenBalancesReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetTokenBalancesRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AlchemyServiceGetAssetTransfersDesc: UnaryMethodDefinitionish = {
  methodName: "GetAssetTransfers",
  service: AlchemyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAssetTransfersReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetAssetTransfersRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AlchemyServiceGetLatestTransferDesc: UnaryMethodDefinitionish = {
  methodName: "GetLatestTransfer",
  service: AlchemyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetLatestTransferReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetLatestTransferRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AlchemyServiceGetWETHBalanceDesc: UnaryMethodDefinitionish = {
  methodName: "GetWETHBalance",
  service: AlchemyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetWETHBalanceReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetWETHBalanceRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AlchemyServiceGetNFTMetadataDesc: UnaryMethodDefinitionish = {
  methodName: "GetNFTMetadata",
  service: AlchemyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetNFTMetadataReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetNFTMetadataRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AlchemyServiceGetTokenMetadataDesc: UnaryMethodDefinitionish = {
  methodName: "GetTokenMetadata",
  service: AlchemyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetTokenMetadataReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetTokenMetadataRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const AlchemyServiceGetAllNftTransfersDesc: UnaryMethodDefinitionish = {
  methodName: "GetAllNftTransfers",
  service: AlchemyServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAllNftTransfersReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetAllNftTransfersRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1000;
  const nanos = (date.getTime() % 1000) * 1000000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1000;
  millis += (t.nanos || 0) / 1000000;
  return new Date(millis);
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
