import { FC, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { isBeasyNFT } from 'utils';

import { Rarity } from 'components/Rarity';
import { ImageView } from 'components/image/Avatar/image/View';

import { Path } from 'constants/enumTypes';

import { useNftMetadataQuery } from 'hooks/useNftMetadataQuery';

import { SubHeaderCard } from './SubHeaderCard';
import css from './index.module.css';

interface Props {
  tokenUri: string;
  tokenId?: string;
  contractAddress?: string;
}

export const NftCard: FC<Props> = ({ tokenUri, tokenId, contractAddress }) => {
  const navigate = useNavigate();

  const { data: nftMetadata } = useNftMetadataQuery(tokenUri);

  const handleLink = useCallback(() => {
    if (isBeasyNFT(tokenUri)) {
      tokenId && navigate(`${Path.Nfts}/${tokenId}`);
    } else {
      tokenId && contractAddress && navigate(`${Path.Nfts}/${contractAddress}/${tokenId}`);
    }
  }, [contractAddress, tokenId, navigate, tokenUri]);

  return (
    <article onClick={handleLink} className={css.container}>
      <figure className={css.thumbnail}>
        <ImageView src={nftMetadata?.image} />
      </figure>

      <ImageView className={css.borderRadius} src={nftMetadata?.image} />

      <SubHeaderCard header={nftMetadata?.name!} subHeader={'NFT'} />
      {nftMetadata ? <Rarity quantity={nftMetadata?.beasy?.quantity ?? 0} /> : <Skeleton width={15} />}
    </article>
  );
};
