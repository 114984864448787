import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorIsLoading, updateFlags } from 'redux/reducers/flags';
import { getCollectibleIdFromTokenId } from 'utils';

import Button from 'components/Button';
import { ErrorMessageQueryLoading } from 'components/Messages/ErrorMessageQueryLoading';
import { Modal } from 'components/Modal';
import { OwnershipAuthenticator } from 'components/OwnershipAuthenticator';

import { Path } from 'constants/enumTypes';

import { getAssetHistory, getCollectibleById } from 'services/apiServices/collectiblesService';

import css from './index.module.css';

export const BeasyNftHistoryPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectorIsLoading);
  const { tokenId } = useParams();

  const collectibleId = getCollectibleIdFromTokenId(tokenId ?? '');

  const collectibleQuery = useQuery(['getCollectibleById', collectibleId], () => {
    return getCollectibleById(collectibleId);
  });

  const collectibleHistoryQuery = useQuery(['getAssetHistory', tokenId], () => {
    return getAssetHistory(tokenId ?? '');
  });

  useEffect(() => {
    if (collectibleHistoryQuery.isLoading && !isLoading) {
      dispatch(updateFlags({ isLoading: true }));
    }
    if (!collectibleHistoryQuery.isLoading && isLoading) {
      dispatch(updateFlags({ isLoading: false }));
    }
    // eslint-disable-next-line
  }, [collectibleHistoryQuery.isLoading]);

  return (
    <Modal>
      <div className={css.container}>
        <div className={css.containerHeader}>
          <h2 className={css.headerPage}>Ownership Timeline</h2>
          {collectibleQuery.isSuccess && collectibleQuery.data?.name && (
            <h1 className={css.nameCollectible}>{collectibleQuery.data?.name}</h1>
          )}
        </div>
        {collectibleHistoryQuery.isError && (
          <ErrorMessageQueryLoading
            message="Could not read collectible history"
            onRetry={() => collectibleHistoryQuery.refetch()}
          />
        )}
        {collectibleHistoryQuery.isSuccess && (
          <div className={css.containerHistory}>
            <OwnershipAuthenticator history={collectibleHistoryQuery.data.history} />
          </div>
        )}
        <Button className={css.button} onClick={() => navigate(Path.Nfts)}>
          OK
        </Button>
      </div>
    </Modal>
  );
};
