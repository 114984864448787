import { IconProps } from '../types';

const ASPECT_RATIO = 1.5385;

export const EthereumIcon = ({ className, size = 40, onClick }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
    width={size}
    height={size / ASPECT_RATIO}
    fill="none"
    viewBox="0 0 40 26"
  >
    <rect x="0.25" y="0.25" width="39.5" height="25.5" rx="1.75" fill="white" />
    <path d="M19.9982 3L19.8672 3.45564V16.6771L19.9982 16.8109L25.9964 13.1832L19.9982 3Z" fill="#343434" />
    <path d="M19.9982 3L14 13.1832L19.9982 16.811V10.3937V3Z" fill="#8C8C8C" />
    <path d="M19.9977 17.9729L19.9238 18.065V22.7748L19.9977 22.9954L25.9994 14.347L19.9977 17.9729Z" fill="#3C3C3B" />
    <path d="M19.9982 22.9955V17.9729L14 14.347L19.9982 22.9955Z" fill="#8C8C8C" />
    <path d="M19.998 16.8109L25.9962 13.1832L19.998 10.3937V16.8109Z" fill="#141414" />
    <path d="M14 13.1833L19.9981 16.811V10.3938L14 13.1833Z" fill="#393939" />
    <rect
      x="0.25"
      y="0.25"
      width="39.5"
      height="25.5"
      rx="1.75"
      stroke="#B2B2B2"
      strokeWidth="0.5"
      style={{ mixBlendMode: 'multiply' }}
    />
  </svg>
);
