import * as ethers from 'ethers';

export class WalletFactory {
  static fromPrivateKey(privateKey: string): ethers.Wallet {
    return new ethers.Wallet(privateKey);
  }

  static fromMnemonic(mnemonic: string): ethers.Wallet {
    return ethers.Wallet.fromMnemonic(mnemonic);
  }
}
