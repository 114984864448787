import { getSdkError } from '@walletconnect/utils';
import { Fragment, useEffect, useState } from 'react';

import Button, { ButtonColorScheme } from 'components/Button';

import { web3wallet } from 'services/WalletConnectV2';

interface IProps {
  topic: string;
  logo?: string;
  name?: string;
  url?: string;
  onClose: () => void;
}

export default function SessionPage({ logo, name, url, topic , onClose}: IProps) {
  const session = web3wallet.getActiveSessions()[topic];
  if  (!session)  { 
    onClose()
  }
  // Get necessary data from session
  const expiryDate = new Date(session.expiry * 1000);

  // Handle deletion of a session
  async function onDeleteSession() {
    await web3wallet.disconnectSession({ topic, reason: getSdkError('USER_DISCONNECTED') });
  }

  return (
    <div>
      <div style={{ flex: 1 }}>
        <h5 style={{ marginLeft: '$9' }}>Expiry:</h5>
        <h5 style={{ marginLeft: '$9' }}>{expiryDate.toDateString()}</h5>
      </div>
      <Button color={ButtonColorScheme.ERROR} onClick={onDeleteSession}>
        DELETE
      </Button>
    </div>
  );
}
