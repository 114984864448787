import { filterList } from 'pages/friends/FriendPage/utils';
import { useEffect, useMemo, useState } from 'react';
import { BsCheck } from 'react-icons/bs';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { setTokenIdAndCollection } from 'redux/reducers/transfer';

import Button, { ButtonColorScheme } from 'components/Button';
import { EmptyListMessageText } from 'components/Messages/EmptyListMessage';
import { ErrorMessageQueryLoading } from 'components/Messages/ErrorMessageQueryLoading';
import { SmallNftCard } from 'components/SmallNftCard';
import { Search } from 'components/inputs/Search';

import { Path } from 'constants/enumTypes';

import { useGetAlchemyNFTs } from 'hooks/useGetAlchemyNFTs';

import { GetNFTsRes_OwnedNft } from 'protobuf/lib/alchemy';

import css from './index.module.css';
import { selectorIsLoading, updateFlags } from 'redux/reducers/flags';
import { useNetwork } from 'hooks/useNetwork';

export const NftDesktopDetails = () => {
  const isLoading = useAppSelector(selectorIsLoading);
  const [selected, setSelected] = useState(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { currentNetwork } = useNetwork();
  const [selectedCollectible, setSelectedCollectible] = useState<GetNFTsRes_OwnedNft>();

  const handleSetSelectedCollectible = (nft: GetNFTsRes_OwnedNft, i: any) => {
    setSelected(i);
    setSelectedCollectible(nft);
  };
  const handleContinue = () => {
    if (selectedCollectible && selectedCollectible.contract && selectedCollectible.id) {
      const { address: contractAddress } = selectedCollectible.contract;
      const { tokenId } = selectedCollectible.id;

      const tokenType = selectedCollectible?.id?.tokenMetadata?.tokenType?.toLowerCase();
      
      dispatch(setTokenIdAndCollection({ tokenId, collection: contractAddress, chainId: Number(currentNetwork!.chainId!) }));

      navigate(generatePath(Path.TransferDesktopRecipient, { contractAddress, tokenId, tokenType: tokenType === undefined ? '' : tokenType }));
    }
  };

  const cancel = () => {
    navigate(generatePath(Path.Nfts));
  };

  const [searchInput, setSearchInput] = useState('');

  const nftsQuery = useGetAlchemyNFTs({ includeBeasy: true });

  useEffect(() => {
    if (!isLoading && nftsQuery.isLoading) {
      dispatch(updateFlags({ isLoading: true }));
    }
    if (isLoading && !nftsQuery.isLoading) {
      dispatch(updateFlags({ isLoading: false }));
    }
  }, [isLoading, nftsQuery.isLoading, dispatch]);

  const filteredNfts = useMemo(() => {
    const nfts = nftsQuery.isSuccess ? nftsQuery.data : [];
    return searchInput.length === 0 ? nfts : filterList('title', searchInput, nfts);
  }, [nftsQuery.data, nftsQuery.isSuccess, searchInput]);

  return (
    <div className={css.bodyHeight}>
      <div className={css.mainItems}>
        <div className={css.containerHeader}>
          <h2 className={css.header}>Select the NFT you want to transfer</h2>
        </div>
        <div className={css.containerSearch}>
          <Search onChange={setSearchInput} />
        </div>

        {nftsQuery.isSuccess && (
          <div className={css.containerList}>
            <ul className={css.list}>
              {filteredNfts.length === 0 ? (
                <EmptyListMessageText message="The list is empty" />
              ) : (
                filteredNfts.map((nft, i) => (
                  <li key={i} onClick={() => handleSetSelectedCollectible(nft, i)}>
                    {nft.contract?.address && nft.id?.tokenId && (
                      <>
                        <SmallNftCard contractAddress={nft.contract.address} tokenId={nft.id.tokenId} />
                        {selected === i && (
                          <span className={css.selectedItem}>
                            <BsCheck style={{ color: 'white' }} />
                          </span>
                        )}
                      </>
                    )}
                  </li>
                ))
              )}
            </ul>
          </div>
        )}

        {nftsQuery.isError && <ErrorMessageQueryLoading onRetry={() => nftsQuery.refetch()} />}
      </div>

      <div className={css.footerContainer}>
        <div className={css.cancelButton}>
          <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={cancel}>
            Cancel
          </Button>
        </div>

        <div className={css.continueButton}>
          <div className={css.previousButton}>
            <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={() => navigate(-1)}>
              Previous Step
            </Button>
          </div>

            <div className={css.previousButton}>
              <Button disabled={!selectedCollectible} onClick={handleContinue}>Continue</Button>
            </div>
          
        </div>
      </div>
    </div>
  );
};
