import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { TransferProgressBar } from 'components/TransferProgressBar';

import { CryptoDesktopDetails } from './CryptoDetails';
import { NftDesktopDetails } from './NftDetails';
import css from './index.module.css';

const TABS = ['1. NFT/Crypto', '2. Transaction details', '3. Choose Recipient', '4. Review'];

export const TransferDetails = () => {
  const [selectedTabIndex] = useState(1);
  const { type } = useParams();

  return (
    <>
      <div className={css.container}>
        <TransferProgressBar tabs={TABS} selectedTabIndex={selectedTabIndex} />
        {type === '0' ? (
          <>
            <NftDesktopDetails />
          </>
        ) : (
          <>
            <CryptoDesktopDetails />
          </>
        )}
      </div>
    </>
  );
};
