import { IpfsFile } from 'beasy-fe-commons';
import React, { useCallback } from 'react';
import { isSupportedAudio, isSupportedVideo } from 'utils';

import { AudioPlayer } from 'components/AudioPlayer';
import { VideoPlayer } from 'components/VideoPlayer';
import { LoadingImage } from 'components/loading/Image';

import { getIpfsUrl } from 'services/apiServices/ipfs';

import css from './index.module.css';

interface Props {
  ipfsFile: IpfsFile;
  onClick: (ipfsUrl: string) => void;
}

export const GalleryItem = ({ ipfsFile, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick(getIpfsUrl(ipfsFile.cid));
  }, [ipfsFile, onClick]);

  if (isSupportedVideo(ipfsFile.name)) {
    return <VideoPlayer mediaItem={ipfsFile} />;
  }

  if (isSupportedAudio(ipfsFile.name)) {
    return <AudioPlayer mediaItem={ipfsFile} />;
  }

  return <LoadingImage src={getIpfsUrl(ipfsFile.cid)} className={css.image} onClick={handleClick} />;
};
