import React, { useMemo } from 'react';

import { JsonView } from './JsonView';
import css from './Message.module.css';

interface MessageProps {
  json: string;
}

export const Message = ({ json }: MessageProps) => {
  const data = useMemo(() => JSON.parse(json), [json]);

  return (
    <div className={css.message}>
      <h3>Domain:</h3>
      <JsonView data={data.domain} />

      <h3>Message:</h3>
      <JsonView data={data.message} />
    </div>
  );
};
