//import { captureException, Severity } from '@sentry/react';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { store } from 'redux/configStore';
import { updateAccountInfo } from 'redux/reducers/accountInfo';
import { updateAlertModal } from 'redux/reducers/alert';
import { chainIdSelector } from 'redux/reducers/blockchain';
import { init as initTx } from 'redux/reducers/tx';
import { walletPersistenceService, walletService, RedirectAfterPinService } from 'services';

import { AlertType } from 'constants/enumTypes';

import { InvalidPasswordError } from 'services/WalletPersistenceService';
import { makeAuthMeta } from 'services/auth';
import { subscriberClient } from 'services/subscriber';

import { initIpfsApi } from '../services/apiServices/ipfs';
import { useCheckPrivacyPolicy } from './useCheckPrivacyPolicy';
import { providerService } from '../services/index';


export const usePin = () => {
  const checkPrivacyPolicy = useCheckPrivacyPolicy();
  const queryClient = useQueryClient();
  const chainId = useSelector(chainIdSelector);
  const navigate = useNavigate();

  return useCallback(
    async (password: string) => {
      try {
        const [storedWallet] = await walletPersistenceService.getStoredWallets();

        if (storedWallet) {
         
          store.dispatch(
            updateAlertModal({
              isVisibleAlert: true,
              alertType: AlertType.PinLock,
            }),
          );

          const wallet = await walletPersistenceService.getWallet(storedWallet.id, password);

          if (wallet) {
            walletService.setWallet(wallet);
            await providerService.setProvider(137)
            await subscriberClient.start();

            await store.dispatch(initTx());

            const { jwt } = await makeAuthMeta();
            initIpfsApi(jwt);

            // Must not use `useDispatch` here because `useAuthInit` is used in `App` outside of `Provider`
            store.dispatch(updateAccountInfo({ ...walletService.getAccount(), isLoggedIn: true }));

            setTimeout(() => {
              RedirectAfterPinService.redirectIfNecessary(navigate);
              queryClient.refetchQueries('balance').then();
              checkPrivacyPolicy();
            });
          }
        }

        //captureException(new Error('Could not get accountInfo.'), { level: Severity.Debug });
      } catch (error) {
        if (error instanceof InvalidPasswordError) {
          throw error;
        }

        console.log(error);
        //captureException(error);
      }
    },
    [chainId, navigate, queryClient, checkPrivacyPolicy],
  );
};
