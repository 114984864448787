import { SentTx } from 'types';

import { LocalStorageService } from './LocalStorageService';

const SentTxPersistenceKey = 'sentTxs';

export class SentTxPersistenceService {
  static loadTransactions(): Record<string /* uuid */, SentTx> {
    return LocalStorageService.getItem<Record<string /* uuid */, SentTx>>(SentTxPersistenceKey) ?? {};
  }

  static flushTransactions(sentTxs: Record<string /* uuid */, SentTx>): void {
    try {
      LocalStorageService.setItem(SentTxPersistenceKey, sentTxs);
    } catch (error) {
      //
    }
  }
}
