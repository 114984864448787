import beasyLogo from 'assets/images/beasyLogo.png';
import beasyLogo2x from 'assets/images/beasyLogo@2x.png';
import beasyLogo3x from 'assets/images/beasyLogo@3x.png';

import css from './index.module.css';

export const SplashPage = () => {
  return (
    <div className={css.splash}>
      <img src={beasyLogo} alt="beasyLogo" srcSet={`${beasyLogo} 1x, ${beasyLogo2x} 2x, ${beasyLogo3x} 3x`} />
    </div>
  );
};
