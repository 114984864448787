import React from 'react';

import css from './index.module.css';

interface Props {
  label: string;
  children: React.ReactNode;
}

export const FieldInfo: React.FC<Props> = ({ label, children }) => {
  return (
    <div className={css.container}>
      <h2 className="label">{label}</h2>
      <div className="subText">{children}</div>
    </div>
  );
};
