//import { captureException } from '@sentry/react';
import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorAlert, updateAlertModal } from 'redux/reducers/alert';
import {  walletService } from 'services';

import { showGenericSuccessToast } from 'components/AppToasts';
import Button, { ButtonColorScheme } from 'components/Button';
import { Modal } from 'components/Modal';

import { web3wallet } from 'services/WalletConnectV2';

import css from './index.module.css';

export const ApprovePersonalSign = () => {
  const dispatch = useAppDispatch();
  const { sessionData } = useAppSelector(selectorAlert);
  const { topic, params, id } = sessionData;
  const { request } = params;
  const message = request.params[0];

  const onPressRejectEventHandle = useCallback(() => {
    dispatch(
      updateAlertModal({
        isVisibleAlert: false,
      }),
    );
   // walletConnectService.rejectRequest(sessionData, 'Rejected by user');
  }, [dispatch, sessionData]);

  const onPressAcceptEventHandle = useCallback(async () => {
    try {
      const result = await walletService.signMessage(message);

      if (result) {
        const response = { id, result, jsonrpc: '2.0' };
        await web3wallet.respondSessionRequest({
          topic,
          response,
        });
        showGenericSuccessToast('Successfully signed message');
      } else {
      //  walletConnectService.rejectRequest(sessionData, 'Could not sign message');
      }

      dispatch(updateAlertModal({ isVisibleAlert: false }));
    } catch (error) {
     // walletConnectService.rejectRequest(sessionData, 'Could not sign message');
      //captureException(error);
    }
  }, [message, sessionData, dispatch]);

  return (
    <Modal className={css.modal}>
      <div className={css.container}>
        <h2 className={css.titleText}>Sign Message</h2>

        <p className={css.messageText}>{message}</p>

        <Button className={css.button} onClick={onPressAcceptEventHandle}>
          Sign
        </Button>
        <Button className={css.button} colorScheme={ButtonColorScheme.SECONDARY} onClick={onPressRejectEventHandle}>
          Reject
        </Button>
      </div>
    </Modal>
  );
};
