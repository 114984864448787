import { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import Button from 'components/Button';

import { Path } from 'constants/enumTypes';

import css from './index.module.css';
import { TransferProgressBar } from 'components/TransferProgressBar';

const tabs = [
  { id: 'NFT', data: 'nft' },
  { id: 'Crypto', data: 'crypto' },
];

const TABS = ['1. NFT/Crypto', '2. Transaction details', '3. Choose Recipient', '4. Review'];

export enum ButtonColorScheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ERROR = 'error',
}

interface PropsButtons {
  activeTab?: number;
  buttons?: any;
  setActiveTab?: any;
}

interface Props {
  activeTab?: number;
  onNextStepAuth?: () => void;
  onPrevStepAuth?: () => void;
}

export const TransferType = ({ onNextStepAuth, onPrevStepAuth }: Props) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [selectedTabIndex] = useState(0);

  const nextStep = () => {
    if (activeTab !== -1) {
      const type = JSON.stringify(activeTab);
      navigate(generatePath(Path.TransferDesktopDetails, { type }));
    }
  };

  const cancel = () => {
    navigate(generatePath(Path.Nfts));
  };

  const ButtonsSelect = ({ activeTab = -1, buttons, setActiveTab }: PropsButtons) => {
    return (
      <div className={css.buttonsContainer}>
        {buttons.map((btn: any, i: any) => {
          const isActive = i === activeTab;
          return (
            <div className={css.button}>
              <Button
                key={btn.id}
                style={{ backgroundColor: isActive ? '#0066ff' : '#2f3542', height: 200 }}
                className={`${btn.id} ${isActive && activeTab}`}
                onClick={() => setActiveTab(i)}
              >
                {btn.id}
              </Button>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <ul className={css.container}>
      <TransferProgressBar className={css.tabs} tabs={TABS} selectedTabIndex={selectedTabIndex} />
      <div className={css.bodyHeight}>
        <ButtonsSelect activeTab={activeTab} buttons={tabs} setActiveTab={setActiveTab} />
      </div>
      <div className={css.footerContainer}>
        <div className={css.cancelButton}>
          <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={cancel}>
            Cancel
          </Button>
        </div>
        <div className={css.continueButton}>
          <div>
            <Button onClick={nextStep}>Continue</Button>
          </div>
        </div>
      </div>
    </ul>
  );
};
