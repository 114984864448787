import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/configStore';
import { selectorAccountInfo } from 'redux/reducers/accountInfo';

import Button from 'components/Button';
import { FriendList } from 'components/FriendList';
import { LandingHeader } from 'components/Header/LandingHeader';

import { Path } from 'constants/enumTypes';

import { useFriendLink } from 'hooks/useFriendLink';

import { Friend } from 'protobuf/lib/friend';

import css from './index.module.css';
import { PageHeader } from 'components/page';

export const FriendPage = () => {
  const navigate = useNavigate();

  const { address } = useAppSelector(selectorAccountInfo);

  const handleFriendClick = useCallback(
    (friend: Friend) => {
      navigate(`${Path.Friends}/${friend.address}`);
    },
    [navigate],
  );

  const handleFriendLink = useFriendLink();

  return (
    <>
      <div className={css.containerWallet}>
        <LandingHeader />

        <div className={css.containerHeader}>
          <h1
            className="pageTitle"
            title="From your Friends page, you will be able to view all of your friends. In order to add a BEASY Wallet holder
            to your friends list, you must press on their Friend Link. In order to copy your friend link, press the
            &ldquo;Friend Link&rdquo; button."
          >
            My Friends
          </h1>
        </div>

        <section className={css.content}>
          <FriendList address={address} onClickFriend={handleFriendClick} />
          <div className={css.footer}>
            <Button onClick={handleFriendLink}>Friend Link</Button>
          </div>
        </section>
      </div>

      <div className={css.containerDesktop}>
        <div className={css.containerHeader_desktop}>
          <div className={css.header}>
            <PageHeader title="Friends"></PageHeader>
          </div>
          <div className={css.footer}>
            <Button onClick={handleFriendLink}>Friend Link</Button>
          </div>
        </div>

        <section className={css.content}>
          <FriendList address={address} onClickFriend={handleFriendClick} />
        </section>
      </div>
    </>
  );
};
