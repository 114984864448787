import React from 'react';
import { toast } from 'react-toastify';

import { InputErrorMessage } from '../Messages/InputErrorMessage';
import { MessageWithIcon } from '../Messages/MessageWithIcon';
import { TradingEventToast, Props as TradingEventToastProps } from './TradingEventToast';

export const showGenericTradingEventToast = (props: TradingEventToastProps) => {
  toast(<TradingEventToast {...props} />);
};

export const showGenericSuccessToast = (message: string) => {
  toast(<MessageWithIcon message={message} />);
};

export const showGenericErrorToast = (message: string) => {
  toast(<InputErrorMessage message={message} />);
};
