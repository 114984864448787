import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import beasyLightText from 'assets/images/beasy-light-text.png';
import beasyLightText2x from 'assets/images/beasy-light-text@2x.png';
import beasyLightText3x from 'assets/images/beasy-light-text@3x.png';

import Button from 'components/Button';
import { WebVersion } from 'components/NFTLogo';

import { Path } from 'constants/enumTypes';

import css from './index.module.css';

export const StartPage = () => {
  const navigate = useNavigate();
  const handleRestoreWalletClick = useCallback(() => navigate(Path.SignIn), [navigate]);
  const handleCreateWalletClick = useCallback(() => navigate(Path.SignUp), [navigate]);

  return (
    <section className={css.container}>
      <div className={css.container__info}>
        <img
          src={beasyLightText}
          alt="beasyLogo"
          srcSet={`${beasyLightText} 1x, ${beasyLightText2x} 2x, ${beasyLightText3x} 3x`}
        />
        <div className={css.container__info__titleContainer}>
          <span className={css.container__info__titleContainer__title}>Sign Up Today!</span>
          <span className={css.container__info__titleContainer__subTitle}>
            Create a username and enter your email address to set up your account to start buying, selling, and
            collecting NFTs.
          </span>
        </div>
        <div className={css.container__info__buttonContainer}>
          <Button onClick={handleCreateWalletClick}>Create account</Button>
          <button className={css.container__info__buttonContainer__logInButton} onClick={handleRestoreWalletClick}>
            I already have an account
          </button>
        </div>
      </div>
      <WebVersion />
    </section>
  );
};
