import { useCallback } from 'react';
import { useAppDispatch } from 'redux/configStore';
import { updateAlertModal } from 'redux/reducers/alert';

import { AlertType } from 'constants/enumTypes';

import { getCurrencyPrivacyPolicyDate, getPrivacyPolicyDate } from 'services/privacyPolicy';

export const useCheckPrivacyPolicy = () => {
  const dispatch = useAppDispatch();

  return useCallback(async () => {
    const currencyPrivacyPolicyDate = getCurrencyPrivacyPolicyDate();
    const newPrivacyPolicyDate = await getPrivacyPolicyDate();

    if (newPrivacyPolicyDate) {
      if (currencyPrivacyPolicyDate !== newPrivacyPolicyDate) {
        dispatch(updateAlertModal({ isVisibleAlert: true, alertType: AlertType.PrivacyPolicyUpdate }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
