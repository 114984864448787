import { grpc } from '@improbable-eng/grpc-web';
//import { captureException } from '@sentry/react';

import {
  GetAssetHistoryRes,
  GrpcWebImpl,
  CollectibleServiceClientImpl,
  GetSharedHistoryRes,
  GetHistoryRes,
  Collectible,
} from 'protobuf/lib/collectibleService';

import { environment } from 'environment';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const collectibleServiceClient = new CollectibleServiceClientImpl(rpc);

export const getCollectibleWithIpfsByOwner = async (address: string) => {
  try {
    const response = await collectibleServiceClient.GetCollectiblesByOwner({ address });

    return {
      purchased: response.collectiblesPurchased,
      created: response.collectiblesCreator,
    };
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

export const getCollectibleById = async (collectibleId: string): Promise<Collectible> => {
  try {
    const response = await collectibleServiceClient.GetCollectibleById({ collectibleId });

    if (!response.collectible) {
      throw new Error(`Could not find collectible with id ${collectibleId}`);
    }

    return response.collectible;
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

export const getAssetHistory = async (tokenId: string): Promise<GetAssetHistoryRes> => {
  try {
    return await collectibleServiceClient.GetAssetHistory({ tokenId });
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

export const getHistory = async (address: string): Promise<GetHistoryRes> => {
  try {
    return await collectibleServiceClient.GetHistory({ address });
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

export const getSharedHistory = async (address: string, friendAddress: string): Promise<GetSharedHistoryRes> => {
  try {
    return await collectibleServiceClient.GetSharedHistory({ address, friendAddress });
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

export interface CollectibleWithToken extends Collectible {
  tokenId: string;
}

export const getCollectibleWithTokens = (collectible: Collectible): CollectibleWithToken[] => {
  return collectible.tokens.map(token => ({ ...collectible, tokenId: token.tokenId }));
};

export const getFirstCollectibleWithToken = (collectible: Collectible): CollectibleWithToken => {
  return { ...collectible, tokenId: collectible.tokens[0].tokenId };
};

export const getCollectibleWithTokenById = (collectible: Collectible, tokenId: string): CollectibleWithToken => ({
  ...collectible,
  tokenId,
});
