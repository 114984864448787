import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import { Modal } from 'components/Modal';

import css from './index.module.css';

export const HelpSuccessModal = () => {
  const navigate = useNavigate();
  return (
    <Modal>
      <div className={css.container}>
        <h1>Your issue has been successfully submitted to BEASY Customer Support.</h1>
        <p>We apologize for the inconvenience and will look to solve your problems as quickly as possible.</p>
        <Button onClick={() => navigate(-1)}>OK</Button>
      </div>
    </Modal>
  );
};
