import { useCallback, useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorAccountInfo, updateAccountInfo } from 'redux/reducers/accountInfo';
import { updateAlertModal } from 'redux/reducers/alert';
import { selectorIsLoading, selectorIsSessionConnected, updateFlags } from 'redux/reducers/flags';

import avatarPlaceholder from 'assets/images/avatar-placeholder.png';
import edit from 'assets/images/edit.png';
import edit2x from 'assets/images/edit@2x.png';
import edit3x from 'assets/images/edit@3x.png';

import Button, { ButtonColorScheme } from 'components/Button';
import { LandingHeader } from 'components/Header/LandingHeader';
import { LockArea } from 'components/LockArea';
import { TopTabBar } from 'components/TopTabBar';
import { UserInfo } from 'components/UserInfo';
import { TextAreaInputWithCopy } from 'components/inputs/TextAreaInputWithCopy';
import { PageArea, PageHeader } from 'components/page';

import { AlertType } from 'constants/enumTypes';

import { getIpfsUrl } from 'services/apiServices/ipfs';
import { getCurrentUserProfile } from 'services/userProfile';

import { AboutPage } from '../AboutPage';
import { CustomerSupportPage } from '../CustomerSupportPage';
import { LogoutConfirmationDialog } from '../ProfilePage/LogoutConfirmationDialog';
import { AccountDetails } from './AccountDetails';
import { Payments } from './PaymentsBalance';
import css from './index.module.css';

const TABS = ['Account details', 'Balance', 'Customer Support', 'About'];

export const AccountPage = () => {
  const isLoading = useAppSelector(selectorIsLoading);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const dispatch = useAppDispatch();
  const [isLogoutConfirmationShown, toggleIsLogoutConfirmationShown] = useReducer((isShow: boolean) => !isShow, false);
  const isSessionConnected = useAppSelector(selectorIsSessionConnected);

  const { address, mnemonicPhrase } = useAppSelector(selectorAccountInfo);
  const getCurrentUserQuery = useQuery('getCurrentUserProfile', getCurrentUserProfile);

  useEffect(() => {
    if (!isLoading && getCurrentUserQuery.isLoading) {
      dispatch(updateFlags({ isLoading: true }));
    }
    if (isLoading && !getCurrentUserQuery.isLoading) {
      dispatch(updateFlags({ isLoading: false }));
    }
  }, [isLoading, getCurrentUserQuery.isLoading, dispatch]);

  const handleDisconnect = useCallback(() => {
    dispatch(
      updateAlertModal({
        isVisibleAlert: true,
        alertType: AlertType.Disconnect,
      }),
    );
  }, [dispatch]);

  const handleOpen = useCallback(() => {
    dispatch(updateAlertModal({ isVisibleAlert: true, alertType: AlertType.Avatar }));
  }, [dispatch]);

  const handleOpenPin = useCallback(() => {
    dispatch(
      updateAlertModal({
        isVisibleAlert: true,
        alertType: AlertType.PinForMnemonic,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(updateAccountInfo({ mnemonicPhrase: '' }));
    };
  }, [dispatch]);

  return (
    <>
      <div className={css.containerMobile}>
        <LandingHeader isGoBack={true} />
        <h1 className="pageTitle">Account</h1>
        <section className={css.content}>
          <UserInfo
            avatarCid={getCurrentUserQuery.data?.avatarCid ?? ''}
            nickname={getCurrentUserQuery.data?.nickname ?? ''}
            isEditable={true}
          />

          {mnemonicPhrase ? (
            <TextAreaInputWithCopy
              label="Mnemonic Phrase"
              value={mnemonicPhrase}
              successMessage="Mnemonic phrase copied"
            />
          ) : (
            <LockArea label="Mnemonic Phrase" message="Tap to see the mnemonic phrase" onClick={handleOpenPin} />
          )}

          <TextAreaInputWithCopy label="Address" value={address} successMessage="Address copied" />

          {isSessionConnected && (
            <Button className={css.walletButton} colorScheme={ButtonColorScheme.ERROR} onClick={handleDisconnect}>
              Disconnect from Website
            </Button>
          )}
        </section>
      </div>
      <div className={css.containerDesktop}>
        <PageArea>
          {getCurrentUserQuery.isSuccess && (
            <PageHeader
              title={getCurrentUserQuery.data?.nickname ?? ''}
              className={css.boxRightContentHeading}
            ></PageHeader>
          )}

          {getCurrentUserQuery.isSuccess && getCurrentUserQuery.isSuccess && (
            <>
              <div className={css.allAccountInfo}>
                <div className={css.avatarDiv} onClick={handleOpen} title={'Click to edit'}>
                  <img
                    className={css.profileImage}
                    src={getIpfsUrl(getCurrentUserQuery.data?.avatarCid) || avatarPlaceholder}
                    alt="avatar"
                  />
                  <div className={css.editProfilePic}>
                    <img
                      className={css.editIcon}
                      src={edit}
                      alt="Edit"
                      srcSet={`${edit} 1x, ${edit2x} 2x, ${edit3x} 3x`}
                    />
                  </div>
                </div>

                <TopTabBar
                  className={css.tabs}
                  tabs={TABS}
                  selectedTabIndex={selectedTabIndex}
                  onSelectedTabIndexChange={setSelectedTabIndex}
                />

                {selectedTabIndex === 0 && <AccountDetails />}
                {selectedTabIndex === 1 && <Payments />}
                {selectedTabIndex === 2 && <CustomerSupportPage />}
                {selectedTabIndex === 3 && <AboutPage />}
              </div>
              <div className={css.logOutButton}>
                <Button
                  className={css.button}
                  colorScheme={ButtonColorScheme.SECONDARY}
                  onClick={toggleIsLogoutConfirmationShown}
                >
                  Log out
                </Button>
                {isLogoutConfirmationShown && <LogoutConfirmationDialog onCancel={toggleIsLogoutConfirmationShown} />}
              </div>
            </>
          )}
        </PageArea>
      </div>
    </>
  );
};
