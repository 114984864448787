import { grpc } from '@improbable-eng/grpc-web';
//import { captureException } from '@sentry/react';

import { GrpcWebImpl, FrontstoreServiceClientImpl } from 'protobuf/lib/frontstore';

import { environment } from 'environment';

import { makeAuthMeta } from './auth';
import { walletService } from './index';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const frontStoreServiceClient = new FrontstoreServiceClientImpl(rpc);

export const checkFrontstore = async (applicationToken: string): Promise<boolean> => {
  try {
    const res = await frontStoreServiceClient.CheckFrontstore({ applicationToken });

    if (res.status === 'success') {
      return true;
    }
  } catch (error) {
    //captureException(error);
    throw error;
  }

  return false;
};

export const authFrontstore = async (applicationToken: string): Promise<string | null> => {
  try {
    const proof = await makeAuthMeta();
    const meta = new grpc.Metadata({ ...proof });
    const ethAddress = walletService.getAddress();
    const res = await frontStoreServiceClient.AuthFrontstore({ applicationToken, ethAddress }, meta);

    return res.jwt;
  } catch (error) {
    //captureException(error);
    throw error;
  }
};
