import { environment } from 'environment';

export const isSupportedAudio = (fileName: string) =>
  environment.audioExtensions.some(ext => fileName.toLowerCase().endsWith(ext));

export const isSupportedImage = (fileName: string) =>
  environment.imageExtensions.some(ext => fileName.toLowerCase().endsWith(ext));

export const isSupportedVideo = (fileName: string) =>
  environment.videoExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
