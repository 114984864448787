import { useQuery, UseQueryResult } from 'react-query';
import { fetchWithTimeout, getAlchemyUri, getUriIpfs } from 'utils';

import { getAlchemyNft } from 'services/apiServices/alchemyService';

import { Nft, NftMetadata } from 'types';

import { useLoading } from './useLoading';

export const useNftQuery = (contractAddress: string, tokenId: string): UseQueryResult<Nft | null> => {
  const query = useQuery(
    ['useNftQuery', contractAddress, tokenId],
    async () => {
      const nft = await getAlchemyNft(contractAddress, tokenId);
      if (!nft) {
        return null;
      }

      if (!nft.tokenUri) {
        return { metadata: nft.metadata ?? {} };
      }

      try {
        const metadataUri = getAlchemyUri(nft.tokenUri);
        const response = await fetchWithTimeout(metadataUri);

        // TODO: validate response
        if (response.data) {
          const metadata: NftMetadata = {
            ...nft.metadata,
            image: getUriIpfs(response.data.image || nft.metadata?.image),
            name: response.data.name || nft.metadata?.name,
            description: response.data.description || nft.metadata?.description,
            beasy: response.data.beasy,
          };

          return { metadata };
        } else {
          return { metadata: nft.metadata ?? {} };
        }
      } catch (error) {
        return { metadata: nft.metadata ?? {} };
      }
    },
    { cacheTime: 10 * 3600e3 /* 10 hours */ },
  );

  useLoading(query.isLoading);

  return query;
};
