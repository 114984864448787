//import { captureException } from '@sentry/react';
import axios from 'axios';

import { environment } from 'environment';

import { InfoFT } from './alchemyService';

const validateAddress = (address: string | undefined): boolean => {
  if (!address) {
    return false;
  }

  return address.startsWith('0x') && address.length === 42;
};

const validateDecimals = (decimals: number | undefined): boolean => {
  return Number.isInteger(decimals);
};

const validateLogoURI = (logoURI: string | undefined): boolean => {
  if (logoURI) {
    const START_URI = 'http';
    const START_URI_2 = 'ipfs';
    const ICON_EXT = ['.png', '.svg', '.gif', '.ico', '.jpg', '.jpeg'];

    return ICON_EXT.some(ext => {
      const _logoURI = logoURI.toLowerCase();

      return _logoURI.startsWith(START_URI || START_URI_2) && _logoURI.endsWith(ext);
    });
  }

  return false;
};

const validateSymbol = (symbol: string | undefined): boolean => {
  return !!symbol;
};

export const getListTokenPolygon = async (tokenListUrl?: string, wethAddress?: string): Promise<InfoFT[]> => {
  try {
    let tokens: InfoFT[] = [];
    if (tokenListUrl !== undefined) {
      tokens = await axios(tokenListUrl).then(res => {
        if (res.data) {
          if (res.data?.tokens?.length > 0) {
            return res.data.tokens
              .filter(({ address, decimals, logoURI, symbol }: InfoFT) => {
                return (
                  validateAddress(address) &&
                  validateDecimals(decimals) &&
                  validateLogoURI(logoURI) &&
                  validateSymbol(symbol)
                );
              })
              .map(({ address, decimals, logoURI, symbol }: InfoFT) => ({
                address,
                decimals,
                logoURI,
                symbol,
              }));
          } else if (res.data?.length > 0) {
            return res.data
              .filter(({ address, decimals, logoURI, symbol }: InfoFT) => {
                return (
                  validateAddress(address) &&
                  validateDecimals(decimals) &&
                  validateLogoURI(logoURI) &&
                  validateSymbol(symbol)
                );
              })
              .map(({ address, decimals, logoURI, symbol }: InfoFT) => ({
                address,
                decimals,
                logoURI,
                symbol,
              }));
          }
        }

        return [];
      });
    }

    const wethIndex = tokens.findIndex(token => {
      if (wethAddress === undefined) {
        return token.address.toLowerCase() === environment.contracts.weth.address.toLowerCase();
      } else {
        return token.address.toLowerCase() === wethAddress.toLowerCase();
      }
    });

    if (wethIndex > -1) {
      tokens[wethIndex].symbol = 'WETH';
    }

    return tokens;
  } catch (error) {
    //captureException(error);
    throw error;
  }
};
