import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { clearCurrentTx } from 'redux/reducers/tx';

import { Modal } from 'components/Modal';
import ProgressBar from 'components/ProgressBar';

import { SentTx, TxStatus } from 'types';

import css from './index.module.css';

interface Props {
  tx: SentTx;
}

export const TxProgress = ({ tx }: Props) => {
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(clearCurrentTx());
  }, [dispatch]);

  const progress = useMemo(() => {
    const hashes = Object.keys(tx.attempts);

    if (hashes.some(hash => tx.attempts[hash].status === TxStatus.Success)) {
      return 0.8;
    }

    if (hashes.some(hash => tx.attempts[hash].status === TxStatus.Pending)) {
      return 0.4;
    }

    return 0.1;
  }, [tx]);

  return (
    <Modal onClose={handleClose}>
      <div className={css.container}>
        <h2 className={css.titleText}>Transaction Processing</h2>
        <>
          <ProgressBar progress={progress} />
          <p className={css.messageText}>Please wait...</p>
        </>
      </div>
    </Modal>
  );
};
