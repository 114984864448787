import { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorAccountInfo } from 'redux/reducers/accountInfo';
import { selectorIsLoading, updateFlags } from 'redux/reducers/flags';

import { LandingHeader } from 'components/Header/LandingHeader';
import { TradeHistory } from 'components/TradeHistory';
import { UserInfo } from 'components/UserInfo';
import { TextAreaInputWithCopy } from 'components/inputs/TextAreaInputWithCopy';

import { useSharedTransferHistoryQuery } from 'hooks/useSharedTransferHistory';

import { readFriend } from 'services/apiServices/FriendService';
import { getRoleNameMap, UserProfileQueryKey } from 'services/userProfile';

import css from './index.module.css';
import { useNetwork } from 'hooks/useNetwork';

export const FriendDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { friendAddress = '' } = useParams();

  const { address } = useAppSelector(selectorAccountInfo);
  const isLoading = useAppSelector(selectorIsLoading);

  const friendQuery = useQuery(['readFriend', address, friendAddress], () => {
    return readFriend(address ?? '', friendAddress);
  });

  const sharedHistoryQuery = useSharedTransferHistoryQuery(friendAddress);

  useEffect(() => {
    if (!isLoading && (friendQuery.isLoading || sharedHistoryQuery.isLoading)) {
      dispatch(updateFlags({ isLoading: true }));
    }
    if (isLoading && !friendQuery.isLoading && !sharedHistoryQuery.isLoading) {
      dispatch(updateFlags({ isLoading: false }));
    }
  }, [isLoading, friendQuery.isLoading, sharedHistoryQuery.isLoading, dispatch]);

  const getHistory = () => {
    if (sharedHistoryQuery.isSuccess) {
      if (sharedHistoryQuery?.data?.length === 0) {
        return <p className={css.empty}>{`You have not transferred any NFT to ${friendQuery.data?.name}`}</p>;
      }

      return (
        <>
          <div className={css.historyHeader}>
            <h2 className={css.historyTitle}>{`Transfer History (${sharedHistoryQuery.data.length})`}</h2>
          </div>

          <TradeHistory transfers={sharedHistoryQuery.data} />
        </>
      );
    }

    return '';
  };
  const {currentNetwork} = useNetwork();
  const friendRole = friendQuery.data?.userProfile?.roles?.find(r => r.network === JSON.stringify(currentNetwork!.id));
  const getRoleNameMapQuery = useQuery(UserProfileQueryKey.getRoleNameMap, () => getRoleNameMap());
  const getRoleName = useCallback(
    (role: number) => {
      return getRoleNameMapQuery?.data![role];
    },
    [getRoleNameMapQuery?.data],
  );
  return (
    <div className={css.container}>
      <div className={css.containerHeader}>
        <LandingHeader isGoBack={true} />
        <h1 className="pageTitle">User Profile</h1>
      </div>

      <section className={css.containerContent}>
        <div className={css.containerUserInfo}>
          <UserInfo
            avatarCid={friendQuery.data?.userProfile?.avatarCid ?? ''}
            nickname={friendQuery.data?.userProfile?.nickname ?? ''}
            isCreator={!!friendQuery.data?.userProfile?.roles.length}
            business={friendQuery.data?.additionalData?.businessName ?? ''}
            userRole={friendRole ? getRoleName(friendRole.role) : '-'}
          />
          <TextAreaInputWithCopy
            label="Address"
            value={friendQuery.data?.userProfile?.ethAddress ?? ''}
            successMessage="Address copied"
          />
        </div>

        <div className={css.historyContainer}>{getHistory()}</div>
      </section>
    </div>
  );
};
