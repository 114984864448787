import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import { useAppSelector } from 'redux/configStore';
import { mnemonicSelector } from 'redux/reducers/onboarding';

import Button from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { OnboardingHeader } from 'components/Header/OnboardingHeader';
import { InputErrorMessage } from 'components/Messages/InputErrorMessage';
import { WebVersion } from 'components/NFTLogo';
import { TextInput } from 'components/inputs';

import { useCreateAccount } from 'hooks/useCreateAccount';

import { savePrivacyPolicyDate } from 'services/privacyPolicy';
import { UserProfileAlreadyExistsError } from 'services/userProfile';

import { environment } from 'environment';

import validationSchema from '../validationSchema';
import css from './index.module.css';

interface Props {
  onNextStepAuth: () => void;
  onPrevStepAuth: () => void;
  externalAccount?: boolean;
}

export const CreateWallet = ({ onNextStepAuth, onPrevStepAuth, externalAccount = false }: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const mnemonic = useAppSelector(mnemonicSelector);

  const createAccount = useCreateAccount(externalAccount ? mnemonic : '');
  const subTitle = externalAccount
    ? 'Create a username for your account'
    : 'Enter your username';
  const nameButtonSubmit = externalAccount ? 'Create Your Account' : 'Sign Up';

  const handleSubmit = useCallback(
    async ({ username, email }: { username: string; email: string }) => {
      try {
        if (formik.isValid && isChecked) {
          await createAccount(username, email);
          setIsChecked(false);
          formik.resetForm();
          savePrivacyPolicyDate().then();
          onNextStepAuth();
        }
      } catch (error) {
        if (error instanceof UserProfileAlreadyExistsError) {
          formik.setFieldError('username', `Username ${username} or email ${email} already exists`);
        }
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isChecked, setIsChecked, createAccount],
  );

  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
    },
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const handleAgreeCheckboxPress = useCallback(() => {
    setIsChecked(isChecked => !isChecked);
  }, [setIsChecked]);

  return (
    <section className={css.container}>
      <div className={css.container__info}>
        <div>
          <OnboardingHeader goBack={onPrevStepAuth} />

          <div className={css.header}>
            <p className={css.title}>Create account</p>
            <p className={css.subTitle}>{subTitle}</p>
          </div>

          <label className={css.username}>
            <div>Username</div>
            <TextInput
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange('username')}
              onBlur={formik.handleBlur}
            />
            {formik.errors.username && formik.touched.username && (
              <InputErrorMessage message={formik.errors.username} />
            )}
          </label>

          <label className={css.email}>
            <div>Email (optional)</div>
            <TextInput
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange('email')}
              onBlur={formik.handleBlur}
            />
            {formik.errors.email && formik.touched.email && <InputErrorMessage message={formik.errors.email} />}
          </label>

          <div className={css.agreement}>
            <Checkbox isChecked={isChecked} onClick={handleAgreeCheckboxPress} />
            <p>
              I agree to{' '}
              <a
                className={css.agreementLink}
                href={environment.externalLinks.termsOfService}
                target="_blank"
                rel="noreferrer"
              >
                Terms of Services
              </a>
              {' and '}
              <a
                className={css.agreementLink}
                href={environment.externalLinks.privacyPolicy}
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
        <Button disabled={!isChecked || !formik.isValid} onClick={formik.submitForm}>
          {nameButtonSubmit}
        </Button>
      </div>
      <WebVersion />
    </section>
  );
};
