import * as ethers from 'ethers';

export const getTxTotalPrice = (tx: ethers.providers.TransactionRequest) => {
  const gasLimit = ethers.BigNumber.from(tx.gasLimit);
  const maxFeePerGas = ethers.BigNumber.from(tx.maxFeePerGas ?? tx.gasPrice ?? 0);

  const price = ethers.utils.formatEther(gasLimit.mul(maxFeePerGas));

  return Number(price);
};
