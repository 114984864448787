import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthSteps, Path } from 'constants/enumTypes';

import { ConfirmPin } from '../ConfirmPin';
import { CreatePin } from '../CreatePin';
import { CreateWallet } from '../CreateWallet';
import { CreateWalletReview } from '../CreateWalletReview';
import { MnemonicConfirmation } from '../MnemonicConfirmation';
import { MnemonicReview } from '../MnemonicReview';

export const SignUpPage = () => {
  const navigate = useNavigate();
  const [stepSignUp, setStepSignUp] = useState(AuthSteps.CreateWallet);

  switch (stepSignUp) {
    case AuthSteps.CreateWalletReview:
      return (
        <CreateWalletReview
          onNextStepAuth={() => setStepSignUp(AuthSteps.MnemonicReview)}
          onPrevStepAuth={() => setStepSignUp(AuthSteps.CreateWallet)}
        />
      );
    case AuthSteps.MnemonicReview:
      return (
        <MnemonicReview
          onNextStepAuth={() => setStepSignUp(AuthSteps.MnemonicConfirmation)}
          onPrevStepAuth={() => setStepSignUp(AuthSteps.CreateWalletReview)}
        />
      );
    case AuthSteps.MnemonicConfirmation:
      return (
        <MnemonicConfirmation
          onNextStepAuth={() => setStepSignUp(AuthSteps.CreatePin)}
          onPrevStepAuth={() => setStepSignUp(AuthSteps.MnemonicReview)}
          onCreateAccount={() => setStepSignUp(AuthSteps.MnemonicReview)}
        />
      );
    case AuthSteps.CreatePin:
      return (
        <CreatePin
          onNextStepAuth={() => setStepSignUp(AuthSteps.ConfirmPin)}
          onPrevStepAuth={() => setStepSignUp(AuthSteps.MnemonicConfirmation)}
        />
      );
    case AuthSteps.ConfirmPin:
      return (
        <ConfirmPin
          onNextStepAuth={() => navigate(Path.Nfts)}
          onPrevStepAuth={() => setStepSignUp(AuthSteps.CreatePin)}
        />
      );
    default:
      return (
        <CreateWallet
          onNextStepAuth={() => setStepSignUp(AuthSteps.CreateWalletReview)}
          onPrevStepAuth={() => navigate(-1)}
        />
      );
  }
};
