import { grpc } from '@improbable-eng/grpc-web';
//import { captureException } from '@sentry/react';

import {
  GrpcWebImpl,
  AlchemyServiceClientImpl,
  GetNFTsRes_OwnedNft,
  GetTokenBalancesRes_TokenBalance,
  GetNFTMetadataRes,
} from 'protobuf/lib/alchemy';

import { environment } from 'environment';

import { FTListService } from '../FTListService';
import { authenticated } from '../grpc';
import { getCurrentChainId } from './network';

export interface InfoBeasy {
  name?: string;
  cid?: string;
}

export interface DocsBeasy {
  license?: InfoBeasy[];
  ownership?: InfoBeasy[];
}

export interface IpfsBeasy {
  docs?: DocsBeasy;
  id?: string;
  media?: InfoBeasy[];
  quantity?: number;
}

export interface InfoFT {
  address: string;
  decimals: number;
  logoURI: string;
  symbol: string;
}

export interface InfoFTWithBalance extends InfoFT {
  tokenBalance: number;
}

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const alchemyCollectibleServiceClient = new AlchemyServiceClientImpl(rpc);

const NFTsSort = (a: GetNFTsRes_OwnedNft, b: GetNFTsRes_OwnedNft) => {
  const aTimeStamp = +new Date(a.timeLastUpdated);
  const bTimeStamp = +new Date(b.timeLastUpdated);

  return bTimeStamp - aTimeStamp;
};

export const getAllAlchemyNFTs = async ({
  includeBeasy = false,
  contractAddresses = [],
}: {
  includeBeasy?: boolean;
  contractAddresses?: string[];
}) => {
  try {
    return await authenticated(async meta => {
      meta.headersMap['network'] = [getCurrentChainId()];

      let NFTs: GetNFTsRes_OwnedNft[] = [];
      let pageKey = '';
      let totalCountNFT = 0;

      do {
        const pageNFTs = await alchemyCollectibleServiceClient.GetNFTs(
          { contractAddresses, includeBeasy, withMetadata: true, pageKey },
          meta,
        );

        NFTs = NFTs.concat(pageNFTs.ownedNfts);
        totalCountNFT = pageNFTs.totalCount;
        pageKey = pageNFTs.pageKey;
      } while (NFTs.length < totalCountNFT);

      return NFTs.sort(NFTsSort);
    });
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

export const getAlchemyBalance = async (): Promise<InfoFTWithBalance[]> => {
  try {
    return await authenticated(async meta => {
      meta.headersMap['network'] = [getCurrentChainId()];
      const listTokenInfo = await FTListService.getFTListForCurrentNetwork();

      const listTokenBalance = await alchemyCollectibleServiceClient
        .GetTokenBalances({ contractAddresses: getListAddressFromListTokenInfo(listTokenInfo) }, meta)
        .then(res => res.tokenBalances)
        .then(listTokenBalance => listTokenBalance.filter(isNotEmptyBalanceFT));

      const tokensInfoByAddress = getTokensInfoByAddress(listTokenInfo);
      return listTokenBalance.map(({ contractAddress, tokenBalance }) => {
        const tokenInfo = tokensInfoByAddress[contractAddress];
        const balance = parseInt(tokenBalance) / 10 ** tokenInfo.decimals;

        return {
          tokenBalance: balance,
          ...tokenInfo,
        };
      });
    });
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

export const getAlchemyWETHBalance = async () => {
  try {
    return await authenticated(async meta => {
      meta.headersMap['network'] = [getCurrentChainId()];
      alchemyCollectibleServiceClient.GetWETHBalance({}, meta);
    });
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

const getListAddressFromListTokenInfo = (list: InfoFT[]): string[] => {
  return list.length > 0 ? list.map(({ address }) => address) : [];
};

const isNotEmptyBalanceFT = (FT: GetTokenBalancesRes_TokenBalance): boolean => {
  if (FT.tokenBalance) {
    const tokenBalance = parseInt(FT.tokenBalance);

    if (isNaN(tokenBalance)) {
      return false;
    }

    return tokenBalance !== 0;
  }

  return false;
};

const getTokensInfoByAddress = (listTokenInfo: InfoFT[]): Record<string, InfoFT> => {
  return listTokenInfo.reduce((obj, tokenInfo) => ({ ...obj, [tokenInfo.address]: tokenInfo }), {});
};

export const getAlchemyNft = async (contractAddress: string, tokenId: string): Promise<GetNFTMetadataRes> => {
  try {
    return await authenticated(async meta => {
      meta.headersMap['network'] = [getCurrentChainId()];
      return await alchemyCollectibleServiceClient.GetNFTMetadata({ contractAddress, tokenId }, meta);
    });
  } catch (error) {
    //captureException(error);
    throw error;
  }
};
