import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateAlertModal } from 'redux/reducers/alert';
import { roundEth } from 'utils';

import { Chevron } from 'assets/icons';
import ethLogo from 'assets/svg/eth-logo.svg';

import Button from 'components/Button';

import { AlertType } from 'constants/enumTypes';

import { useAlchemyBalance, useBalanceQuery } from 'hooks/useBalanceQuery';
import { useNetwork } from 'hooks/useNetwork';

import css from './index.module.css';

interface Props {
  onTopUpClick?: () => void;
}

export const CryptoBalance: FC<Props> = ({ onTopUpClick }) => {
  const dispatch = useDispatch();
  const { currentNetworkName, currentCoinCode } = useNetwork();
  const balanceQuery = useBalanceQuery();

  const handleChainSwitch = useCallback(() => {
    dispatch(updateAlertModal({ isVisibleAlert: true, alertType: AlertType.SwitchChain }));
  }, [dispatch]);
  const listFTsBalance = useAlchemyBalance();

  return (
    <div className={css.container}>
      <div className={css.contents}>
        <div className={css.network} onClick={handleChainSwitch}>
          <label htmlFor="network">Current network</label>
          <input value={currentNetworkName} name="network" readOnly={true} />
          <Chevron />
        </div>
        <div className={css.balance}>
          <label>Your crypto balance</label>
          {listFTsBalance.isSuccess && listFTsBalance.data.length
            ? listFTsBalance.data.map(({ tokenBalance, symbol, logoURI, address }) => (
                <div key={address} className={css.balanceRow}>
                  <img className={css.logo} src={logoURI} alt="logo" />
                  <div>
                    <span className={css.balanceValue}>{roundEth(tokenBalance)}</span>
                    <span> {symbol}</span>
                  </div>
                </div>
              ))
            : balanceQuery.isSuccess &&
              !balanceQuery.isStale && (
                <div className={css.balanceRow}>
                  <img className={css.logo} src={ethLogo} alt="logo" />
                  <div>
                    <h1 className={css.balanceValue}>
                      {balanceQuery.data.toFixed(6)} {currentCoinCode}
                    </h1>
                  </div>
                </div>
              )}
        </div>
        <p className={css.info}>
          You will need {currentCoinCode} in your wallet to perform any blockchain transaction such as NFT minting and
          trading.
        </p>
      </div>

      <footer className={css.footer}>
        <Button className={css.topUpButton} onClick={onTopUpClick}>
          How to top up
        </Button>
      </footer>
    </div>
  );
};
