import clsx from 'clsx';

import { ButtonBase } from 'components/ButtonBase';
import { ButtonProps } from 'components/types';

import css from './index.module.css';

export const ButtonGhost = ({ className, ...rest }: ButtonProps) => (
  <ButtonBase className={clsx(css.button, className)} {...rest} />
);
