import { grpc } from '@improbable-eng/grpc-web';
//import { captureException, Severity } from '@sentry/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateFlags } from 'redux/reducers/flags';
import { setMnemonic } from 'redux/reducers/onboarding';
import { WalletFactory } from 'services';
import { pasteFromClipboard } from 'utils';

import paste from 'assets/images/paste.png';
import paste2x from 'assets/images/paste@2x.png';
import paste3x from 'assets/images/paste@3x.png';

import Button from 'components/Button';
import { OnboardingHeader } from 'components/Header/OnboardingHeader';
import { InputErrorMessage } from 'components/Messages/InputErrorMessage';
import { WebVersion } from 'components/NFTLogo';

import { isGrpcError } from 'services/grpc';
import { getUserProfile } from 'services/userProfile';

import css from './index.module.css';

interface Props {
  onNextStepAuth: () => void;
  onCreateAccount: () => void;
  onPrevStepAuth: () => void;
}

export const RestoreWallet = ({ onPrevStepAuth, onNextStepAuth, onCreateAccount }: Props) => {
  const dispatch = useDispatch();
  const [mnemonicText, setMnemonicText] = useState('');
  const [isMnemonicInvalid, setIsMnemonicInvalid] = useState(false);

  const handleContinue = useCallback(async () => {
    dispatch(updateFlags({ isLoading: true }));
    setIsMnemonicInvalid(false);

    const sanitizedMnemonic = mnemonicText.trim();

    let address: string;

    try {
      const wallet = WalletFactory.fromMnemonic(sanitizedMnemonic);

      address = wallet.address;
      dispatch(setMnemonic(sanitizedMnemonic));
    } catch (error) {
      //captureException(error, { level: Severity.Debug, extra: { sanitizedMnemonic } });
      setIsMnemonicInvalid(true);
      dispatch(updateFlags({ isLoading: false }));
      return;
    }

    try {
      const account = await getUserProfile(address);

      if (account && account.ethAddress.toLowerCase() === address.toLowerCase()) {
        onNextStepAuth();
      } else {
        setIsMnemonicInvalid(true);
      }
    } catch (error) {
      //captureException(error, { level: Severity.Debug, extra: { sanitizedMnemonic } });
      if (isGrpcError(error) && error.code === grpc.Code.NotFound) {
        onCreateAccount();
      }
    } finally {
      dispatch(updateFlags({ isLoading: false }));
    }
  }, [mnemonicText, setIsMnemonicInvalid, dispatch, onNextStepAuth, onCreateAccount]);

  useEffect(() => {
    setIsMnemonicInvalid(false);
  }, [mnemonicText]);

  const handlePaste = useCallback(async () => {
    const text = await pasteFromClipboard();
    setMnemonicText(text);
  }, [setMnemonicText]);

  return (
    <section className={css.container}>
      <div className={css.container__info}>
        <OnboardingHeader goBack={onPrevStepAuth} />
        <article>
          <p className={css.container__info__createLabel}>Sign In</p>
          <p>Please enter your secret mnemonic phrase to restore your existing BEASY Wallet</p>
        </article>
        <fieldset className={css.mnemonic}>
          <label htmlFor="mnemonic">Mnemonic Phrase</label>
          <textarea
            name="mnemonic"
            value={mnemonicText}
            placeholder="Enter your mnemonic phrase"
            onChange={e => setMnemonicText(e.currentTarget.value)}
          />
          <button className={css.paste} onClick={handlePaste}>
            <img src={paste} alt="paste" srcSet={`${paste} 1x, ${paste2x} 2x, ${paste3x} 3x`} />
          </button>
          <p>Usually 12 or 24 words separated by spaces</p>
          {isMnemonicInvalid && <InputErrorMessage message="Incorrect mnemonic phrase. Please, try again." />}
        </fieldset>
        <Button
          className={!isMnemonicInvalid ? css.container__info__button : css.container__info__button__error}
          disabled={!mnemonicText}
          onClick={handleContinue}
        >
          Sign In
        </Button>
      </div>
      <WebVersion />
    </section>
  );
};
