import clsx from 'clsx';
import React from 'react';

import { Close } from 'assets/icons';

import css from './index.module.css';

interface Props {
  onClose?: () => void;
  children: React.ReactNode;
  className?: string;
  zIndex?: number;
}

export const Modal = ({ onClose, children, className, zIndex = 100 }: Props) => {
  return (
    <div className={clsx(css.overlay)} style={{ zIndex }}>
      <div className={clsx(css.container, className)}>
        {onClose && (
          <button className={css.closeButton} onClick={onClose}>
            <Close />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};
