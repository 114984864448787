import { IpfsCollectibleInfo } from 'beasy-fe-commons';
import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'redux/configStore';
import { updateFlags } from 'redux/reducers/flags';
import { getCollectibleIdFromTokenId } from 'utils';

import Button, { ButtonColorScheme } from 'components/Button';
import { LandingHeader } from 'components/Header/LandingHeader';
import { OwnershipAuthenticator } from 'components/OwnershipAuthenticator';

import { Path } from 'constants/enumTypes';

import { useIpfsInfo } from 'hooks/useIpfs';

import { getAssetHistory, getCollectibleById } from 'services/apiServices/collectiblesService';
import { getIpfsUrl } from 'services/apiServices/ipfs';

import { CarouselCollectible } from './CarouselCollectible';
import { CollectibleHeader } from './CollectibleHeader';
import { CollectibleImgFullScreen } from './CollectibleImgFullScreen';
import { FieldInfo } from './FieldInfo';
import { HistoryQrCodeModal } from './HistoryQrCodeModal';
import css from './index.module.css';

export const BeasyNftDetailsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tokenId = '' } = useParams();
  const collectibleId = getCollectibleIdFromTokenId(tokenId);
  const [isHistoryQrCodeShown, toggleOpenHistoryQrCode] = useReducer((isOpen: boolean) => !isOpen, false);

  const [collectibleImgFullScreen, setCollectibleImgFullScreen] = useState<{ isShow: boolean; uri?: string }>({
    isShow: false,
  });

  const collectibleQuery = useQuery(['getCollectibleById', collectibleId], () => {
    return getCollectibleById(collectibleId);
  });
  const ipfsStructure = useIpfsInfo<IpfsCollectibleInfo>(collectibleQuery.data?.CID);
  const attributes = ipfsStructure ? ipfsStructure.attributes : [];

  const setAttributeType = (displayType: string): string => {
    switch (displayType) {
      case 'string': {
        return 'Text';
      }
      case 'boost_percentage': {
        return 'Percentage';
      }
      case 'number': {
        return 'Number';
      }
      case 'date': {
        return 'Date';
      }
    }
    return '';
  };
  const formatedValue = (value: string, type: string): string => {
    if (type === 'boost_percentage') return value + '%';
    if (type === 'date') {
      const d = new Date(parseInt(value));
      return d.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    }
    return value;
  };

  // const roleMapQuery = useQuery(UserProfileQueryKey.getRoleNameMap, () => getRoleNameMap());

  useEffect(() => {
    if (collectibleQuery.isLoading) {
      dispatch(updateFlags({ isLoading: true }));
    } else {
      dispatch(updateFlags({ isLoading: false }));
    }
  }, [dispatch, collectibleQuery.isLoading]);

  const agreements = useMemo(() => {
    const license = ipfsStructure?.beasy?.docs.license[0] ?? null;
    const ownership = ipfsStructure?.beasy?.docs.ownership?.[0] ?? null;

    return [
      {
        label: 'Ownership Agreement',
        name: ownership ? ownership.name : null,
        url: ownership?.cid ? getIpfsUrl(ownership.cid) : null,
      },
      {
        label: 'License Agreement',
        name: license ? license.name : null,
        url: license?.cid ? getIpfsUrl(license.cid) : null,
      },
    ];
  }, [ipfsStructure]);

  const collectibleHistoryQuery = useQuery(
    ['getAssetHistory', tokenId],
    () => {
      return getAssetHistory(tokenId);
    },
    { enabled: false, onSettled: () => dispatch(updateFlags({ isLoading: false })) },
  );

  const handleTradeClick = useCallback(() => {
    if (tokenId && collectibleQuery.isSuccess) {
      navigate(`${Path.TransferNft}/${collectibleQuery.data.collection}/${tokenId}`);
    }
  }, [collectibleQuery.data?.collection, collectibleQuery.isSuccess, navigate, tokenId]);

  const handleTradeClickDesktop = useCallback(() => {
    if (tokenId && collectibleQuery.data?.collection && collectibleQuery.isSuccess) {
      const contractAddress = collectibleQuery.data.collection;
      navigate(generatePath(Path.TransferDesktopRecipient, { contractAddress, tokenId, tokenType: 'erc721' }));
    }
  }, [collectibleQuery.data?.collection, collectibleQuery.isSuccess, navigate, tokenId]);

  const handleShowCollectibleImgFullScreen = useCallback((uri: string) => {
    setCollectibleImgFullScreen({ isShow: true, uri });
  }, []);

  const handleCloseCollectibleImgFullScreen = useCallback(() => {
    setCollectibleImgFullScreen({ isShow: false });
  }, []);

  useEffect(() => {
    if (tokenId) {
      dispatch(updateFlags({ isLoading: true }));
      collectibleHistoryQuery.refetch().then();
    }
    // eslint-disable-next-line
  }, [tokenId]);

  return (
    <>
      {!collectibleQuery.isLoading && (
        <>
          {collectibleQuery.isError ? (
            <>
              <div>
                <LandingHeader isGoBack={true} />
                <div className={css.errorLoading}>
                  <p>The NFT could not be loaded correctly, please check back later to see if the error persists. </p>
                  <p>If so, please contact our technical service through the contact page.</p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={css.containerWallet}>
                <div className={css.containerHeader}>
                  <LandingHeader isGoBack={true} />
                  {collectibleQuery.isSuccess && <CollectibleHeader name={collectibleQuery.data.name} type="NFT" />}
                </div>

                <div className={css.containerContent}>
                  <CarouselCollectible
                    onOpenFullScreen={handleShowCollectibleImgFullScreen}
                    media={ipfsStructure?.beasy.media ?? []}
                  />

                  <div className={css.containerInfo}>
                    <FieldInfo label="Description">{collectibleQuery.data?.description || 'N/A'}</FieldInfo>

                    {/*Ownership & License agreements */}
                    {agreements.map(({ label, name, url }, i) => (
                      <FieldInfo key={i} label={label}>
                        {name && url ? (
                          <a className={css.link} href={url} target="_blank" rel="noreferrer">
                            {name}
                          </a>
                        ) : (
                          <p className="subText">{'N/A'}</p>
                        )}
                      </FieldInfo>
                    ))}

                    <FieldInfo label="Contributors">
                      {collectibleQuery.data?.contributors.length === 0
                        ? 'N/A'
                        : collectibleQuery.data?.contributors.map(({ userProfile }) => (
                            <div key={userProfile?.ethAddress}>{userProfile?.nickname}</div>
                          ))}
                    </FieldInfo>

                    <FieldInfo label="Attributes">
                      {attributes
                        ? attributes.length === 0
                          ? '-'
                          : attributes.map(({ display_type, trait_type, value }, index) => {
                              return (
                                <div key={index}>
                                  {setAttributeType(display_type)} - {trait_type} - {formatedValue(value, display_type)}
                                </div>
                              );
                            })
                        : '-'}
                    </FieldInfo>

                    <FieldInfo label="Ownership Authenticator">
                      {collectibleHistoryQuery.isSuccess && collectibleHistoryQuery.data?.history.length !== 0 ? (
                        <OwnershipAuthenticator
                          history={collectibleHistoryQuery.data.history.sort((a, b) => b.timestamp - a.timestamp)}
                        />
                      ) : (
                        '-'
                      )}
                    </FieldInfo>

                    <div className={css.containerFooter}>
                      {tokenId && (
                        <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={toggleOpenHistoryQrCode}>
                          Share QR Code
                        </Button>
                      )}

                      {collectibleQuery.isSuccess && tokenId && (
                        <Button onClick={handleTradeClick}>Transfer NFT</Button>
                      )}
                    </div>
                  </div>
                </div>

                {isHistoryQrCodeShown && <HistoryQrCodeModal tokenId={tokenId} onClose={toggleOpenHistoryQrCode} />}

                {collectibleImgFullScreen.isShow && (
                  <CollectibleImgFullScreen
                    uri={collectibleImgFullScreen.uri ?? ''}
                    onClose={handleCloseCollectibleImgFullScreen}
                  />
                )}
              </div>

              <div className={css.containerDesktop}>
                <div className={css.containerHeader}>
                  <LandingHeader isGoBack={true} />
                  {collectibleQuery.isSuccess && <CollectibleHeader name={collectibleQuery.data.name} type="NFT" />}
                </div>
                <div className={css.nftInfo}>
                  <div className={css.containerInfo}>
                    <div className={css.description}>
                      <FieldInfo label="Description">{collectibleQuery.data?.description || 'N/A'}</FieldInfo>
                    </div>
                    <div className={css.ownership}>
                      {/*Ownership & License agreements */}
                      {agreements.map(({ label, name, url }, i) => (
                        <FieldInfo key={i} label={label}>
                          {name && url ? (
                            <a className={css.link} href={url} target="_blank" rel="noreferrer">
                              {name}
                            </a>
                          ) : (
                            <p className="subText">{'N/A'}</p>
                          )}
                        </FieldInfo>
                      ))}

                      <FieldInfo label="Contributors">
                        {collectibleQuery.data?.contributors.length === 0
                          ? 'N/A'
                          : collectibleQuery.data?.contributors.map(({ userProfile }) => (
                              <div key={userProfile?.ethAddress}>{userProfile?.nickname}</div>
                            ))}
                      </FieldInfo>

                      <FieldInfo label="Attributes">
                        {attributes
                          ? attributes.length === 0
                            ? '-'
                            : attributes.map(({ display_type, trait_type, value }, index) => {
                                return (
                                  <div key={index}>
                                    {setAttributeType(display_type)} - {trait_type} -{' '}
                                    {formatedValue(value, display_type)}
                                  </div>
                                );
                              })
                          : '-'}
                      </FieldInfo>

                      {/*<FieldInfo label="Royalty holders">*/}
                      {/* {collectibleQuery.data?.royaltyHolders.length === 0*/}
                      {/* ? 'N/A'*/}
                      {/* : collectibleQuery.data?.royaltyHolders.map(({ userProfile, royaltyShare }, index) => {*/}
                      {/* const networkId = providerService.getNetworkId().toString();*/}
                      {/* const userRole = userProfile && userProfile.roles.find(role => role.network === networkId);*/}
                      {/* return (*/}
                      {/* <div key={index}>*/}
                      {/* {roleMapQuery.data && userRole ? roleMapQuery.data[userRole.role] : '-'}: {userProfile?.nickname}{' '}*/}
                      {/* with a {royaltyShare}% Revenue Share*/}
                      {/* </div>*/}
                      {/* );*/}
                      {/* })}*/}
                      {/*</FieldInfo>*/}

                      <FieldInfo label="Ownership Timeline">
                        {collectibleHistoryQuery.isSuccess && collectibleHistoryQuery.data?.history.length !== 0 ? (
                          <OwnershipAuthenticator
                            history={collectibleHistoryQuery.data.history.sort((a, b) => b.timestamp - a.timestamp)}
                          />
                        ) : (
                          '-'
                        )}
                      </FieldInfo>
                    </div>
                  </div>
                  <div className={css.containerIpfs}>
                    <CarouselCollectible
                      onOpenFullScreen={handleShowCollectibleImgFullScreen}
                      media={ipfsStructure?.beasy.media ?? []}
                    />
                  </div>
                  {isHistoryQrCodeShown && <HistoryQrCodeModal tokenId={tokenId} onClose={toggleOpenHistoryQrCode} />}

                  {collectibleImgFullScreen.isShow && (
                    <CollectibleImgFullScreen
                      uri={collectibleImgFullScreen.uri ?? ''}
                      onClose={handleCloseCollectibleImgFullScreen}
                    />
                  )}
                </div>
                <div className={css.containerFooter}>
                  {collectibleQuery.isSuccess && tokenId && (
                    <Button onClick={handleTradeClickDesktop}>Transfer NFT</Button>
                  )}
                  {tokenId && (
                    <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={toggleOpenHistoryQrCode}>
                      Share QR Code
                    </Button>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
