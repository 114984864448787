import React from 'react';

import { FriendList } from 'components/FriendList';
import { Modal } from 'components/Modal';

import { Friend } from 'protobuf/lib/friend';

import css from './index.module.css';

interface Props {
  address: string;
  onSelect: (friend: Friend) => void;
  onClose: () => void;
}

export const SelectFriendModal = ({ address, onSelect, onClose }: Props) => {
  return (
    <Modal className={css.modal} onClose={onClose}>
      <div className={css.container}>
        <div className={css.containerHeader}>
          <h2 className={css.header}>Select Friend</h2>
        </div>
        <FriendList address={address} onClickFriend={onSelect} isVisibleFriendLink={true} />
      </div>
    </Modal>
  );
};
