//import { captureException } from '@sentry/react';
import React, { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorAlert, updateAlertModal } from 'redux/reducers/alert';
import { walletService } from 'services';

import { showGenericSuccessToast } from 'components/AppToasts';
import Button, { ButtonColorScheme } from 'components/Button';
import { Modal } from 'components/Modal';

import { Timers } from 'services/Timers';

import { Message } from './Message';
import css from './index.module.css';

const SignDelay = 20e3;

export const ApproveEthSignTypedData = () => {
  const dispatch = useAppDispatch();
  const { sessionData } = useAppSelector(selectorAlert);
  const message = sessionData.params[1];

  const onPressRejectEventHandle = useCallback(() => {
    dispatch(
      updateAlertModal({
        isVisibleAlert: false,
      }),
    );
   // walletConnectService.rejectRequest(sessionData, 'Rejected by user');
  }, [dispatch, sessionData]);

  const onPressAcceptEventHandle = useCallback(async () => {
    try {
      // See: https://easybeasy.atlassian.net/browse/AT-775
      // For some reason OpenSea throws error unless there's a delay between the request and the response
      await Timers.delay(SignDelay);

      const result = await walletService.signTypedData(message);
      
      if (result) {
        //walletConnectService.approveRequest(sessionData, result);
        showGenericSuccessToast('Successfully signed data');
      } else {
        //walletConnectService.rejectRequest(sessionData, 'Could not sign data');
      }

      dispatch(updateAlertModal({ isVisibleAlert: false }));
    } catch (error) {
      //walletConnectService.rejectRequest(sessionData, 'Could not sign data');
      //captureException(error);
    }
  }, [message, sessionData, dispatch]);

  const onPressAcceptEventHandleMutation = useMutation('onPressAcceptEventHandle', onPressAcceptEventHandle);

  return (
    <Modal className={css.modal}>
      <div className={css.container}>
        <h2 className={css.titleText}>Sign Data</h2>

        <div className={css.message}>
          <Message json={message} />
        </div>

        <Button
          className={css.button}
          disabled={onPressAcceptEventHandleMutation.isLoading}
          onClick={() => onPressAcceptEventHandleMutation.mutate()}
        >
          {onPressAcceptEventHandleMutation.isLoading ? 'Please wait...' : 'Sign'}
        </Button>

        <Button className={css.button} colorScheme={ButtonColorScheme.SECONDARY} onClick={onPressRejectEventHandle}>
          Reject
        </Button>
      </div>
    </Modal>
  );
};
