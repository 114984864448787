import React from 'react';
import { roundEth } from 'utils';

import css from './FeeDetails.module.css';

interface Props {
  amount: number;
  fee: number;
  coinName: string;
  currencyName: string;
}

export const FeeDetails = ({ amount, fee, coinName, currencyName }: Props) => {
  const total = amount + fee;

  return (
    <div className={css.container}>
      <dl>
        <dt>Amount to transfer</dt>
        <dd>
          {!!amount && amount} {currencyName}
        </dd>
      </dl>

      <dl>
        <dt>Gas fee</dt>
        <dd>
          {roundEth(fee)} {coinName}
        </dd>
      </dl>

      {currencyName !== 'WETH' && (
        <dl>
          <dt>Grand total</dt>
          <dd>
            {roundEth(total)} {coinName}
          </dd>
        </dl>
      )}
    </div>
  );
};
