import * as ethers from 'ethers';
import { walletService } from 'services';

import { GetAssetTransfersReq_Category } from 'protobuf/lib/alchemy';

import { InfoFT } from 'services/apiServices/alchemyService';

import {
  AssetTransfer,
  CryptoTransfer,
  CryptoTransferTxContext,
  NftTransfer,
  NftTransferTxContext,
  SentTx,
  SentTxAttempt,
  Transfer,
  TransferType,
  TxContextType,
  TxStatus,
} from 'types';

const hexWeiToAmount = (wei: string): number => {
  if (!wei) {
    return 0;
  }

  const amount = ethers.utils.formatEther(ethers.BigNumber.from(wei));

  return Number(amount);
};

const findSuccessfulAttemptHash = (attempts: Record<string /* hash */, SentTxAttempt>) => {
  return Object.keys(attempts).find(hash => attempts[hash].status === TxStatus.Success);
};

const findFirstUnsuccessfulAttemptHash = (attempts: Record<string /* hash */, SentTxAttempt>) => {
  return Object.keys(attempts)
    .sort((a, b) => attempts[a].timestamp - attempts[b].timestamp)
    .find(hash => attempts[hash].status !== TxStatus.Success);
};

const getCryptoTransferSymbol = (fts: InfoFT[], contractAddress: string) => {
  const ft = fts.find(ft => ft.address.toLowerCase() === contractAddress.toLowerCase());
  return ft?.symbol;
};

export const isUserAddress = (address: string) => {
  return address.toLowerCase() === walletService.getAddress().toLowerCase();
};

export class TransferHistoryItemFactory {
  static makeTransferFromAlchemyHistory(fts: InfoFT[], item: AssetTransfer): Transfer | null {
    if (!item.rawContract) {
      return null;
    }

    const contractAddress = item.rawContract.address.toLowerCase();
    var coinName: any;
    switch (item.category) {
      case GetAssetTransfersReq_Category.ERC1155:
        return {
          type: TransferType.Nft,
          from: item.from,
          to: item.to,
          txStatus: TxStatus.Success,
          timestamp: item.timestamp,
          contractAddress,
          txHash: item.hash,
          txBlock: item.blockNum,
          tokenId: item.tokenId,
          chainId: item.chainId,
        };
      case GetAssetTransfersReq_Category.ERC721:
        return {
          type: TransferType.Nft,
          from: item.from,
          to: item.to,
          txStatus: TxStatus.Success,
          timestamp: item.timestamp,
          contractAddress,
          txHash: item.hash,
          txBlock: item.blockNum,
          tokenId: item.tokenId,
          chainId: item.chainId,
        };
      case GetAssetTransfersReq_Category.ERC20:
        coinName = getCryptoTransferSymbol(fts, contractAddress);
        if (!coinName) {
          return null;
        }

        return {
          type: TransferType.Crypto,
          from: item.from,
          to: item.to,
          txStatus: TxStatus.Success,
          timestamp: item.timestamp,
          txHash: item.hash,
          txBlock: item.blockNum,
          amount: hexWeiToAmount(item.rawContract?.value ?? ''),
          contractAddress,
          coinName,
          chainId: item.chainId,
        };
      case GetAssetTransfersReq_Category.EXTERNAL:

        return {
          type: TransferType.Crypto,
          from: item.from,
          to: item.to,
          txStatus: TxStatus.Success,
          timestamp: item.timestamp,
          txHash: item.hash,
          txBlock: item.blockNum,
          amount: hexWeiToAmount(item.rawContract?.value ?? ''),
          contractAddress,
          coinName: item.asset,
          chainId: item.chainId,
        };
      default:
        return null;
    }
  }

  static makeTransferFromSentTx(uuid: string, sentTx: SentTx): Transfer | null {
    const hash = findSuccessfulAttemptHash(sentTx.attempts) ?? findFirstUnsuccessfulAttemptHash(sentTx.attempts);

    if (!hash) {
      return null;
    }

    switch (sentTx.context.type) {
      case TxContextType.NftTransfer: {
        return isUserAddress(sentTx.context.from)
          ? TransferHistoryItemFactory.makeNftTransferFromSentTx(sentTx.context, sentTx.attempts[hash], hash, uuid)
          : null;
      }
      case TxContextType.CryptoTransfer: {
        return isUserAddress(sentTx.context.from)
          ? TransferHistoryItemFactory.makeCryptoTransferFromSentTx(sentTx.context, sentTx.attempts[hash], hash, uuid)
          : null;
      }
      default:
        return null;
    }
  }

  private static makeNftTransferFromSentTx(
    context: NftTransferTxContext,
    attempt: SentTxAttempt,
    hash: string,
    uuid: string,
  ): NftTransfer | null {
    return {
      type: TransferType.Nft,
      from: context.from,
      txStatus: attempt.status,
      to: context.to,
      timestamp: attempt.timestamp,
      txHash: hash,
      tokenId: context.tokenId,
      contractAddress: context.contractAddress,
      txBlock: attempt.block,
      chainId: context.chainId,
      uuid,
    };
  }

  private static makeCryptoTransferFromSentTx(
    context: CryptoTransferTxContext,
    attempt: SentTxAttempt,
    hash: string,
    uuid: string,
  ): CryptoTransfer | null {
    return {
      type: TransferType.Crypto,
      from: context.from,
      txStatus: attempt.status,
      to: context.to,
      timestamp: attempt.timestamp,
      txHash: hash,
      amount: context.amount,
      txBlock: attempt.block,
      contractAddress: context.contractAddress,
      coinName: context.coinName,
      chainId: context.chainId,
      uuid,
    };
  }
}
