import { IconProps } from '../types';

const ASPECT_RATIO = 1.5385;

export const PolygonIcon = ({ className, size = 40, onClick }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
    width={size}
    height={size / ASPECT_RATIO}
    fill="none"
    viewBox="0 0 40 26"
  >
    <rect x="0.25" y="0.25" width="39.5" height="25.5" rx="1.75" fill="white" />
    <g clipPath="url(#clip0_2371_19584)">
      <path
        d="M25.1042 9.48059C24.7396 9.26566 24.2708 9.26566 23.8542 9.48059L20.9375 11.2537L18.9583 12.3821L16.0937 14.1552C15.7292 14.3701 15.2604 14.3701 14.8437 14.1552L12.6042 12.7582C12.2396 12.5433 11.9792 12.1134 11.9792 11.6298V8.94327C11.9792 8.51342 12.1875 8.08357 12.6042 7.81491L14.8437 6.47163C15.2083 6.25671 15.6771 6.25671 16.0937 6.47163L18.3333 7.86865C18.6979 8.08357 18.9583 8.51342 18.9583 8.997V10.7701L20.9375 9.58805V7.76118C20.9375 7.33133 20.7292 6.90148 20.3125 6.63283L16.1458 4.10745C15.7812 3.89253 15.3125 3.89253 14.8958 4.10745L10.625 6.68656C10.2083 6.90148 10 7.33133 10 7.76118V12.8119C10 13.2418 10.2083 13.6716 10.625 13.9403L14.8437 16.4657C15.2083 16.6806 15.6771 16.6806 16.0937 16.4657L18.9583 14.7463L20.9375 13.5642L23.8021 11.8448C24.1667 11.6298 24.6354 11.6298 25.0521 11.8448L27.2917 13.188C27.6562 13.403 27.9167 13.8328 27.9167 14.3164V17.003C27.9167 17.4328 27.7083 17.8627 27.2917 18.1313L25.1042 19.4746C24.7396 19.6895 24.2708 19.6895 23.8542 19.4746L21.6146 18.1313C21.25 17.9164 20.9896 17.4866 20.9896 17.003V15.2836L19.0104 16.4657V18.2388C19.0104 18.6686 19.2187 19.0985 19.6354 19.3672L23.8542 21.8925C24.2187 22.1075 24.6875 22.1075 25.1042 21.8925L29.3229 19.3672C29.6875 19.1522 29.9479 18.7224 29.9479 18.2388V13.1343C29.9479 12.7045 29.7396 12.2746 29.3229 12.006L25.1042 9.48059Z"
        fill="#8247E5"
      />
    </g>
    <rect
      x="0.25"
      y="0.25"
      width="39.5"
      height="25.5"
      rx="1.75"
      stroke="#B2B2B2"
      strokeWidth="0.5"
      style={{ mixBlendMode: 'multiply' }}
    />
    <defs>
      <clipPath id="clip0_2371_19584">
        <rect width="20" height="18" fill="white" transform="translate(10 4)" />
      </clipPath>
    </defs>
  </svg>
);
