import React, { useMemo } from 'react';
import { groupTransferItems } from 'utils';

import { Transfer } from 'types';

import { TradeHistoryItem } from './TradeHistoryItem';
import css from './index.module.css';

interface Props {
  transfers: Transfer[];
}

export const TradeHistory = ({ transfers }: Props) => {
  const data = useMemo(() => {
    const groupedHistory = groupTransferItems(transfers);
    return Object.keys(groupedHistory).reduce(
      (acc, key) => [...acc, key, ...groupedHistory[key]],
      [] as (string | Transfer)[],
    );
  }, [transfers]);

  return (
    <div className={css.container}>
      {data.map((item, i) => {
        if (typeof item === 'string') {
          return (
            <div key={i} className={css.date}>
              <p>{item}</p>
            </div>
          );
        }

        return (
          <div key={i} className={css.item}>
            <TradeHistoryItem transfer={item} />
          </div>
        );
      })}
    </div>
  );
};
