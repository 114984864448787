import React, { useCallback } from 'react';
import { copyToClipboard, shortenAddress } from 'utils';

import css from './JsonView.module.css';

interface JsonViewProps<T> {
  data: T;
}

const ObjectView = ({ data }: { data: any }) => {
  return (
    <ul>
      {Object.keys(data).map(key => (
        <li key={key}>
          <span className={css.label}>{key}: </span>
          <JsonView data={data[key]} />
        </li>
      ))}
    </ul>
  );
};

const ArrayView = ({ data }: { data: any[] }) => {
  return (
    <ul>
      {data.map((item, i) => (
        <li key={i}>
          <JsonView data={item} />
        </li>
      ))}
    </ul>
  );
};

const PrimitiveView = ({ data }: { data: any }) => {
  const isAddress = typeof data === 'string' && data.startsWith('0x') && data.length === 42;

  const handleClick = useCallback(() => {
    copyToClipboard({ data, successMessage: 'Copied!' });
  }, [data]);

  return <span onClick={handleClick}>{isAddress ? shortenAddress(data) : data}</span>;
};

export const JsonView = <T extends {}>({ data }: JsonViewProps<T>) => {
  if (Array.isArray(data)) {
    return <ArrayView data={data} />;
  }

  if (typeof data === 'object' && data !== null) {
    return <ObjectView data={data} />;
  }

  return <PrimitiveView data={data} />;
};
