import React from 'react';

import { EthereumIcon } from 'assets/icons/Network/EthereumIcon';
import { PolygonIcon } from 'assets/icons/Network/PolygonIcon';
import checkCircleSuccess from 'assets/images/checkCircleSuccess.png';
import checkCircleSuccess2x from 'assets/images/checkCircleSuccess@2x.png';
import checkCircleSuccess3x from 'assets/images/checkCircleSuccess@3x.png';

import { Modal } from 'components/Modal';


import css from './index.module.css';

interface Props {
  coinName: string;
  coinNameSelected: string;
  onSelect: (coinName: string) => void;
  onClose: () => void;
}

export const SelectCurrencyModal = ({ coinName, coinNameSelected, onSelect, onClose }: Props) => {
  const items = [coinName, 'WETH'];
  return (
    <Modal className={css.modal} onClose={onClose}>
      <div className={css.container}>
        <div className={css.containerHeader}>
          <h2 className={css.header}>Select Currency</h2>
        </div>

        <ul className={css.list}>
          {items.map(item => (
            <li key={item} onClick={() => onSelect(item)}>
              <div>
                {item === 'ETH' || item === 'WETH' ? <EthereumIcon /> : <PolygonIcon />}
                <div className={css.coinName}>{item}</div>
              </div>

              {item === coinNameSelected && (
                <img
                  className={css.check}
                  src={checkCircleSuccess}
                  alt=""
                  srcSet={`${checkCircleSuccess} 1x, ${checkCircleSuccess2x} 2x, ${checkCircleSuccess3x} 3x`}
                />
              )}
            </li>
          ))}
        </ul>
      </div>
    </Modal>
  );
};
