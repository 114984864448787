//import { captureException } from '@sentry/react';

import { showGenericSuccessToast } from 'components/AppToasts';

/**
 * It seems like on iOS writing to clipboard only works in synchronous functions.
 * This could be because iOS only lets copying to clipboard only as a result of user interactions.
 *
 * @param data
 * @param successMessage
 */
export const copyToClipboard = ({ data, successMessage }: { data: string; successMessage: string }) => {
  try {
    navigator.clipboard.writeText(data ?? '').then(() => showGenericSuccessToast(successMessage));
  } catch (error) {
    //captureException(error);
  }
};

export const pasteFromClipboard = async (): Promise<string> => {
  try {
    return await navigator.clipboard.readText();
  } catch (error) {
    //captureException(error);
    return '';
  }
};
