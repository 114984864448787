import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { updateFlags } from 'redux/reducers/flags';
import { setMnemonic, setUsername } from 'redux/reducers/onboarding';
import { MnemonicFactory, WalletFactory } from 'services';

import { createUserProfile } from 'services/userProfile';

export const useCreateAccount = (externalMnemonic: string) => {
  const dispatch = useDispatch();

  return useCallback(
    async (username: string, email: string) => {
      try {
        dispatch(updateFlags({ isLoading: true }));

        let mnemonic: string;

        if (externalMnemonic) {
          mnemonic = externalMnemonic;
        } else {
          mnemonic = MnemonicFactory.random();
        }

        const wallet = WalletFactory.fromMnemonic(mnemonic);
        const { address } = wallet;

        dispatch(setUsername(username));
        dispatch(setMnemonic(mnemonic));

        await createUserProfile(address, username);

        // TODO: remove this. Zapier hooks should be called be the API, when the `createUserProfile` method is called.
       /*
        if (email) {
          await axios.get('https://hooks.zapier.com/hooks/catch/11610906/bfn521w/', {
            params: {
              username,
              address,
              email,
            },
          });
        }
        */
      } catch (error) {
        console.log(error);
        //captureException(error);
        throw error;
      } finally {
        dispatch(updateFlags({ isLoading: false }));
      }
    },
    [dispatch, externalMnemonic],
  );
};
