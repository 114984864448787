import { LocalStorageService } from 'services/LocalStorageService';
import { InfoFT } from 'services/apiServices/alchemyService';

const FT_INFO_KEY_LOCALSTORAGE = 'FTsInfo';

export class FTListPersistenceService {
  static getFTListForNetwork(networkId: number): InfoFT[] | null {
    const ftList = FTListPersistenceService.getFTList();

    if (ftList) {
      return ftList[networkId];
    }

    return null;
  }

  static saveFTListForNetwork(networkId: number, list: InfoFT[]): void {
    const ftList = FTListPersistenceService.getFTList();
    LocalStorageService.setItem(FT_INFO_KEY_LOCALSTORAGE, { ...ftList, [networkId]: list });
  }

  private static getFTList(): Record<number, InfoFT[]> {
    return LocalStorageService.getItem<Record<string, InfoFT[]>>(FT_INFO_KEY_LOCALSTORAGE) ?? {};
  }
}
