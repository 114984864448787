//import { captureException } from '@sentry/react';
import { walletService } from 'services';

import { Timers } from 'services/Timers';
import { subscriberClient } from 'services/subscriber';

import { queryClient } from '../queryClient';

export interface Account {
  address: string;
}

export const logout = async () => {
  try {
    subscriberClient.stop();
    walletService.clearWallet();

    Timers.clearAllTimeouts();
    Timers.clearAllIntervals();

    queryClient.clear();
  } catch (error) {
    //captureException(error);
    throw error;
  }
};
