import { useCallback } from 'react';
import { useAppDispatch } from 'redux/configStore';
import { accountInfoInitialState, updateAccountInfo } from 'redux/reducers/accountInfo';
import { walletPersistenceService, walletService } from 'services';
import { logout } from 'utils';

export const useLogout = () => {
  const dispatch = useAppDispatch();

  return useCallback(async () => {
    dispatch(updateAccountInfo(accountInfoInitialState));
    walletPersistenceService.removeWallets();
    await logout();
  }, [dispatch]);
};
