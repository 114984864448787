//import { captureException, Severity } from '@sentry/react';

import { environment } from 'environment';

export const PRIVACY_POLICY_KEY = 'privacy_policy_date';

export const getPrivacyPolicyDate = async () => {
  try {
    const res = await fetch(environment.externalLinks.privacyPolicy);

    return res.headers.get('last-modified');
  } catch (error) {
    //captureException(error, { level: Severity.Debug, extra: { error } });
  }
};

export const savePrivacyPolicyDate = async () => {
  const newPrivacyPolicyDate = await getPrivacyPolicyDate();

  if (newPrivacyPolicyDate) {
    localStorage.setItem(PRIVACY_POLICY_KEY, newPrivacyPolicyDate);
  }
};

export const getCurrencyPrivacyPolicyDate = () => localStorage.getItem(PRIVACY_POLICY_KEY);
