import { Transfer } from 'types';

import { LocalStorageService } from './LocalStorageService';

const TransferHistoryPersistenceKey = 'transfers';

export class TransferHistoryPersistenceService {
  static getTransfers(): Transfer[] {
    const transfers = LocalStorageService.getItem<Transfer[]>(TransferHistoryPersistenceKey);
    return transfers ?? [];
  }

  static saveTransfers(transfers: Transfer[]) {
    const existingTransfers = TransferHistoryPersistenceService.getTransfers();
    LocalStorageService.setItem(TransferHistoryPersistenceKey, [...existingTransfers, ...transfers]);
  }
}
