import { Payments } from 'pages/profile/AccountPage/PaymentsBalance';
import { CryptoBalance } from 'pages/profile/BalancePage/CryptoBalance';
import PubSub from 'pubsub-js';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorIsLoading, updateFlags } from 'redux/reducers/flags';
import { getAlchemyUri } from 'utils';

import { LandingHeader } from 'components/Header/LandingHeader';
import { EmptyListMessageText } from 'components/Messages/EmptyListMessage';
import { ErrorMessageQueryLoading } from 'components/Messages/ErrorMessageQueryLoading';
import { TopTabBar } from 'components/TopTabBar';
import LoadingScreen from 'components/loading/Screen';
import { PageArea, PageHeader } from 'components/page';

import { useGetAlchemyNFTs } from 'hooks/useGetAlchemyNFTs';

import { NftCard } from './NftCard';
import css from './index.module.css';

const TABS = ['NFTs', 'Crypto'];

export const TokensPage = () => {
  const isLoading = useAppSelector(selectorIsLoading);
  const dispatch = useAppDispatch();
  const nftQuery = useGetAlchemyNFTs({ includeBeasy: true });

  useEffect(() => {
    if (!isLoading && nftQuery.isLoading) {
      dispatch(updateFlags({ isLoading: true }));
    }
    if (isLoading && !nftQuery.isLoading) {
      dispatch(updateFlags({ isLoading: false }));
    }
  }, [isLoading, nftQuery.isLoading, dispatch]);

  const mySubscriber = () => {
    nftQuery.refetch();
  };
  useEffect(() => {
    PubSub.subscribe('chainSwitch', mySubscriber);
  });

  const { data: NFTs, isError, isSuccess, refetch } = useGetAlchemyNFTs({ includeBeasy: true });

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const renderListNFTs = useMemo(() => {
    if (isError) {
      return (
        <div className={css.infoMessage}>
          <ErrorMessageQueryLoading onRetry={() => refetch()} />
        </div>
      );
    }

    if (isSuccess && NFTs && NFTs.length > 0) {
      return NFTs.map((NFT, i) =>
        NFT.tokenUri ? (
          <NftCard
            key={i}
            tokenUri={getAlchemyUri(NFT.tokenUri)}
            tokenId={NFT.id?.tokenId}
            contractAddress={NFT.contract?.address}
          />
        ) : null,
      );
    }

    return (
      <div className={css.infoMessage}>
        <EmptyListMessageText message="At this moment you have no NFTs" />
      </div>
    );
  }, [NFTs, isError, isSuccess, refetch]);

  return (
    <div className={css.container}>
      <div className={css.container__info}>
        <PageArea className={css.pageArea}>
          <PageHeader title="Tokens">
            <TopTabBar
              className={css.tabs}
              tabs={TABS}
              selectedTabIndex={selectedTabIndex}
              onSelectedTabIndexChange={setSelectedTabIndex}
            />
          </PageHeader>

          {selectedTabIndex === 0 && (
            <>{isLoading ? <LoadingScreen /> : <div className={css.list}>{renderListNFTs}</div>}</>
          )}
          {selectedTabIndex === 1 && (
            <>
              <Payments />
            </>
          )}
        </PageArea>
        <div className={css.collections}>
          <div className={css.containerHeader}>
            <LandingHeader />
            <h1
              className="pageTitle"
              title="Once you purchase a NFT from a BEASY enabled marketplace, you will be able to view your
            NFTs here under the &ldquo;Collected&rdquo; section. You will be able to either view or
            transfer your NFT to other BEASY Wallet holders."
            >
              Tokens
            </h1>

            <TopTabBar
              className={css.tabs}
              tabs={TABS}
              selectedTabIndex={selectedTabIndex}
              onSelectedTabIndexChange={setSelectedTabIndex}
            />
          </div>

          {selectedTabIndex === 0 && (
            <>{isLoading ? <LoadingScreen /> : <div className={css.list}>{renderListNFTs}</div>}</>
          )}
          {selectedTabIndex === 1 && <CryptoBalance />}
        </div>
      </div>
    </div>
  );
};
