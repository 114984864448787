import { walletService } from './index';

export class LocalStorageService {
  static getItem<T>(key: string): T | null {
    try {
      const accountKey = LocalStorageService.getAccountKey(key);

      const contents = localStorage.getItem(accountKey);
      if (contents) {
        return JSON.parse(contents);
      }

      return null;
    } catch (error) {
      return null;
    }
  }

  static setItem<T>(key: string, item: T): void {
    try {
      const accountKey = LocalStorageService.getAccountKey(key);

      const contents = JSON.stringify(item);
      localStorage.setItem(accountKey, contents);
    } catch (error) {
      //
    }
  }

  static removeItem(key: string): void {
    try {
      const accountKey = LocalStorageService.getAccountKey(key);

      localStorage.removeItem(accountKey);
    } catch (error) {
      //
    }
  }

  private static getAccountKey(key: string): string {
    return walletService.getAddress() + ':' + key;
  }
}
