//import { captureException } from '@sentry/react';
import * as ethers from 'ethers';
import { v4 } from 'uuid';

import { WalletPasswordService } from './WalletPasswordService';

interface StoredWallet {
  id: string;
  name: string;
  address: string;
  encryptedData: string;
}

const WalletPersistenceStorageKey = 'wallets';

const isStoredWalletValid = (wallet: StoredWallet) => {
  const isFieldValid = <T>(value: T) => value && typeof value === 'string';

  return isFieldValid(wallet.id) && isFieldValid(wallet.address) && isFieldValid(wallet.encryptedData);
};

export class InvalidPasswordError extends Error {}

export class WalletPersistenceService {
  private storedWallets = new Map<string, StoredWallet>();

  constructor(private passwordService: WalletPasswordService) {}

  async init(): Promise<void> {
    try {
      const contents = await localStorage.getItem(WalletPersistenceStorageKey);
      if (contents) {
        const wallets = JSON.parse(contents);
        wallets
          .filter(isStoredWalletValid)
          .forEach((wallet: StoredWallet) => this.storedWallets.set(wallet.id, wallet));
      }
    } catch (error) {
      console.error(error);
      //captureException(error);
    }
  }

  getStoredWallets(): StoredWallet[] {
    return Array.from(this.storedWallets.values());
  }

  async getWallet(walletId: string, pin: string): Promise<ethers.Wallet | null> {
    try {
      const storedWallet = this.storedWallets.get(walletId);

      if (storedWallet) {
        const password = await this.passwordService.getPasswordForPin(pin);
        return await ethers.Wallet.fromEncryptedJson(storedWallet.encryptedData, password);
      }
    } catch (error) {
      console.error(error);

      if (error instanceof Error && error.message === 'invalid password') {
        throw new InvalidPasswordError();
      }
    }

    return null;
  }

  async saveWallet(wallet: ethers.Wallet, name: string, pin: string) {
    const password = await this.passwordService.getPasswordForPin(pin);
    const encryptedData = await wallet.encrypt(password, { scrypt: { N: 1024 } });

    const id = v4();
    const storedWallet: StoredWallet = { id, name, encryptedData, address: wallet.address };

    // TODO: remove this line for multiple wallet support
    this.storedWallets.clear();

    this.storedWallets.set(id, storedWallet);

    await this.flushStoredWallets();
  }

  async removeWallets() {
    this.storedWallets.clear();
    await localStorage.setItem(WalletPersistenceStorageKey, '');
  }

  private async flushStoredWallets(): Promise<void> {
    const wallets = this.getStoredWallets();
    await localStorage.setItem(WalletPersistenceStorageKey, JSON.stringify(wallets));
  }
}
