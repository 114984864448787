import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'redux/configStore';
import { updateAlertModal } from 'redux/reducers/alert';
import { updateFlags } from 'redux/reducers/flags';

import { Chevron } from 'assets/icons/Chevron';
import qrCode from 'assets/images/qrCode.png';
import qrCode2x from 'assets/images/qrCode@2x.png';
import qrCode3x from 'assets/images/qrCode@3x.png';

import { AlertType } from 'constants/enumTypes';

import { ChainInfo } from './ChainInfo';
import css from './index.module.css';

interface Props {
  isGoBack?: boolean;
}

export const LandingHeader = ({ isGoBack = false }: Props) => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const handleGoBack = useCallback(() => navigation(-1), [navigation]);
  const handleClick = useCallback(() => dispatch(updateFlags({ isVisibleScanner: true })), [dispatch]);

  const handleChainSwitch = useCallback(() => {
    dispatch(updateAlertModal({ isVisibleAlert: true, alertType: AlertType.SwitchChain }));
  }, [dispatch]);

  return (
    <nav className={css.container}>
      {isGoBack ? (
        <button onClick={handleGoBack}>
          <Chevron size={18} className={css.backIcon} />
        </button>
      ) : (
        <div className={css.chainInfo}>
          <ChainInfo onChainSwitch={handleChainSwitch} />
        </div>
      )}
      <img
        className={css.qr}
        src={qrCode}
        alt="QR"
        srcSet={`${qrCode} 1x, ${qrCode2x} 2x, ${qrCode3x} 3x`}
        onClick={handleClick}
      />
    </nav>
  );
};
