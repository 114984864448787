import lockGrey from 'assets/images/lock_grey.png';
import lockGrey2x from 'assets/images/lock_grey@2x.png';
import lockGrey3x from 'assets/images/lock_grey@3x.png';

import css from './index.module.css';

interface Props {
  label: string;
  message: string;
  onClick: () => void;
}

export const LockArea = ({ label, message, onClick }: Props) => {
  return (
    <div className={css.container}>
      <p>{label}</p>
      <div className={css.lockArea} onClick={onClick}>
        <img
          className={css.lockGrey}
          src={lockGrey}
          alt="lockGrey"
          srcSet={`${lockGrey} 1x, ${lockGrey2x} 2x, ${lockGrey3x} 3x`}
        />
        <p className={css.message}>{message}</p>
      </div>
    </div>
  );
};
