import React, { DetailedHTMLProps, HTMLAttributes } from 'react';

import exclamation from 'assets/images/exclamation.png';
import exclamation2x from 'assets/images/exclamation@2x.png';
import exclamation3x from 'assets/images/exclamation@3x.png';

import css from './index.module.css';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
  // Optional:
  text?: string;
}

export const ErrorMessage = ({ text = 'Something went wrong', ...props }: Props) => {
  return (
    <p {...props}>
      <img
        className={css.icon}
        src={exclamation}
        alt=""
        srcSet={`${exclamation} 1x, ${exclamation2x} 2x, ${exclamation3x} 3x`}
      />

      <span className={css.message}>{text}</span>
    </p>
  );
};
