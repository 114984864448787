import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account } from 'utils';

import { RootState } from '../configStore';

interface AccountInfo extends Account {
  isLoggedIn: boolean;
  mnemonicPhrase?: string;
}

export const accountInfoInitialState: AccountInfo = {
  isLoggedIn: false,
  address: '',
};

const accountInfoSlice = createSlice({
  name: 'accountInfo',
  initialState: accountInfoInitialState,
  reducers: {
    updateAccountInfo: (state, { payload }: PayloadAction<Partial<AccountInfo>>) => {
      return { ...state, ...payload };
    },
  },
});

export { accountInfoSlice };

export const { updateAccountInfo } = accountInfoSlice.actions;

export const selectorAccountInfo = (state: RootState): AccountInfo => state.accountInfo;
