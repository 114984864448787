import { useFormik } from 'formik';
import React, { useCallback, useReducer } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorAccountInfo } from 'redux/reducers/accountInfo';
import { updateFlags } from 'redux/reducers/flags';

import { Chevron } from 'assets/icons';

import Button from 'components/Button';
import { LandingHeader } from 'components/Header/LandingHeader';

import { sendHelpRequest } from 'services/apiServices/HelpService';

import { HelpSuccessModal } from './HelpSuccessModal';
import { SelectTopicModal } from './SelectTopicModal';
import css from './index.module.css';

const topics = [
  'Transferring',
  'Adding Friends',
  'Connecting to a storefront',
  'Purchasing a NFT',
  'Profile Security',
  'Other',
];

export const CustomerSupportPage = () => {
  const dispatch = useAppDispatch();
  const { address } = useAppSelector(selectorAccountInfo);
  const [isSelectTopicModalVisible, toggleIsSelectTopicModalVisible] = useReducer(isVisible => !isVisible, false);
  const [isHelpStatusVisible, toggleIsHelpStatusVisible] = useReducer(isVisible => !isVisible, false);

  const formik = useFormik({
    initialValues: {
      descriptionText: '',
      chooseProblem: '',
      email: '',
    },
    onSubmit: () => {},
  });

  const handleHelpRequest = useCallback(() => {
    dispatch(updateFlags({ isLoading: true }));

    sendHelpRequest({
      topic: formik.values.chooseProblem,
      description: formik.values.descriptionText,
      email: formik.values.email,
      address,
    })
      .then(() => {
        toggleIsHelpStatusVisible();
      })
      .catch(error => {
        // TODO: ?
        alert(error.message);
      })
      .finally(() => dispatch(updateFlags({ isLoading: false })));
  }, [formik.values.chooseProblem, formik.values.descriptionText, formik.values.email, address, dispatch]);

  const handleSelect = (option: string) => {
    formik.setFieldValue('chooseProblem', option);
    toggleIsSelectTopicModalVisible();
  };

  return (
    <div className={css.container}>
      <LandingHeader isGoBack={true} />
      <div className={css.pageTitle}>
        <h1 className="pageTitle">Customer Support</h1>
      </div>
      <div className={css.content}>
        <h2 className={css.problemTitle}>Tell us about your problem</h2>

        <div className={css.formFields}>
          <fieldset className={css.chooseProblem}>
            <label htmlFor="chooseProblem">What kind of problem?</label>
            <input
              value={formik.values.chooseProblem}
              placeholder="Choose a category"
              readOnly={true}
              onClick={toggleIsSelectTopicModalVisible}
            />
            <Chevron />
          </fieldset>

          <fieldset className={css.fieldset}>
            <label htmlFor="email">Email</label>
            <input
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange('email')}
              placeholder="mail@example.com"
            />
          </fieldset>

          <fieldset className={css.fieldset}>
            <label htmlFor="descriptionText">Description</label>
            <textarea
              name="descriptionText"
              value={formik.values.descriptionText}
              onChange={formik.handleChange('descriptionText')}
              placeholder="Describe the problem"
            />
          </fieldset>
        </div>

        <Button
          className={css.sendRequestButton}
          disabled={!formik.values.descriptionText || !formik.values.chooseProblem || !formik.values.email}
          onClick={handleHelpRequest}
        >
          Send the request
        </Button>
      </div>
      {isSelectTopicModalVisible && (
        <SelectTopicModal tabs={topics} onSelect={handleSelect} onClose={toggleIsSelectTopicModalVisible} />
      )}

      {isHelpStatusVisible && <HelpSuccessModal />}
    </div>
  );
};
