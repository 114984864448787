import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
//import { captureException } from '@sentry/react';
import * as ethers from 'ethers';
import { providerService, walletService } from 'services';
import { assert, setIncrementalInterval, getTxTotalPrice } from 'utils';
import { v4 } from 'uuid';

import { TxRes } from 'protobuf/lib/chargeMaster';

//import { MetaTxService } from 'services/MetaTransaction';
import { SentTxPersistenceService } from 'services/SentTxPersistenceService';
import { Timers } from 'services/Timers';
import { TxGenerator } from 'services/TxGenerator';
import { getNetworks } from 'services/apiServices/network';
import { DEFAULT_CURRENCY, getExchangeRates } from 'services/currency';

import { SentTx, SentTxAttempt, TxContext, TxStatus } from 'types';

import { AppDispatch, RootState } from '../configStore';

const TxWaitTimeout = 30e3;

export interface QueueNoneResult {
  status: TxStatus.None;
}

export interface QueueSuccessResult {
  status: TxStatus.Success;
  response: ethers.providers.TransactionResponse;
}

export interface QueuePendingResult {
  status: TxStatus.Pending;
  response: ethers.providers.TransactionResponse;
}

export interface QueueErrorResult {
  status: TxStatus.Error;
  response: ethers.providers.TransactionResponse;
}

export type QueueResult = QueueNoneResult | QueueSuccessResult | QueuePendingResult | QueueErrorResult;

export interface TxState {
  sentTxs: Record<string /* uuid */, SentTx>;
  currentTxUuid: string | null;
  tx: ethers.providers.TransactionRequest | null;
  txPrice: number | null;
  txPriceInDefaultCurrency: number | null;
  isBalanceSufficient: boolean;
}

export interface TxSlice {
  tx: TxState;
}

const initialState: TxState = {
  sentTxs: {},
  currentTxUuid: null,
  tx: null,
  txPrice: null,
  txPriceInDefaultCurrency: null,
  isBalanceSufficient: false,
};

const findFirstUnsuccessfulAttemptHash = (attempts: Record<string /* hash */, SentTxAttempt>) => {
  return Object.keys(attempts)
    .sort((a, b) => attempts[a].timestamp - attempts[b].timestamp)
    .find(hash => attempts[hash].status !== TxStatus.Success);
};

const txSlice = createSlice({
  name: 'tx',
  initialState,
  reducers: {
    setTx: (
      state,
      action: PayloadAction<{
        tx: ethers.providers.TransactionRequest;
        txPrice: number;
        txPriceInDefaultCurrency: number;
        isBalanceSufficient: boolean;
      }>,
    ) => {
      state.tx = action.payload.tx;
      state.txPrice = action.payload.txPrice;
      state.txPriceInDefaultCurrency = action.payload.txPriceInDefaultCurrency;
      state.isBalanceSufficient = action.payload.isBalanceSufficient;
    },
    setInsufficientBalance: state => {
      state.isBalanceSufficient = true;
    },
    setCurrentTxUuid: (state, action: PayloadAction<string>) => {
      state.currentTxUuid = action.payload;
    },
    clearCurrentTx: state => {
      state.tx = null;
      state.txPrice = null;
      state.txPriceInDefaultCurrency = null;
      state.isBalanceSufficient = false;
      state.currentTxUuid = null;
    },
    addSentTx: (state, action: PayloadAction<{ uuid: string; sentTx: SentTx }>) => {
      const { uuid, sentTx } = action.payload;
      state.sentTxs[uuid] = sentTx;
    },
    addSentTxAttempt: (
      state,
      action: PayloadAction<{ uuid: string; hash: string; timestamp: number; block: number }>,
    ) => {
      const { uuid, hash, timestamp, block } = action.payload;

      const { attempts } = state.sentTxs[uuid];
      const firstUnsuccessfulAttemptHash = findFirstUnsuccessfulAttemptHash(attempts);

      const firstAttemptTimestamp = firstUnsuccessfulAttemptHash
        ? attempts[firstUnsuccessfulAttemptHash].timestamp
        : timestamp;

      state.sentTxs[uuid].attempts = { [hash]: { status: TxStatus.None, timestamp: firstAttemptTimestamp, block } };
    },
    setStatus: (state, action: PayloadAction<{ uuid: string; hash: string; status: TxStatus }>) => {
      const { uuid, hash, status } = action.payload;

      if (status === TxStatus.Success) {
        delete state.sentTxs[uuid];
      } else {
        state.sentTxs[uuid].attempts[hash].status = status;
      }
    },
  },
});

export { txSlice };

export const { clearCurrentTx, setInsufficientBalance } = txSlice.actions;

export const txSelector = (state: TxSlice) => state.tx;

export const sentTxsSelector = createSelector(txSelector, state => state.sentTxs);

export const currentTxSelector = createSelector(txSelector, ({ sentTxs, currentTxUuid }) => {
  if (currentTxUuid === null) {
    return null;
  }

  return sentTxs[currentTxUuid];
});

export const txPriceSelector = createSelector(txSelector, state => state.txPrice);

export const txPriceInDefaultCurrencySelector = createSelector(txSelector, state => state.txPriceInDefaultCurrency);

export const isBalanceSufficientSelector = createSelector(txSelector, state => state.isBalanceSufficient);

export const setTx = (tx: ethers.providers.TransactionRequest) => {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    try {
      const state = getState();
      const txPrice = getTxTotalPrice(tx);
      const { chainId } = state.blockchain;
      const networksResponse = await getNetworks();
      const currentNetwork = networksResponse.networks.find(({ id }) => id === `${chainId}`);

      const amount = tx.value ? Number(ethers.utils.formatEther(tx.value)) : 0;
      const balance = await walletService.getBalance();
      const isBalanceSufficient = balance >= amount + txPrice + Number.EPSILON;

      if (currentNetwork?.coin) {
        const exchangeRatesResponse = await getExchangeRates({
          from: currentNetwork.coin.code,
          to: [DEFAULT_CURRENCY],
        });
        const { rate } = exchangeRatesResponse.rates[0];

        const txPriceInDefaultCurrency = txPrice * (rate ?? 0);

        dispatch(txSlice.actions.setTx({ tx, txPrice, txPriceInDefaultCurrency, isBalanceSufficient }));
      } else {
        dispatch(txSlice.actions.setTx({ tx, txPrice: 0, txPriceInDefaultCurrency: 0, isBalanceSufficient }));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

const addSentTx = (uuid: string, sentTx: SentTx) => {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    dispatch(txSlice.actions.addSentTx({ sentTx, uuid }));
    await Timers.delay(100);
    SentTxPersistenceService.flushTransactions(getState().tx.sentTxs);
  };
};

const addSentTxAttempt = (uuid: string, hash: string, block: number) => {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    dispatch(txSlice.actions.addSentTxAttempt({ uuid, hash, timestamp: Date.now(), block }));
    await Timers.delay(100);
    SentTxPersistenceService.flushTransactions(getState().tx.sentTxs);
  };
};

const setStatus = (uuid: string, hash: string, status: TxStatus) => {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    dispatch(txSlice.actions.setStatus({ uuid, hash, status }));
    await Timers.delay(100);
    SentTxPersistenceService.flushTransactions(getState().tx.sentTxs);
  };
};

const waitForTransaction = (hash: string, confirmations: number): Promise<ethers.providers.TransactionResponse> => {
  return new Promise((resolve, reject) => {
    let timer: ReturnType<typeof setIncrementalInterval> | null = setIncrementalInterval(
      () => {
        providerService
          .getTransaction(hash)
          .then((tx: any) => {
            if (tx) {
              if (tx.confirmations >= confirmations) {
                resolve(tx);

                if (timer) {
                  clearTimeout(timer);
                  timer = null;
                }
              }
            }
          })
          .catch(reject);
      },
      5e3,
      600e3,
    );
  });
};

const watch = (uuid: string, hash: string) => {
  return async (dispatch: AppDispatch): Promise<ethers.providers.TransactionResponse | void> => {
    const updateStatus = (status: TxStatus) => {
      dispatch(setStatus(uuid, hash, status));
    };

    try {
      await waitForTransaction(hash, 0);
      updateStatus(TxStatus.Pending);
    } catch (error) {
      updateStatus(TxStatus.Error);
      throw error;
    }

    try {
      const response = await waitForTransaction(hash, 1);
      dispatch(txSlice.actions.clearCurrentTx());
      updateStatus(TxStatus.Success);
      return response;
    } catch (error) {
      updateStatus(TxStatus.Error);
      dispatch(txSlice.actions.clearCurrentTx());
      throw error;
    }
  };
};

/*
 * Should be dispatched once after login and wallet initialization.
 *
 * This action:
 *  - gets sentTxs from localStorage
 *  - filters out successful transactions
 *  - initializes Redux sentTxs with pending and failed transactions
 *  - starts watching pending tx statuses
 */
export const init = () => {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<void> => {
    const sentTxs = SentTxPersistenceService.loadTransactions();

    for (const uuid of Object.keys(sentTxs)) {
      const { attempts, context } = sentTxs[uuid];

      const unsuccessfulTxHashes = Object.keys(attempts).filter(hash => attempts[hash].status !== TxStatus.Success);
      const unsuccessfulTxs = await providerService.getTransactions(unsuccessfulTxHashes);

      const confirmedUnsuccessfulTxs = unsuccessfulTxs.filter((tx: { confirmations: number; }) => tx.confirmations === 0);

      if (confirmedUnsuccessfulTxs.length > 0) {
        await dispatch(
          addSentTx(uuid, {
            context,
            attempts: confirmedUnsuccessfulTxs.reduce((acc: any, tx: { hash: string | number; }) => ({ ...acc, [tx.hash]: attempts[tx.hash] }), {}),
          }),
        );
      }

      confirmedUnsuccessfulTxs.forEach((tx: { hash: string; }) => {
        // This should work in the background and should NOT be `await`ed
        dispatch(watch(uuid, tx.hash));
      });
    }

    await Timers.delay(100);
    SentTxPersistenceService.flushTransactions(getState().tx.sentTxs);
  };
};

const queue = (uuid: string, response: ethers.providers.TransactionResponse | TxRes) => {
  return async (dispatch: AppDispatch): Promise<QueueResult> => {
    try {
      //Different way to get the hash between tx and metatx
      let hash: string;
      (response as ethers.providers.TransactionResponse).hash === undefined
        ? (hash = (response as TxRes).transactionReceipt!.transactionHash)
        : (hash = (response as ethers.providers.TransactionResponse).hash);

      const txResponse = await Promise.race([dispatch(watch(uuid, hash)), Timers.delay(TxWaitTimeout)]);

      if (txResponse) {
        return {
          status: TxStatus.Success,
          response: response as ethers.providers.TransactionResponse,
        };
      }

      return {
        status: TxStatus.Pending,
        response: response as ethers.providers.TransactionResponse,
      };
    } catch (error) {
      return {
        status: TxStatus.Error,
        response: response as ethers.providers.TransactionResponse,
      };
    }
  };
};

/*
 * Sends populated transaction.
 * Use this action to send all new transactions, e.g. for minting NFTs and transferring NFT and crypto.
 *
 * Do NOT use this action to retry stuck transactions.
 *
 * This action:
 *  - sends the transaction
 *  - persists the transaction context & hash in Redux & localStorage
 *  - starts watching tx status
 */
export const send = (context: TxContext) => {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<QueueResult> => {
    try {
      const state = getState();
      const { tx } = state.tx;

      assert(tx, 'The tx is not defined');
      console.log("SEND TX: TX",tx)
      const response = await walletService.sendTransaction(tx);
      console.log("SEND TX: RESPONSE",response)
      const uuid = v4();

      await dispatch(
        addSentTx(uuid, {
          context,
          attempts: {
            [response.hash]: {
              timestamp: Date.now(),
              status: TxStatus.None,
              block: response.blockNumber ?? 0,
            },
          },
        }),
      );

      dispatch(txSlice.actions.setCurrentTxUuid(uuid));

      return await dispatch(queue(uuid, response));
    } catch (error) {
      //captureException(error);
      throw error;
    }
  };
};

/*
 * Sends populated transaction with Metatx.
 * Use this action to send all new transactions, e.g. for minting NFTs and transferring NFT and crypto.
 *
 * Do NOT use this action to retry stuck transactions.
 *
 * This action:
 *  - sends the transaction
 *  - persists the transaction context & hash in Redux & localStorage
 *  - starts watching tx status
 */
/*
export const sendMetaTx = (context: TxContext) => {
  return async (dispatch: AppDispatch, getState: () => RootState): Promise<QueueResult> => {
    try {
      const state = getState();
      const { tx } = state.tx;

      assert(tx, 'The tx is not defined');
      //Start here the process to display the popup
      const uuid = v4();
      await dispatch(
        addSentTx(uuid, {
          context,
          attempts: {
            [uuid]: {
              timestamp: Date.now(),
              status: TxStatus.None,
              block: 0,
            },
          },
        }),
      );
      await dispatch(txSlice.actions.setCurrentTxUuid(uuid));

      const response = await MetaTxService(tx);
      //Repeat the sentTx to set the correct transactionHash here the process to display the popup
      await dispatch(
        addSentTx(uuid, {
          context,
          attempts: {
            [response.transactionReceipt!.transactionHash!]: {
              timestamp: Date.now(),
              status: TxStatus.None,
              block: 0,
            },
          },
        }),
      );

      return await dispatch(queue(uuid, response));
    } catch (error) {
      //captureException(error);
      throw error;
    }
  };
};*/

/*
 * Retries pending transactions.
 *
 *  This action:
 *  - sends the transaction
 *  - persists the transaction attempt & hash for the correct context in Redux & localStorage
 *  - starts watching tx status
 */
export const retry = (uuid: string, hash: string) => {
  return async (dispatch: AppDispatch): Promise<QueueResult> => {
    try {
      const [tx] = await providerService.getTransactions([hash]);
      const retryTx = await TxGenerator.makeResendTx(tx);

      const response = await walletService.sendTransaction(retryTx);

      await dispatch(addSentTxAttempt(uuid, response.hash, response.blockNumber ?? 0));

      dispatch(txSlice.actions.setCurrentTxUuid(uuid));

      return await dispatch(queue(uuid, response));
    } catch (error) {
      //captureException(error);
      throw error;
    }
  };
};
