import React from 'react';

import { Modal } from 'components/Modal';

import css from './index.module.css';

interface Props {
  uri: string;
  onClose: () => void;
}

export const CollectibleImgFullScreen = ({ onClose, uri }: Props) => {
  return (
    <Modal className={css.modal} onClose={onClose}>
      <div className={css.container}>
        <img className={css.media} alt="" src={uri} />
      </div>
    </Modal>
  );
};
