import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import WalletConnect from '@walletconnect/client';

import { RootState } from '../configStore';

export interface ApplicationConnection {
  connected: boolean;
  applicationToken?: string;
  name: string;
  connector?: WalletConnect;
}

interface ConnectedApplications {
  [key: string]: ApplicationConnection;
}

const connectedApplicationsInitialState: ConnectedApplications = {};

const connectedApplicationsSlice = createSlice({
  name: 'connectedApplications',
  initialState: connectedApplicationsInitialState,
  reducers: {
    updateConnectedApplications: (state, { payload }: PayloadAction<ApplicationConnection>) => {
      state[payload.name] = payload;
    },
  },
});

export { connectedApplicationsSlice };

export const { updateConnectedApplications } = connectedApplicationsSlice.actions;

export const selectorConnectedApplications = (state: RootState): ConnectedApplications => state.connectedApplications;
