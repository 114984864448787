import React from 'react';
import { useQuery } from 'react-query';
import { shortenAddress } from 'utils';

import arrowRight from 'assets/images/arrowRight.png';
import arrowRight2x from 'assets/images/arrowRight@2x.png';
import arrowRight3x from 'assets/images/arrowRight@3x.png';

import { getAvatarUriFromCid, getUserProfile } from 'services/userProfile';

import css from './index.module.css';

interface Props {
  to: string;
}

export const TransferCardMint = ({ to }: Props) => {
  const toQuery = useQuery(['getUserProfile', to], () => getUserProfile(to));

  return (
    <div className={css.container}>
      <div className={css.to}>
        {toQuery.isSuccess && <img className={css.avatar} src={getAvatarUriFromCid(toQuery.data.avatarCid)} alt="" />}
      </div>

      <span className={css.address}>Mint</span>
      <img
        className={css.arrow}
        src={arrowRight}
        alt="Arrow"
        srcSet={`${arrowRight} 1x, ${arrowRight2x} 2x, ${arrowRight3x} 3x`}
      />
      <span className={css.address}>{shortenAddress(to)}</span>
    </div>
  );
};

export default TransferCardMint;
