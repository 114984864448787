//import { captureException } from '@sentry/react';
import { fetchWithTimeout } from 'utils';
import { isIpfsUri } from 'utils/ipfs';

import { NftMetadata } from 'types';

import { NftMetadaPersistenceService } from './NftMetadaPersistenceService';

export class NftMetadataService {
  static async getNftMetadata(uri: string): Promise<NftMetadata | null> {
    if (uri) {
      try {
        if (isIpfsUri(uri)) {
          const ipfsNftMetadata = NftMetadaPersistenceService.getIpfsNftMetadata(uri);

          if (ipfsNftMetadata) {
            return ipfsNftMetadata;
          }
        }

        const response = await fetchWithTimeout(uri);

        if (response.data) {
          const nftMetadata: NftMetadata = {
            beasy: response.data.beasy,
            description: response.data.description,
            image: response.data.image,
            name: response.data.name,
          };

          if (
            isIpfsUri(uri) &&
            (nftMetadata.beasy || nftMetadata.description || nftMetadata.image || nftMetadata.name)
          ) {
            NftMetadaPersistenceService.setIpfsNftsMetadata(uri, nftMetadata);
          }

          return nftMetadata;
        }
      } catch (error) {
        //captureException(error);
      }
    }

    return null;
  }
}
