import clsx from 'clsx';
import React from 'react';

import { CheckBlueCircleSuccess } from 'assets/icons/CheckBlueCircleSuccess';

import css from './index.module.css';

interface Props {
  children?: React.ReactNode;
  message: string;
  className?: string;
}

export const MessageWithIcon = ({ message, children, className }: Props) => (
  <div className={clsx(css.container, className)}>
    <div className={css.containerIcon}>
      <div>{children ? children : <CheckBlueCircleSuccess />}</div>
    </div>
    <div>{message}</div>
  </div>
);
