import React, { useMemo } from 'react';

import { Friend } from 'protobuf/lib/friend';

import { getAvatarUriFromCid } from 'services/userProfile';

import css from './index.module.css';

interface Props {
  friend: Friend;
  onClick: () => void;
}

export const FriendInfo = ({ friend, onClick }: Props) => {
  const avatarSource = useMemo(
    () => getAvatarUriFromCid(friend.userProfile?.avatarCid ?? ''),
    [friend.userProfile?.avatarCid],
  );

  return (
    <fieldset className={css.userInfo} onClick={onClick}>
      <div className={css.avatar}>
        <img alt="Avatar" src={avatarSource} />
      </div>
      <h3 className={css.nickName}>{friend.userProfile?.nickname}</h3>
    </fieldset>
  );
};
