import { useQuery } from 'react-query';

import { getAllAlchemyNFTs } from 'services/apiServices/alchemyService';

import { useLoading } from './useLoading';

interface Props {
  includeBeasy?: boolean;
  contractAddresses?: string[];
}

export const useGetAlchemyNFTs = ({ includeBeasy, contractAddresses }: Props) => {
  const query = useQuery(['getAlchemyNFTs', contractAddresses], () =>
    getAllAlchemyNFTs({ includeBeasy, contractAddresses }),
  );

  useLoading(query.isLoading);

  return query;
};
