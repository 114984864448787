import React from 'react';
import { Link } from 'react-router-dom';

import { Chevron } from 'assets/icons';

import css from './index.module.css';

interface Props {
  to: string;
  label: string;
}

export const MenuItem = ({ to, label }: Props) => {
  return (
    <li className={css.container}>
      <Link className={css.label} to={to}>
        {label}
        <Chevron size={16} className={css.arrow} />
      </Link>
    </li>
  );
};
