import React from 'react';

import beasyLogo from 'assets/images/beasyLogo.png';
import beasyLogo2x from 'assets/images/beasyLogo@2x.png';
import beasyLogo3x from 'assets/images/beasyLogo@3x.png';

import { LandingHeader } from 'components/Header/LandingHeader';

import { environment } from 'environment';

import packageInfo from '../../../../package.json';
import { MenuItem } from './MenuItem';
import css from './index.module.css';

export const AboutPage = () => {
  return (
    <div className={css.container}>
      <LandingHeader isGoBack={true} />
      <h1 className="pageTitle">About</h1>
      <section className={css.content}>
        <img
          className={css.logo}
          src={beasyLogo}
          alt="beasyLogo"
          srcSet={`${beasyLogo} 1x, ${beasyLogo2x} 2x, ${beasyLogo3x} 3x`}
        />
        <p className={css.version}>v{packageInfo.version}</p>
        <ul className={css.menu}>
          <MenuItem href={environment.externalLinks.termsOfService} label="Terms of Service" />
          <MenuItem href={environment.externalLinks.privacyPolicy} label="Privacy Policy" />
        </ul>
      </section>
    </div>
  );
};
