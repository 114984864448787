import React from 'react';
import { useAppSelector } from 'redux/configStore';
import { selectorAlert } from 'redux/reducers/alert';
import { currentTxSelector } from 'redux/reducers/tx';

import { AlertType } from 'constants/enumTypes';

import { ApproveEthSignTypedData } from './ApproveEthSignTypedData';
import { ApprovePersonalSign } from './ApprovePersonalSign';
import { Avatar } from './Avatar';
import { Disconnect } from './Disconnect';
import { PinForMnemonic } from './PinForMnemonic';
import { PinLock } from './PinLock';
import { PrivacyPolicyUpdate } from './PrivacyPolicyUpdate';
import { SendTransaction } from './SendTransaction';
import { SwitchChain } from './SwitchChain';
import { SwitchPayMethod } from './SwitchPayMethod';
import { TxProgress } from './TxProgress';
import { Pairing } from './Pairing';
import { Sessions } from './Sessions';

export const AppModals = () => {
  const { alertType, isVisibleAlert } = useAppSelector(selectorAlert);
  const currentTx = useAppSelector(currentTxSelector);

  if (isVisibleAlert) {
    switch (alertType) {
      case AlertType.Disconnect:
        return <Disconnect />;
      case AlertType.SendTransaction:
        return <SendTransaction />;
      case AlertType.ApprovePersonalSign:
        return <ApprovePersonalSign />;
      case AlertType.ApproveEthSignTypedData:
        return <ApproveEthSignTypedData />;
      case AlertType.SwitchChain:
        return <SwitchChain />;
      case AlertType.SwitchPayMethod:
        return <SwitchPayMethod />;
      case AlertType.PinLock:
        return <PinLock />;
      case AlertType.PinForMnemonic:
        return <PinForMnemonic />;
      case AlertType.Avatar:
        return <Avatar />;
      case AlertType.PrivacyPolicyUpdate:
        return <PrivacyPolicyUpdate />;
      case AlertType.Pairing:
        return <Pairing />;
      case AlertType.Session:
        return <Sessions/>
    }
  }

  if (currentTx) {
    return <TxProgress tx={currentTx} />;
  }

  return null;
};
