//import { captureException } from '@sentry/react';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/configStore';
import { updateAccountInfo } from 'redux/reducers/accountInfo';
import { chainIdSelector } from 'redux/reducers/blockchain';
import { updateFlags } from 'redux/reducers/flags';
import { mnemonicSelector, usernameSelector } from 'redux/reducers/onboarding';
import { init as initTx } from 'redux/reducers/tx';
import { WalletFactory, providerService, walletPersistenceService, walletService } from 'services';

import { makeAuthMeta } from 'services/auth';
import { subscriberClient } from 'services/subscriber';

import { initIpfsApi } from '../services/apiServices/ipfs';
import { useCheckPrivacyPolicy } from './useCheckPrivacyPolicy';

export const useLogin = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const checkPrivacyPolicy = useCheckPrivacyPolicy();

  const mnemonic = useSelector(mnemonicSelector);
  const username = useSelector(usernameSelector);
  const chainId = useSelector(chainIdSelector);

  return useCallback(
    async (passcode: string) => {
      try {
        dispatch(updateFlags({ isLoading: true }));

        const wallet = WalletFactory.fromMnemonic(mnemonic);
        if (!wallet) {
          dispatch(updateFlags({ isLoading: false }));
          return false;
        }

        await walletPersistenceService.saveWallet(wallet, '', passcode);

        walletService.setWallet(wallet);
        await providerService.setProvider(137)
        await dispatch(initTx());

        const { jwt } = await makeAuthMeta();
        initIpfsApi(jwt);

        if (username) {
          await queryClient.refetchQueries('getUserProfile');
        }

        await subscriberClient.start();

        dispatch(updateAccountInfo({ address: walletService.getAddress(), isLoggedIn: true }));

        setTimeout(() => {
          queryClient.refetchQueries('balance').then();
          checkPrivacyPolicy();
        });
       
        return true;
      } catch (error) {
        //captureException(error);
      } finally {
        dispatch(updateFlags({ isLoading: false }));
      }
    },
    [dispatch, mnemonic, chainId, username, queryClient, checkPrivacyPolicy],
  );
};
