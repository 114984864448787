//import { captureException } from '@sentry/react';
import { SessionTypes } from '@walletconnect/types';

const StorageKey = 'WalletSession';

export class WalletConnectSessionPersistenceService {
  getWalletSession(): SessionTypes.Struct | null {
    try {
      const contents = localStorage.getItem(StorageKey);

      if (contents) {
        return JSON.parse(contents);
      }

      return null;
    } catch (error) {
      //captureException(error);
      return null;
    }
  }

  setWalletSession(walletSession: SessionTypes.Struct) {
    try {
      const contents = JSON.stringify(walletSession);
      localStorage.setItem(StorageKey, contents);
    } catch (error) {
      //captureException(error);
    }
  }

  removeWalletSession() {
    try {
      localStorage.removeItem(StorageKey);
    } catch (error) {
      //captureException(error);
    }
  }
}
