import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AlertType } from 'constants/enumTypes';

import { RootState } from '../configStore';

interface AlertData {
  tx: any;
  tokenId: string;
  friendName: string;
  from: string;
  to: string;
}

interface Alert {
  isVisibleAlert: boolean;
  alertType?: AlertType;
  message?: string;
  sessionData?: any;
  data?: AlertData;
}

const alertInitialState: Alert = {
  isVisibleAlert: false,
};

const alertSlice = createSlice({
  name: 'alert',
  initialState: alertInitialState,
  reducers: {
    updateAlertModal: (state, { payload }: PayloadAction<Alert>) => {
      return { ...state, ...payload };
    },
  },
});

export { alertSlice };

export const { updateAlertModal } = alertSlice.actions;

export const selectorAlert = (state: RootState): Alert => state.alert;
