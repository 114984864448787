import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { generatePath, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/configStore';
import { isTransferCryptoFormValidSelector, transfer, transferSelector } from 'redux/reducers/transfer';
import { isBalanceSufficientSelector, txPriceSelector } from 'redux/reducers/tx';

import { EthereumIcon } from 'assets/icons/Network/EthereumIcon';
import { PolygonIcon } from 'assets/icons/Network/PolygonIcon';
import checkCircleSuccess from 'assets/images/checkCircleSuccess.png';
import checkCircleSuccess2x from 'assets/images/checkCircleSuccess@2x.png';
import checkCircleSuccess3x from 'assets/images/checkCircleSuccess@3x.png';
import exclamation from 'assets/images/exclamation.png';
import exclamation2x from 'assets/images/exclamation@2x.png';
import exclamation3x from 'assets/images/exclamation@3x.png';

import Button, { ButtonColorScheme } from 'components/Button';
import { LandingHeader } from 'components/Header/LandingHeader';
import { ErrorMessage } from 'components/Messages/ErrorMessage';
import { TransferProgressBar } from 'components/TransferProgressBar';
import { UserInfo } from 'components/UserInfo';
import { TextAreaInputWithCopy } from 'components/inputs/TextAreaInputWithCopy';

import { Path } from 'constants/enumTypes';

import { useNetwork } from 'hooks/useNetwork';

import { getUserProfile, UserProfileQueryKey } from 'services/userProfile';

import { Balance } from '../Balance';
import { FeeDetails } from './FeeDetails';
import css from './index.module.css';

const TABS = ['1. NFT/Crypto', '2. Transaction details', '3. Choose recipient', '4. Review'];

export const TransferCryptoConfirmationPage = () => {
  const [selectedTabIndex] = useState(3);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { recipientAddress = '' } = useParams();

  const { amount, coinName } = useAppSelector(transferSelector);
  const isFormValid = useAppSelector(isTransferCryptoFormValidSelector);
  const txPrice = useAppSelector(txPriceSelector);
  const isBalanceSufficient = useAppSelector(isBalanceSufficientSelector);
  const { currentCoinCode } = useNetwork();

  const transferMutation = useMutation(async () => {
    await dispatch(transfer());
    navigate(Path.Transfers);
  });

  const friendQuery = useQuery([UserProfileQueryKey.getUserProfile, recipientAddress], () =>
    getUserProfile(recipientAddress),
  );

  const cancel = () => {
    navigate(generatePath(Path.Nfts));
  };

  if (!isFormValid) {
    return <Navigate to={Path.Transfers} />;
  }

  return (
    <>
      <div className={css.container}>
        <div className={css.headerPage}>
          <LandingHeader isGoBack={true} />
          <h1 className="pageTitle">Transfer Details</h1>
        </div>

        <section className={css.contentContainer}>
          <p className={css.description}>In order to transfer your tokens, you will need to pay the gas fee</p>

          <div className={css.feeDetails}>
            <h2 className={css.feeDetailsTitle}>Fee details</h2>
            <FeeDetails
              amount={amount ?? 0}
              fee={txPrice ?? 0}
              coinName={currentCoinCode}
              currencyName={currentCoinCode}
            />
          </div>

          <div className={css.paymentMethod}>
            <h2 className={css.paymentMethodTitle}>Payment Method</h2>

            <div className={css.paymentMethodContents}>
              <span>{currentCoinCode}</span>
            </div>
          </div>

          <div className={css.balance}>
            <Balance currencyName={currentCoinCode} />

            {isBalanceSufficient ? (
              <img
                className={css.balanceStatusIcon}
                src={checkCircleSuccess}
                alt=""
                srcSet={`${checkCircleSuccess} 1x, ${checkCircleSuccess2x} 2x, ${checkCircleSuccess3x} 3x`}
              />
            ) : (
              <img
                className={css.balanceStatusIcon}
                src={exclamation}
                alt="Insufficient funds"
                srcSet={`${exclamation} 1x, ${exclamation2x} 2x, ${exclamation3x} 3x`}
              />
            )}
          </div>

          {transferMutation.isError && <ErrorMessage />}

          {isBalanceSufficient && (
            <Button
              className={css.transferButton}
              disabled={transferMutation.isLoading}
              onClick={() => transferMutation.mutate()}
            >
              {transferMutation.isError ? 'Try Again' : 'Transfer'}
            </Button>
          )}
        </section>
      </div>

      <div className={css.containerDesktop}>
        <TransferProgressBar className={css.tabs} tabs={TABS} selectedTabIndex={selectedTabIndex} />
        <section className={css.contentContainer}>
          <div className={css.reviewUser}>
            <UserInfo
              avatarCid={friendQuery.data?.avatarCid ?? ''}
              nickname={friendQuery.data?.nickname ?? ''}
              business={friendQuery.data?.additionalData?.businessName ?? ''}
            />
            <TextAreaInputWithCopy label="Address" value={recipientAddress} successMessage="Address copied" />
          </div>
          <div className={css.spacer}></div>
          <div className={css.payReview}>
            <p className={css.description}>
              In order to transfer your tokens, you will need to pay the following fees:
            </p>

            <div className={css.feeDetails}>
              <h2 className={css.feeDetailsTitle}>Fee details</h2>
              <FeeDetails amount={amount ?? 0} fee={txPrice ?? 0} coinName={coinName} currencyName={coinName} />
            </div>

            <div className={css.paymentMethod}>
              <h2 className={css.paymentMethodTitle}>Payment Method</h2>

              <div className={css.paymentMethodContents}>
                {coinName === 'MATIC' ? (
                  <PolygonIcon className={css.paymentMethodIcon} />
                ) : (
                  <EthereumIcon className={css.paymentMethodIcon} />
                )}

                <span>{currentCoinCode}</span>
              </div>
            </div>

            <div className={css.balance}>
              <Balance currencyName={coinName} />

              {isBalanceSufficient ? (
                <img
                  className={css.balanceStatusIcon}
                  src={checkCircleSuccess}
                  alt=""
                  srcSet={`${checkCircleSuccess} 1x, ${checkCircleSuccess2x} 2x, ${checkCircleSuccess3x} 3x`}
                />
              ) : (
                <img
                  className={css.balanceStatusIcon}
                  src={exclamation}
                  alt="Insufficient funds"
                  srcSet={`${exclamation} 1x, ${exclamation2x} 2x, ${exclamation3x} 3x`}
                />
              )}
            </div>

            {transferMutation.isError && <ErrorMessage />}
          </div>
        </section>
        {isBalanceSufficient ? (
          <div className={css.footerContainer}>
            <div className={css.cancelButton}>
              <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={cancel}>
                Cancel
              </Button>
            </div>
            <div className={css.continueButton}>
              <div className={css.previousButton}>
                <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={() => navigate(-1)}>
                  Previous Step
                </Button>
              </div>
              <div className={css.previousButton}>
                <Button disabled={!txPrice} onClick={() => transferMutation.mutate()}>
                  Transfer Digital Asset
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className={css.footerContainer}>
              <div className={css.cancelButton}>
                <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={cancel}>
                  Cancel
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
