import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { LandingHeader } from 'components/Header/LandingHeader';

import { Path } from 'constants/enumTypes';

import { CryptoBalance } from './CryptoBalance';
import css from './index.module.css';

export const BalancePage = () => {
  const navigate = useNavigate();
  const handleTopUpClick = useCallback(() => navigate(Path.BalanceTopUp), [navigate]);

  return (
    <div className={css.container}>
      <LandingHeader isGoBack={true} />
      <h1 className="pageTitle">Balance</h1>
      <CryptoBalance onTopUpClick={handleTopUpClick} />
    </div>
  );
};
