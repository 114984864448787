import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { accountInfoSlice } from './reducers/accountInfo';
import { alertSlice } from './reducers/alert';
import { blockchainSlice } from './reducers/blockchain';
import { connectedApplicationsSlice } from './reducers/connectedApplications';
import { flagsSlice } from './reducers/flags';
import { onboardingSlice } from './reducers/onboarding';
import { transferSlice } from './reducers/transfer';
import { txSlice } from './reducers/tx';

const rootReducer = combineReducers({
  [flagsSlice.name]: flagsSlice.reducer,
  [accountInfoSlice.name]: accountInfoSlice.reducer,
  [connectedApplicationsSlice.name]: connectedApplicationsSlice.reducer,
  [alertSlice.name]: alertSlice.reducer,
  [blockchainSlice.name]: blockchainSlice.reducer,
  [onboardingSlice.name]: onboardingSlice.reducer,
  [transferSlice.name]: transferSlice.reducer,
  [txSlice.name]: txSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch = () => useDispatch<AppDispatch>();
