import React from 'react';

import css from './index.module.css';

interface Props {
  message: string;
}

export const EmptyListMessageText = ({ message }: Props) => {
  return (
    <div className={css.container}>
      <p className={css.text}>{message}</p>
    </div>
  );
};
