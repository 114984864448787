import { useState, ReactNode } from 'react';

import { ImageProps } from '../types';

interface Props extends ImageProps {
  fallback: ReactNode;
}

// from https://stackoverflow.com/a/59396181/7138254

/** you mostly should use ImageView  */
export const ImageLoading = ({ src, fallback, style, ...rest }: Props) => {
  const [isLoaded, setLoaded] = useState(false);

  return (
    <>
      {src && isLoaded ? null : fallback}
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img
        {...rest}
        src={src}
        style={src && isLoaded ? style : { ...style, display: 'none' }}
        onLoad={() => setLoaded(true)}
      />
    </>
  );
};
