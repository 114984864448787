//import { captureException } from '@sentry/react';

import { environment } from 'environment';

const PersistenceKey = 'chainId';

export class ChainPersistenceService {
  static getDefaultChainId(): number {
    const availableChains = environment.chains;

    try {
      const contents = localStorage.getItem(PersistenceKey);
      if (contents) {
        const { chainId } = JSON.parse(contents);

        // Verifying that we actually support this chain ID
        if (availableChains.some(chain => chain.chainId === chainId)) {
          return chainId;
        }
      }
      return availableChains[0].chainId;
    } catch (error) {
      //captureException(error);
      return availableChains[0].chainId;
    }
  }

  static setDefaultChainId(chainId: number) {
    try {
      localStorage.setItem(PersistenceKey, JSON.stringify({ chainId }));
    } catch (error) {
      //captureException(error);
    }
  }
}
