import React, { FC, ReactNode } from 'react';

import LoadingSpinner from 'components/loading/Spinner';

import css from './index.module.css';

export interface LoadingScreenProps {
  message?: string;
  children?: ReactNode;
}

export const LoadingScreen: FC<LoadingScreenProps> = ({ children, message }) => {
  return (
    <div className={css.backing}>
      {message && <div>{message}</div>}
      <LoadingSpinner />
      {children}
    </div>
  );
};

export default LoadingScreen;
