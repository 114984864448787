import clsx from 'clsx';

import css from './index.module.css';

interface Properties {
  tabs: string[];
  selectedTabIndex: number;
  onSelectedTabIndexChange?: (index: number) => void;
  // Optional:
  className?: string;
}

export const TransferProgressBar = ({ tabs, selectedTabIndex, onSelectedTabIndexChange, className }: Properties) => (
  <ul className={clsx(css.container, className)}>
    {tabs.map((tab, index) => (
      <li key={tab + index}>
        <button
          className={clsx(css.tab, selectedTabIndex === index ? css.active : '')}
        >
          {tab}
        </button>
      </li>
    ))}
  </ul>
);
