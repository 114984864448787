import { grpc } from '@improbable-eng/grpc-web';
//import { captureException } from '@sentry/react';

import { GrpcWebImpl, getAccountRes, deleteAccountRes, AccountServiceClientImpl } from 'protobuf/lib/account';

import { environment } from 'environment';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const accountServiceClient = new AccountServiceClientImpl(rpc);

export const getAccount = async (id: string): Promise<getAccountRes> => {
  try {
    return await accountServiceClient.getAccount({ id });
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

export const deleteAccount = async (id: string): Promise<deleteAccountRes> => {
  try {
    return await accountServiceClient.deleteAccount({ id });
  } catch (error) {
    //captureException(error);
    throw error;
  }
};
