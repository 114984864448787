import React from 'react';
import QRCode from 'react-qr-code';

import Button, { ButtonColorScheme } from 'components/Button';
import { Modal } from 'components/Modal';

import css from './index.module.css';

interface Props {
  tokenId: string;
  contractAddress?: string;
  onClose: () => void;
}

export const HistoryQrCodeModal = ({ tokenId, onClose, contractAddress }: Props) => {
  const url = contractAddress
    ? `${window.location.host}/NFTs/${contractAddress}/${tokenId}/history`
    : `${window.location.host}/NFTs/${tokenId}/history`;

  return (
    <Modal>
      <div className={css.modalContent}>
        <div className={css.qrCode}>
          <QRCode value={url} size={256} />
        </div>

        <Button className={css.button} colorScheme={ButtonColorScheme.SECONDARY} onClick={onClose}>
          Done
        </Button>
      </div>
    </Modal>
  );
};
