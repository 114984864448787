import { grpc } from '@improbable-eng/grpc-web';
//import { captureException } from '@sentry/react';

import placeholderProfile from 'assets/images/profile_placeholder.png';

import { GrpcWebImpl, UserProfile, UserProfileServiceClientImpl } from 'protobuf/lib/userProfileService';

import { environment } from 'environment';

import { walletService } from '.';
import { settings } from '../settings';
import { getIpfsApi } from './apiServices/ipfs';
import { authenticated, isGrpcError } from './grpc';
import generateThumbnail from './thumbnail';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const userProfileServiceClient = new UserProfileServiceClientImpl(rpc);

export enum UserProfileQueryKey {
  getUserProfile = 'getUserProfile',
  createUserProfile = 'createUserProfile',
  getRoleNameMap = 'getRoleNameMap',
}

export class UserProfileAlreadyExistsError extends Error {}

export const getUserProfile = async (address: string): Promise<UserProfile> => {
  try {
    return await userProfileServiceClient.GetUserProfile({ ethAddress: address });
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

export const getCurrentUserProfile = async (): Promise<UserProfile> => {
  try {
    const ethAddress = walletService.getAddress();
    return await userProfileServiceClient.GetUserProfile({ ethAddress });
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

export const createUserProfile = async (
  ethAddress: string,
  nickname: string,
  avatarCid?: string,
): Promise<UserProfile> => {
  try {
    return await userProfileServiceClient.CreateUserProfile({ ethAddress, nickname, avatarCid });
  } catch (error) {
    //captureException(error);

    if (isGrpcError(error) && error.code === grpc.Code.AlreadyExists) {
      throw new UserProfileAlreadyExistsError();
    }

    throw error;
  }
};

export const updateUserProfile = async (nickname?: string, avatarCid?: string): Promise<UserProfile> => {
  try {
    const ethAddress = walletService.getAddress();
    return await authenticated(meta => {
      return userProfileServiceClient.UpdateUserProfile({ ethAddress, data: { nickname, avatarCid } }, meta);
    });
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

export const updateUserAvatar = async (avatar: File): Promise<UserProfile> => {
  try {
    const currentUserProfile = await getCurrentUserProfile();
    const avatarThumbnail = await generateThumbnail(avatar, settings.thumbnails);
    const avatarToUpload = avatarThumbnail ?? avatar;
    const avatarCid = await getIpfsApi().add(avatarToUpload);
    return await authenticated(meta => {
      return userProfileServiceClient.UpdateUserProfile(
        {
          ethAddress: currentUserProfile.ethAddress,
          data: {
            nickname: currentUserProfile.nickname,
            avatarCid,
            additionalData: currentUserProfile.additionalData,
          },
        },
        meta,
      );
    });
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

export const getAvatarUriFromCid = (avatarCid: string): string => {
  return avatarCid ? `${environment.ipfsGatewayUrl}/${avatarCid}` : placeholderProfile;
};

export const getAvatarSrcFromCid = (avatarCid: string) => {
  return avatarCid ? { uri: `${environment.ipfsGatewayUrl}/${avatarCid}` } : placeholderProfile;
};

export interface RoleNameMap {
  [key: number]: string;
}

export const getRoleNameMap = async (): Promise<RoleNameMap> => {
  try {
    const response = await userProfileServiceClient.GetRoles({});
    return response.roles.reduce((acc, item) => ({ ...acc, [item.role]: item.roleName }), {});
  } catch (error) {
    //captureException(error);
    throw error;
  }
};
