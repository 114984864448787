import React from 'react';

import close from 'assets/images/close.png';
import close2x from 'assets/images/close@2x.png';
import close3x from 'assets/images/close@3x.png';

import css from './index.module.css';

interface Props {
  value: string[];
  onClear: () => void;
  onRemove: (world: string) => void;
}

export const Mnemonic = ({ value, onRemove, onClear }: Props) => {
  return (
    <div className={css.container}>
      {value.map(word => (
        <div key={word} className={css.word} onClick={() => onRemove(word)}>
          {word}
        </div>
      ))}
      <button className={css.clear} onClick={onClear}>
        <img title="Clear" src={close} alt="close" srcSet={`${close} 1x, ${close2x} 2x, ${close3x} 3x`} />
      </button>
    </div>
  );
};
