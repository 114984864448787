import clsx from 'clsx';
import React from 'react';

import { IconProps } from '../types';
import css from './index.module.css';

const ASPECT_RATIO = 1.083;

export const Close = ({ className, size = 13, onClick }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(css.icon, className)}
    onClick={onClick}
    width={size}
    height={size / ASPECT_RATIO}
    viewBox="0 0 13 12"
  >
    <path d="M8.40278 5.99915L12.6986 1.71268C12.8867 1.52453 12.9924 1.26935 12.9924 1.00326C12.9924 0.737183 12.8867 0.481998 12.6986 0.29385C12.5105 0.105701 12.2554 0 11.9893 0C11.7233 0 11.4681 0.105701 11.28 0.29385L6.99414 4.59031L2.70828 0.29385C2.52016 0.105701 2.26501 -1.98247e-09 1.99897 0C1.73292 1.98247e-09 1.47778 0.105701 1.28965 0.29385C1.10153 0.481998 0.995846 0.737183 0.995846 1.00326C0.995846 1.26935 1.10153 1.52453 1.28965 1.71268L5.5855 5.99915L1.28965 10.2856C1.19602 10.3785 1.12169 10.489 1.07097 10.6108C1.02025 10.7325 0.994141 10.8631 0.994141 10.995C0.994141 11.1269 1.02025 11.2575 1.07097 11.3793C1.12169 11.501 1.19602 11.6116 1.28965 11.7044C1.38253 11.7981 1.49302 11.8724 1.61476 11.9232C1.7365 11.9739 1.86708 12 1.99897 12C2.13085 12 2.26143 11.9739 2.38317 11.9232C2.50491 11.8724 2.61541 11.7981 2.70828 11.7044L6.99414 7.40799L11.28 11.7044C11.3729 11.7981 11.4834 11.8724 11.6051 11.9232C11.7268 11.9739 11.8574 12 11.9893 12C12.1212 12 12.2518 11.9739 12.3735 11.9232C12.4953 11.8724 12.6058 11.7981 12.6986 11.7044C12.7923 11.6116 12.8666 11.501 12.9173 11.3793C12.968 11.2575 12.9941 11.1269 12.9941 10.995C12.9941 10.8631 12.968 10.7325 12.9173 10.6108C12.8666 10.489 12.7923 10.3785 12.6986 10.2856L8.40278 5.99915Z" />
  </svg>
);
