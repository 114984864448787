export const roundPrice = (num: number, accuracy = 100) => Math.round((num + Number.EPSILON) * accuracy) / accuracy;

export const roundEth = (value: number) => roundPrice(value, 10e6).toFixed(6);


export const fromScientificNotationToDecimal = (amount:number) => {
    let amountString = amount.toString();
    if (amountString.includes('e-')) {
        const exponente = amountString.substring(amountString.indexOf('-') + 1, amountString.length);
        const digitos = amountString.substring(0,amountString.indexOf('e')).length;
        const corrector = digitos === 1 ? 0 : digitos-2;
        amountString = amount.toFixed(parseInt(exponente)+corrector);
    }
    return amountString;
}


