import { grpc } from '@improbable-eng/grpc-web';
//import { captureException } from '@sentry/react';

import { Friend, FriendServiceClientImpl, GrpcWebImpl } from 'protobuf/lib/friend';

import { environment } from 'environment';

import { authenticated, isGrpcError } from '../grpc';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const friendServiceClient = new FriendServiceClientImpl(rpc);

export const readFriends = async (address: string): Promise<Friend[]> => {
  const response = await authenticated(async meta => {
    return await friendServiceClient.GetFriends({ address }, meta);
  });

  return response.friends;
};

export const readFriend = async (address: string, friendAddress: string): Promise<Friend> => {
  const response = await authenticated(async meta => {
    return await friendServiceClient.GetFriend({ address, friendAddress }, meta);
  });

  if (response.friend) {
    return response.friend;
  }

  throw new Error();
};

export const createFriendSender = async (address: string, name: string): Promise<string> => {
  try {
    const response = await authenticated(async meta => {
      return await friendServiceClient.CreateFriendSender({ address, name }, meta);
    });

    return response.code;
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

export class FriendNotFoundError extends Error {}
export class FriendAddressBelongsToSenderError extends Error {}

export const createFriendRecipient = async (address: string, code: string): Promise<Friend> => {
  try {
    const response = await authenticated(async meta => {
      return await friendServiceClient.CreateFriendRecipient({ address, code }, meta);
    });

    if (!response.friend) {
      throw new FriendNotFoundError();
    }

    return response.friend;
  } catch (error) {
    if (isGrpcError(error) && error.code === grpc.Code.InvalidArgument) {
      throw new FriendAddressBelongsToSenderError();
    }

    //captureException(error);
    throw error;
  }
};
