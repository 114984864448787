import React, { useReducer } from 'react';

import Button from 'components/Button';
import { ButtonColorScheme } from 'components/Button';
import { LandingHeader } from 'components/Header/LandingHeader';

import { Path } from 'constants/enumTypes';

import { LogoutConfirmationDialog } from './LogoutConfirmationDialog';
import { MenuItem } from './MenuItem';
import css from './index.module.css';

export const ProfilePage = () => {
  const [isLogoutConfirmationShown, toggleIsLogoutConfirmationShown] = useReducer((isShow: boolean) => !isShow, false);

  return (
    <div className={css.container}>
      <LandingHeader />
      <h1 className="pageTitle">My Profile</h1>
      <section className={css.content}>
        <ul className={css.menu}>
          <MenuItem to={Path.Account} label="Account" />
          <MenuItem to={Path.Balance} label="Balance" />
          <MenuItem to={Path.Notifications} label="Notifications" />
          <MenuItem to={Path.CustomerSupport} label="Customer Support" />
          <MenuItem to={Path.About} label="About" />
        </ul>
        <Button
          className={css.button}
          colorScheme={ButtonColorScheme.SECONDARY}
          onClick={toggleIsLogoutConfirmationShown}
        >
          Log out
        </Button>
        {isLogoutConfirmationShown && <LogoutConfirmationDialog onCancel={toggleIsLogoutConfirmationShown} />}
      </section>
    </div>
  );
};
