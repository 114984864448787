import React from 'react';
import { isZeroAddress } from 'utils';

import TransferCardDefault from './TransferCardDefault';
import TransferCardMint from './TransferCardMint';

interface Props {
  from: string;
  to: string;
}

export const TransferCard = ({ from, to }: Props) => {
  if (isZeroAddress(from)) {
    return <TransferCardMint to={to} />;
  }

  return <TransferCardDefault from={from} to={to} />;
};

export default TransferCard;
