import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setPin } from 'redux/reducers/onboarding';

import Button from 'components/Button';
import { OnboardingHeader } from 'components/Header/OnboardingHeader';
import { WebVersion } from 'components/NFTLogo';
import { Pin } from 'components/Pin';

import css from './index.module.css';

interface Props {
  onNextStepAuth: () => void;
  onPrevStepAuth: () => void;
}

export const CreatePin = ({ onNextStepAuth, onPrevStepAuth }: Props) => {
  const [passcode, setPasscode] = useState<number[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (passcode.length === 4) {
      dispatch(setPin(passcode.join('')));
      onNextStepAuth();
    }
  }, [passcode, dispatch, onNextStepAuth]);

  return (
    <div className={css.container}>
      <div className={css.container__info}>
        <div className={css.header}>
          <OnboardingHeader goBack={onPrevStepAuth} />
          <div className={css.title__mobile}>Create a passcode</div>
          <div className={css.title__desktop}>Enter passcode</div>
          <div className={css.description}>Enter a 4-digit passcode that will be used for accessing the app</div>
        </div>
        <div className={css.content}>
          <Pin value={passcode} onChange={setPasscode} />
        </div>
        <div className={css.disabledButton}>
          <Button disabled={true}>Continue</Button>
        </div>
      </div>
      <WebVersion />
    </div>
  );
};
