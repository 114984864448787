import clsx from 'clsx';
import React from 'react';

import css from './index.module.css';

export enum ButtonColorScheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ERROR = 'error',
}

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  // Optional:
  colorScheme?: ButtonColorScheme;
}

const Button = ({ colorScheme = ButtonColorScheme.PRIMARY, className, ...rest }: Props) => {
  return <button className={clsx(css.button, css[colorScheme], className)} {...rest} />;
};

export default Button;
