import React, { useCallback } from 'react';
import { useAppDispatch } from 'redux/configStore';
import { updateAlertModal } from 'redux/reducers/alert';

import Button, { ButtonColorScheme } from 'components/Button';
import { Modal } from 'components/Modal';

import { savePrivacyPolicyDate } from 'services/privacyPolicy';

import { environment } from 'environment';

import css from './index.module.css';

export const PrivacyPolicyUpdate = () => {
  const dispatch = useAppDispatch();
  const readPrivacyPolicy = useCallback(() => {
    window.open(environment.externalLinks.privacyPolicy, '_blank');
  }, []);

  const agreePrivacyPolicy = useCallback(() => {
    savePrivacyPolicyDate().then();
    dispatch(updateAlertModal({ isVisibleAlert: false }));
  }, [dispatch]);

  return (
    <Modal>
      <div className={css.container}>
        <h2>Important</h2>
        <div>
          <h1>Privacy policy update</h1>
          <p>
            This is to inform you that we have updated our privacy policy. Please do not disregard this message like you
            usually do
          </p>
        </div>
        <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={readPrivacyPolicy}>
          Read Privacy Policy
        </Button>
        <Button onClick={agreePrivacyPolicy}>Agree</Button>
      </div>
    </Modal>
  );
};
