import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { mnemonicSelector } from 'redux/reducers/onboarding';
import { copyToClipboard } from 'utils';

import duplicate from 'assets/images/duplicate.png';
import duplicate2x from 'assets/images/duplicate@2x.png';
import duplicate3x from 'assets/images/duplicate@3x.png';

import Button from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { OnboardingHeader } from 'components/Header/OnboardingHeader';
import { WebVersion } from 'components/NFTLogo';

import css from './index.module.css';

interface Props {
  onNextStepAuth: () => void;
  onPrevStepAuth: () => void;
}

export const MnemonicReview = ({ onNextStepAuth, onPrevStepAuth }: Props) => {
  const [isChecked, setIsChecked] = useState(false);

  const mnemonic = useSelector(mnemonicSelector);

  const handleAgreeCheckboxPress = useCallback(() => {
    setIsChecked(isChecked => !isChecked);
  }, [setIsChecked]);

  const handleCopy = useCallback(
    () => copyToClipboard({ data: mnemonic, successMessage: 'Mnemonic phrase copied' }),
    [mnemonic],
  );

  return (
    <section className={css.container}>
      <div className={css.container__info}>
        <OnboardingHeader goBack={onPrevStepAuth} />
        <article>
          <p className={css.createLabel}>Your mnemonic phrase</p>
          <p>
            Write down or copy these words in the right order and save them somewhere safe. If you lose it, you’ll lose
            access to your account.
          </p>
        </article>
        <fieldset className={css.mnemonic}>
          <label htmlFor="mnemonic">Mnemonic Phrase</label>
          <textarea name="mnemonic" value={mnemonic} readOnly />
          <button className={css.duplicate} onClick={handleCopy}>
            <img src={duplicate} alt="duplicate" srcSet={`${duplicate} 1x, ${duplicate2x} 2x, ${duplicate3x} 3x`} />
          </button>
        </fieldset>
        <article className={css.agreement}>
          <Checkbox isChecked={isChecked} onClick={handleAgreeCheckboxPress} />
          <p>I understand that if I lose my mnemonic phrase, I will not be able to access my account.</p>
        </article>
        <Button disabled={!isChecked} onClick={onNextStepAuth}>
          Continue
        </Button>
      </div>
      <WebVersion />
    </section>
  );
};
