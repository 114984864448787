import { NftMetadata } from 'types';

import { LocalStorageService } from './LocalStorageService';

export interface IpfsNftsMetadata {
  [k: string]: NftMetadata;
}

const NFT_METADATA_KEY_LOCALSTORAGE = 'IpfsNftMetadata';

export class NftMetadaPersistenceService {
  static getIpfsNftMetadata = (key: string): NftMetadata | null => {
    const nftsMetadata = LocalStorageService.getItem<IpfsNftsMetadata>(NFT_METADATA_KEY_LOCALSTORAGE);

    if (nftsMetadata && nftsMetadata[key]) {
      return nftsMetadata[key];
    }

    return null;
  };

  static setIpfsNftsMetadata = (key: string, nftMetadata: NftMetadata): void => {
    const nftsMetadata = LocalStorageService.getItem<IpfsNftsMetadata>(NFT_METADATA_KEY_LOCALSTORAGE) ?? {};

    nftsMetadata[key] = nftMetadata;
    LocalStorageService.setItem(NFT_METADATA_KEY_LOCALSTORAGE, nftsMetadata);
  };
}
