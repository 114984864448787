//import { captureException } from '@sentry/react';
import { useFormik } from 'formik';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorIsVisibleScanner, updateFlags } from 'redux/reducers/flags';

import { pasteFromClipboard } from 'utils';

import paste from 'assets/images/paste.png';
import paste2x from 'assets/images/paste@2x.png';
import paste3x from 'assets/images/paste@3x.png';

import Button from '../Button';
import { InputErrorMessage } from '../Messages/InputErrorMessage';
import { Modal } from '../Modal';
import { QRScanner } from './QrScanner';
import css from './index.module.css';
import { web3wallet } from 'services/WalletConnectV2';

const isForgeUri = (uri: string): boolean => uri.startsWith('wc:');
const isLocalUri = (uri: string): boolean => uri.startsWith(`${window.location.host}`);

export const Scanner = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isVisibleScanner = useAppSelector(selectorIsVisibleScanner);

  const handleClose = useCallback(() => {
    dispatch(updateFlags({ isVisibleScanner: false }));
  }, [dispatch]);

  const handleScan = useCallback(
    async (uri: string) => {
      try {
        if (isForgeUri(uri)) {
          await web3wallet.pair({uri});
        } else if (isLocalUri(uri)) {
          navigate(uri.slice(window.location.host.length));
        }
      } catch (error) {
        //captureException(error);
        throw error;
      } finally {
        handleClose();
      }
    },
    [handleClose, navigate],
  );

  const formik = useFormik({
    initialValues: {
      uri: '',
    },
    onSubmit: ({ uri }, { resetForm, setFieldError }) => {
      if (isForgeUri(uri) || isLocalUri(uri)) {
        handleScan(uri).then();
        resetForm();
      } else {
        setFieldError('uri', 'Invalid uri');
      }
    },
  });

  const handlePaste = useCallback(
    async (e:any) => {
      e.preventDefault();
      const text = await pasteFromClipboard();
      await formik.setFieldValue('uri', text);
    },
    [formik],
  );

  return isVisibleScanner ? (
    <Modal className={css.modal} onClose={handleClose}>
      <section className={css.container}>
        <QRScanner onScan={handleScan} />
        <div className={css.uriContainer}>
          <div className={css.field}>
            <input
              className={css.uriInput}
              value={formik.values.uri}
              onChange={formik.handleChange('uri')}
              placeholder="Paste the copied uri"
            />
            {formik.errors.uri && formik.touched.uri && <InputErrorMessage message={formik.errors.uri} />}
          </div>
          <button className={css.paste} onClick={handlePaste}>
            <img src={paste} alt="paste" srcSet={`${paste} 1x, ${paste2x} 2x, ${paste3x} 3x`} />
          </button>

          <Button
            onClick={e => {
              e.preventDefault();
              formik.submitForm().then();
            }}
          >
            Submit
          </Button>
        </div>
      </section>
    </Modal>
  ) : null;
};
