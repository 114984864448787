import { useQuery } from 'react-query';
import { walletService, wethService } from 'services';

import { getAlchemyBalance } from 'services/apiServices/alchemyService';

import { useLoading } from './useLoading';
import { useNetwork } from './useNetwork';

export const useBalanceQuery = () => {
  return useQuery('balance', async () => await walletService.getBalance(), {
    refetchInterval: 60e3,
    staleTime: 120e3,
    retryOnMount: true,
  });
};

export const useWethBalanceQuery = () => {
  const { currentNetwork } = useNetwork();
  return useQuery('wethBalance', async () => await wethService.getBalance(currentNetwork?.wethAddress!), {
    refetchInterval: 60e3,
    staleTime: 120e3,
    retryOnMount: true,
  });
};

export const useAlchemyBalance = () => {
  const query = useQuery('AlchemyBalance', async () => getAlchemyBalance());

  useLoading(query.isLoading);

  return query;
};
