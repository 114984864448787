import React from 'react';
import { Link, generatePath } from 'react-router-dom';

import { Chip, ChipStatus } from 'components/Chip';
import { SmallNftCard } from 'components/SmallNftCard';
import TransferCard from 'components/TransferCard';

import { Path } from 'constants/enumTypes';

import { Transfer, TransferType, TxStatus } from 'types';

import { CryptoTransferCard } from '../CryptoTransferCard';
import css from './index.module.css';

interface Props {
  transfer: Transfer;
}

const chipStatuses = {
  [TxStatus.None]: ChipStatus.None,
  [TxStatus.Pending]: ChipStatus.Warning,
  [TxStatus.Success]: ChipStatus.None,
  [TxStatus.Error]: ChipStatus.Error,
};

const chipStatusLabels = {
  [TxStatus.None]: 'Pending',
  [TxStatus.Pending]: 'Pending',
  [TxStatus.Success]: 'Confirmed',
  [TxStatus.Error]: 'Rejected',
};

export const TradeHistoryItem = ({ transfer }: Props) => {
  const { type, from, to, txStatus, txHash, contractAddress } = transfer;

  return (
    <Link className={css.container} to={generatePath(Path.TxDetails, { contractAddress, hash: txHash })}>
      <div className={css.chips}>
        <Chip>
          {type === TransferType.Nft && <>NFT</>}
          {type === TransferType.Crypto && <>Crypto</>}
        </Chip>

        <Chip status={chipStatuses[txStatus]}>{chipStatusLabels[txStatus]}</Chip>
      </div>

      {transfer.type === TransferType.Nft && (
        <SmallNftCard contractAddress={transfer.contractAddress} tokenId={transfer.tokenId} />
      )}

      {transfer.type === TransferType.Crypto && (
        <CryptoTransferCard amount={transfer.amount} coinName={transfer.coinName} />
      )}

      {from && to && <TransferCard from={from} to={to} />}
    </Link>
  );
};
