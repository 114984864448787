import React from 'react';

import { Modal } from 'components/Modal';

import css from './index.module.css';

interface Props {
  tabs: string[];
  onSelect: (name: string) => void;
  onClose: () => void;
}

export const SelectTopicModal = ({ tabs, onSelect, onClose }: Props) => {
  return (
    <Modal className={css.modal} onClose={onClose}>
      <section className={css.container}>
        <h3 className={css.modalTitle}>Choose a category</h3>
        <ul className={css.menu}>
          {tabs.map((name, i) => (
            <li key={i} onClick={() => onSelect(name)}>
              {name}
            </li>
          ))}
        </ul>
      </section>
    </Modal>
  );
};
