import * as ethers from 'ethers';
import { providerService, walletService, wethService } from 'services';

import { MinimalTransactionInfo, TransactionInfo } from 'protobuf/lib/transactionMessage';

import { populateTransaction } from 'services/apiServices/transactionService';

const getNextGasPrice = <T1, T2>(v1: T1, v2: T2) => {
  const multiplier = 2;

  const bn1 = ethers.BigNumber.from(v1);
  const bn2 = ethers.BigNumber.from(v2);

  if (bn1.gt(bn2)) {
    return bn1.mul(multiplier).toHexString();
  }

  return bn2.mul(multiplier).toHexString();
};

export class TxGenerator {
  static async makeGenericTx(
    tx: Partial<ethers.providers.TransactionRequest>,
    skipFeeCalculation = false,
  ): Promise<ethers.providers.TransactionRequest> {
    if (skipFeeCalculation) {
      return await walletService.populateTransaction(tx);
    }
    const { primary, additional } = await populateTransaction({
      ...tx,
      chainId: providerService.getChainId(),
    } as MinimalTransactionInfo);

    return await walletService.populateTransaction({ ...primary, ...additional });
  }

  static async makeNftTransferTx(
    tokenId: string,
    from: string,
    to: string,
    contractAddress: string,
    tokenType: string,
  ): Promise<ethers.providers.TransactionRequest> {
    const draftTx = await wethService.makeNftTransferTx(from, to, tokenId, contractAddress, tokenType);

    const response = await populateTransaction({
      ...draftTx,
      chainId: providerService.getChainId(),
    } as MinimalTransactionInfo);

    const tx = { ...response.primary, ...response.additional };

    return await walletService.populateTransaction(tx);
  }

  static async makeCryptoTransferTx(
    amount: string,
    from: string,
    to: string,
  ): Promise<ethers.providers.TransactionRequest> {
    const response = await populateTransaction({
      from,
      to,
      value: amount,
      chainId: providerService.getChainId(),
    });

    return await walletService.populateTransaction({ ...response.primary, ...response.additional });
  }

  static async makeWethCryptoTransferTx(
    amount: string,
    from: string,
    to: string,
  ): Promise<ethers.providers.TransactionRequest> {
    const draftTx = await wethService.makeTransferTx(to, amount);

    const response = await populateTransaction({
      ...draftTx,
      chainId: providerService.getChainId(),
    } as MinimalTransactionInfo);

    const tx = { ...response.primary, ...response.additional };

    return await walletService.populateTransaction(tx);
  }

  static async makeResendTx(tx: ethers.providers.TransactionResponse): Promise<ethers.providers.TransactionRequest> {
    const txToResend = {
      chainId: tx.chainId,
      data: tx.data,
      from: tx.from,
      to: tx.to,
      type: tx.type,
      value: tx.value.toHexString(),
    };

    const { primary, additional }: TransactionInfo = await populateTransaction(txToResend as MinimalTransactionInfo);

    const secondTx: Partial<ethers.providers.TransactionRequest> = {
      ...primary,
      ...additional,
      nonce: tx.nonce,
    };

    if (secondTx.maxFeePerGas) {
      secondTx.maxFeePerGas = getNextGasPrice(tx.maxFeePerGas, secondTx.maxFeePerGas);
    }

    if (secondTx.maxPriorityFeePerGas) {
      secondTx.maxPriorityFeePerGas = getNextGasPrice(tx.maxPriorityFeePerGas, secondTx.maxPriorityFeePerGas);
    }

    if (secondTx.gasLimit) {
      secondTx.gasLimit = getNextGasPrice(tx.gasLimit, secondTx.gasLimit);
    }

    return await walletService.populateTransaction(secondTx);
  }
}
