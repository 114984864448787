import { Close } from 'assets/icons';

import { ButtonGhost } from 'components/ButtonGhost';

interface Props {
  onClick: React.MouseEventHandler;
}

export const DialogClose = ({ onClick }: Props) => {
  return (
    <ButtonGhost onClick={onClick}>
      <Close />
    </ButtonGhost>
  );
};
