import { Timers } from '../services/Timers';

export const setIncrementalInterval = (
  callback: () => void,
  initialInterval: number,
  maxInterval = Infinity,
  pollingInterval = 1e3,
  incrementBy = 2,
): ReturnType<typeof Timers.setInterval> => {
  let lastExecutionTime = 0;
  let interval = initialInterval;

  return Timers.setInterval(() => {
    const now = Date.now();

    if (now > lastExecutionTime + interval) {
      const incrementedInterval = interval * incrementBy;
      if (incrementedInterval <= maxInterval) {
        interval = incrementedInterval;
      }

      lastExecutionTime = now;
      callback();
    }
  }, pollingInterval);
};
