import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'redux/configStore';
import { updateAlertModal } from 'redux/reducers/alert';
import { transferSelector } from 'redux/reducers/transfer';

import { EthereumIcon } from 'assets/icons/Network/EthereumIcon';
import { PolygonIcon } from 'assets/icons/Network/PolygonIcon';
import checkCircleSuccess from 'assets/images/checkCircleSuccess.png';
import checkCircleSuccess2x from 'assets/images/checkCircleSuccess@2x.png';
import checkCircleSuccess3x from 'assets/images/checkCircleSuccess@3x.png';
import visaImage from 'assets/svg/visa.svg';

import Button, { ButtonColorScheme } from 'components/Button';
import { Modal } from 'components/Modal';

import css from './index.module.css';

export const SwitchPayMethod = () => {
  const dispatch = useDispatch();

  const [selectedMethod, setSelectedPayMethod] = useState<any>(1);
  const { coinName } = useAppSelector(transferSelector);
  const handleOkPress = useCallback(() => {
    dispatch(updateAlertModal({ isVisibleAlert: false }));
  }, [dispatch]);

  const PAY_METHOD = [
    { id: 1, name: 'MATIC', icon: 'MATIC' },
    { id: 2, name: 'Credit Card', icon: 'VISA' },
  ];

  return (
    <Modal>
      <div className={css.container}>
        <div className={css.title}>Choose payment method</div>

        <div className={css.chainList}>
          {PAY_METHOD.map(method => (
            <div key={method.id} className={css.chainListItem} onClick={() => setSelectedPayMethod(method.id)}>
              <div className={css.chainNameContainer}>
                <div className={css.chainIcon}>
                  {method.icon === 'MATIC' ? (
                    <div className={css.chainIcon}>
                      {coinName === 'ETH' || coinName === 'WETH' ? <EthereumIcon /> : <PolygonIcon />}
                    </div>
                  ) : (
                    <div className={css.chainIcon}>
                      <img className={css.balanceStatusIcon} src={visaImage} alt="" />
                    </div>
                  )}
                </div>
                <div className={css.chainName}>{method.name}</div>
              </div>

              {method.id === selectedMethod && (
                <img
                  className={css.check}
                  src={checkCircleSuccess}
                  alt="Choose payment method"
                  srcSet={`${checkCircleSuccess} 1x, ${checkCircleSuccess2x} 2x, ${checkCircleSuccess3x} 3x`}
                />
              )}
            </div>
          ))}
        </div>

        <Button color={ButtonColorScheme.SECONDARY} onClick={handleOkPress}>
          OK
        </Button>
      </div>
    </Modal>
  );
};
