import { filterList } from 'pages/friends/FriendPage/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorIsLoading, updateFlags } from 'redux/reducers/flags';

import { EmptyListMessageText } from 'components/Messages/EmptyListMessage';
import { ErrorMessageQueryLoading } from 'components/Messages/ErrorMessageQueryLoading';
import { Modal } from 'components/Modal';
import { SmallNftCard } from 'components/SmallNftCard';
import { Search } from 'components/inputs/Search';
import LoadingScreen from 'components/loading/Screen';

import { useGetAlchemyNFTs } from 'hooks/useGetAlchemyNFTs';

import { GetNFTsRes_OwnedNft } from 'protobuf/lib/alchemy';

import css from './index.module.css';

interface Props {
  onSelect: (nft: GetNFTsRes_OwnedNft) => void;
  onClose: () => void;
}

export const SelectCollectibleModal = ({ onSelect, onClose }: Props) => {
  const [searchInput, setSearchInput] = useState('');
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectorIsLoading);
  const nftsQuery = useGetAlchemyNFTs({ includeBeasy: true });

  useEffect(() => {
    if (!isLoading && nftsQuery.isLoading) {
      dispatch(updateFlags({ isLoading: true }));
    }
    if (isLoading && !nftsQuery.isLoading) {
      dispatch(updateFlags({ isLoading: false }));
    }
  }, [isLoading, nftsQuery.isLoading, dispatch]);

  const filteredNfts = useMemo(() => {
    const nfts = nftsQuery.isSuccess ? nftsQuery.data : [];
    return searchInput.length === 0 ? nfts : filterList('title', searchInput, nfts);
  }, [nftsQuery.data, nftsQuery.isSuccess, searchInput]);

  return (
    <Modal className={css.modal} onClose={onClose}>
      <div className={css.container}>
        <div className={css.containerHeader}>
          <h2 className={css.header}>Select NFT</h2>
        </div>

        <Search onChange={setSearchInput} />
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <>
            {nftsQuery.isSuccess && (
              <ul className={css.list}>
                {filteredNfts.length === 0 ? (
                  <EmptyListMessageText message="The list is empty" />
                ) : (
                  filteredNfts.map((nft, i) => (
                    <li key={i} onClick={() => onSelect(nft)}>
                      {nft.contract?.address && nft.id?.tokenId && (
                        <SmallNftCard contractAddress={nft.contract.address} tokenId={nft.id.tokenId} />
                      )}
                    </li>
                  ))
                )}
              </ul>
            )}
          </>
        )}

        {nftsQuery.isError && <ErrorMessageQueryLoading onRetry={() => nftsQuery.refetch()} />}
      </div>
    </Modal>
  );
};
