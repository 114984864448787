
import React, { useCallback, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button, { ButtonColorScheme } from 'components/Button';
import { LandingHeader } from 'components/Header/LandingHeader';
import { LoadingImage } from 'components/loading/Image';

import { Path } from 'constants/enumTypes';

import { useNftQuery } from 'hooks/useNftQuery';

import { CollectibleHeader } from '../CollectibleHeader';
import { CollectibleImgFullScreen } from '../CollectibleImgFullScreen';
import { FieldInfo } from '../FieldInfo';
import { HistoryQrCodeModal } from '../HistoryQrCodeModal';
import css from './index.module.css';

export const NftDetailsPage = () => {
  const { contractAddress = '', tokenId = '' } = useParams();

  const navigate = useNavigate();

  const [isHistoryQrCodeShown, toggleOpenHistoryQrCode] = useReducer((isOpen: boolean) => !isOpen, false);

  const [isNFTImgFullScreen, toggleIsNFTImgFullScreen] = useReducer((isOpen: boolean) => !isOpen, false);

  const nftQuery = useNftQuery(contractAddress, tokenId);

  const handleTransferClick = useCallback(() => {
    if (tokenId) {
      navigate(`${Path.TransferNft}/${contractAddress}/${tokenId}`);
    }
  }, [contractAddress, navigate, tokenId]);


  return (
    <div className={css.content}>
      <div className={css.container}>
        <div className={css.containerHeader}>
          <LandingHeader isGoBack={true} />
          {nftQuery.isSuccess && <CollectibleHeader name={nftQuery.data?.metadata?.name ?? 'N/A'} type="NFT" />}
        </div>

        <div className={css.containerContent}>
          <LoadingImage src={nftQuery.data?.metadata?.image} onClick={toggleIsNFTImgFullScreen} />

          <FieldInfo label="Description">{nftQuery.data?.metadata?.description || 'N/A'}</FieldInfo>

          <div className={css.containerFooter}>
            <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={toggleOpenHistoryQrCode}>
              Share QR Code
            </Button>

            <Button onClick={handleTransferClick}>Transfer NFT</Button>
          </div>
        </div>

        {isHistoryQrCodeShown && (
          <HistoryQrCodeModal tokenId={tokenId} onClose={toggleOpenHistoryQrCode} contractAddress={contractAddress} />
        )}

        {isNFTImgFullScreen && nftQuery.data?.metadata?.image && (
          <CollectibleImgFullScreen uri={nftQuery.data?.metadata?.image} onClose={toggleIsNFTImgFullScreen} />
        )}
      </div>

      <div className={css.containerDesktop}>
        <div className={css.containerHeader}>
          <LandingHeader isGoBack={true} />
          {nftQuery.isSuccess && <CollectibleHeader name={nftQuery.data?.metadata?.name ?? 'N/A'} type="NFT" />}
        </div>
        <div className={css.nftInfo}>
          <div className={css.containerInfo}>
            <div className={css.description}>
              <FieldInfo label="Description">{nftQuery.data?.metadata?.description || 'N/A'}</FieldInfo>
            </div>
            <div className={css.ownership}>
              {/*Ownership & License agreements */}

              <FieldInfo label="Ownership Agreement">{'N/A'}</FieldInfo>

              <FieldInfo label="License Agreement">{'N/A'}</FieldInfo>

              <FieldInfo label="Contributors">{'N/A'}</FieldInfo>

              <FieldInfo label="Ownership Timeline">{'-'}</FieldInfo>
            </div>
          </div>
          <div className={css.containerIpfs}>
            <LoadingImage src={nftQuery.data?.metadata?.image} onClick={toggleIsNFTImgFullScreen} />
          </div>
        </div>
        <div className={css.containerFooter}>
          {nftQuery.isSuccess && tokenId && <Button onClick={handleTransferClick}>Transfer NFT</Button>}
          {tokenId && (
            <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={toggleOpenHistoryQrCode}>
              Share QR Code
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
