import clsx from 'clsx';
import React from 'react';

import { RecipientType } from 'types';

import css from './index.module.css';

interface Props {
  value: RecipientType;
  onChange: (value: RecipientType) => void;
}

const items = [
  {
    type: RecipientType.Friend,
    text: 'A friend',
  },
  {
    type: RecipientType.Stranger,
    text: 'New user',
  },
];

export const RecipientTypeSelector = ({ value, onChange }: Props) => {
  return (
    <div className={css.container}>
      {items.map(item => (
        <div key={item.type} className={css.item}>
          <button
            className={item.type === value ? clsx(css.itemContents, css.itemContentsSelected) : css.itemContents}
            onClick={() => onChange(item.type)}
          >
            {item.text}
          </button>
        </div>
      ))}
    </div>
  );
};
