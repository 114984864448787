import groupBy from 'lodash/groupBy';
import { DateTime } from 'luxon';

import { Transfer } from 'types';

const getTransferDate = (item: Transfer): string => {
  const today = DateTime.now();
  const itemDate = DateTime.fromMillis(item.timestamp);

  if (itemDate.hasSame(today, 'day')) {
    return 'Today';
  }

  return itemDate.toLocaleString(DateTime.DATE_FULL);
};

export const groupTransferItems = (transfers: Transfer[]): Record<string /* formatted date */, Transfer[]> => {
  return groupBy(transfers, getTransferDate);
};
