import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';

import tokensActive from 'assets/images/ethereum.png';
import tokensActive2x from 'assets/images/ethereum@2x.png';
import tokensActive3x from 'assets/images/ethereum@3x.png';
import friendsActive from 'assets/images/friendsActive.png';
import friendsActive2x from 'assets/images/friendsActive@2x.png';
import friendsActive3x from 'assets/images/friendsActive@3x.png';
import profileActive from 'assets/images/profile.png';
import profileActive2x from 'assets/images/profile@2x.png';
import profileActive3x from 'assets/images/profile@3x.png';
import transfersActive from 'assets/images/transfersActive.png';
import transfersActive2x from 'assets/images/transfersActive@2x.png';
import transfersActive3x from 'assets/images/transfersActive@3x.png';

import { Path } from 'constants/enumTypes';

import css from './index.module.css';

export const LandingFooter = () => {
  const handleClass = ({ isActive }: { isActive: boolean }): string => {
    if (isActive) {
      return clsx(css.action, css.active);
    }
    return css.action;
  };
  return (
    <nav className={css.container}>
      <NavLink to={Path.Nfts} className={handleClass}>
        <img
          className={css.actionImg}
          src={tokensActive}
          alt="collectibles"
          srcSet={`${tokensActive} 1x, ${tokensActive2x} 2x, ${tokensActive3x} 3x`}
        />
        <p className={css.actionTitle}>Tokens</p>
      </NavLink>
      <NavLink to={Path.Transfers} className={handleClass}>
        <img
          className={css.actionImg}
          src={transfersActive}
          alt="transfers"
          srcSet={`${transfersActive} 1x, ${transfersActive2x} 2x, ${transfersActive3x} 3x`}
        />
        <p className={css.actionTitle}>Transfers</p>
      </NavLink>
      <NavLink to={Path.Friends} className={handleClass}>
        <img
          className={css.actionImg}
          src={friendsActive}
          alt="friends"
          srcSet={`${friendsActive} 1x, ${friendsActive2x} 2x, ${friendsActive3x} 3x`}
        />
        <p className={css.actionTitle}>Friends</p>
      </NavLink>
      <NavLink title="" to={Path.Profile} className={handleClass}>
        <img
          className={css.actionImg}
          src={profileActive}
          alt="Profile"
          srcSet={`${profileActive} 1x, ${profileActive2x} 2x, ${profileActive3x} 3x`}
        />
        <p className={css.actionTitle}>Profile</p>
      </NavLink>
    </nav>
  );
};
