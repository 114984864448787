import { grpc } from '@improbable-eng/grpc-web';
//import { captureException } from '@sentry/react';
import { store } from 'redux/configStore';
import { updateFlags } from 'redux/reducers/flags';
import { logout } from 'utils';

import { makeAuthMeta } from './auth';

interface GrpcError {
  code: grpc.Code;
}

export function isGrpcError(error: unknown): error is GrpcError {
  const { code } = error as GrpcError;
  return code !== undefined && code >= 0 && code <= 16;
}

export const authenticated = async <R>(callback: (meta: grpc.Metadata) => R): Promise<R> => {
  let meta: grpc.Metadata;

  try {
    const { jwt } = await makeAuthMeta();
    meta = new grpc.Metadata({ jwt });
  } catch (error) {
    //captureException(error);
    throw error;
  }

  try {
    return await callback(meta);
  } catch (error) {
    if (isGrpcError(error) && error.code === grpc.Code.PermissionDenied) {
      store.dispatch(updateFlags({ isSessionConnected: false }));
      await logout();

      //captureException(error);
    }

    throw error;
  }
};
