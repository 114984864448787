import React from 'react';
import { useQuery } from 'react-query';
import { shortenAddress } from 'utils';

import arrowRight from 'assets/images/arrowRight.png';
import arrowRight2x from 'assets/images/arrowRight@2x.png';
import arrowRight3x from 'assets/images/arrowRight@3x.png';

import placeholderProfile from 'assets/images/profile_placeholder.png';

import { getAvatarUriFromCid, getUserProfile } from 'services/userProfile';

import css from './index.module.css';

interface Props {
  from: string;
  to: string;
}

export const TransferCardDefault = ({ from, to }: Props) => {
  const fromQuery = useQuery(['getUserProfile', from], () => getUserProfile(from));
  const toQuery = useQuery(['getUserProfile', to], () => getUserProfile(to));

  return (
    <div className={css.container}>
      <div className={css.from}>
        {fromQuery.isSuccess && fromQuery.data !== undefined ? (
          <img className={css.avatar} src={getAvatarUriFromCid(fromQuery.data.avatarCid)} alt="" />
        ) : (
          <img className={css.avatar} src={placeholderProfile} alt="" />
        )}
      </div>
      <div className={css.to}>
        {toQuery.isSuccess && toQuery.data !== undefined ? (
          <img className={css.avatar} src={getAvatarUriFromCid(toQuery.data.avatarCid)} alt="" />
        ) : (
          <img className={css.avatar} src={placeholderProfile} alt="" />
        )}
      </div>

      <span className={css.address}>{shortenAddress(from)}</span>
      <img
        className={css.arrow}
        src={arrowRight}
        alt="Arrow"
        srcSet={`${arrowRight} 1x, ${arrowRight2x} 2x, ${arrowRight3x} 3x`}
      />
      <span className={css.address}>{shortenAddress(to)}</span>
    </div>
  );
};

export default TransferCardDefault;
