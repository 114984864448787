import clsx from 'clsx';

import checkmark from 'assets/images/checkmark.png';
import checkmark2x from 'assets/images/checkmark@2x.png';
import checkmark3x from 'assets/images/checkmark@3x.png';

import css from './index.module.css';

interface Props {
  isChecked: boolean;
  onClick: () => void;
}

export const Checkbox = ({ isChecked, onClick }: Props) => {
  return (
    <div className={clsx(css.container, isChecked ? css.checked : '')} onClick={() => onClick()}>
      {isChecked && (
        <img src={checkmark} alt="Check Box" srcSet={`${checkmark} 1x, ${checkmark2x} 2x, ${checkmark3x} 3x`} />
      )}
    </div>
  );
};
