import { FriendInfo } from 'pages/friends/FriendPage/FriendInfo';
import { filterList } from 'pages/friends/FriendPage/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorIsLoading, updateFlags } from 'redux/reducers/flags';

import Button from 'components/Button';
import { EmptyListMessageText } from 'components/Messages/EmptyListMessage';
import { ErrorMessageQueryLoading } from 'components/Messages/ErrorMessageQueryLoading';
import { Search } from 'components/inputs/Search';
import { LoadingScreen } from 'components/loading/Screen';

import { useFriendLink } from 'hooks/useFriendLink';

import { Friend } from 'protobuf/lib/friend';

import { readFriends } from 'services/apiServices/FriendService';

import css from './index.module.css';

interface Props {
  address: string;
  onClickFriend: (friend: Friend) => void;
  isVisibleFriendLink?: boolean;
}

export const FriendList = ({ address, onClickFriend, isVisibleFriendLink = false }: Props) => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectorIsLoading);
  const [searchInput, setSearchInput] = useState('');

  const friendsQuery = useQuery('readFriends', async () => {
    if (!address) {
      throw new Error('User address is empty');
    }

    return await readFriends(address ?? '');
  });

  const friendsToDisplay = useMemo(() => {
    if (friendsQuery.data?.length === 0) {
      return friendsQuery.data;
    }
    return filterList('name', searchInput, friendsQuery.data ?? []).sort((a, b) => a.name.localeCompare(b.name));
  }, [friendsQuery.data, searchInput]);

  const handleFriendLink = useFriendLink();

  useEffect(() => {
    if (!isLoading && friendsQuery.isLoading) {
      dispatch(updateFlags({ isLoading: true }));
    }
    if (isLoading && !friendsQuery.isLoading) {
      dispatch(updateFlags({ isLoading: false }));
    }
  }, [isLoading, friendsQuery.isLoading, dispatch]);

  const listSearch = () => {
    const { isSuccess, isError, data, refetch } = friendsQuery;

    if (isError) {
      return <ErrorMessageQueryLoading onRetry={() => refetch()} />;
    }

    if (isSuccess && data?.length === 0) {
      return (
        <>
          <EmptyListMessageText message="At this moment you have no friends. Share the friend link to add friends" />
          {isVisibleFriendLink && (
            <div className={css.footer}>
              <Button onClick={handleFriendLink}>Friend Link</Button>
            </div>
          )}
        </>
      );
    } else {
    }

    if (friendsQuery.isSuccess) {
      return (
        <>
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <>
              <Search onChange={setSearchInput} />
              <div className={css.friendList}>
                {friendsToDisplay.length > 0 ? (
                  <ul className={css.listFriend}>
                    {friendsToDisplay.map(friend => (
                      <li key={friend.address}>
                        <FriendInfo friend={friend} onClick={() => onClickFriend(friend)} />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className={css.noSuccess}>No match satisfying search condition</p>
                )}
              </div>
            </>
          )}
        </>
      );
    }
  };

  return <div className={css.container}>{listSearch()}</div>;
};
