import { Transfer } from 'types';

import { LocalStorageService } from './LocalStorageService';

export class AssetTransferHistoryPersistenceService {
  static getTransfers(contractAddress: string): Transfer[] {
    const key = AssetTransferHistoryPersistenceService.getPersistenceKey(contractAddress);
    const transfers = LocalStorageService.getItem<Transfer[]>(key);
    return transfers ?? [];
  }

  static saveTransfers(contractAddress: string, transfers: Transfer[]) {
    const key = AssetTransferHistoryPersistenceService.getPersistenceKey(contractAddress);
    const existingTransfers = AssetTransferHistoryPersistenceService.getTransfers(contractAddress);
    LocalStorageService.setItem(key, [...existingTransfers, ...transfers]);
  }

  private static getPersistenceKey(contractAddress: string): string {
    return 'assetTransfers:' + contractAddress;
  }
}
