import React from 'react';
import { fromScientificNotationToDecimal } from 'utils';

import css from './index.module.css';

interface Props {
  amount: number;
  coinName: string;
}

export const CryptoTransferCard = ({ amount, coinName }: Props) => {

  return (
    <div className={css.container}>
      {fromScientificNotationToDecimal(amount)} {coinName}
    </div>
  );
};
