import { LogoutConfirmationDialog } from 'pages/profile/ProfilePage/LogoutConfirmationDialog';
import { useCallback, useEffect, useReducer } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorAccountInfo, updateAccountInfo } from 'redux/reducers/accountInfo';
import { updateAlertModal } from 'redux/reducers/alert';

import { LockArea } from 'components/LockArea';
import { ChainInfoWallet } from 'components/WalletHeader/ChainInfoWallet';
import { TextAreaInputWithCopy } from 'components/inputs/TextAreaInputWithCopy';

import { AlertType } from 'constants/enumTypes';

import css from './index.module.css';

export const AccountDetails = () => {
  const dispatch = useAppDispatch();
  const [isLogoutConfirmationShown, toggleIsLogoutConfirmationShown] = useReducer((isShow: boolean) => !isShow, false);
  const { address, mnemonicPhrase } = useAppSelector(selectorAccountInfo);

  const handleOpenPin = useCallback(() => {
    dispatch(
      updateAlertModal({
        isVisibleAlert: true,
        alertType: AlertType.PinForMnemonic,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(updateAccountInfo({ mnemonicPhrase: '' }));
    };
  }, [dispatch]);

  return (
    <div>
      <TextAreaInputWithCopy label="Address" value={address} successMessage="Address copied" />
      {mnemonicPhrase ? (
        <TextAreaInputWithCopy label="Mnemonic Phrase" value={mnemonicPhrase} successMessage="Mnemonic phrase copied" />
      ) : (
        <LockArea label="Mnemonic Phrase" message="Show my Mnemonic" onClick={handleOpenPin} />
      )}

      <div className={css.networks}>
        <h3>Available networks</h3>
        <div className={css.chain}>
          <ChainInfoWallet />
        </div>
      </div>
      {isLogoutConfirmationShown && <LogoutConfirmationDialog onCancel={toggleIsLogoutConfirmationShown} />}
    </div>
  );
};
