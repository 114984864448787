import { CoreTypes, SignClientTypes } from '@walletconnect/types';
import { buildApprovedNamespaces } from '@walletconnect/utils';
import { Web3WalletTypes } from '@walletconnect/web3wallet';
import { RpcMethod } from 'beasy-fe-commons';
import { useCallback, useEffect } from 'react';
import { updateAlertModal } from 'redux/reducers/alert';
import { walletService } from 'services';
import { showGenericSuccessToast } from 'components/AppToasts';
import { AlertType } from 'constants/enumTypes';

import { web3wallet } from 'services/WalletConnectV2';
import { initIpfsApi } from 'services/apiServices/ipfs';
import { makeAuthMeta } from 'services/auth';
import { authFrontstore } from 'services/frontstore';

import { store, useAppDispatch } from 'redux/configStore';

import { environment } from 'environment';



export default function useWalletConnectEventsManager(initialized: boolean) {
  const onSessionProposal = useCallback(async (proposal: SignClientTypes.EventArguments['session_proposal']) => {
    console.log("walletService.getAddress() ", walletService.getAddress())
    const approvedNamespaces = buildApprovedNamespaces({
      proposal: proposal.params,
      supportedNamespaces: {
        eip155: {
          chains: ['eip155:1', 'eip155:80001', 'eip155:137'],
          methods: [
            'eth_sendTransaction',
            'eth_signTransaction',
            'eth_sign',
            'personal_sign',
            'eth_getBalance',
            'eth_signTypedData',
            'eth_signTypedData_v4',
            'getJwt',
            'logout',
          ],
          events: ['accountsChanged', 'chainChanged'],
          accounts: [
            'eip155:1:' + walletService.getAddress(),
            'eip155:80001:' + walletService.getAddress(),
            'eip155:137:' + walletService.getAddress()
          ],
        },
      },
    });

    const session = await web3wallet.approveSession({
      id: proposal.id,
      namespaces: approvedNamespaces,
    });
    makeAuthMeta().then(({ jwt }) => {
      initIpfsApi(jwt);
    });
    showGenericSuccessToast(`You are successfully connected to ${session.peer.metadata.name}`);
  }, []);

  function isPeerForge(url: string) {
    const { forge } = environment;
    return (
      forge.some(app => url.endsWith(app.domain)) || url.startsWith('http://localhost') || url.endsWith('obseshlockr.com')
    );
  }
  const onSessionRequest = useCallback(async (requestEvent: SignClientTypes.EventArguments['session_request']) => {
    const { topic, params, id } = requestEvent;
    const { request } = params;
    const check = isPeerForge(requestEvent.verifyContext.verified.origin);
    switch (request.method) {
      case RpcMethod.PersonalSign: {
        try {
          if (check) {
            const result = await walletService.signMessage(request.params[0]);
            const response = { id, result, jsonrpc: '2.0' };
            await web3wallet.respondSessionRequest({
              topic,
              response,
            });
          } else {
            store.dispatch(
              updateAlertModal({
                isVisibleAlert: true,
                alertType: AlertType.ApprovePersonalSign,
                sessionData: requestEvent,
              }),
            );
          }
        } catch (error) {
          //captureException(error);
          /* await this.walletConnectClient.rejectRequest({
                id: payload.id,
              });*/
        }
        break;
      }

      case RpcMethod.EthSignTypedData: {
        store.dispatch(
          updateAlertModal({
            isVisibleAlert: true,
            alertType: AlertType.ApproveEthSignTypedData,
            sessionData: request,
          }),
        );
        break;
      }

      case RpcMethod.EthSendTransaction: {
        store.dispatch(
          updateAlertModal({
            isVisibleAlert: true,
            alertType: AlertType.SendTransaction,
            sessionData: { ...requestEvent }
          }),
        );
        break;
      }

      case RpcMethod.GetUserJwt: {
        try {
          const jwt = await authFrontstore(request.params[0]);
          const response = { id, result: { jwt }, jsonrpc: '2.0' };
          await web3wallet.respondSessionRequest({ topic, response });
        } catch (error) {
          //captureException(error);
          const response = { id, result: null, jsonrpc: '2.0' };
          await web3wallet.respondSessionRequest({ topic, response });
        }
        break;
      }

      case RpcMethod.GetJwt: {
        try {
          const { jwt } = await makeAuthMeta();
          const response = { id, result: jwt, jsonrpc: '2.0' };
          await web3wallet.respondSessionRequest({ topic, response });
        } catch (error) {
          //captureException(error);
          const response = { id, result: null, jsonrpc: '2.0' };
          await web3wallet.respondSessionRequest({ topic, response });
        }
        break;
      }

      case RpcMethod.Logout: {
        try {
          const response = { id, result: 'logout', jsonrpc: '2.0' };
          await web3wallet.respondSessionRequest({ topic, response });
        } catch (error) {
          //captureException(error);
        }
        break;
      }
    }
  }, []);


  useEffect(() => {
    if (initialized) {
      web3wallet.on('session_proposal', onSessionProposal);
      web3wallet.on('session_request', onSessionRequest);
    }
  }, [initialized, onSessionProposal, onSessionRequest]);
}

export function sendResponse(hash: string, transactionType: AlertType, id: number, topic: string) {
  const result = { 'hash': hash, transactionType: transactionType }
  const response = { id, result: result, jsonrpc: '2.0' };
  web3wallet.respondSessionRequest({ topic, response })
};
