import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../configStore';

interface Flags {
  isLoading: boolean;
  isVisibleScanner: boolean;
  isSessionConnected: boolean;
}

const flagsInitialState: Flags = {
  isLoading: false,
  isVisibleScanner: false,
  isSessionConnected: false,
};

const flagsSlice = createSlice({
  name: 'flags',
  initialState: flagsInitialState,
  reducers: {
    updateFlags: (state, { payload }: PayloadAction<Partial<Flags>>) => {
      return { ...state, ...payload };
    },
  },
});

export { flagsSlice };

export const { updateFlags } = flagsSlice.actions;

export const selectorIsLoading = (state: RootState): boolean => state.flags.isLoading;

export const selectorIsVisibleScanner = (state: RootState): boolean => state.flags.isVisibleScanner;

export const selectorIsSessionConnected = (state: RootState): boolean => state.flags.isSessionConnected;
