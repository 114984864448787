export const isAddressValid = (address: string | null) => {
  return address && address.startsWith('0x') && address.length === 42;
};

export const shortenAddress = (address?: string) => {
  if (!address) {
    return '';
  }

  const head = address.slice(0, 6);
  const tail = address.slice(-4);

  return `@${head}…${tail}`;
};

export const isZeroAddress = (address: string): boolean => {
  try {
    return parseInt(address) === 0;
  } catch (e) {
    return false;
  }
};
