import { useState } from 'react';

import { ArrowIcon } from 'assets/icons';

import css from './index.module.css';
import SessionPage from '../SessionDetail';

interface IProps {
  topic: string;
  logo?: string;
  name?: string;
  url?: string;
}

export default function SesionItem({ logo, name, url, topic }: IProps) {
  const [isModalVisible, setModalVisible] = useState(false);
  const onClick = () => {
    setModalVisible(true);
  };

  const OnClose = () => {
    setModalVisible(false);
  }

  return (
    <div>
      <div className={css.item}>
        <img className={css.logo} src={logo} />
        <div style={{ flex: 1 }}>
          <h5 style={{ marginLeft: '$9' }}>{name}</h5>
        </div>
        <ArrowIcon onClick={onClick} />
      </div>
      {isModalVisible && <SessionPage topic={topic} onClose={OnClose}/>}
    </div>
  );
}
