import exclamation from 'assets/images/exclamation.png';
import exclamation2x from 'assets/images/exclamation@2x.png';
import exclamation3x from 'assets/images/exclamation@3x.png';

import css from './index.module.css';

interface Props {
  message: string;
}

export const InputErrorMessage = ({ message }: Props) => {
  return (
    <div className={css.container}>
      <img src={exclamation} alt="exclamation" srcSet={`${exclamation} 1x, ${exclamation2x} 2x, ${exclamation3x} 3x`} />
      <p>{message}</p>
    </div>
  );
};
