import { grpc } from '@improbable-eng/grpc-web';
//import { captureException } from '@sentry/react';

import { GrpcWebImpl, HelpServiceClientImpl } from 'protobuf/lib/helpService';

import { getUserProfile } from 'services/userProfile';

import { environment } from 'environment';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const helpServiceClient = new HelpServiceClientImpl(rpc);

interface SendHelpRequestParams {
  topic: string;
  description: string;
  address: string;
  email: string;
}

export const sendHelpRequest = async ({ topic, description, address, email }: SendHelpRequestParams): Promise<void> => {
  try {
    const { nickname: username } = await getUserProfile(address);
    await helpServiceClient.SendHelpRequest({ topic, description, username, address, email });
  } catch (error) {
    //captureException(error);
    throw error;
  }
};
