export const filterList = <K extends string, T extends { [key in K]: string }>(
  key: K,
  value: string,
  items: T[],
): T[] => {
  const chunks = value.toLowerCase().split(' ').filter(Boolean);

  if (chunks.length === 0) {
    return items;
  }

  return items.filter(item => {
    const prop = item[key].toLowerCase();
    return chunks.some(chunk => prop.includes(chunk));
  });
};
