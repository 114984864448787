//import { captureException } from '@sentry/react';
import { PrivatePage } from 'pages/PrivatePage';
import { PublicPage } from 'pages/PublicPage';
import { SplashPage } from 'pages/SplashPage';
import { ActivatePage } from 'pages/auth/ActivatePage';
import { SignInPage } from 'pages/auth/SignInPage';
import { SignUpPage } from 'pages/auth/SignUpPage';
import { StartPage } from 'pages/auth/StartPage';
import { AddFriendPage } from 'pages/friends/AddFriendPage';
import { FriendDetailsPage } from 'pages/friends/FriendDetailsPage';
import { FriendPage } from 'pages/friends/FriendPage';
import { AboutPage } from 'pages/profile/AboutPage';
import { AccountPage } from 'pages/profile/AccountPage';
import { BalancePage } from 'pages/profile/BalancePage';
import { BalanceTopUpPage } from 'pages/profile/BalanceTopUpPage';
import { CustomerSupportPage } from 'pages/profile/CustomerSupportPage';
import { NotificationsPage } from 'pages/profile/NotificationsPage';
import { ProfilePage } from 'pages/profile/ProfilePage';
import { BeasyNftDetailsPage } from 'pages/tokens/BeasyNftDetailsPage';
import { NftDetailsPage } from 'pages/tokens/BeasyNftDetailsPage/NftDetailsPage';
import { BeasyNftHistoryPage } from 'pages/tokens/BeasyNftHistoryPage';
import { NftHistoryPage } from 'pages/tokens/BeasyNftHistoryPage/NftHistoryPage';
import { TokensPage } from 'pages/tokens/TokensPage';
import { TransferCryptoConfirmationPage } from 'pages/transfers/TransferCryptoConfirmationPage';
import { TransferCryptoPage } from 'pages/transfers/TransferCryptoPage';
import { TransferNftConfirmationPage } from 'pages/transfers/TransferNftConfirmationPage';
import { TransferNftPage } from 'pages/transfers/TransferNftPage';
import { TransfersPage } from 'pages/transfers/TransfersPage';
import { TxDetailsPage } from 'pages/transfers/TxDetailsPage';
import { TransferDetails } from 'pages/transfersDesktop/TransferDetails';
import { TransferRecipient } from 'pages/transfersDesktop/TransferRecipient';
import { TransferReview } from 'pages/transfersDesktop/TransferReview';
import { TransferType } from 'pages/transfersDesktop/TransferTypeSwitch';
import { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { RedirectAfterPinService } from 'services';

import { WalletHeader } from 'components/WalletHeader';
import { WalletSidebar } from 'components/WalletSidebar';

import { Path } from 'constants/enumTypes';

import { useAppInit } from 'hooks/useAppInit';
import useWalletConnectEventsManager from 'hooks/useWalletConnectEventsManager';

import { createWeb3Wallet } from 'services/WalletConnectV2';

import './App.css';
import { Timers } from './services/Timers';

const SplashTimeout = 2_000;

export const App = () => {
  const [isSplashVisible, setIsSplashVisible] = useState(true);
  const [isWeb3WalletCreated, setIsWeb3WalletCreated] = useState(false);

  useWalletConnectEventsManager(isWeb3WalletCreated);
  const appInit = useAppInit();
  useEffect(() => {
    RedirectAfterPinService.init();
  }, []);

  useEffect(() => {
    const init = async () => {
      await appInit();
      await createWeb3Wallet();
      setIsWeb3WalletCreated(true);
      await Timers.delay(SplashTimeout);
      setIsSplashVisible(false);
    };

    init().catch(/*captureException*/);
    // eslint-disable-next-line
  }, []);





  if (isSplashVisible) {
    return <SplashPage />;
  }

  return (
    <section data-testid="home-page" className="container">
      <main className="routes">
        <Routes>
          <Route element={<PublicPage />}>
            <Route path={Path.Start} element={<StartPage />} />
            <Route path={Path.SignUp} element={<SignUpPage />} />
            <Route path={Path.SignIn} element={<SignInPage />} />
            <Route path={Path.Activate} element={<ActivatePage />} />
          </Route>
          <Route element={<PrivatePage isFooterVisible={false} />}>
            <Route
              path={Path.BeasyNftDetails}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <BeasyNftDetailsPage />
                  </div>
                </>
              }
            />
            <Route
              path={Path.NftDetails}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <NftDetailsPage />
                  </div>
                </>
              }
            />

            <Route
              path={Path.Account}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <AccountPage />
                  </div>
                </>
              }
            />
            <Route
              path={Path.Balance}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <BalancePage />
                  </div>
                </>
              }
            />
            <Route
              path={Path.BalanceTopUp}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <BalanceTopUpPage />
                  </div>
                </>
              }
            />

            <Route
              path={Path.FriendDetails}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <FriendDetailsPage />
                  </div>
                </>
              }
            />
            <Route
              path={Path.AddFriend}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <AddFriendPage />
                  </div>
                </>
              }
            />

            <Route
              path={Path.TransferNft}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <TransferNftPage />
                  </div>
                </>
              }
            >
              <Route
                path=":contractAddress/:tokenId"
                element={
                  <>
                    <header className="header">
                      <WalletHeader />
                    </header>
                    <div className="content">
                      <WalletSidebar />
                      <TransferNftPage />
                    </div>
                  </>
                }
              />
            </Route>

            <Route
              path={Path.TransferNftConfirmation}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <TransferNftConfirmationPage />
                  </div>
                </>
              }
            />
            <Route
              path={Path.TransferCrypto}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <TransferCryptoPage />
                  </div>
                </>
              }
            />
            <Route
              path={Path.TransferCryptoConfirmation}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <TransferCryptoConfirmationPage />
                  </div>
                </>
              }
            />
            <Route
              path={Path.TransferCryptoConfirmationDesktop}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <TransferCryptoConfirmationPage />
                  </div>
                </>
              }
            />

            <Route
              path={Path.TxDetails}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <TxDetailsPage />
                  </div>
                </>
              }
            />

            <Route
              path={Path.TransferDesktopType}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <TransferType />
                  </div>
                </>
              }
            />
            <Route
              path={Path.TransferDesktopDetails}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <TransferDetails />
                  </div>
                </>
              }
            />
            <Route
              path={Path.TransferDesktopRecipient}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <TransferRecipient />
                  </div>
                </>
              }
            />
            <Route
              path={Path.TransferDesktopRecipientCrypto}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <TransferRecipient />
                  </div>
                </>
              }
            />

            <Route
              path={Path.TransferDesktopConfirmation}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <TransferReview />
                  </div>
                </>
              }
            />
          </Route>

          <Route element={<PrivatePage />}>
            <Route
              path={Path.BeasyNftHistory}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <BeasyNftHistoryPage />
                  </div>
                </>
              }
            />
            <Route
              path={Path.NftHistory}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <NftHistoryPage />
                  </div>
                </>
              }
            />

            <Route
              path={Path.Nfts}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <TokensPage />
                  </div>
                </>
              }
            />

            <Route
              path={Path.Transfers}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <TransfersPage />
                  </div>
                </>
              }
            />

            <Route
              path={Path.Profile}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <ProfilePage />
                  </div>
                </>
              }
            />
            <Route
              path={Path.Notifications}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <NotificationsPage />
                  </div>
                </>
              }
            />
            <Route
              path={Path.CustomerSupport}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <CustomerSupportPage />
                  </div>
                </>
              }
            />
            <Route
              path={Path.About}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <AboutPage />
                  </div>
                </>
              }
            />

            <Route
              path={Path.Friends}
              element={
                <>
                  <header className="header">
                    <WalletHeader />
                  </header>
                  <div className="content">
                    <WalletSidebar />
                    <FriendPage />
                  </div>
                </>
              }
            />
          </Route>
          <Route path="*" element={<Navigate to={Path.Start} />} />
        </Routes>
      </main>
    </section>
  );
};
