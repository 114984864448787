import React, { useMemo } from 'react';

import css from './index.module.css';

interface Props {
  mnemonic: string;
  currentMnemonic: string[];
  onSelectWord: (word: string) => void;
}

export const WordList = ({ mnemonic, onSelectWord, currentMnemonic }: Props) => {
  const words = useMemo(() => (mnemonic ? mnemonic.split(' ').sort() : []), [mnemonic]);
  const usedWords = useMemo(() => new Set(currentMnemonic), [currentMnemonic]);

  return (
    <article className={css.words}>
      {words.map((word, i) => (
        <button className={css.word} key={word + i} disabled={usedWords.has(word)} onClick={() => onSelectWord(word)}>
          {word}
        </button>
      ))}
    </article>
  );
};
