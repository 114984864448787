import React from 'react';

import { NFT } from 'assets/icons';

import css from './index.module.css';

export const WebVersion = () => {
  return (
    <div className={css.container__nftImg}>
      <NFT />
    </div>
  );
};
