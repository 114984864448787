import React, { useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorIsLoading, updateFlags } from 'redux/reducers/flags';
import { roundEth } from 'utils';

import { useBalanceQuery, useWethBalanceQuery } from 'hooks/useBalanceQuery';

import css from './index.module.css';

interface Props {
  currencyName: string;
}

export const Balance = ({ currencyName }: Props) => {
  const isLoading = useAppSelector(selectorIsLoading);
  const dispatch = useAppDispatch();
  const balanceQuery = useBalanceQuery();
  const wethBalanceQuery = useWethBalanceQuery();

  useEffect(() => {
    if (!isLoading && balanceQuery.isLoading && wethBalanceQuery.isLoading) {
      dispatch(updateFlags({ isLoading: true }));
    }
    if (isLoading && !balanceQuery.isLoading && !wethBalanceQuery.isLoading) {
      dispatch(updateFlags({ isLoading: false }));
    }
  }, [isLoading, balanceQuery.isLoading, wethBalanceQuery.isLoading, dispatch]);

  return (
    <div className={css.container}>
      Balance:&nbsp;
      {currencyName === 'WETH' ? (
        <>
          {wethBalanceQuery.isSuccess ? (
            roundEth(wethBalanceQuery.data)
          ) : (
            <Skeleton containerClassName={css.skeleton} />
          )}
        </>
      ) : (
        <>{balanceQuery.isSuccess ? roundEth(balanceQuery.data) : <Skeleton containerClassName={css.skeleton} />}</>
      )}
      &nbsp;{currencyName}
    </div>
  );
};
