import { grpc } from '@improbable-eng/grpc-web';
import { providerService } from 'services';

import { AlchemyServiceClientImpl, GetAssetTransfersReq_Category, GrpcWebImpl } from 'protobuf/lib/alchemy';

import { authenticated } from 'services/grpc';

import { environment } from 'environment';

import { AssetTransfer } from 'types';
import { getCurrentChainId } from './network';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const alchemyServiceClient = new AlchemyServiceClientImpl(rpc);

export class AlchemyHistoryService {
  static async getTransfers(fromBlock: number): Promise<AssetTransfer[]> {
    const result: AssetTransfer[] = [];
    let pageKey: string | undefined = undefined;

    while (pageKey !== '') {
      const response: { transfers: AssetTransfer[]; pageKey: string } = await AlchemyHistoryService.getTransfersPage(
        fromBlock,
        pageKey,
      );
      result.push(...response.transfers);
      pageKey = response.pageKey;
    }

    return result.sort((a, b) => b.timestamp - a.timestamp);
  }

  private static async getTransfersPage(
    fromBlock: number,
    pageKey = '',
  ): Promise<{ transfers: AssetTransfer[]; pageKey: string }> {
    return await authenticated(async meta => {
      meta.headersMap['network'] = [getCurrentChainId()];
      const response = await alchemyServiceClient.GetAssetTransfers(
        {
          category: [
            GetAssetTransfersReq_Category.ERC20,
            GetAssetTransfersReq_Category.ERC721,
            GetAssetTransfersReq_Category.ERC1155,
            GetAssetTransfersReq_Category.EXTERNAL
          ],
          fromBlock,
          pageKey,
        },
        meta,
      );

      return {
        ...response,
        transfers: await Promise.all(
          response.transfers.map(async transfer => {
            const block = await providerService.getBlockByNumber(transfer.blockNum);
            return {
              ...transfer,
              timestamp: 1e3 * block.timestamp,
            };
          }),
        ),
      };
    });
  }
}
