import React from 'react';

import { LandingHeader } from 'components/Header/LandingHeader';
import { Switch } from 'components/Switch';

import css from './index.module.css';

export const NotificationsPage = () => {
  return (
    <div className={css.container}>
      <LandingHeader isGoBack={true} />
      <h1 className="pageTitle">Notifications</h1>
      <section className={css.content}>
        <Switch name="notification" value={true} onChange={() => {}} labelLeft="Push notifications" />
        <p>
          BEASY will send push notifications alerting you of changes in the connection between your wallet and web
          browsers
        </p>
      </section>
    </div>
  );
};
