import PubSub from 'pubsub-js';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorIsLoading, updateFlags } from 'redux/reducers/flags';

import Button from 'components/Button';
import { LandingHeader } from 'components/Header/LandingHeader';
import { EmptyListMessageText } from 'components/Messages/EmptyListMessage';
import { ErrorMessageQueryLoading } from 'components/Messages/ErrorMessageQueryLoading';
import { TradeHistory } from 'components/TradeHistory';
import LoadingScreen from 'components/loading/Screen';
import { PageHeader } from 'components/page';

import { Path } from 'constants/enumTypes';

import { useTransferHistoryQuery } from 'hooks/useTransferHistory';

import css from './index.module.css';

// TODO: Enable when switching to a new design
// const tabs = ['Overview', 'History'];

export const TransfersPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoadingApp = useAppSelector(selectorIsLoading);

  const [selectedTabIndex] = useState(1);

  const historyQuery = useTransferHistoryQuery();

  const mySubscriber = () => {
    historyQuery.refetch();
  };
  useEffect(() => {
    PubSub.subscribe('chainSwitch', mySubscriber);
  });
  useEffect(() => {
    if (!isLoadingApp && historyQuery.isLoading) {
      dispatch(updateFlags({ isLoading: true }));
    }
    if (isLoadingApp && !historyQuery.isLoading) {
      dispatch(updateFlags({ isLoading: false }));
    }
  }, [isLoadingApp, historyQuery.isLoading, dispatch]);

  const getHistoryContent = () => {
    const { isError, isSuccess, data, refetch, isLoading } = historyQuery;
    if (isLoading) {
      return <LoadingScreen />;
    } else {
      if (isError) {
        return <ErrorMessageQueryLoading onRetry={() => refetch()} />;
      }

      if (isSuccess && data?.length === 0) {
        return <EmptyListMessageText message="You have no transactions" />;
      }

      return data && <TradeHistory transfers={data} />;
    }
  };

  const handleTradeClick = useCallback(() => {
    navigate(Path.TransferNft);
  }, [navigate]);

  return (
    <div className={css.container}>
      <header className={css.containerHeader}>
        <LandingHeader />
        <h1
          className="pageTitle"
          title="You can view your transfer history, and initiate new transfers by pressing the &ldquo;New Trade&rdquo;
            button."
        >
          Transfers
        </h1>
      </header>
      <div className={css.header}>
        <PageHeader title="Transfers"></PageHeader>
      </div>

      <section className={css.contentContainer}>
        <div className={css.content}>{selectedTabIndex === 1 && getHistoryContent()}</div>
        <div className={css.footer}>
          <Button className={css.button} onClick={handleTradeClick}>
            New Transfer
          </Button>
        </div>
      </section>
    </div>
  );
};
