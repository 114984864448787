import { IpfsApi, IpfsGateway } from 'beasy-fe-commons';
import { IpfsCid } from 'beasy-fe-commons/build/ipfs/types';
import { environment } from 'environment';

let ipfsApi: IpfsApi;

export const getIpfsApi = () => ipfsApi;

export const initIpfsApi = (jwt: string) => {
  ipfsApi = new IpfsApi('https://ipfs.infura.io:5001/api/v0', jwt);
};

export const ipfsGateway = new IpfsGateway(environment.ipfsGatewayUrl);

export const getIpfsUrl = <T extends IpfsCid | undefined>(cid: T) => cid && `${environment.ipfsGatewayUrl}/${cid}`;

initIpfsApi('');
