import clsx from 'clsx';
import React, { Fragment, useCallback, useEffect } from 'react';

import backspace from 'assets/images/backspace.png';
import backspace2x from 'assets/images/backspace@2x.png';
import backspace3x from 'assets/images/backspace@3x.png';

import css from './index.module.css';

const BackspaceKey = 'Backspace';

type KeyboardKey = number | typeof BackspaceKey | null;

const digits: KeyboardKey[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, null, 0, BackspaceKey];
const dots = [1, 2, 3, 4];
const KEYS: string = '0123456789';

interface Props {
  value: number[];
  onChange: (value: number[]) => void;
}

export const Pin = ({ value, onChange }: Props) => {
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.repeat) return;

        if (e.key === BackspaceKey) {
          onChange(value.slice(0, -1));
        } else if (KEYS.includes(e.key)) {
          if (value.length < 4) {
            onChange([...value, Number(e.key)]);
          }
        }

      
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => document.removeEventListener('keydown', handleKeyPress);
    // eslint-disable-next-line
  }, [value]);

  const renderKey = useCallback(
    (digit: KeyboardKey) => {
      switch (digit) {
        case null:
          return <div className={css.keyboardKey} />;
        case BackspaceKey:
          return (
            <div className={css.keyboardKey} onClick={() => onChange(value.slice(0, -1))}>
              <img src={backspace} alt="Backspace" srcSet={`${backspace} 1x, ${backspace2x} 2x, ${backspace3x} 3x`} />
            </div>
          );
        default:
          return (
            <div className={css.keyboardKey} onClick={() => onChange([...value, digit])}>
              <div className={css.digit}>{digit}</div>
            </div>
          );
      }
    },
    [value, onChange],
  );

  return (
    <>
      <div className={css.dots}>
        {dots.map(dot => (
          <div key={dot} className={css.dotContainer}>
            <div className={clsx(css.dot, value.length >= dot && css.dotActive)} />
          </div>
        ))}
      </div>

      <div className={css.keyboard}>
        <div className={css.keyboardKeys}>
          {digits.map(digit => (
            <Fragment key={digit}>{renderKey(digit)}</Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
