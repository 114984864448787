import { IpfsFile } from 'beasy-fe-commons';
import React, { useEffect } from 'react';

import { getIpfsUrl } from 'services/apiServices/ipfs';

import css from './index.module.css';

export const VideoPlayer = ({ mediaItem }: { mediaItem: IpfsFile }) => {
  useEffect(() => {
    let videoElement = document.getElementById('video') as HTMLMediaElement;
    if (videoElement) {
      videoElement.currentTime = 1;
    }
  }, []);

  return (
    <video id={'video'} className={css.video} controls src={getIpfsUrl(mediaItem.cid)} preload="metadata">
      Your browser does not support the video tag.
    </video>
  );
};
