import { ReactNode, useCallback, useState } from 'react';

import { IconCopy, TickIcon } from 'assets/icons';

import { ButtonGhost } from 'components/ButtonGhost';

import css from './index.module.css';

interface Props {
  /** text to copy */
  text?: string;

  title?: string;

  children?: ReactNode;
}

export const ClipboardCopy = ({ text, title = 'Copy to clipboard', children }: Props) => {
  const [copied, setCopied] = useState(false);

  const handleClick = useCallback(() => {
    if (!copied) {
      text && navigator.clipboard.writeText(text);
      setCopied(true);
    }
  }, [copied, text]);

  const handleLeave = useCallback(() => {
    setCopied(false);
  }, []);

  return (
    <ButtonGhost title={title} className={css.wrapper} onClick={handleClick} onMouseLeave={handleLeave}>
      {children}
      {copied ? <TickIcon size={14} /> : <IconCopy size={14} className={css.copyIcon} />}
    </ButtonGhost>
  );
};
