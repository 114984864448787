import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectorIsLoading, updateFlags } from 'redux/reducers/flags';

import { useAppSelector } from '../redux/configStore';

export const useLoading = (isLoading: boolean) => {
  const dispatch = useDispatch();
  const isLoadingStore = useAppSelector(selectorIsLoading);

  useEffect(() => {
    if (isLoadingStore !== isLoading) {
      dispatch(updateFlags({ isLoading }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
};
