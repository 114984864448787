import React, { useMemo } from 'react';
import { groupHistoryItems } from 'utils';

import TransferCard from 'components/TransferCard';

import { History } from 'protobuf/lib/collectibleService';

import css from './index.module.css';

interface Props {
  history: History[];
}

export const OwnershipAuthenticator = ({ history }: Props) => {
  const groupedHistory = useMemo(() => groupHistoryItems(history), [history]);

  return (
    <div className={css.container}>
      {Object.keys(groupedHistory).map(date => (
        <div key={date} className={css.containerByDate}>
          <div className={css.date}>{date}</div>
          <div className={css.items}>
            {groupedHistory[date].map(item => (
              <div key={item.block} className={css.item}>
                {item.from && item.to && <TransferCard from={item.from.ethAddress} to={item.to.ethAddress} />}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
