import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { useAppDispatch } from 'redux/configStore';
import { updateAlertModal } from 'redux/reducers/alert';

import edit from 'assets/images/edit.png';
import edit2x from 'assets/images/edit@2x.png';
import edit3x from 'assets/images/edit@3x.png';

import { AlertType } from 'constants/enumTypes';

import { getAvatarUriFromCid } from 'services/userProfile';

import css from './index.module.css';

interface Props {
  avatarCid: string;
  nickname: string;
  isCreator?: boolean;
  isEditable?: boolean;
  business?: string;
  userRole?: any;
}

export const UserInfo = ({
  avatarCid,
  nickname = '',
  business,
  userRole,
  isCreator = false,
  isEditable = false,
}: Props) => {
  const dispatch = useAppDispatch();
  const avatarSource = useMemo(() => getAvatarUriFromCid(avatarCid), [avatarCid]);

  const handleOpen = useCallback(() => {
    isEditable && dispatch(updateAlertModal({ isVisibleAlert: true, alertType: AlertType.Avatar }));
  }, [dispatch, isEditable]);

  return (
    <fieldset className={css.userInfo}>
      <div className={css.avatarContainer} onClick={handleOpen} title={isEditable ? 'Click to edit' : undefined}>
        <img className={clsx(css.avatar, isEditable ? css.cursor : '')} alt="Avatar" src={avatarSource} />
        {isEditable && (
          <img className={css.editIcon} src={edit} alt="Edit" srcSet={`${edit} 1x, ${edit2x} 2x, ${edit3x} 3x`} />
        )}
      </div>
      <div className={css.nickName}>
        <h3>{nickname}</h3>
        {isCreator && <p className={css.creator}>Creator</p>}
      </div>
      <div className={css.nickNameDesktop}>
        <p>
          Username: <span>{nickname}</span>
        </p>
        <p>
          Business: <span>{business}</span>
        </p>
        {userRole && (
          <p>
            Role: <span>{userRole}</span>
          </p>
        )}
      </div>
    </fieldset>
  );
};
