import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorAccountInfo } from 'redux/reducers/accountInfo';
import {
  transferSelector,
  setRecipientType,
  setRecipientAddress,
  setFriend,
  openSelectFriendModal,
  closeSelectFriendModal,
  populateNftTransferTx,
  setTokenIdAndCollection,
} from 'redux/reducers/transfer';
import { isAddressValid } from 'utils';

import { Chevron } from 'assets/icons';

import Button from 'components/Button';
import { LandingHeader } from 'components/Header/LandingHeader';
import { InputErrorMessage } from 'components/Messages/InputErrorMessage';
import { SmallNftCard } from 'components/SmallNftCard';
import { TextAreaInputWithPaste } from 'components/inputs/TextAreaInputWithPaste';

import { Path } from 'constants/enumTypes';

import { useGetAlchemyDetailsNFTbyTokenId } from 'hooks/useGetAlchemyDetailsNFTbyTokenId';

import { GetNFTsRes_OwnedNft } from 'protobuf/lib/alchemy';
import { Friend } from 'protobuf/lib/friend';

import { RecipientType } from 'types';

import { RecipientTypeSelector } from '../RecipientTypeSelector';
import { SelectFriendModal } from '../SelectFriendModal';
import { TransferTypeSwitch } from '../TransferTypeSwitch';
import { SelectCollectibleModal } from './SelectCollectibleModal';
import css from './index.module.css';
import { useNetwork } from 'hooks/useNetwork';

export const TransferNftPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { tokenId = '', contractAddress = '' } = useParams();

  const { address } = useAppSelector(selectorAccountInfo);
  const { isSelectFriendModalOpen, recipientType, recipientAddress, friend } = useAppSelector(transferSelector);

  const [isSelectCollectibleModalVisible, toggleIsSelectCollectibleModalVisible] = useReducer(
    isValue => !isValue,
    false,
  );

  const [isSelectedAddressValid, setIsSelectedAddressValid] = useState(false);
  const [isChangeAddress, setIsChangeAddress] = useState(false);
  const [selectedCollectible, setSelectedCollectible] = useState<GetNFTsRes_OwnedNft>();
 const { currentNetwork } = useNetwork();
  const alchemyNft = useGetAlchemyDetailsNFTbyTokenId({ contractAddress, tokenId });

  useEffect(() => {
    if (alchemyNft) {
      setSelectedCollectible(alchemyNft);
    }
  }, [alchemyNft, setSelectedCollectible]);

  const handleSetSelectedCollectible = (nft: GetNFTsRes_OwnedNft) => {
    setSelectedCollectible(nft);
    toggleIsSelectCollectibleModalVisible();
  };

  const handleSetRecipientAddress = (address: string) => {
    dispatch(setRecipientAddress(address));
    setIsChangeAddress(address.length > 0);
    if (isAddressValid(address)) {
      setIsSelectedAddressValid(true);
    } else {
      setIsSelectedAddressValid(false);
    }
  };

  const handleChangeRecipientTypeSelector = (recipientType: RecipientType) => {
    setIsSelectedAddressValid(false);
    dispatch(setRecipientType(recipientType));
    if (recipientType === RecipientType.Friend) {
      setIsChangeAddress(false);
    }
  };

  const handleSetFriend = useCallback(
    (friend: Friend) => {
      dispatch(setFriend(friend));
      setIsSelectedAddressValid(true);
    },
    [dispatch, setIsSelectedAddressValid],
  );

  const handleContinue = useCallback(async () => {
    if (selectedCollectible && selectedCollectible.contract && selectedCollectible.id && isSelectedAddressValid) {
      const { address: contractAddress } = selectedCollectible.contract;
      const { tokenId } = selectedCollectible.id;

      const tokenType = selectedCollectible?.id?.tokenMetadata?.tokenType?.toLowerCase();

      dispatch(setTokenIdAndCollection({ tokenId, collection: contractAddress, chainId: Number(currentNetwork!.chainId!)}));
      await dispatch(populateNftTransferTx(tokenType));

      navigate(generatePath(Path.TransferNftConfirmation, { contractAddress, tokenId, recipientAddress }));
    }
  }, [selectedCollectible, isSelectedAddressValid, dispatch, currentNetwork, navigate, recipientAddress]);

  return (
    <>
      <div className={css.containerWallet}>
        <div className={css.headerPage}>
          <LandingHeader isGoBack={true} />
          <h1
            className="pageTitle"
            title="In order to send a NFT, you must first select a NFT from your wallet to send. You may
                send the NFT to users from your friends list by pressing the check-mark and selecting the friend
                you wish to send the NFT to. Or you can enter the Ethereum address of the Beasy Wallet holder.
                The Ethereum address can be found underneath your profile image."
          >
            New Transfer
          </h1>
        </div>

        <section className={css.contentContainer}>
          <div>
            <TransferTypeSwitch />
          </div>

          <div className={css.collectibles} onClick={toggleIsSelectCollectibleModalVisible}>
            {selectedCollectible && selectedCollectible.contract && selectedCollectible.id ? (
              <SmallNftCard
                contractAddress={selectedCollectible.contract.address}
                tokenId={selectedCollectible.id.tokenId}
              />
            ) : (
              <div className={css.addCollectible}>
                <p className={css.addCollectibleText}>+ Add NFT</p>
              </div>
            )}
          </div>

          <div className={css.recipientType}>
            <h2 className={css.tradeWith}>Transfer to</h2>
            <RecipientTypeSelector value={recipientType} onChange={handleChangeRecipientTypeSelector} />
          </div>

          {recipientType === RecipientType.Friend ? (
            <div className={css.chooseFriend}>
              <label htmlFor="chooseFriend">Friend</label>
              <input
                value={friend?.name ?? ''}
                name="chooseFriend"
                placeholder="Select a friend for transferring"
                readOnly={true}
                onClick={() => dispatch(openSelectFriendModal())}
              />
              <Chevron onClick={() => dispatch(openSelectFriendModal())} />
            </div>
          ) : (
            <div className={css.recipientAddress}>
              <TextAreaInputWithPaste label="Address" value={recipientAddress} onSetPaste={handleSetRecipientAddress} />
              {isChangeAddress && !isSelectedAddressValid && <InputErrorMessage message="Incorrect address" />}
            </div>
          )}

          <div className={css.containerFooter}>
            {selectedCollectible && isSelectedAddressValid && <Button onClick={handleContinue}>Continue</Button>}
          </div>
        </section>

        {isSelectFriendModalOpen && (
          <SelectFriendModal
            address={address}
            onSelect={handleSetFriend}
            onClose={() => dispatch(closeSelectFriendModal())}
          />
        )}

        {isSelectCollectibleModalVisible && (
          <SelectCollectibleModal
            onSelect={handleSetSelectedCollectible}
            onClose={toggleIsSelectCollectibleModalVisible}
          />
        )}
      </div>
    </>
  );
};
