//import { captureException } from '@sentry/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pinSelector } from 'redux/reducers/onboarding';
import { walletService } from 'services';

import { showGenericErrorToast } from 'components/AppToasts';
import Button from 'components/Button';
import { OnboardingHeader } from 'components/Header/OnboardingHeader';
import { WebVersion } from 'components/NFTLogo';
import { Pin } from 'components/Pin';

import { useLogin } from 'hooks/useLogin';

import css from './index.module.css';

interface Props {
  onNextStepAuth: () => void;
  onPrevStepAuth: () => void;
  isActivate?: boolean;
}

export const ConfirmPin = ({ onNextStepAuth, onPrevStepAuth, isActivate }: Props) => {
  const [passcode, setPasscode] = useState<number[]>([]);
  const login = useLogin();

  const previousPin = useSelector(pinSelector);
  const [passcodeCreate] = useState<any>(previousPin);
  const submitPin = () => {
    if (passcode.length === 4) {
      submit().then();
    }
  };
  const cancelChange = () => {
    return false;
  };
  const submit = useCallback(async () => {
    try {
      const pin = passcode.join('');

      if (pin === previousPin) {
        await login(pin).then(res => {
          if (res === true) {
            if (isActivate)
              // Last step of the activation process
             
            onNextStepAuth();
          } else {
            showGenericErrorToast('Something went wrong. Please try again.');
          }
        });
      } else {
        setPasscode([]);
        showGenericErrorToast('Incorrect passcode');
      }
    } catch (error) {
      //captureException(error);
      showGenericErrorToast('Something went wrong. Please try again.');
    }
  }, [passcode, previousPin, login, isActivate, onNextStepAuth]);

  useEffect(() => {
    if (window.innerWidth <= 820) {
      if (passcode.length === 4) {
        submit().then();
      }
    }
  }, [passcode.length, submit]);

  return (
    <div className={css.container}>
      <div className={css.container__info}>
        <div className={css.header}>
          <OnboardingHeader goBack={onPrevStepAuth} />
          <div className={css.title__mobile}>Create a passcode</div>
          <div className={css.title__desktop}>Enter passcode</div>
          <div className={css.description}>Enter a 4-digit passcode that will be used for accessing the app</div>
          <div className={css.description__confirm}>Type your 4-digit passcode again</div>
        </div>

        <div className={css.content__previousPin}>
          <Pin value={passcodeCreate} onChange={cancelChange} />
        </div>
        <div className={css.content}>
          <div className={css.description__confirm__desktop}>Type your 4-digit passcode again</div>
          <Pin value={passcode} onChange={setPasscode} />
        </div>
        <div className={css.disabledButton}>
          <Button disabled={passcode.length < 4} onClick={submitPin}>
            Continue
          </Button>
        </div>
      </div>
      <WebVersion />
    </div>
  );
};
