import { grpc } from '@improbable-eng/grpc-web';
//import { captureException } from '@sentry/react';

//TODO: Change it by the TransactionReceipt
import { TxRes } from 'protobuf/lib/chargeMaster';
import { GrpcWebImpl, TransactionData, TransactionServiceClientImpl } from 'protobuf/lib/transaction';
import { MinimalTransactionInfo, TransactionInfo } from 'protobuf/lib/transactionMessage';

import { environment } from 'environment';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const transactionServiceClient = new TransactionServiceClientImpl(rpc);
export const populateTransaction = async (item: MinimalTransactionInfo): Promise<TransactionInfo> => {
  try {
    return await transactionServiceClient.populateTransaction(item);
  } catch (error) {
    //captureException(error, { extra: { item } });
    throw error;
  }
};

export const metaTransaction = async (itemToTransact: TransactionData): Promise<TxRes> => {
  try {
    return await transactionServiceClient.metaTransaction(itemToTransact);
  } catch (error) {
    //captureException(error, { extra: { itemToTransact } });
    throw error;
  }
};

export const estimateTransactionCostanyNetwork = async (item: any) => {
  try {
    return await transactionServiceClient.estimateTransactionCostAnyNetwork(item);
  } catch (error) {
    //captureException(error, { extra: { item } });
    throw error;
  }
};
