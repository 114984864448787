import * as ethers from 'ethers';
import { assert } from 'utils';

import { Network } from 'protobuf/lib/network';

import { WalletService } from './WalletService';
import { getNetworks } from './apiServices/network';
import { Timers } from './Timers';

export class ProviderService {
  private chains: Network[] = [];
  private chainId: number = 137;

  private provider: ethers.providers.Provider | null = null;

  constructor(private walletService: WalletService) {
    getNetworks().then(result => {
      console.log("GET NETWORKS: ",result.networks)
      this.chains = result.networks;
    });
  }

  getChainId(): number {
    assert(this.chainId, 'Chain is not initialized');
    return this.chainId;
  }

  getNetworks(): Network[] {
    return this.chains;
  }

  async setProvider(chainId: number): Promise<void> {
    await Timers.delay(200);
    const currentNetwork = this.chains?.find(({ id }) => id === `${chainId}`);
    console.log("CURRENT NETWORK: ",currentNetwork)
    this.provider = new ethers.providers.JsonRpcProvider(
      { url: currentNetwork?.rpcUrl! },
      { name: currentNetwork!.name, chainId: Number(currentNetwork!.id) },
    );
    console.log("THIS PROVIDER: ",this.provider)
    this.walletService.connect(this.provider);
    this.chainId = chainId;
  }

  async getTransaction(hash: string): Promise<ethers.providers.TransactionResponse> {
    assert(this.provider, 'Provider is not initialized');
    return this.provider.getTransaction(hash);
  }

  async getTransactionReceipt(hash: string): Promise<ethers.providers.TransactionReceipt> {
    assert(this.provider, 'Provider is not initialized');
    return this.provider.getTransactionReceipt(hash);
  }

  async getTransactions(hashes: string[]): Promise<ethers.providers.TransactionResponse[]> {
    const txs = await Promise.all(
      hashes.map(hash => {
        assert(this.provider, 'Provider is not initialized');
        return this.provider.getTransaction(hash).catch(error => {
          console.log(error);
          return null;
        });
      }),
    );

    return txs.filter(Boolean) as ethers.providers.TransactionResponse[];
  }

  async getBlockByNumber(block: number): Promise<ethers.providers.Block> {
    assert(this.provider, 'Provider is not initialized');
    return await this.provider.getBlock(block);
  }
}
