import React from 'react';

import { Chevron } from 'assets/icons';

import css from './index.module.css';

interface Props {
  href: string;
  label: string;
}

export const MenuItem = ({ href, label }: Props) => {
  return (
    <li className={css.container}>
      <a className={css.label} href={href} target="_blank" rel="noreferrer">
        <div>
          {label}
          <Chevron size={16} className={css.arrow} />
        </div>
      </a>
    </li>
  );
};
