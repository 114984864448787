import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/Button';
import { ErrorMessageQueryLoading } from 'components/Messages/ErrorMessageQueryLoading';
import { Modal } from 'components/Modal';
import { TradeHistory } from 'components/TradeHistory';

import { Path } from 'constants/enumTypes';

import { useAssetTransferHistoryQuery } from 'hooks/useAssetTransferHistory';
import { useNftQuery } from 'hooks/useNftQuery';

import css from './index.module.css';

export const NftHistoryPage = () => {
  const navigate = useNavigate();
  const { contractAddress = '', tokenId = '' } = useParams();

  const nftQuery = useNftQuery(contractAddress, tokenId);

  const collectibleHistoryQuery = useAssetTransferHistoryQuery(contractAddress, tokenId);

  return (
    <Modal className={css.modal}>
      <div className={css.container}>
        <div className={css.containerHeader}>
          <h2 className={css.headerPage}>Ownership Authentication</h2>
          {nftQuery.isSuccess && nftQuery.data?.metadata.name && (
            <h1 className={css.nameCollectible}>{nftQuery.data.metadata.name}</h1>
          )}
        </div>

        {collectibleHistoryQuery.isError && (
          <ErrorMessageQueryLoading
            message="Could not read collectible history"
            onRetry={() => collectibleHistoryQuery.refetch()}
          />
        )}

        {collectibleHistoryQuery.isLoading && (
          <div className={css.containerHistory}>
            <TradeHistory transfers={[]}/>
          </div>
        )}

        {collectibleHistoryQuery.isSuccess && (
          <div className={css.containerHistory}>
            <TradeHistory transfers={collectibleHistoryQuery.data} />
          </div>
        )}

        <footer className={css.footer}>
          <Button className={css.button} onClick={() => navigate(Path.Nfts)}>
            OK
          </Button>
        </footer>
      </div>
    </Modal>
  );
};
