import clsx from 'clsx';
import React, { FC } from 'react';

import css from './index.module.css';

export enum ChipStatus {
  None = 'none',
  Warning = 'warning',
  Error = 'error',
}

interface Props {
  // Optional
  children: React.ReactNode;
  className?: string;
  status?: ChipStatus;
}

export const Chip: FC<Props> = ({ children, className, status = ChipStatus.None }) => {
  return <div className={clsx(css.chip, css[status], className)}>{children}</div>;
};
