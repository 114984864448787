import React, { useCallback } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/configStore';
import { selectorAccountInfo } from 'redux/reducers/accountInfo';
import { updateAlertModal } from 'redux/reducers/alert';
import {
  closeCurrencyModal,
  closeSelectFriendModal,
  isTransferCryptoFormValidSelector,
  openCurrencyModal,
  openSelectFriendModal,
  populateTx,
  setAmount,
  setFriend,
  setRecipientAddress,
  setRecipientType,
  setTransferCurrency,
  transferSelector,
} from 'redux/reducers/transfer';

import { Chevron } from 'assets/icons';

import Button from 'components/Button';
import { LandingHeader } from 'components/Header/LandingHeader';
import { ErrorMessage } from 'components/Messages/ErrorMessage';
import { TextAreaInputWithPaste } from 'components/inputs/TextAreaInputWithPaste';

import { AlertType, Path } from 'constants/enumTypes';

import { useBalanceQuery } from 'hooks/useBalanceQuery';
import { useNetwork } from 'hooks/useNetwork';

import { Friend } from 'protobuf/lib/friend';

import { RecipientType } from 'types';

import { AmountInput } from '../AmountInput';
import { Balance } from '../Balance';
import { RecipientTypeSelector } from '../RecipientTypeSelector';
import { SelectCurrencyModal } from '../SelectCurrencyModal';
import { SelectFriendModal } from '../SelectFriendModal';
import { TransferTypeSwitch } from '../TransferTypeSwitch';
import css from './index.module.css';

export const TransferCryptoPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { address } = useAppSelector(selectorAccountInfo);
  const { isSelectFriendModalOpen, recipientAddress, recipientType, friend, amount, isCurrencyModalOpen } =
    useAppSelector(transferSelector);
  const isFormValid = useAppSelector(isTransferCryptoFormValidSelector);

  const handleChangeRecipientTypeSelector = (recipientType: RecipientType) => {
    dispatch(setRecipientType(recipientType));
  };

  const handleSetFriend = useCallback(
    (friend: Friend) => {
      dispatch(setFriend(friend));
    },
    [dispatch],
  );

  const { currentNetwork, currentNetworkName, currentCoinCode } = useNetwork();

  const handleChainSwitch = useCallback(() => {
    dispatch(updateAlertModal({ isVisibleAlert: true, alertType: AlertType.SwitchChain }));
  }, [dispatch]);

  const handleSetRecipientAddress = useCallback(
    (recipientAddress:any) => {
      dispatch(setRecipientAddress(recipientAddress));
    },
    [dispatch],
  );

  const continueMutation = useMutation(async () => {
    await dispatch(populateTx());
    navigate(Path.TransferCryptoConfirmation);
  });

  const balanceQuery = useBalanceQuery();

  const isBalanceInsufficient = amount !== null && balanceQuery.isSuccess && amount > balanceQuery.data;

  return (
    <div className={css.container}>
      <div className={css.headerPage}>
        <LandingHeader isGoBack={true} />
        <h1 className="pageTitle">Transfer Crypto</h1>
      </div>

      <div className={css.scrollableContainer}>
        <section className={css.contentContainer}>
          <div className={css.transferTypeSwitch}>
            <TransferTypeSwitch />
          </div>

          <div className={css.network} onClick={handleChainSwitch}>
            <label htmlFor="network">Current network</label>
            <input value={currentNetworkName} name="network" readOnly={true} />
            <Chevron />
          </div>

          <div className={css.network} onClick={() => dispatch(openCurrencyModal())}>
            <label htmlFor="currency">Pick currency</label>
            <input value={currentCoinCode} name="currency" readOnly={true} />
            <Chevron />
          </div>

          <Balance currencyName={currentCoinCode} />

          <div className={css.amount}>
            <AmountInput
              hasError={isBalanceInsufficient}
              placeholder="Enter Amount"
              value={amount ?? ''}
              onChange={e => dispatch(setAmount(Number(e.target.value)))}
            />
          </div>

          <div className={css.recipientType}>
            <h2 className={css.tradeWith}>Transfer to</h2>
            <RecipientTypeSelector value={recipientType} onChange={handleChangeRecipientTypeSelector} />
          </div>

          <div className={css.recipient}>
            {recipientType === RecipientType.Friend ? (
              <div className={css.chooseFriend}>
                <label htmlFor="chooseFriend">Friend</label>
                <input
                  value={friend?.name ?? ''}
                  name="chooseFriend"
                  placeholder="Select a friend for transferring"
                  readOnly={true}
                  onClick={() => dispatch(openSelectFriendModal())}
                />
                <Chevron />
              </div>
            ) : (
              <div className={css.recipientAddress}>
                <TextAreaInputWithPaste
                  label="Address"
                  value={recipientAddress}
                  onSetPaste={handleSetRecipientAddress}
                />
              </div>
            )}
          </div>

          <footer className={css.footer}>
            {continueMutation.isError && <ErrorMessage />}

            {isFormValid && (
              <Button
                className={css.continueButton}
                disabled={continueMutation.isLoading}
                onClick={() => continueMutation.mutate()}
              >
                {continueMutation.isError ? 'Try Again' : 'Continue'}
              </Button>
            )}
          </footer>
        </section>
      </div>

      {isSelectFriendModalOpen && (
        <SelectFriendModal
          address={address}
          onSelect={handleSetFriend}
          onClose={() => dispatch(closeSelectFriendModal())}
        />
      )}

      {isCurrencyModalOpen && (
        <SelectCurrencyModal
          coinName={currentCoinCode!}
          coinNameSelected={currentCoinCode!}
          onSelect={currentCoinCode => dispatch(setTransferCurrency({ coinName: currentCoinCode, wethAddress: currentNetwork?.wethAddress! }))}
          onClose={() => dispatch(closeCurrencyModal())}
        />
      )}
    </div>
  );
};
