import React, { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { updateAlertModal } from 'redux/reducers/alert';
import { updateChain } from 'redux/reducers/blockchain';

import checkCircleSuccess from 'assets/images/checkCircleSuccess.png';
import checkCircleSuccess2x from 'assets/images/checkCircleSuccess@2x.png';
import checkCircleSuccess3x from 'assets/images/checkCircleSuccess@3x.png';

import Button, { ButtonColorScheme } from 'components/Button';
import { Modal } from 'components/Modal';

import { useNetwork } from 'hooks/useNetwork';
import PubSub from 'pubsub-js';
import css from './index.module.css';
import { providerService } from 'services';
import { setTransferCurrency } from 'redux/reducers/transfer';
import { getCurrentChainId } from 'services/apiServices/network';
import { useBoolean } from 'use-boolean';
import ChangeNetworkModal from './ChangeNetworkModal';
import { selectorIsSessionConnected } from 'redux/reducers/flags';
import { useAppSelector } from 'redux/configStore';

export const SwitchChain = () => {
  const dispatch = useDispatch();
  const isSessionConnected = useAppSelector(selectorIsSessionConnected);
  const chainId = getCurrentChainId();
  const queryClient = useQueryClient();
  const { allNetworks, currentNetwork } = useNetwork();
  const [selectedChainId, setSelectedChainId] = useState<number>(Number(currentNetwork!.chainId));
  const [isChangeNetworkModalOpen, showChangeNetworkModal, closeChangeNetworkModal] = useBoolean(false);

  const changeNetwork = useCallback(() => {
    dispatch(updateChain(selectedChainId));
    providerService.setProvider(selectedChainId);
    queryClient.invalidateQueries('balance').then();
    dispatch(setTransferCurrency({ coinName: '' , wethAddress: ''}))
    dispatch(updateAlertModal({ isVisibleAlert: false }));
    PubSub.publish('chainSwitch', chainId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryClient, selectedChainId]);

  const handleOkPress = useCallback(() => {
    isSessionConnected && (Number(currentNetwork!.chainId) !== selectedChainId) ? 
      showChangeNetworkModal() : changeNetwork();
  }, [isSessionConnected, selectedChainId, currentNetwork, showChangeNetworkModal, changeNetwork]);

  const handleAcceptChange = useCallback(async () => {
    changeNetwork();
  }, [changeNetwork]);

  return (
    <Modal>
      <div className={css.container}>
        <div className={css.title}>Choose network</div>

        <div className={css.chainList}>
          {allNetworks.map(chain => (
            <div
              key={chain.id}
              className={css.chainListItem}
              onClick={() => setSelectedChainId(Number(chain.id))}
            >
              <div className={css.chainNameContainer}>
                <div className={css.chainName}>{chain.name}</div>
              </div>

              {Number(chain.chainId) === selectedChainId && (
                <img
                  className={css.check}
                  src={checkCircleSuccess}
                  alt="Choose network"
                  srcSet={`${checkCircleSuccess} 1x, ${checkCircleSuccess2x} 2x, ${checkCircleSuccess3x} 3x`}
                />
              )}
            </div>
          ))}
        </div>
        <Button color={ButtonColorScheme.SECONDARY} onClick={handleOkPress}>
          OK
        </Button>
      </div>
      {isChangeNetworkModalOpen && (
        <ChangeNetworkModal
          onAccept={handleAcceptChange}
          onCancel={closeChangeNetworkModal}
        />
      )}
    </Modal>
  );
};

