import React from 'react';

import css from './index.module.css';

interface Props {
  onChange: (value: string) => void;
}

export const Search = ({ onChange }: Props) => {
  return (
    <input className={css.search} type="search" placeholder="Search" onChange={event => onChange(event.target.value)} />
  );
};
