import { useMemo } from 'react';

import { useGetAlchemyNFTs } from './useGetAlchemyNFTs';

interface Props {
  tokenId: string;
  contractAddress: string;
}

export const useGetAlchemyDetailsNFTbyTokenId = ({ contractAddress, tokenId }: Props) => {
  const { data: NFTs, isSuccess } = useGetAlchemyNFTs({ contractAddresses: [contractAddress], includeBeasy: true });

  return useMemo(() => {
    if (isSuccess) {
      return NFTs?.find(NFT => NFT.id?.tokenId.toLowerCase() === tokenId.toLowerCase());
    }
  }, [NFTs, isSuccess, tokenId]);
};
