import React, { useCallback } from 'react';
import { copyToClipboard } from 'utils';

import duplicate from 'assets/images/duplicate.png';
import duplicate2x from 'assets/images/duplicate@2x.png';
import duplicate3x from 'assets/images/duplicate@3x.png';

import css from './index.module.css';

interface Props {
  label: string;
  value: string;
  successMessage: string;
}

export const TextAreaInputWithCopy = ({ label, value, successMessage }: Props) => {
  const handleCopy = useCallback(() => copyToClipboard({ data: value, successMessage }), [value, successMessage]);

  return (
    <div className={css.container}>
      <label htmlFor="field">{label}</label>
      <textarea className={css.textField} name="field" value={value} readOnly />
      <button className={css.duplicate} onClick={handleCopy}>
        <img src={duplicate} alt="duplicate" srcSet={`${duplicate} 1x, ${duplicate2x} 2x, ${duplicate3x} 3x`} />
      </button>
    </div>
  );
};
