import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
//import { captureException } from '@sentry/react';
import { ethers } from 'ethers';
import { TxContextFactory } from 'factories/TxContextFactory';
import { queryClient } from 'queryClient';
import { AppDispatch, RootState } from 'redux/configStore';
import { providerService, walletService } from 'services';
import { assert, fromScientificNotationToDecimal, shortenAddress } from 'utils';

import { showGenericErrorToast, showGenericSuccessToast } from 'components/AppToasts';

import { AlertType } from 'constants/enumTypes';

import { Friend } from 'protobuf/lib/friend';
import { UserProfile } from 'protobuf/lib/userProfileService';

import { TxGenerator } from 'services/TxGenerator';
import { getUserProfile } from 'services/userProfile';

import { RecipientType, TransferType, TxStatus } from 'types';

import { send, /*sendMetaTx,*/ setInsufficientBalance, setTx } from './tx';
import { sendResponse } from 'hooks/useWalletConnectEventsManager';

export interface TransferState {
  type: TransferType;
  isSelectFriendModalOpen: boolean;
  recipientType: RecipientType;
  friend: Friend | null;
  recipientAddress: string;

  // Transfer NFT:
  tokenId: string | null;
  collection: string | null;
  chainId: number;
  // Transfer crypto:
  isCurrencyModalOpen: boolean;
  amount: number | null;
  coinName: string;
  wethAddress: string;
}

export interface TransferSlice {
  transfer: TransferState;
}

const initialState: TransferState = {
  type: TransferType.Nft,
  isSelectFriendModalOpen: false,
  recipientType: RecipientType.Friend,
  friend: null,
  recipientAddress: '',

  // Transfer NFT:
  tokenId: null,
  collection: null,
  chainId: 0,

  // Transfer crypto:
  isCurrencyModalOpen: false,
  amount: null,
  coinName: '',
  wethAddress: '',
};

const transferSlice = createSlice({
  name: 'transfer',
  initialState,
  reducers: {
    openSelectFriendModal: state => {
      state.isSelectFriendModalOpen = true;
    },
    closeSelectFriendModal: state => {
      state.isSelectFriendModalOpen = false;
    },
    setRecipientType: (state, action: PayloadAction<RecipientType>) => {
      state.recipientType = action.payload;
      state.recipientAddress = '';
      state.friend = null;
    },
    setRecipientAddress: (state, action: PayloadAction<string>) => {
      state.recipientAddress = action.payload;
    },
    setTokenIdAndCollection: (state, action: PayloadAction<{ tokenId: string; collection: string; chainId: number }>) => {
      state.tokenId = action.payload.tokenId;
      state.collection = action.payload.collection;
      state.chainId = action.payload.chainId;
    },
    setFriend: (state, action: PayloadAction<Friend>) => {
      state.friend = action.payload;
      state.recipientAddress = action.payload.address;
      state.isSelectFriendModalOpen = false;
    },
    openCurrencyModal: state => {
      state.isCurrencyModalOpen = true;
    },
    closeCurrencyModal: state => {
      state.isCurrencyModalOpen = false;
    },
    setTransferCurrency: (state, action: PayloadAction<{ coinName: string; wethAddress: string }>) => {
      state.coinName = action.payload.coinName;
      state.wethAddress = action.payload.wethAddress;
      state.isCurrencyModalOpen = false;
    },
    setAmount: (state, action: PayloadAction<number>) => {
      state.amount = action.payload;
    },
    reset: () => initialState,
  },
});

export { transferSlice };

export const {
  openSelectFriendModal,
  closeSelectFriendModal,
  setRecipientType,
  setRecipientAddress,
  setTokenIdAndCollection,
  setFriend,
  setAmount,
  openCurrencyModal,
  closeCurrencyModal,
  setTransferCurrency,
} = transferSlice.actions;

export const transferSelector = (state: TransferSlice) => state.transfer;

export const isTransferCryptoFormValidSelector = createSelector(transferSelector, state => {
  return Boolean(
    state.amount &&
      Number(state.amount) > 0 &&
      state.recipientAddress &&
      (state.recipientType === RecipientType.Stranger || state.friend),
  );
});

export const currencyNameSelector = createSelector(transferSelector, transfer => {
  return transfer.coinName;
});

export const populateTx = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const state = getState();

    const amount = ethers.utils.parseEther(`${state.transfer.amount}`).toHexString();

    switch (state.transfer.coinName) {
      case 'WETH': {
        const tx = await TxGenerator.makeWethCryptoTransferTx(
          amount,
          state.accountInfo.address,
          state.transfer.recipientAddress,
        );
        dispatch(setTx(tx));
        break;
      }
      default: {
        const tx = await TxGenerator.makeCryptoTransferTx(
          amount,
          state.accountInfo.address,
          state.transfer.recipientAddress,
        );
        dispatch(setTx(tx));
        break;
      }
    }
  } catch (error) {
    if (error.code === 'INSUFFICIENT_FUNDS') {
      dispatch(setInsufficientBalance());
    } else {
      //captureException(error);
      throw error;
    }
  }
};

export const populateNftTransferTx =
  (tokenType: string = 'erc721') =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { transfer } = getState();
      const { tokenId, recipientAddress, collection } = transfer;

      assert(tokenId, 'Token ID is not set');
      assert(collection, 'Contract address is not set');

      const tx = await TxGenerator.makeNftTransferTx(
        tokenId,
        walletService.getAddress(),
        recipientAddress,
        collection,
        tokenType,
      );
      dispatch(setTx(tx));
    } catch (error) {
      if (error.code === 'INSUFFICIENT_FUNDS') {
        dispatch(setInsufficientBalance());
      } else {
        //captureException(error);
        throw error;
      }
    }
  };

export const transfer = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const state = getState();
    const { recipientAddress, amount, coinName, wethAddress } = state.transfer;

    assert(amount, 'The amount is not set');

    const contractAddress = coinName === 'WETH' ? wethAddress : '';

    const context = TxContextFactory.makeCryptoTransferTxContext(
      amount,
      state.accountInfo.address,
      recipientAddress,
      providerService.getChainId(),
      contractAddress,
      coinName,
    );

    const result = await dispatch(send(context));

    switch (result.status) {
      case TxStatus.Success:
        await queryClient.refetchQueries('balance');

        let amountString;
        if (state.transfer.amount) {
          amountString = fromScientificNotationToDecimal(state.transfer.amount);
        }

        showGenericSuccessToast(`Successfully transferred ${amountString} ${coinName}`);
        break;
      case TxStatus.Pending:
        showGenericSuccessToast('Your transaction is still processing and should finish shortly.');
        break;
      default:
        showGenericSuccessToast('The transaction has failed.');
    }
  } catch (error) {
    showGenericSuccessToast('The transaction has failed.');
    //captureException(error);
    throw error;
  } finally {
    dispatch(transferSlice.actions.reset());
  }
};

export const transferNft = (isMetaTx?: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const state = getState();
    const { tokenId, recipientAddress, collection, chainId } = state.transfer;

    assert(tokenId, 'Token ID is not set');
    assert(collection, 'Collection is not set');
    assert(recipientAddress, 'The recipient address is not set');

    let toAccount: UserProfile | null = null;
    try {
      toAccount = await getUserProfile(recipientAddress);
    } catch (error) {
      console.error(error);
    }

    const context = TxContextFactory.makeNftTransferTxContext(
      tokenId,
      collection,
      state.accountInfo.address,
      recipientAddress,
      chainId,
    );

    let result: any;
   /* if (isMetaTx === true) {
      result = await dispatch(sendMetaTx(context));
    } else {*/
      result = await dispatch(send(context));
   //}

    switch (result.status) {
      case TxStatus.Success:
        showGenericSuccessToast(
          `Successfully transferred token to ${toAccount?.nickname || shortenAddress(recipientAddress)}`,
        );
        break;
      case TxStatus.Pending:
        showGenericSuccessToast('Your transaction is still processing and should finish shortly.');
        break;
      default:
        showGenericSuccessToast('The transaction has failed.');
    }
  } catch (error) {
    showGenericSuccessToast('The transaction has failed.');
    //captureException(error);
    throw error;
  } finally {
    dispatch(transferSlice.actions.reset());
  }
};

export const handleMetaTx = (result: any, tx: any) => async () => {
  switch (result.transactionReceipt.status) {
    case true:
      showGenericSuccessToast('Transaction successful');
      break;
    case false:
      showGenericSuccessToast('The transaction has failed.');
      break;
    default:
      showGenericSuccessToast('The transaction has failed.');
  }
};

export const handleGenericTx = (sessionData: any, id: number, topic: string) => async (dispatch: AppDispatch) => {
  const handleTxStatus = (hash: string, transactionType: AlertType, message: string) => {

    sendResponse(hash, transactionType, id, topic)
    if (transactionType === AlertType.TransactionFailed) {
      showGenericErrorToast(message);
    } else {
      showGenericSuccessToast(message);
    }
  };

  try {
    let result: any;
    let hash: string;
    const context = TxContextFactory.makeGenericTxContext(providerService.getChainId());
   
      result = await dispatch(send(context));
      hash = result.response.hash;
    
    switch (result.status) {
      case TxStatus.Success:
        console.log('status: ', result);
        handleTxStatus(hash, AlertType.TransactionSuccessful, 'Transaction successful');
        break;
      case TxStatus.Pending:
        handleTxStatus(
          hash,
          AlertType.TransactionPending,
          'Your transaction is still processing and should finish shortly',
        );
        break;
      default:
        handleTxStatus('', AlertType.TransactionFailed, 'Transaction failed');
    }
  } catch (error) {
    handleTxStatus('', AlertType.TransactionFailed, 'Transaction failed');
    //captureException(error);
    throw error;
  } finally {
    dispatch(transferSlice.actions.reset());
  }
};
