import clsx from 'clsx';
import React, { DetailedHTMLProps, InputHTMLAttributes } from 'react';

import css from './index.module.css';

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  // Optional:
  hasError?: boolean;
}

export const AmountInput = ({ hasError, ...props }: Props) => {
  return <input {...props} className={clsx(css.input, hasError && css.error)} type="number" lang="en" min="0" step="0.01" pattern="[0-9]+([.][0-9]+)?"/>;
};
