import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthSteps, Path } from 'constants/enumTypes';

import { ConfirmPin } from '../ConfirmPin';
import { CreatePin } from '../CreatePin';
import { CreateWallet } from '../CreateWallet';
import { CreateWalletReview } from '../CreateWalletReview';
import { MnemonicConfirmation } from '../MnemonicConfirmation';
import { MnemonicReview } from '../MnemonicReview';
import { StartPage } from '../StartPage';

export const ActivatePage = () => {
  const navigate = useNavigate();
  const [stepActivate, setStepActivate] = useState(AuthSteps.CreateWalletReview);

  switch (stepActivate) {
    case AuthSteps.CreateWalletReview:
      return (
        <CreateWalletReview
          onNextStepAuth={() => setStepActivate(AuthSteps.MnemonicReview)}
          onPrevStepAuth={() => navigate(Path.Start)}
          isActivate={true}
        />
      );
    case AuthSteps.MnemonicReview:
      return (
        <MnemonicReview
          onNextStepAuth={() => setStepActivate(AuthSteps.MnemonicConfirmation)}
          onPrevStepAuth={() => setStepActivate(AuthSteps.CreateWalletReview)}
        />
      );
    case AuthSteps.MnemonicConfirmation:
      return (
        <MnemonicConfirmation
          onNextStepAuth={() => setStepActivate(AuthSteps.CreatePin)}
          onPrevStepAuth={() => setStepActivate(AuthSteps.MnemonicReview)}
          onCreateAccount={() => setStepActivate(AuthSteps.CreateWallet)}
        />
      );
    case AuthSteps.CreateWallet:
      return (
        <CreateWallet
          onNextStepAuth={() => setStepActivate(AuthSteps.CreatePin)}
          onPrevStepAuth={() => setStepActivate(AuthSteps.MnemonicConfirmation)}
          externalAccount={true}
        />
      );
    case AuthSteps.CreatePin:
      return (
        <CreatePin
          onNextStepAuth={() => setStepActivate(AuthSteps.ConfirmPin)}
          onPrevStepAuth={() => setStepActivate(AuthSteps.MnemonicConfirmation)}
        />
      );
    case AuthSteps.ConfirmPin:
      return (
        <ConfirmPin
          onNextStepAuth={() => navigate(Path.Nfts)}
          onPrevStepAuth={() => setStepActivate(AuthSteps.CreatePin)}
          isActivate={true}
        />
      );
    default:
      return <StartPage />;
  }
};
