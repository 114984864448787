import commonCss from './common.module.css';
import { InputProps } from './types';

interface Props extends InputProps<string, HTMLInputElement> {
  // Optional:
  placeholder?: string;
  readOnly?: boolean;
}

export const TextInput = (props: Props) => {
  return <input className={commonCss.box} {...props} />;
};
