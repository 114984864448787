import { Core } from '@walletconnect/core'
import { ICore } from '@walletconnect/types'
import { Web3Wallet, IWeb3Wallet } from '@walletconnect/web3wallet'
export let web3wallet: IWeb3Wallet
export let core: ICore

export async function createWeb3Wallet() {
  core = new Core({
    logger: 'debug',
    projectId: '05e20af34afa536b40aa04d5493fb769',
    relayUrl: 'wss://relay.walletconnect.com',
  })

  web3wallet = await Web3Wallet.init({
    core,
    metadata: {
        name: 'BEASY Wallet',
        description: 'BEASY Wallet',
        url: '#',
        icons: [],
      },
    });
}

export async function pair(params: { uri: string }) {
  return await core.pairing.pair({ uri: params.uri })
}