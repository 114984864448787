import { GetAssetTransfersRes_Transfer } from './protobuf/lib/alchemy';

export enum RecipientType {
  Friend = 1,
  Stranger,
}

export enum TxStatus {
  None = 'None',
  Pending = 'Pending',
  Success = 'Success',
  Error = 'Error',
}

export enum TxContextType {
  NftMinting = 'NftMinting',
  NftTransfer = 'NftTransfer',
  CryptoTransfer = 'CryptoTransfer',
}

export interface BaseTxContext {
  type: TxContextType;
  chainId: number;
}

export interface GenericTxContext extends BaseTxContext {
  type: TxContextType.NftMinting;
}

export interface NftTransferTxContext extends BaseTxContext {
  type: TxContextType.NftTransfer;
  from: string;
  to: string;
  tokenId: string;
  contractAddress: string;
}

export interface CryptoTransferTxContext extends BaseTxContext {
  type: TxContextType.CryptoTransfer;
  from: string;
  to: string;
  amount: number;
  contractAddress: string;
  coinName: string;
}

export type TxContext = GenericTxContext | NftTransferTxContext | CryptoTransferTxContext;

export interface SentTxAttempt {
  timestamp: number;
  status: TxStatus;
  block: number;
}

export interface SentTx {
  context: TxContext;
  attempts: Record<string /* hash */, SentTxAttempt>;
}

export enum TransferType {
  Nft = 'Nft',
  Crypto = 'Crypto',
}

export interface BaseTransfer {
  type: TransferType;
  txBlock: number;
  txStatus: TxStatus;
  txHash: string;
  timestamp: number;
  from: string;
  to: string;
  contractAddress: string;
  chainId: number;
  // Optional:
  uuid?: string;
}

export interface NftTransfer extends BaseTransfer {
  type: TransferType.Nft;
  tokenId: string;
}

export interface CryptoTransfer extends BaseTransfer {
  type: TransferType.Crypto;
  amount: number;
  coinName: string;
}

export type Transfer = NftTransfer | CryptoTransfer;

export interface AssetTransfer extends GetAssetTransfersRes_Transfer {
  timestamp: number;
}

export interface NftBeasyMetadataSlice {
  quantity: number;
}

export interface NftMetadata {
  // NftMetadata
  // Optional
  name?: string;
  description?: string;
  image?: string;
  beasy?: NftBeasyMetadataSlice;
}

export interface Nft {
  metadata: NftMetadata;
}
