//import { captureException } from '@sentry/react';
import { v4 } from 'uuid';

const WalletPasswordSecretStorageKey = 'walletPasswordSecret';

export class WalletPasswordService {
  async getPasswordForPin(pin: string): Promise<string> {
    const secret = await WalletPasswordService.getSecret();
    return secret + pin;
  }

  private static async getSecret(): Promise<string> {
    try {
      // TODO: encrypted storage
      let secret = await localStorage.getItem(WalletPasswordSecretStorageKey);

      if (!secret) {
        secret = v4();
        await localStorage.setItem(WalletPasswordSecretStorageKey, secret);
      }

      return secret;
    } catch (error) {
      //captureException(error);
      return '';
    }
  }
}
