import { useState } from 'react';
import { useAppSelector } from 'redux/configStore';
import { transferSelector } from 'redux/reducers/transfer';

import { EthereumIcon } from 'assets/icons/Network/EthereumIcon';
import { PolygonIcon } from 'assets/icons/Network/PolygonIcon';
import visaImage from 'assets/images/card-icon-white.png';
import checkCircleSuccess from 'assets/images/checkCircleSuccess.png';
import checkCircleSuccess2x from 'assets/images/checkCircleSuccess@2x.png';
import checkCircleSuccess3x from 'assets/images/checkCircleSuccess@3x.png';
import chevronDown from 'assets/images/chevronDown.png';
import chevronDown2x from 'assets/images/chevronDown@2x.png';
import chevronDown3x from 'assets/images/chevronDown@3x.png';

import Button, { ButtonColorScheme } from 'components/Button';
import { Modal } from 'components/Modal';

import css from './index.module.css';

export const PaymentMethodList = ({ isModify, childToParent }: any) => {
  const [selectedMethod, setSelectedPayMethod] = useState<any>(1);
  const [changeMethod, setChangeMethod] = useState(false);
  const { coinName } = useAppSelector(transferSelector);
  const PAY_METHOD = [
    { id: 1, name: coinName, icon: coinName },
    { id: 2, name: 'Credit Card', icon: 'VISA' },
  ];
  const handleOkPress = () => {
    childToParent(selectedMethod);
    setChangeMethod(false);
  };

  const onMethodSwitch = () => {
    setChangeMethod(true);
  };

  const setMethod = (methodId: any) => {
    setSelectedPayMethod(methodId);
  };

  return (
    <>
      {changeMethod && (
        <Modal>
          <div className={css.containerModal}>
            <div className={css.titleModal}>Choose payment method</div>

            <div className={css.chainListModal}>
              {PAY_METHOD.map(method => (
                <div key={method.id} className={css.chainListItemModal} onClick={() => setMethod(method.id)}>
                  <div className={css.chainNameContainerModal}>
                    {method.icon === 'MATIC' ? (
                      <div className={css.chainIconModal}>
                        {coinName === 'MATIC' ? <PolygonIcon /> : <EthereumIcon />}
                      </div>
                    ) : (
                      <div className={css.chainIconModal}>
                        <img className={css.balanceStatusIconModal} src={visaImage} alt="" />
                      </div>
                    )}

                    <div className={css.chainNameModal}>{method.name}</div>
                  </div>

                  {method.id === selectedMethod && (
                    <img
                      className={css.checkModal}
                      src={checkCircleSuccess}
                      alt="Choose payment method"
                      srcSet={`${checkCircleSuccess} 1x, ${checkCircleSuccess2x} 2x, ${checkCircleSuccess3x} 3x`}
                    />
                  )}
                </div>
              ))}
            </div>

            <Button color={ButtonColorScheme.SECONDARY} onClick={handleOkPress}>
              OK
            </Button>
          </div>
        </Modal>
      )}

      <div className={css.container}>
        <div className={css.chain} onClick={onMethodSwitch}>
          {selectedMethod === 1 ? (
            <div className={css.iconName}>
              <div className={css.chainIcon}>{coinName === 'MATIC' ? <PolygonIcon /> : <EthereumIcon />}</div>
              <div className={css.name}>{PAY_METHOD[0].name}</div>
            </div>
          ) : (
            <div className={css.iconName}>
              <div className={css.chainIcon}>
                <img className={css.balanceStatusIcon} src={visaImage} alt="" />
              </div>
              <div className={css.name}>{PAY_METHOD[1].name}</div>
            </div>
          )}

          {isModify && (
            <img
              className={css.rightIcon}
              src={chevronDown}
              alt="Choose payment method"
              srcSet={`${chevronDown} 1x, ${chevronDown2x} 2x, ${chevronDown3x} 3x`}
            />
          )}
        </div>
      </div>
    </>
  );
};
