import { grpc } from '@improbable-eng/grpc-web';
//import { captureException } from '@sentry/react';

import { GrpcWebImpl, AuthServiceClientImpl, GetCodeReq, GetJwtReq, Code, JWT } from 'protobuf/lib/auth';

import { environment } from 'environment';

const rpc = new GrpcWebImpl(environment.grpcUrl, {
  transport: grpc.XhrTransport({}),
  debug: environment.isDevelopment,
  metadata: new grpc.Metadata({}),
});

const authClient = new AuthServiceClientImpl(rpc);

export const getCode = async (params: GetCodeReq): Promise<Code> => {
  try {
    return authClient.GetCode(params);
  } catch (error) {
    //captureException(error);
    throw error;
  }
};

export const getJwt = async (params: GetJwtReq): Promise<JWT> => {
  try {
    return authClient.GetJwt(params);
  } catch (error) {
    //captureException(error);
    throw error;
  }
};
