import { IpfsCid } from 'beasy-fe-commons/build/ipfs/types';
import { useQuery } from 'react-query';

import { ipfsGateway } from '../services/apiServices/ipfs';

const queryName = 'getIpfsInfo';

export const useIpfsInfo = <T>(infoCid?: IpfsCid) => {
  const query = useQuery([queryName, infoCid], () => ipfsGateway.get<T>(infoCid!), {
    enabled: Boolean(infoCid),
  });

  return query.data;
};
