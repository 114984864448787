export class Timers {
  static cacheTimeouts = new Set<NodeJS.Timeout>();
  static cacheIntervals = new Set<NodeJS.Timeout>();

  static setTimeout(...args: [callback: (...args: any[]) => void, ms: number, ...args: any[]]) {
    const timeoutID = setTimeout.apply(undefined, args);

    Timers.cacheTimeouts.add(timeoutID);

    return timeoutID;
  }

  static clearTimeout(timeoutID: NodeJS.Timeout) {
    Timers.cacheTimeouts.delete(timeoutID);

    return clearTimeout(timeoutID);
  }

  static clearAllTimeouts() {
    Timers.cacheTimeouts.forEach(timeoutID => clearTimeout(timeoutID));
    Timers.cacheTimeouts.clear();
  }

  static setInterval(...args: [callback: (...args: any[]) => void, ms: number, ...args: any[]]) {
    const intervalID = setInterval.apply(undefined, args);
    Timers.cacheIntervals.add(intervalID);

    return intervalID;
  }

  static clearInterval(timeoutID: NodeJS.Timeout) {
    Timers.cacheIntervals.delete(timeoutID);

    return clearInterval(timeoutID);
  }

  static clearAllIntervals() {
    Timers.cacheIntervals.forEach(timeoutID => clearInterval(timeoutID));
    Timers.cacheIntervals.clear();
  }

  static delay(ms: number) {
    return new Promise<void>(resolve => Timers.setTimeout(resolve, ms));
  }
}
