import axios from 'axios';

export async function fetchWithTimeout(uri: string, timeout = 8000) {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await axios(uri, {
    signal: controller.signal,
  });

  clearTimeout(id);

  return response;
}
