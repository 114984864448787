import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorAccountInfo } from 'redux/reducers/accountInfo';
import { updateFlags } from 'redux/reducers/flags';

import { showGenericErrorToast, showGenericSuccessToast } from 'components/AppToasts';

import { Path } from 'constants/enumTypes';

import { createFriendRecipient, readFriends } from 'services/apiServices/FriendService';

export const AddFriendPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { address } = useAppSelector(selectorAccountInfo);

  useEffect(() => {
    if (!address) navigate(Path.Friends);

    dispatch(updateFlags({ isLoading: true }));

    const addFriend = async (code: string | null) => {
      try {
        if (code) {
          const friends = await readFriends(address);
          const friend = await createFriendRecipient(address, code);

          const isAlreadyAdded = friends.find(f => f.address.toLowerCase() === friend.address.toLowerCase());

          if (!isAlreadyAdded) {
            showGenericSuccessToast(`You have successfully added ${friend.name} to your friend’s list!`);
          }
        }
      } catch (error) {
        showGenericErrorToast('We were unsuccessful adding this user to your friend’s list. Please try again!');
      } finally {
        navigate(Path.Friends);
      }
    };

    addFriend(params.friendAddress ?? '').then();

    return () => {
      dispatch(updateFlags({ isLoading: false }));
    };
    // eslint-disable-next-line
  }, []);
  return <div />;
};
