import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  username: Yup.string()
    .trim()
    .required('Please enter your username')
    .matches(/^[a-zA-Z0-9 ]*$/, 'Please enter a valid username')
    .max(35),
  email: Yup.string().email('Please enter a valid email'),
});

export default validationSchema;
