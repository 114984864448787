import { TransferHistoryItemFactory } from 'factories/TransferHistoryItemFactory';
import { filterNonEmpty } from 'utils';

import { AlchemyHistoryService } from 'services/apiServices/AlchemyHistoryService';

import { Transfer } from 'types';

import { AssetTransferHistoryPersistenceService } from './AssetTransferHistoryPersistenceService';
import { FTListService } from './FTListService';
import { TransferHistoryPersistenceService } from './TransferHistoryPersistenceService';
import { AlchemyAssetHistoryService } from './apiServices/AlchemyAssetHistoryService';

export class TransferHistoryService {
  static async getTransfers(chainId?: string) {
    const fts = await FTListService.getFTListForCurrentNetwork();
    const cachedTransfers = TransferHistoryPersistenceService.getTransfers();
    let transfersByChainId: Transfer[] = [];
    cachedTransfers.forEach(transferElement => {
      if (transferElement.chainId === Number(chainId)) {
        transfersByChainId.push(transferElement);
      }
    });
    const fromBlock = transfersByChainId.reduce((acc, transfer) => Math.max(acc, transfer.txBlock), 0) + 1;
    const newAlchemyTransfers = await AlchemyHistoryService.getTransfers(fromBlock);
    const newTransfers = filterNonEmpty(
      newAlchemyTransfers.map(transfer => TransferHistoryItemFactory.makeTransferFromAlchemyHistory(fts, transfer)),
    );
    TransferHistoryPersistenceService.saveTransfers(newTransfers);
    return [...transfersByChainId, ...newTransfers];
  }

  static async getSharedTransfers(address: string) {
    const transfers = await TransferHistoryService.getTransfers();
    return transfers.filter(transfer => transfer.from === address || transfer.to === address);
  }

  static async getAssetTransfers(contractAddress: string, tokenId: string, chainId: string) {
    const fts = await FTListService.getFTListForCurrentNetwork();
    const cachedTransfers = AssetTransferHistoryPersistenceService.getTransfers(contractAddress);
    let transfersByChainId: Transfer[] = [];
    cachedTransfers.forEach(transferElement => {
      if (transferElement.chainId === Number(chainId)) {
        transfersByChainId.push(transferElement);
      }
    });
    const fromBlock = cachedTransfers.reduce((acc, transfer) => Math.max(acc, transfer.txBlock), 0) + 1;
    const newAlchemyTransfers = await AlchemyAssetHistoryService.getTransfers(contractAddress, fromBlock);
    const newTransfers = filterNonEmpty(
      newAlchemyTransfers
        .filter(transfer => transfer.tokenId.toLowerCase() === tokenId.toLowerCase())
        .map(transfer => TransferHistoryItemFactory.makeTransferFromAlchemyHistory(fts, transfer)),
    );
    AssetTransferHistoryPersistenceService.saveTransfers(contractAddress, newTransfers);
    return [...cachedTransfers, ...newTransfers];
  }
}
