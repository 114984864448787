import { getSdkError } from '@walletconnect/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAlertModal } from 'redux/reducers/alert';

import Button, { ButtonColorScheme } from 'components/Button';
import { Modal } from 'components/Modal';

import { web3wallet } from 'services/WalletConnectV2';

import PairingItem from './PairingItem';
import css from './index.module.css';

export const Pairing = () => {
  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    dispatch(updateAlertModal({ isVisibleAlert: false }));
  }, [dispatch]);
  const [pairings, setPairings] = useState(web3wallet.engine.signClient.core.pairing.pairings.values);
  console.log("PAIRINGs:" ,pairings)
  async function onDelete(topic: string) {
    await web3wallet.disconnectSession({ topic, reason: getSdkError('USER_DISCONNECTED') });
    const newPairings = pairings.filter(pairing => pairing.topic !== topic);
    setPairings(newPairings);
  }
  return (
    <Modal>
      <div className={css.container}>
        <div className={css.title}>Pairings</div>
        <div className={css.list}>
          {pairings.length ? (
            pairings.map(pairing => {
              const { peerMetadata } = pairing;

              return (
                <PairingItem
                  key={pairing.topic}
                  logo={peerMetadata?.icons[0]}
                  url={peerMetadata?.url}
                  name={peerMetadata?.name}
                  onDelete={() => onDelete(pairing.topic)}
                />
              );
            })
          ) : (
            <div className={css.title}>No pairings</div>
          )}
        </div>
      </div>
      <Button color={ButtonColorScheme.SECONDARY} onClick={handleClose}>
        OK
      </Button>
    </Modal>
  );
};
