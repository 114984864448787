import * as ethers from 'ethers';

const isMnemonicUnique = (mnemonic: string) => {
  const words = mnemonic.split(' ');
  const uniqueWords = new Set(words);
  return words.length === uniqueWords.size;
};

export class MnemonicFactory {
  static random(): string {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const entropy = ethers.utils.randomBytes(16);
      const mnemonic = ethers.utils.entropyToMnemonic(entropy);

      // See: AT-475
      if (isMnemonicUnique(mnemonic)) {
        return mnemonic;
      }
    }
  }
}
