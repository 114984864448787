import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface OnboardingState {
  mnemonic: string;
  username: string;
  pin: string;
}

export interface OnboardingSlice {
  onboarding: OnboardingState;
}

const onboardingInitialState: OnboardingState = {
  mnemonic: '',
  username: '',
  pin: '',
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: onboardingInitialState,
  reducers: {
    setMnemonic: (state, action: PayloadAction<string>): OnboardingState => {
      return { ...state, mnemonic: action.payload };
    },
    setUsername: (state, action: PayloadAction<string>): OnboardingState => {
      return { ...state, username: action.payload };
    },
    setPin: (state, action: PayloadAction<string>): OnboardingState => {
      return { ...state, pin: action.payload };
    },
    clear: (): OnboardingState => {
      return onboardingInitialState;
    },
  },
});

export { onboardingSlice };

export const { setMnemonic, setUsername, setPin } = onboardingSlice.actions;

const onboardingSelector = (state: OnboardingSlice) => state.onboarding;

export const usernameSelector = createSelector(onboardingSelector, state => state.username);

export const mnemonicSelector = createSelector(onboardingSelector, state => state.mnemonic);

export const pinSelector = createSelector(onboardingSelector, state => state.pin);
