import groupBy from 'lodash/groupBy';
import { DateTime } from 'luxon';

import { History } from 'protobuf/lib/collectibleService';

const getHistoryItemDate = (item: History) => {
  const today = DateTime.now();
  const itemDate = DateTime.fromMillis(item.timestamp);

  if (itemDate.hasSame(today, 'day')) {
    return 'Today';
  }
  return itemDate.toLocaleString(DateTime.DATE_FULL);
};

export const groupHistoryItems = (history: History[]) => {
  return groupBy(history, getHistoryItemDate);
};
