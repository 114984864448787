import clsx from 'clsx';

import { ButtonGhost } from 'components/ButtonGhost';
import { ButtonProps } from 'components/types';

import css from './index.module.css';

export const ButtonGhost2 = ({ className, ...rest }: ButtonProps) => (
  <ButtonGhost className={clsx(css.button, className)} {...rest} />
);
