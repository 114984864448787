import { getCode, getJwt } from 'services/apiServices/auth';

import { walletService } from '.';

interface AuthMeta {
  jwt: string;
}

export const makeAuthMeta = async (): Promise<AuthMeta> => {
  const address = walletService.getAddress();
  const { value: code } = await getCode({ address });
  const sig = await walletService.signMessage(code);
  const { value: jwt } = await getJwt({ address, code, sig });
  return {
    jwt,
  };
};
