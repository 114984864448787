import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/configStore';
import { updateAccountInfo } from 'redux/reducers/accountInfo';
import { updateAlertModal } from 'redux/reducers/alert';
import { updateFlags } from 'redux/reducers/flags';
import { walletPersistenceService } from 'services';

import { showGenericErrorToast } from 'components/AppToasts';
import Button, { ButtonColorScheme } from 'components/Button';
import { Modal } from 'components/Modal';
import { Pin } from 'components/Pin';

import css from './index.module.css';

export const PinForMnemonic = () => {
  const dispatch = useAppDispatch();

  const [value, setValue] = useState<number[]>([]);

  const handleCancel = useCallback(() => {
    dispatch(updateAlertModal({ isVisibleAlert: false }));
  }, [dispatch]);

  useEffect(() => {
    const submit = async () => {
      try {
        dispatch(updateFlags({ isLoading: true }));

        const passcode = value.join('');
        const [storedWallet] = walletPersistenceService.getStoredWallets();
        const wallet = await walletPersistenceService.getWallet(storedWallet.id, passcode);

        dispatch(updateAccountInfo({ mnemonicPhrase: wallet?.mnemonic.phrase ?? '' }));
        handleCancel();
      } catch (error) {
        setValue([]);
        showGenericErrorToast('Incorrect passcode');
      }
      dispatch(updateFlags({ isLoading: false }));
    };

    if (value.length === 4) {
      submit().then();
    }
  }, [value, dispatch, handleCancel]);

  return (
    <Modal>
      <div className={css.container}>
        <div className={css.title}>Enter the passcode</div>

        <div className={css.contents}>
          <Pin value={value} onChange={setValue} />
        </div>

        <Button color={ButtonColorScheme.SECONDARY} onClick={handleCancel}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
