import { TransactionRequest } from '@ethersproject/abstract-provider';
import { Deferrable } from '@ethersproject/properties';
import * as ethers from 'ethers';
import { assert } from 'utils';

import { WalletFactory } from './WalletFactory';

export class WalletService {
  private wallet: ethers.Wallet | null = null;

  connect(provider: ethers.providers.Provider): void {
    assert(this.wallet, 'Wallet is not initialized');
    this.wallet = this.wallet.connect(provider);
  }

  get hasWallet(): boolean {
    // console.log('hardhat: ', ethersHardhat)
    return !!this.wallet;
  }

  getWallet(): ethers.Wallet | null {
    return this.wallet;
  }

  setWallet(wallet: ethers.Wallet): void {
    // Important:
    // We use the `WalletFactory` instead of simply `this.wallet = wallet`
    // because we must NOT keep the mnemonic in memory
    // When we create a new wallet from private key, the resulting wallet will not have the `mnemonic` field
    this.wallet = WalletFactory.fromPrivateKey(wallet.privateKey);
  }

  clearWallet(): void {
    this.wallet = null;
  }

  getAddress(): string {
    assert(this.wallet, 'Wallet is not initialized');
    return this.wallet.address;
  }

  getAccount() {
    assert(this.wallet, 'Wallet is not initialized');
    return {
      address: this.wallet.address,
      privateKey: this.wallet.privateKey,
      publicKey: this.wallet.publicKey,
      mnemonic: this.wallet.mnemonic,
    };
  }

  async getBalance(): Promise<number> {
    assert(this.wallet, 'Wallet is not initialized');
    console.log("This wallet: ",this.wallet)
    const balance = await this.wallet.getBalance();
    return Number(ethers.utils.formatEther(balance));
  }

  async signMessage(message: string): Promise<string> {
    assert(this.wallet, 'Wallet is not initialized');

    const isUuid = message.match(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i);
    const messageToSign = isUuid ? message : ethers.utils.arrayify(message);
    return await this.wallet.signMessage(messageToSign);
  }

  async signTypedData(data: string): Promise<string> {
    assert(this.wallet, 'Wallet is not initialized');
    
    const {
      domain,
      message,
      types: { EIP712Domain, ...types },
    } = JSON.parse(data);

    return await this.wallet._signTypedData(domain, types, message);
  }

  async populateTransaction(tx: Deferrable<TransactionRequest>): Promise<TransactionRequest> {
    assert(this.wallet, 'Wallet is not initialized');
    return await this.wallet.populateTransaction(tx);
  }

  async sendTransaction(
    transaction: Deferrable<ethers.providers.TransactionRequest>,
  ): Promise<ethers.providers.TransactionResponse> {
    assert(this.wallet, 'Wallet is not initialized');
    return await this.wallet.sendTransaction(transaction);
  }
}
