import clsx from 'clsx';
import React, { useState, DetailedHTMLProps, ImgHTMLAttributes, useCallback } from 'react';

import emptyImg from 'assets/images/emptyImg.png';
import emptyImg2x from 'assets/images/emptyImg@2x.png';
import emptyImg3x from 'assets/images/emptyImg@3x.png';

import css from './index.module.css';

type Props = DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export const LoadingImage = ({ src, style, className, ...rest }: Props) => {
  const [isLoaded, setLoaded] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleLoad = useCallback(() => setLoaded(true), []);

  const handleError = useCallback(() => setIsError(true), []);

  if (isError || !src) {
    return (
      <img
        className={clsx(css.borderRadius, className)}
        src={emptyImg}
        alt="Check Box"
        srcSet={`${emptyImg} 1x, ${emptyImg2x} 2x, ${emptyImg3x} 3x`}
      />
    );
  }

  return (
    <>
      {isLoaded ? null : <div className={clsx(css.loading, className)} />}
      <img
        className={clsx(css.borderRadius, className, css.fullHeight)}
        alt=""
        {...rest}
        src={src}
        style={isLoaded ? style : { ...style, display: 'none' }}
        onLoad={handleLoad}
        onError={handleError}
      />
    </>
  );
};
