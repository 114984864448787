import { IpfsFile } from 'beasy-fe-commons';
import React from 'react';

import audioImage from 'assets/svg/audio.svg';

import { getIpfsUrl } from 'services/apiServices/ipfs';

import css from './index.module.css';

export const AudioPlayer = ({ mediaItem }: { mediaItem: IpfsFile }) => {
  return (
    <div className={css.container}>
      <img src={audioImage} alt="" />

      <div className={css.audio}>
        <audio controls preload="auto" src={getIpfsUrl(mediaItem.cid)}>
          Your browser does not support the audio tag.
        </audio>
      </div>
    </div>
  );
};
