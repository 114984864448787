//import { captureException, Severity } from '@sentry/react';
import { useCallback } from 'react';
import { store } from 'redux/configStore';
import { updateAlertModal } from 'redux/reducers/alert';
import { walletPersistenceService } from 'services';

import { AlertType } from 'constants/enumTypes';

export const useAppInit = () => {
  return useCallback(async () => {
    try {
      await walletPersistenceService.init();
      const [storedWallet] = await walletPersistenceService.getStoredWallets();

      if (storedWallet) {
        store.dispatch(updateAlertModal({ isVisibleAlert: true, alertType: AlertType.PinLock }));
      } else {
        //captureException(new Error('No stored wallets found.'), { level: Severity.Debug });
      }
    } catch (error) {
      console.log(error);
      //captureException(error);
    }
  }, []);
};
