import clsx from 'clsx';
import React from 'react';

import { IconProps } from '../types';
import style from './index.module.css';

const ASPECT_RATIO = 1;

export const TickIcon = ({ className, size = 45, onClick }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(style['tick-icon'], className)}
    onClick={onClick}
    width={size}
    height={size / ASPECT_RATIO}
    viewBox="0 0 45 45"
  >
    <g id="Сгруппировать_424" data-name="Сгруппировать 424" transform="translate(-314 -352)">
      <g id="Компонент_3_9" data-name="Компонент 3 – 9" transform="translate(314 352)">
        <circle id="Эллипс_194" data-name="Эллипс 194" cx="22.5" cy="22.5" r="22.5" />
      </g>
      <path
        id="Контур_247"
        data-name="Контур 247"
        d="M-17882.055-17726.66l6.285,5.176,11.484-13.2"
        transform="translate(18209.133 18103.563)"
        strokeLinecap="round"
        strokeWidth="3"
      />
    </g>
  </svg>
);
