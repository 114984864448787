import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
//import { captureException } from '@sentry/react';
import { ChainPersistenceService } from 'services';

import { AppDispatch } from '../configStore';

export interface BlockchainState {
  chainId: number;
}

export interface BlockchainSlice {
  blockchain: BlockchainState;
}

const blockchainInitialState: BlockchainState = {
  chainId: ChainPersistenceService.getDefaultChainId(),
};

const blockchainSlice = createSlice({
  name: 'blockchain',
  initialState: blockchainInitialState,
  reducers: {
    setChain: (state, action: PayloadAction<number>): BlockchainState => {
      return { ...state, chainId: action.payload };
    },
  },
});

export { blockchainSlice };

const { setChain } = blockchainSlice.actions;

export const updateChain = (chainId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setChain(chainId));
    ChainPersistenceService.setDefaultChainId(chainId);
  } catch (error) {
    console.error(error);
    //captureException(error);
  }
};

const blockchainSelector = (state: BlockchainSlice) => state.blockchain;

export const chainIdSelector = createSelector(blockchainSelector, state => state.chainId);
