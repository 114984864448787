import React, { ChangeEvent, ReactNode } from 'react';

import s from './Switch.module.css';
import { ReactComponent as Check } from './check.svg';

interface SwitchProps {
  name: string;
  value: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  // Optional:
  labelLeft?: ReactNode;
  labelRight?: ReactNode;
}

export const Switch = (props: SwitchProps) => {
  const { name, value, onChange, labelLeft, labelRight } = props;

  return (
    <label className={s.container}>
      {labelLeft && <div className={s.label}>{labelLeft}</div>}

      <input type="checkbox" hidden={true} name={name} checked={value} onChange={onChange} />

      <div className={s.switch}>
        <div className={s.handle}>{value && <Check />}</div>
      </div>

      {labelRight && <div className={s.label}>{labelRight}</div>}
    </label>
  );
};
