import chevronDown from 'assets/images/chevronDown.png';
import chevronDown2x from 'assets/images/chevronDown@2x.png';
import chevronDown3x from 'assets/images/chevronDown@3x.png';

import { useBalanceQuery } from 'hooks/useBalanceQuery';
import { useNetwork } from 'hooks/useNetwork';

import css from './index.module.css';

interface Props {
  onChainSwitch?: () => void;
  isModify?: boolean;
}

export const ChainInfoWallet = ({ onChainSwitch, isModify }: Props) => {
  const { allNetworks, currentNetwork } = useNetwork();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _ = useBalanceQuery();
  return (
    <div className={css.container}>
      <div className={css.chain} onClick={onChainSwitch}>
        <div className={css.list}>
          {!isModify && allNetworks.map(network => <div className={css.name}>{network?.name}</div>)}
        </div>
        {isModify && (
          <>
            <div className={css.name}>{currentNetwork?.name}</div>
            <img
              className={css.downIcon}
              src={chevronDown}
              alt="Choose network"
              srcSet={`${chevronDown} 1x, ${chevronDown2x} 2x, ${chevronDown3x} 3x`}
            />
          </>
        )}
      </div>
    </div>
  );
};
