import { DeleteIcon } from 'assets/icons';

import css from './index.module.css';

interface IProps {
  logo?: string;
  name?: string;
  url?: string;
  onDelete: () => Promise<void>;
}

export default function PairingItem({ logo, name, url, onDelete }: IProps) {
  return (
    <div>
      <div className={css.item}>
        <img className={css.logo} src={logo} />
        <div style={{ flex: 1 }}>
          <h5 style={{ marginLeft: '$9' }}>{name}</h5>
        </div>
        <DeleteIcon onClick={onDelete} />
      </div>
    </div>
  );
}
