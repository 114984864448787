import { InfoFT } from './services/apiServices/alchemyService';

export interface ChainProperties {
  chainId: number;
  tokenListUrl: string;
  defaultTokens: InfoFT[];
}

export interface ForgeApp {
  domain: string;
}

export interface AppConfig {
  apiUrl: string;
  grpcUrl: string;
  ipfsApiUrl: string;
  ipfsGatewayUrl: string;
  relayServerUrl: string;
  walletConnectPushServerUrl: string;
  isDevelopment: boolean;
  envName: string;
  showLogs: boolean;
  audioExtensions: string[];
  imageExtensions: string[];
  videoExtensions: string[];
  externalLinks: {
    termsOfService: string;
    privacyPolicy: string;
  };
  chains: ChainProperties[];
  forge: ForgeApp[];
  contracts: {
    weth: {
      address: string;
    };
  };
}

declare const window: Window & { __config: AppConfig };

export const environment: AppConfig = {
  ...window.__config,
  isDevelopment: process.env.NODE_ENV === 'development',
};

export const getChainsById = (chains: ChainProperties[]): Record<number, ChainProperties> => {
  return chains.reduce((acc, item) => ({ ...acc, [item.chainId]: item }), {});
};
