import React, { useCallback, useState } from 'react';
import { pasteFromClipboard } from 'utils';

import paste from 'assets/images/paste.png';
import paste2x from 'assets/images/paste@2x.png';
import paste3x from 'assets/images/paste@3x.png';

import css from './index.module.css';

interface Props {
  label: string;
  value: string;
  onSetPaste: (text: string) => void;
}

export const TextAreaInputWithPaste = ({ label, value, onSetPaste }: Props) => {
  const [text, setText] = useState(value ?? '');

  const handlePaste = useCallback(async () => {
    const text = await pasteFromClipboard();
    onSetPaste(text);
    setText(text);
  }, [onSetPaste]);

  return (
    <div className={css.container}>
      <label htmlFor="field">{label}</label>
      <textarea
        className={css.textField}
        name="field"
        value={text}
        onChange={e => setText(e.target.value)}
        onBlur={() => onSetPaste(text)}
      />
      <button className={css.duplicate} onClick={handlePaste}>
        <img src={paste} alt="paste" srcSet={`${paste} 1x, ${paste2x} 2x, ${paste3x} 3x`} />
      </button>
    </div>
  );
};
