import React, { useCallback } from 'react';
import { useAppDispatch } from 'redux/configStore';
import { updateAlertModal } from 'redux/reducers/alert';

import Button, { ButtonColorScheme } from 'components/Button';
import { InputErrorMessage } from 'components/Messages/InputErrorMessage';
import { Modal } from 'components/Modal';

import css from './index.module.css';

export const Disconnect = () => {
  const dispatch = useAppDispatch();

  //const peer = walletConnectService.getPeerName();
  const hideAlertModal = useCallback(() => dispatch(updateAlertModal({ isVisibleAlert: false })), [dispatch]);

  const onPressEventHandle = useCallback(async () => {
    //await walletConnectService.disconnect();
    hideAlertModal();
  }, [hideAlertModal]);

  return (
    <Modal>
      <div className={css.container}>
        <h2 className={css.titleText}>Disconnect Session</h2>
        <InputErrorMessage message={`Are you sure you want to disconnect from`} />
        <Button className={css.button} colorScheme={ButtonColorScheme.SECONDARY} onClick={hideAlertModal}>
          No, stay
        </Button>
        <Button className={css.button} onClick={onPressEventHandle}>
          Yes, disconnect
        </Button>
      </div>
    </Modal>
  );
};
