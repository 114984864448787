import { providerService } from 'services';

import { FTListPersistenceService } from 'services/FTListPersistenceService';
import { InfoFT } from 'services/apiServices/alchemyService';
import { getListTokenPolygon } from 'services/apiServices/polygonService';

export class FTListService {
  static async getFTListForCurrentNetwork(): Promise<InfoFT[]> {
    const networkId = providerService.getChainId();
    const networks = providerService.getNetworks();
    const savedTokens = FTListPersistenceService.getFTListForNetwork(networkId);
    if (savedTokens) {
      return savedTokens;
    }
    const currentNetwork = networks?.find(({ id }) => id === `${networkId}`);
    if (currentNetwork?.listTokenUrl !== '') {
      const tokens = await getListTokenPolygon(currentNetwork?.listTokenUrl, currentNetwork?.wethAddress);
      if (tokens.length > 0) {
        FTListPersistenceService.saveFTListForNetwork(networkId, tokens);
        return tokens;
      } else {
        const EmptyFTlist: InfoFT[] = [];
        return EmptyFTlist;
      }
    } else {
      const EmptyFTlist: InfoFT[] = [];
      return EmptyFTlist;
    }
  }
}
