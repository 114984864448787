
import { WarningIcon } from 'assets/icons';
import Button, { ButtonColorScheme } from 'components/Button';

import { DialogArea, DialogClose, DialogFooter, DialogHeader, DialogOverlay } from 'components/dialog';

import css from './index.module.css';

interface Props {
  onAccept: () => void;
  onCancel: () => void;
}

const ChangeNetworkModal = ({ onAccept, onCancel }: Props) => {
  return (
    <DialogOverlay>
      <DialogArea>
        <DialogHeader>
          <div className={css.header}>
            <WarningIcon className={css.icon} />
            <h3>Confirm network switch</h3>
          </div>
          <DialogClose onClick={onCancel} />
        </DialogHeader>
        <p className={css.label}>Changing the selected Network will disconnect your wallet session. Please reconnect your wallet after changing networks.</p>
        <DialogFooter>
          <Button style={{backgroundColor: '#2f3542'}} onClick={onCancel}>
            Cancel
          </Button>
          <Button color={ButtonColorScheme.PRIMARY} onClick={onAccept}>
            Accept
          </Button>
        </DialogFooter>
      </DialogArea>
    </DialogOverlay>
  );
};

export default ChangeNetworkModal;
