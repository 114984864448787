//import { captureException, Severity } from '@sentry/react';
import React, { ChangeEvent, useCallback, useRef } from 'react';
import { useQueryClient } from 'react-query';
import { useAppDispatch } from 'redux/configStore';
import { updateAlertModal } from 'redux/reducers/alert';
import { updateFlags } from 'redux/reducers/flags';

import { showGenericErrorToast, showGenericSuccessToast } from 'components/AppToasts';
import Button, { ButtonColorScheme } from 'components/Button';
import { Modal } from 'components/Modal';

import { updateUserAvatar } from 'services/userProfile';

import css from './index.module.css';

export const Avatar = () => {
  const dispatch = useAppDispatch();

  const queryClient = useQueryClient();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleChoosePhoto = useCallback(() => {
    inputRef.current?.click();
  }, []);

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      try {
        dispatch(updateFlags({ isLoading: true }));
        dispatch(
          updateAlertModal({
            isVisibleAlert: false,
          }),
        );

        const file = event.target.files?.[0];

        file && (await updateUserAvatar(file));
        await queryClient.refetchQueries({ queryKey: 'getCurrentUserProfile' });
        showGenericSuccessToast('Profile picture successfully updated');
      } catch (e) {
        //captureException(new Error('Could not update profile picture'), { level: Severity.Debug, extra: { e } });
        showGenericErrorToast('Could not update profile picture');
      } finally {
        dispatch(updateFlags({ isLoading: false }));
      }
    },

    [queryClient, dispatch],
  );

  const handleClose = useCallback(() => dispatch(updateAlertModal({ isVisibleAlert: false })), [dispatch]);

  return (
    <Modal >
      <div className={css.container}>
        <Button className={css.button} onClick={handleChoosePhoto}>
          Choose a photo
        </Button>
        <Button className={css.button} colorScheme={ButtonColorScheme.SECONDARY} onClick={handleClose}>
          Cancel
        </Button>
      </div>
      <input
        ref={inputRef}
        className={css.loadFile}
        type="file"
        accept="image/png, image/jpg, image/jpeg, image/gif, image/svg"
        onChange={handleChange}
      />
    </Modal>
  );
};
