import { ChainPersistenceService } from './ChainPersistenceService';
import { MnemonicFactory } from './MnemonicFactory';
import { ProviderService } from './ProviderService';
import { RedirectAfterPinService } from './RedirectAfterPinService';

import { WalletConnectSessionPersistenceService } from './WalletConnectSessionPersistenceService';
import { WalletFactory } from './WalletFactory';
import { WalletPasswordService } from './WalletPasswordService';
import { WalletPersistenceService } from './WalletPersistenceService';
import { WalletService } from './WalletService';
import { WethService } from './WethService';

export { MnemonicFactory, WalletFactory, ChainPersistenceService, RedirectAfterPinService };

const walletPasswordService = new WalletPasswordService();

export const walletPersistenceService = new WalletPersistenceService(walletPasswordService);

export const walletService = new WalletService();

export const providerService = new ProviderService(walletService);

export const walletConnectSessionPersistenceService = new WalletConnectSessionPersistenceService();


export const wethService = new WethService(walletService);
