/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Empty } from "./google/protobuf/empty";
import { Timestamp } from "./google/protobuf/timestamp";
import { MinimalTransactionInfo } from "./transactionMessage";
import { UserProfile } from "./userProfileService";

export const protobufPackage = "";

export interface MintTokenTransactionRes {
  transaction: MinimalTransactionInfo | undefined;
}

export interface MintTokenTransactionReq {
  collectibleId: string;
  buyerAddress: string;
  priceUsd: string;
  orderId: string;
  prices: MintTokenTransactionReq_Price[];
  collection: string;
}

export interface MintTokenTransactionReq_Price {
  currency: string;
  value: string;
}

export interface Contributor {
  userProfile: UserProfile | undefined;
  revenueShare: string;
}

export interface Token {
  tokenId: string;
}

export interface Collectible {
  collectibleId: string;
  name: string;
  description: string;
  collectibleURL: string;
  price: number;
  quantity: number;
  creator: string;
  blockCreated: number;
  blockUpdated: number;
  CID: string;
  contributors: Contributor[];
  tokens: Token[];
  traded: number;
  networkCreated: string;
  networkUpdated: string;
  collection: string;
}

export interface History {
  from: UserProfile | undefined;
  to: UserProfile | undefined;
  collectibleId: string;
  name: string;
  block: number;
  timestamp: number;
  quantity: number;
  CID: string;
  network: string;
  collection: string;
}

export interface GetCollectibleByIdReq {
  collectibleId: string;
}

export interface DeleteCollectibleByIdReq {
  collectibleId: string;
}

export interface GetCollectibleByIdRes {
  collectible: Collectible | undefined;
}

/** @deprecated */
export interface GetGrossRevenuePointsRes {
  points: GetGrossRevenuePointsRes_Point[];
}

export interface GetGrossRevenuePointsRes_Point {
  price: number;
  timestamp: Date | undefined;
}

/** @deprecated */
export interface GetGrossRevenuePointsReq {
  collectibleId: string;
  fromDate: Date | undefined;
  toDate: Date | undefined;
  currency: string;
}

export interface GetCollectiblesByOwnerReq {
  address: string;
}

export interface GetCollectiblesByOwnerRes {
  collectiblesCreator: Collectible[];
  collectiblesPurchased: Collectible[];
}

/** @deprecated */
export interface GetCollectiblesByCreatorReq {
  address: string;
}

/** @deprecated */
export interface GetCollectiblesByCreatorRes {
  collectibles: Collectible[];
}

export interface GetCollectiblesReq {
  creator?: string | undefined;
  contributor?: string | undefined;
  collection?: string | undefined;
}

export interface GetCollectiblesRes {
  collectibles: Collectible[];
}

export interface GetCurrentCollectiblesByOwnerReq {
  address: string;
}

export interface GetCurrentCollectiblesByOwnerRes {
  collectibles: Collectible[];
}

/** @deprecated */
export interface GetCollectiblesByContributorReq {
  address: string;
}

/** @deprecated */
export interface GetCollectiblesByContributorRes {
  collectibles: Collectible[];
}

export interface GetAssetHistoryReq {
  tokenId: string;
}

export interface GetAssetHistoryRes {
  history: History[];
}

export interface GetHistoryReq {
  address: string;
}

export interface GetHistoryRes {
  history: History[];
}

export interface GetSharedHistoryReq {
  address: string;
  friendAddress: string;
}

export interface GetSharedHistoryRes {
  history: History[];
}

export interface GetSharedCollectiblesWithCreatorReq {
  address: string;
}

export interface GetSharedCollectiblesWithCreatorRes {
  collectibles: Collectible[];
}

export interface CreateCollectibleTransactionReq {
  name: string;
  description: string;
  collectibleURL: string;
  collectibleId: string;
  price: number;
  quantity: number;
  creator: string;
  contributors: CreateCollectibleTransactionReq_Contributor[];
  network: string;
  collection: string;
}

export interface CreateCollectibleTransactionReq_Contributor {
  userProfileId: string;
  revenueShare: string;
}

export interface CreateCollectibleTransactionRes {
  transaction: MinimalTransactionInfo | undefined;
}

function createBaseMintTokenTransactionRes(): MintTokenTransactionRes {
  return { transaction: undefined };
}

export const MintTokenTransactionRes = {
  encode(message: MintTokenTransactionRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transaction !== undefined) {
      MinimalTransactionInfo.encode(message.transaction, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MintTokenTransactionRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMintTokenTransactionRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transaction = MinimalTransactionInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<MintTokenTransactionRes>, I>>(base?: I): MintTokenTransactionRes {
    return MintTokenTransactionRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MintTokenTransactionRes>, I>>(object: I): MintTokenTransactionRes {
    const message = createBaseMintTokenTransactionRes();
    message.transaction = (object.transaction !== undefined && object.transaction !== null)
      ? MinimalTransactionInfo.fromPartial(object.transaction)
      : undefined;
    return message;
  },
};

function createBaseMintTokenTransactionReq(): MintTokenTransactionReq {
  return { collectibleId: "", buyerAddress: "", priceUsd: "", orderId: "", prices: [], collection: "" };
}

export const MintTokenTransactionReq = {
  encode(message: MintTokenTransactionReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectibleId !== "") {
      writer.uint32(10).string(message.collectibleId);
    }
    if (message.buyerAddress !== "") {
      writer.uint32(18).string(message.buyerAddress);
    }
    if (message.priceUsd !== "") {
      writer.uint32(26).string(message.priceUsd);
    }
    if (message.orderId !== "") {
      writer.uint32(34).string(message.orderId);
    }
    for (const v of message.prices) {
      MintTokenTransactionReq_Price.encode(v!, writer.uint32(42).fork()).ldelim();
    }
    if (message.collection !== "") {
      writer.uint32(50).string(message.collection);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MintTokenTransactionReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMintTokenTransactionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibleId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.buyerAddress = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.priceUsd = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.orderId = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.prices.push(MintTokenTransactionReq_Price.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.collection = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<MintTokenTransactionReq>, I>>(base?: I): MintTokenTransactionReq {
    return MintTokenTransactionReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MintTokenTransactionReq>, I>>(object: I): MintTokenTransactionReq {
    const message = createBaseMintTokenTransactionReq();
    message.collectibleId = object.collectibleId ?? "";
    message.buyerAddress = object.buyerAddress ?? "";
    message.priceUsd = object.priceUsd ?? "";
    message.orderId = object.orderId ?? "";
    message.prices = object.prices?.map((e) => MintTokenTransactionReq_Price.fromPartial(e)) || [];
    message.collection = object.collection ?? "";
    return message;
  },
};

function createBaseMintTokenTransactionReq_Price(): MintTokenTransactionReq_Price {
  return { currency: "", value: "" };
}

export const MintTokenTransactionReq_Price = {
  encode(message: MintTokenTransactionReq_Price, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.currency !== "") {
      writer.uint32(10).string(message.currency);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MintTokenTransactionReq_Price {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMintTokenTransactionReq_Price();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.currency = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<MintTokenTransactionReq_Price>, I>>(base?: I): MintTokenTransactionReq_Price {
    return MintTokenTransactionReq_Price.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<MintTokenTransactionReq_Price>, I>>(
    object: I,
  ): MintTokenTransactionReq_Price {
    const message = createBaseMintTokenTransactionReq_Price();
    message.currency = object.currency ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseContributor(): Contributor {
  return { userProfile: undefined, revenueShare: "" };
}

export const Contributor = {
  encode(message: Contributor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userProfile !== undefined) {
      UserProfile.encode(message.userProfile, writer.uint32(10).fork()).ldelim();
    }
    if (message.revenueShare !== "") {
      writer.uint32(18).string(message.revenueShare);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Contributor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseContributor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userProfile = UserProfile.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.revenueShare = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Contributor>, I>>(base?: I): Contributor {
    return Contributor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Contributor>, I>>(object: I): Contributor {
    const message = createBaseContributor();
    message.userProfile = (object.userProfile !== undefined && object.userProfile !== null)
      ? UserProfile.fromPartial(object.userProfile)
      : undefined;
    message.revenueShare = object.revenueShare ?? "";
    return message;
  },
};

function createBaseToken(): Token {
  return { tokenId: "" };
}

export const Token = {
  encode(message: Token, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tokenId !== "") {
      writer.uint32(10).string(message.tokenId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Token {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseToken();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokenId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Token>, I>>(base?: I): Token {
    return Token.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Token>, I>>(object: I): Token {
    const message = createBaseToken();
    message.tokenId = object.tokenId ?? "";
    return message;
  },
};

function createBaseCollectible(): Collectible {
  return {
    collectibleId: "",
    name: "",
    description: "",
    collectibleURL: "",
    price: 0,
    quantity: 0,
    creator: "",
    blockCreated: 0,
    blockUpdated: 0,
    CID: "",
    contributors: [],
    tokens: [],
    traded: 0,
    networkCreated: "",
    networkUpdated: "",
    collection: "",
  };
}

export const Collectible = {
  encode(message: Collectible, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectibleId !== "") {
      writer.uint32(10).string(message.collectibleId);
    }
    if (message.name !== "") {
      writer.uint32(18).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    if (message.collectibleURL !== "") {
      writer.uint32(34).string(message.collectibleURL);
    }
    if (message.price !== 0) {
      writer.uint32(41).fixed64(message.price);
    }
    if (message.quantity !== 0) {
      writer.uint32(48).int64(message.quantity);
    }
    if (message.creator !== "") {
      writer.uint32(58).string(message.creator);
    }
    if (message.blockCreated !== 0) {
      writer.uint32(64).int64(message.blockCreated);
    }
    if (message.blockUpdated !== 0) {
      writer.uint32(72).int64(message.blockUpdated);
    }
    if (message.CID !== "") {
      writer.uint32(82).string(message.CID);
    }
    for (const v of message.contributors) {
      Contributor.encode(v!, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.tokens) {
      Token.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    if (message.traded !== 0) {
      writer.uint32(104).int32(message.traded);
    }
    if (message.networkCreated !== "") {
      writer.uint32(114).string(message.networkCreated);
    }
    if (message.networkUpdated !== "") {
      writer.uint32(122).string(message.networkUpdated);
    }
    if (message.collection !== "") {
      writer.uint32(130).string(message.collection);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Collectible {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCollectible();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibleId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.collectibleURL = reader.string();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.price = longToNumber(reader.fixed64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.quantity = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.creator = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.blockCreated = longToNumber(reader.int64() as Long);
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.blockUpdated = longToNumber(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.CID = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.contributors.push(Contributor.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.tokens.push(Token.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.traded = reader.int32();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.networkCreated = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.networkUpdated = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.collection = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Collectible>, I>>(base?: I): Collectible {
    return Collectible.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Collectible>, I>>(object: I): Collectible {
    const message = createBaseCollectible();
    message.collectibleId = object.collectibleId ?? "";
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.collectibleURL = object.collectibleURL ?? "";
    message.price = object.price ?? 0;
    message.quantity = object.quantity ?? 0;
    message.creator = object.creator ?? "";
    message.blockCreated = object.blockCreated ?? 0;
    message.blockUpdated = object.blockUpdated ?? 0;
    message.CID = object.CID ?? "";
    message.contributors = object.contributors?.map((e) => Contributor.fromPartial(e)) || [];
    message.tokens = object.tokens?.map((e) => Token.fromPartial(e)) || [];
    message.traded = object.traded ?? 0;
    message.networkCreated = object.networkCreated ?? "";
    message.networkUpdated = object.networkUpdated ?? "";
    message.collection = object.collection ?? "";
    return message;
  },
};

function createBaseHistory(): History {
  return {
    from: undefined,
    to: undefined,
    collectibleId: "",
    name: "",
    block: 0,
    timestamp: 0,
    quantity: 0,
    CID: "",
    network: "",
    collection: "",
  };
}

export const History = {
  encode(message: History, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.from !== undefined) {
      UserProfile.encode(message.from, writer.uint32(10).fork()).ldelim();
    }
    if (message.to !== undefined) {
      UserProfile.encode(message.to, writer.uint32(18).fork()).ldelim();
    }
    if (message.collectibleId !== "") {
      writer.uint32(26).string(message.collectibleId);
    }
    if (message.name !== "") {
      writer.uint32(34).string(message.name);
    }
    if (message.block !== 0) {
      writer.uint32(40).int64(message.block);
    }
    if (message.timestamp !== 0) {
      writer.uint32(48).int64(message.timestamp);
    }
    if (message.quantity !== 0) {
      writer.uint32(56).int32(message.quantity);
    }
    if (message.CID !== "") {
      writer.uint32(66).string(message.CID);
    }
    if (message.network !== "") {
      writer.uint32(74).string(message.network);
    }
    if (message.collection !== "") {
      writer.uint32(82).string(message.collection);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): History {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHistory();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.from = UserProfile.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.to = UserProfile.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.collectibleId = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.name = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.block = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.timestamp = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.quantity = reader.int32();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.CID = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.network = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.collection = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<History>, I>>(base?: I): History {
    return History.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<History>, I>>(object: I): History {
    const message = createBaseHistory();
    message.from = (object.from !== undefined && object.from !== null)
      ? UserProfile.fromPartial(object.from)
      : undefined;
    message.to = (object.to !== undefined && object.to !== null) ? UserProfile.fromPartial(object.to) : undefined;
    message.collectibleId = object.collectibleId ?? "";
    message.name = object.name ?? "";
    message.block = object.block ?? 0;
    message.timestamp = object.timestamp ?? 0;
    message.quantity = object.quantity ?? 0;
    message.CID = object.CID ?? "";
    message.network = object.network ?? "";
    message.collection = object.collection ?? "";
    return message;
  },
};

function createBaseGetCollectibleByIdReq(): GetCollectibleByIdReq {
  return { collectibleId: "" };
}

export const GetCollectibleByIdReq = {
  encode(message: GetCollectibleByIdReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectibleId !== "") {
      writer.uint32(10).string(message.collectibleId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectibleByIdReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectibleByIdReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibleId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectibleByIdReq>, I>>(base?: I): GetCollectibleByIdReq {
    return GetCollectibleByIdReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectibleByIdReq>, I>>(object: I): GetCollectibleByIdReq {
    const message = createBaseGetCollectibleByIdReq();
    message.collectibleId = object.collectibleId ?? "";
    return message;
  },
};

function createBaseDeleteCollectibleByIdReq(): DeleteCollectibleByIdReq {
  return { collectibleId: "" };
}

export const DeleteCollectibleByIdReq = {
  encode(message: DeleteCollectibleByIdReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectibleId !== "") {
      writer.uint32(10).string(message.collectibleId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DeleteCollectibleByIdReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDeleteCollectibleByIdReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibleId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DeleteCollectibleByIdReq>, I>>(base?: I): DeleteCollectibleByIdReq {
    return DeleteCollectibleByIdReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<DeleteCollectibleByIdReq>, I>>(object: I): DeleteCollectibleByIdReq {
    const message = createBaseDeleteCollectibleByIdReq();
    message.collectibleId = object.collectibleId ?? "";
    return message;
  },
};

function createBaseGetCollectibleByIdRes(): GetCollectibleByIdRes {
  return { collectible: undefined };
}

export const GetCollectibleByIdRes = {
  encode(message: GetCollectibleByIdRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectible !== undefined) {
      Collectible.encode(message.collectible, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectibleByIdRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectibleByIdRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectible = Collectible.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectibleByIdRes>, I>>(base?: I): GetCollectibleByIdRes {
    return GetCollectibleByIdRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectibleByIdRes>, I>>(object: I): GetCollectibleByIdRes {
    const message = createBaseGetCollectibleByIdRes();
    message.collectible = (object.collectible !== undefined && object.collectible !== null)
      ? Collectible.fromPartial(object.collectible)
      : undefined;
    return message;
  },
};

function createBaseGetGrossRevenuePointsRes(): GetGrossRevenuePointsRes {
  return { points: [] };
}

export const GetGrossRevenuePointsRes = {
  encode(message: GetGrossRevenuePointsRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.points) {
      GetGrossRevenuePointsRes_Point.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetGrossRevenuePointsRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetGrossRevenuePointsRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.points.push(GetGrossRevenuePointsRes_Point.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetGrossRevenuePointsRes>, I>>(base?: I): GetGrossRevenuePointsRes {
    return GetGrossRevenuePointsRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetGrossRevenuePointsRes>, I>>(object: I): GetGrossRevenuePointsRes {
    const message = createBaseGetGrossRevenuePointsRes();
    message.points = object.points?.map((e) => GetGrossRevenuePointsRes_Point.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetGrossRevenuePointsRes_Point(): GetGrossRevenuePointsRes_Point {
  return { price: 0, timestamp: undefined };
}

export const GetGrossRevenuePointsRes_Point = {
  encode(message: GetGrossRevenuePointsRes_Point, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.price !== 0) {
      writer.uint32(9).double(message.price);
    }
    if (message.timestamp !== undefined) {
      Timestamp.encode(toTimestamp(message.timestamp), writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetGrossRevenuePointsRes_Point {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetGrossRevenuePointsRes_Point();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.price = reader.double();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.timestamp = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetGrossRevenuePointsRes_Point>, I>>(base?: I): GetGrossRevenuePointsRes_Point {
    return GetGrossRevenuePointsRes_Point.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetGrossRevenuePointsRes_Point>, I>>(
    object: I,
  ): GetGrossRevenuePointsRes_Point {
    const message = createBaseGetGrossRevenuePointsRes_Point();
    message.price = object.price ?? 0;
    message.timestamp = object.timestamp ?? undefined;
    return message;
  },
};

function createBaseGetGrossRevenuePointsReq(): GetGrossRevenuePointsReq {
  return { collectibleId: "", fromDate: undefined, toDate: undefined, currency: "" };
}

export const GetGrossRevenuePointsReq = {
  encode(message: GetGrossRevenuePointsReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.collectibleId !== "") {
      writer.uint32(10).string(message.collectibleId);
    }
    if (message.fromDate !== undefined) {
      Timestamp.encode(toTimestamp(message.fromDate), writer.uint32(18).fork()).ldelim();
    }
    if (message.toDate !== undefined) {
      Timestamp.encode(toTimestamp(message.toDate), writer.uint32(26).fork()).ldelim();
    }
    if (message.currency !== "") {
      writer.uint32(34).string(message.currency);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetGrossRevenuePointsReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetGrossRevenuePointsReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibleId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.fromDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.toDate = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.currency = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetGrossRevenuePointsReq>, I>>(base?: I): GetGrossRevenuePointsReq {
    return GetGrossRevenuePointsReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetGrossRevenuePointsReq>, I>>(object: I): GetGrossRevenuePointsReq {
    const message = createBaseGetGrossRevenuePointsReq();
    message.collectibleId = object.collectibleId ?? "";
    message.fromDate = object.fromDate ?? undefined;
    message.toDate = object.toDate ?? undefined;
    message.currency = object.currency ?? "";
    return message;
  },
};

function createBaseGetCollectiblesByOwnerReq(): GetCollectiblesByOwnerReq {
  return { address: "" };
}

export const GetCollectiblesByOwnerReq = {
  encode(message: GetCollectiblesByOwnerReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectiblesByOwnerReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectiblesByOwnerReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectiblesByOwnerReq>, I>>(base?: I): GetCollectiblesByOwnerReq {
    return GetCollectiblesByOwnerReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectiblesByOwnerReq>, I>>(object: I): GetCollectiblesByOwnerReq {
    const message = createBaseGetCollectiblesByOwnerReq();
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseGetCollectiblesByOwnerRes(): GetCollectiblesByOwnerRes {
  return { collectiblesCreator: [], collectiblesPurchased: [] };
}

export const GetCollectiblesByOwnerRes = {
  encode(message: GetCollectiblesByOwnerRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.collectiblesCreator) {
      Collectible.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.collectiblesPurchased) {
      Collectible.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectiblesByOwnerRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectiblesByOwnerRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectiblesCreator.push(Collectible.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.collectiblesPurchased.push(Collectible.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectiblesByOwnerRes>, I>>(base?: I): GetCollectiblesByOwnerRes {
    return GetCollectiblesByOwnerRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectiblesByOwnerRes>, I>>(object: I): GetCollectiblesByOwnerRes {
    const message = createBaseGetCollectiblesByOwnerRes();
    message.collectiblesCreator = object.collectiblesCreator?.map((e) => Collectible.fromPartial(e)) || [];
    message.collectiblesPurchased = object.collectiblesPurchased?.map((e) => Collectible.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetCollectiblesByCreatorReq(): GetCollectiblesByCreatorReq {
  return { address: "" };
}

export const GetCollectiblesByCreatorReq = {
  encode(message: GetCollectiblesByCreatorReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectiblesByCreatorReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectiblesByCreatorReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectiblesByCreatorReq>, I>>(base?: I): GetCollectiblesByCreatorReq {
    return GetCollectiblesByCreatorReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectiblesByCreatorReq>, I>>(object: I): GetCollectiblesByCreatorReq {
    const message = createBaseGetCollectiblesByCreatorReq();
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseGetCollectiblesByCreatorRes(): GetCollectiblesByCreatorRes {
  return { collectibles: [] };
}

export const GetCollectiblesByCreatorRes = {
  encode(message: GetCollectiblesByCreatorRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.collectibles) {
      Collectible.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectiblesByCreatorRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectiblesByCreatorRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibles.push(Collectible.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectiblesByCreatorRes>, I>>(base?: I): GetCollectiblesByCreatorRes {
    return GetCollectiblesByCreatorRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectiblesByCreatorRes>, I>>(object: I): GetCollectiblesByCreatorRes {
    const message = createBaseGetCollectiblesByCreatorRes();
    message.collectibles = object.collectibles?.map((e) => Collectible.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetCollectiblesReq(): GetCollectiblesReq {
  return { creator: undefined, contributor: undefined, collection: undefined };
}

export const GetCollectiblesReq = {
  encode(message: GetCollectiblesReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.creator !== undefined) {
      writer.uint32(10).string(message.creator);
    }
    if (message.contributor !== undefined) {
      writer.uint32(18).string(message.contributor);
    }
    if (message.collection !== undefined) {
      writer.uint32(26).string(message.collection);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectiblesReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectiblesReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.creator = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.contributor = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.collection = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectiblesReq>, I>>(base?: I): GetCollectiblesReq {
    return GetCollectiblesReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectiblesReq>, I>>(object: I): GetCollectiblesReq {
    const message = createBaseGetCollectiblesReq();
    message.creator = object.creator ?? undefined;
    message.contributor = object.contributor ?? undefined;
    message.collection = object.collection ?? undefined;
    return message;
  },
};

function createBaseGetCollectiblesRes(): GetCollectiblesRes {
  return { collectibles: [] };
}

export const GetCollectiblesRes = {
  encode(message: GetCollectiblesRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.collectibles) {
      Collectible.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectiblesRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectiblesRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibles.push(Collectible.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectiblesRes>, I>>(base?: I): GetCollectiblesRes {
    return GetCollectiblesRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectiblesRes>, I>>(object: I): GetCollectiblesRes {
    const message = createBaseGetCollectiblesRes();
    message.collectibles = object.collectibles?.map((e) => Collectible.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetCurrentCollectiblesByOwnerReq(): GetCurrentCollectiblesByOwnerReq {
  return { address: "" };
}

export const GetCurrentCollectiblesByOwnerReq = {
  encode(message: GetCurrentCollectiblesByOwnerReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCurrentCollectiblesByOwnerReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCurrentCollectiblesByOwnerReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCurrentCollectiblesByOwnerReq>, I>>(
    base?: I,
  ): GetCurrentCollectiblesByOwnerReq {
    return GetCurrentCollectiblesByOwnerReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCurrentCollectiblesByOwnerReq>, I>>(
    object: I,
  ): GetCurrentCollectiblesByOwnerReq {
    const message = createBaseGetCurrentCollectiblesByOwnerReq();
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseGetCurrentCollectiblesByOwnerRes(): GetCurrentCollectiblesByOwnerRes {
  return { collectibles: [] };
}

export const GetCurrentCollectiblesByOwnerRes = {
  encode(message: GetCurrentCollectiblesByOwnerRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.collectibles) {
      Collectible.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCurrentCollectiblesByOwnerRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCurrentCollectiblesByOwnerRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibles.push(Collectible.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCurrentCollectiblesByOwnerRes>, I>>(
    base?: I,
  ): GetCurrentCollectiblesByOwnerRes {
    return GetCurrentCollectiblesByOwnerRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCurrentCollectiblesByOwnerRes>, I>>(
    object: I,
  ): GetCurrentCollectiblesByOwnerRes {
    const message = createBaseGetCurrentCollectiblesByOwnerRes();
    message.collectibles = object.collectibles?.map((e) => Collectible.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetCollectiblesByContributorReq(): GetCollectiblesByContributorReq {
  return { address: "" };
}

export const GetCollectiblesByContributorReq = {
  encode(message: GetCollectiblesByContributorReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectiblesByContributorReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectiblesByContributorReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectiblesByContributorReq>, I>>(base?: I): GetCollectiblesByContributorReq {
    return GetCollectiblesByContributorReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectiblesByContributorReq>, I>>(
    object: I,
  ): GetCollectiblesByContributorReq {
    const message = createBaseGetCollectiblesByContributorReq();
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseGetCollectiblesByContributorRes(): GetCollectiblesByContributorRes {
  return { collectibles: [] };
}

export const GetCollectiblesByContributorRes = {
  encode(message: GetCollectiblesByContributorRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.collectibles) {
      Collectible.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetCollectiblesByContributorRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetCollectiblesByContributorRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibles.push(Collectible.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetCollectiblesByContributorRes>, I>>(base?: I): GetCollectiblesByContributorRes {
    return GetCollectiblesByContributorRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetCollectiblesByContributorRes>, I>>(
    object: I,
  ): GetCollectiblesByContributorRes {
    const message = createBaseGetCollectiblesByContributorRes();
    message.collectibles = object.collectibles?.map((e) => Collectible.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetAssetHistoryReq(): GetAssetHistoryReq {
  return { tokenId: "" };
}

export const GetAssetHistoryReq = {
  encode(message: GetAssetHistoryReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tokenId !== "") {
      writer.uint32(10).string(message.tokenId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAssetHistoryReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAssetHistoryReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tokenId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetAssetHistoryReq>, I>>(base?: I): GetAssetHistoryReq {
    return GetAssetHistoryReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAssetHistoryReq>, I>>(object: I): GetAssetHistoryReq {
    const message = createBaseGetAssetHistoryReq();
    message.tokenId = object.tokenId ?? "";
    return message;
  },
};

function createBaseGetAssetHistoryRes(): GetAssetHistoryRes {
  return { history: [] };
}

export const GetAssetHistoryRes = {
  encode(message: GetAssetHistoryRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.history) {
      History.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetAssetHistoryRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetAssetHistoryRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.history.push(History.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetAssetHistoryRes>, I>>(base?: I): GetAssetHistoryRes {
    return GetAssetHistoryRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetAssetHistoryRes>, I>>(object: I): GetAssetHistoryRes {
    const message = createBaseGetAssetHistoryRes();
    message.history = object.history?.map((e) => History.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetHistoryReq(): GetHistoryReq {
  return { address: "" };
}

export const GetHistoryReq = {
  encode(message: GetHistoryReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetHistoryReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetHistoryReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetHistoryReq>, I>>(base?: I): GetHistoryReq {
    return GetHistoryReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetHistoryReq>, I>>(object: I): GetHistoryReq {
    const message = createBaseGetHistoryReq();
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseGetHistoryRes(): GetHistoryRes {
  return { history: [] };
}

export const GetHistoryRes = {
  encode(message: GetHistoryRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.history) {
      History.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetHistoryRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetHistoryRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.history.push(History.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetHistoryRes>, I>>(base?: I): GetHistoryRes {
    return GetHistoryRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetHistoryRes>, I>>(object: I): GetHistoryRes {
    const message = createBaseGetHistoryRes();
    message.history = object.history?.map((e) => History.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetSharedHistoryReq(): GetSharedHistoryReq {
  return { address: "", friendAddress: "" };
}

export const GetSharedHistoryReq = {
  encode(message: GetSharedHistoryReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    if (message.friendAddress !== "") {
      writer.uint32(18).string(message.friendAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSharedHistoryReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSharedHistoryReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.friendAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetSharedHistoryReq>, I>>(base?: I): GetSharedHistoryReq {
    return GetSharedHistoryReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSharedHistoryReq>, I>>(object: I): GetSharedHistoryReq {
    const message = createBaseGetSharedHistoryReq();
    message.address = object.address ?? "";
    message.friendAddress = object.friendAddress ?? "";
    return message;
  },
};

function createBaseGetSharedHistoryRes(): GetSharedHistoryRes {
  return { history: [] };
}

export const GetSharedHistoryRes = {
  encode(message: GetSharedHistoryRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.history) {
      History.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSharedHistoryRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSharedHistoryRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.history.push(History.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetSharedHistoryRes>, I>>(base?: I): GetSharedHistoryRes {
    return GetSharedHistoryRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSharedHistoryRes>, I>>(object: I): GetSharedHistoryRes {
    const message = createBaseGetSharedHistoryRes();
    message.history = object.history?.map((e) => History.fromPartial(e)) || [];
    return message;
  },
};

function createBaseGetSharedCollectiblesWithCreatorReq(): GetSharedCollectiblesWithCreatorReq {
  return { address: "" };
}

export const GetSharedCollectiblesWithCreatorReq = {
  encode(message: GetSharedCollectiblesWithCreatorReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.address !== "") {
      writer.uint32(10).string(message.address);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSharedCollectiblesWithCreatorReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSharedCollectiblesWithCreatorReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.address = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetSharedCollectiblesWithCreatorReq>, I>>(
    base?: I,
  ): GetSharedCollectiblesWithCreatorReq {
    return GetSharedCollectiblesWithCreatorReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSharedCollectiblesWithCreatorReq>, I>>(
    object: I,
  ): GetSharedCollectiblesWithCreatorReq {
    const message = createBaseGetSharedCollectiblesWithCreatorReq();
    message.address = object.address ?? "";
    return message;
  },
};

function createBaseGetSharedCollectiblesWithCreatorRes(): GetSharedCollectiblesWithCreatorRes {
  return { collectibles: [] };
}

export const GetSharedCollectiblesWithCreatorRes = {
  encode(message: GetSharedCollectiblesWithCreatorRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.collectibles) {
      Collectible.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetSharedCollectiblesWithCreatorRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetSharedCollectiblesWithCreatorRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.collectibles.push(Collectible.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetSharedCollectiblesWithCreatorRes>, I>>(
    base?: I,
  ): GetSharedCollectiblesWithCreatorRes {
    return GetSharedCollectiblesWithCreatorRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetSharedCollectiblesWithCreatorRes>, I>>(
    object: I,
  ): GetSharedCollectiblesWithCreatorRes {
    const message = createBaseGetSharedCollectiblesWithCreatorRes();
    message.collectibles = object.collectibles?.map((e) => Collectible.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreateCollectibleTransactionReq(): CreateCollectibleTransactionReq {
  return {
    name: "",
    description: "",
    collectibleURL: "",
    collectibleId: "",
    price: 0,
    quantity: 0,
    creator: "",
    contributors: [],
    network: "",
    collection: "",
  };
}

export const CreateCollectibleTransactionReq = {
  encode(message: CreateCollectibleTransactionReq, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.description !== "") {
      writer.uint32(18).string(message.description);
    }
    if (message.collectibleURL !== "") {
      writer.uint32(26).string(message.collectibleURL);
    }
    if (message.collectibleId !== "") {
      writer.uint32(34).string(message.collectibleId);
    }
    if (message.price !== 0) {
      writer.uint32(41).fixed64(message.price);
    }
    if (message.quantity !== 0) {
      writer.uint32(48).int64(message.quantity);
    }
    if (message.creator !== "") {
      writer.uint32(58).string(message.creator);
    }
    for (const v of message.contributors) {
      CreateCollectibleTransactionReq_Contributor.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.network !== "") {
      writer.uint32(74).string(message.network);
    }
    if (message.collection !== "") {
      writer.uint32(82).string(message.collection);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCollectibleTransactionReq {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCollectibleTransactionReq();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.description = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.collectibleURL = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.collectibleId = reader.string();
          continue;
        case 5:
          if (tag !== 41) {
            break;
          }

          message.price = longToNumber(reader.fixed64() as Long);
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.quantity = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.creator = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.contributors.push(CreateCollectibleTransactionReq_Contributor.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.network = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.collection = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateCollectibleTransactionReq>, I>>(base?: I): CreateCollectibleTransactionReq {
    return CreateCollectibleTransactionReq.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCollectibleTransactionReq>, I>>(
    object: I,
  ): CreateCollectibleTransactionReq {
    const message = createBaseCreateCollectibleTransactionReq();
    message.name = object.name ?? "";
    message.description = object.description ?? "";
    message.collectibleURL = object.collectibleURL ?? "";
    message.collectibleId = object.collectibleId ?? "";
    message.price = object.price ?? 0;
    message.quantity = object.quantity ?? 0;
    message.creator = object.creator ?? "";
    message.contributors =
      object.contributors?.map((e) => CreateCollectibleTransactionReq_Contributor.fromPartial(e)) || [];
    message.network = object.network ?? "";
    message.collection = object.collection ?? "";
    return message;
  },
};

function createBaseCreateCollectibleTransactionReq_Contributor(): CreateCollectibleTransactionReq_Contributor {
  return { userProfileId: "", revenueShare: "" };
}

export const CreateCollectibleTransactionReq_Contributor = {
  encode(message: CreateCollectibleTransactionReq_Contributor, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userProfileId !== "") {
      writer.uint32(10).string(message.userProfileId);
    }
    if (message.revenueShare !== "") {
      writer.uint32(18).string(message.revenueShare);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCollectibleTransactionReq_Contributor {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCollectibleTransactionReq_Contributor();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userProfileId = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.revenueShare = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateCollectibleTransactionReq_Contributor>, I>>(
    base?: I,
  ): CreateCollectibleTransactionReq_Contributor {
    return CreateCollectibleTransactionReq_Contributor.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCollectibleTransactionReq_Contributor>, I>>(
    object: I,
  ): CreateCollectibleTransactionReq_Contributor {
    const message = createBaseCreateCollectibleTransactionReq_Contributor();
    message.userProfileId = object.userProfileId ?? "";
    message.revenueShare = object.revenueShare ?? "";
    return message;
  },
};

function createBaseCreateCollectibleTransactionRes(): CreateCollectibleTransactionRes {
  return { transaction: undefined };
}

export const CreateCollectibleTransactionRes = {
  encode(message: CreateCollectibleTransactionRes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.transaction !== undefined) {
      MinimalTransactionInfo.encode(message.transaction, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateCollectibleTransactionRes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateCollectibleTransactionRes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.transaction = MinimalTransactionInfo.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateCollectibleTransactionRes>, I>>(base?: I): CreateCollectibleTransactionRes {
    return CreateCollectibleTransactionRes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateCollectibleTransactionRes>, I>>(
    object: I,
  ): CreateCollectibleTransactionRes {
    const message = createBaseCreateCollectibleTransactionRes();
    message.transaction = (object.transaction !== undefined && object.transaction !== null)
      ? MinimalTransactionInfo.fromPartial(object.transaction)
      : undefined;
    return message;
  },
};

export interface CollectibleService {
  GetCollectiblesByOwner(
    request: DeepPartial<GetCollectiblesByOwnerReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCollectiblesByOwnerRes>;
  /** @deprecated */
  GetCollectiblesByCreator(
    request: DeepPartial<GetCollectiblesByCreatorReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCollectiblesByCreatorRes>;
  GetCollectibles(request: DeepPartial<GetCollectiblesReq>, metadata?: grpc.Metadata): Promise<GetCollectiblesRes>;
  GetCollectiblesByContributor(
    request: DeepPartial<GetCollectiblesByContributorReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCollectiblesByContributorRes>;
  GetSharedCollectiblesWithCreator(
    request: DeepPartial<GetSharedCollectiblesWithCreatorReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetSharedCollectiblesWithCreatorRes>;
  GetCurrentCollectiblesByOwner(
    request: DeepPartial<GetCurrentCollectiblesByOwnerReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCurrentCollectiblesByOwnerRes>;
  GetCollectibleById(
    request: DeepPartial<GetCollectibleByIdReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCollectibleByIdRes>;
  MintTokenTransaction(
    request: DeepPartial<MintTokenTransactionReq>,
    metadata?: grpc.Metadata,
  ): Promise<MintTokenTransactionRes>;
  CreateCollectibleTransaction(
    request: DeepPartial<CreateCollectibleTransactionReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateCollectibleTransactionRes>;
  GetAssetHistory(request: DeepPartial<GetAssetHistoryReq>, metadata?: grpc.Metadata): Promise<GetAssetHistoryRes>;
  GetHistory(request: DeepPartial<GetHistoryReq>, metadata?: grpc.Metadata): Promise<GetHistoryRes>;
  GetSharedHistory(request: DeepPartial<GetSharedHistoryReq>, metadata?: grpc.Metadata): Promise<GetSharedHistoryRes>;
  /** @deprecated */
  GetGrossRevenuePoints(
    request: DeepPartial<GetGrossRevenuePointsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetGrossRevenuePointsRes>;
  DeleteCollectible(request: DeepPartial<DeleteCollectibleByIdReq>, metadata?: grpc.Metadata): Promise<Empty>;
}

export class CollectibleServiceClientImpl implements CollectibleService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetCollectiblesByOwner = this.GetCollectiblesByOwner.bind(this);
    this.GetCollectiblesByCreator = this.GetCollectiblesByCreator.bind(this);
    this.GetCollectibles = this.GetCollectibles.bind(this);
    this.GetCollectiblesByContributor = this.GetCollectiblesByContributor.bind(this);
    this.GetSharedCollectiblesWithCreator = this.GetSharedCollectiblesWithCreator.bind(this);
    this.GetCurrentCollectiblesByOwner = this.GetCurrentCollectiblesByOwner.bind(this);
    this.GetCollectibleById = this.GetCollectibleById.bind(this);
    this.MintTokenTransaction = this.MintTokenTransaction.bind(this);
    this.CreateCollectibleTransaction = this.CreateCollectibleTransaction.bind(this);
    this.GetAssetHistory = this.GetAssetHistory.bind(this);
    this.GetHistory = this.GetHistory.bind(this);
    this.GetSharedHistory = this.GetSharedHistory.bind(this);
    this.GetGrossRevenuePoints = this.GetGrossRevenuePoints.bind(this);
    this.DeleteCollectible = this.DeleteCollectible.bind(this);
  }

  GetCollectiblesByOwner(
    request: DeepPartial<GetCollectiblesByOwnerReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCollectiblesByOwnerRes> {
    return this.rpc.unary(
      CollectibleServiceGetCollectiblesByOwnerDesc,
      GetCollectiblesByOwnerReq.fromPartial(request),
      metadata,
    );
  }

  GetCollectiblesByCreator(
    request: DeepPartial<GetCollectiblesByCreatorReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCollectiblesByCreatorRes> {
    return this.rpc.unary(
      CollectibleServiceGetCollectiblesByCreatorDesc,
      GetCollectiblesByCreatorReq.fromPartial(request),
      metadata,
    );
  }

  GetCollectibles(request: DeepPartial<GetCollectiblesReq>, metadata?: grpc.Metadata): Promise<GetCollectiblesRes> {
    return this.rpc.unary(CollectibleServiceGetCollectiblesDesc, GetCollectiblesReq.fromPartial(request), metadata);
  }

  GetCollectiblesByContributor(
    request: DeepPartial<GetCollectiblesByContributorReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCollectiblesByContributorRes> {
    return this.rpc.unary(
      CollectibleServiceGetCollectiblesByContributorDesc,
      GetCollectiblesByContributorReq.fromPartial(request),
      metadata,
    );
  }

  GetSharedCollectiblesWithCreator(
    request: DeepPartial<GetSharedCollectiblesWithCreatorReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetSharedCollectiblesWithCreatorRes> {
    return this.rpc.unary(
      CollectibleServiceGetSharedCollectiblesWithCreatorDesc,
      GetSharedCollectiblesWithCreatorReq.fromPartial(request),
      metadata,
    );
  }

  GetCurrentCollectiblesByOwner(
    request: DeepPartial<GetCurrentCollectiblesByOwnerReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCurrentCollectiblesByOwnerRes> {
    return this.rpc.unary(
      CollectibleServiceGetCurrentCollectiblesByOwnerDesc,
      GetCurrentCollectiblesByOwnerReq.fromPartial(request),
      metadata,
    );
  }

  GetCollectibleById(
    request: DeepPartial<GetCollectibleByIdReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetCollectibleByIdRes> {
    return this.rpc.unary(
      CollectibleServiceGetCollectibleByIdDesc,
      GetCollectibleByIdReq.fromPartial(request),
      metadata,
    );
  }

  MintTokenTransaction(
    request: DeepPartial<MintTokenTransactionReq>,
    metadata?: grpc.Metadata,
  ): Promise<MintTokenTransactionRes> {
    return this.rpc.unary(
      CollectibleServiceMintTokenTransactionDesc,
      MintTokenTransactionReq.fromPartial(request),
      metadata,
    );
  }

  CreateCollectibleTransaction(
    request: DeepPartial<CreateCollectibleTransactionReq>,
    metadata?: grpc.Metadata,
  ): Promise<CreateCollectibleTransactionRes> {
    return this.rpc.unary(
      CollectibleServiceCreateCollectibleTransactionDesc,
      CreateCollectibleTransactionReq.fromPartial(request),
      metadata,
    );
  }

  GetAssetHistory(request: DeepPartial<GetAssetHistoryReq>, metadata?: grpc.Metadata): Promise<GetAssetHistoryRes> {
    return this.rpc.unary(CollectibleServiceGetAssetHistoryDesc, GetAssetHistoryReq.fromPartial(request), metadata);
  }

  GetHistory(request: DeepPartial<GetHistoryReq>, metadata?: grpc.Metadata): Promise<GetHistoryRes> {
    return this.rpc.unary(CollectibleServiceGetHistoryDesc, GetHistoryReq.fromPartial(request), metadata);
  }

  GetSharedHistory(request: DeepPartial<GetSharedHistoryReq>, metadata?: grpc.Metadata): Promise<GetSharedHistoryRes> {
    return this.rpc.unary(CollectibleServiceGetSharedHistoryDesc, GetSharedHistoryReq.fromPartial(request), metadata);
  }

  GetGrossRevenuePoints(
    request: DeepPartial<GetGrossRevenuePointsReq>,
    metadata?: grpc.Metadata,
  ): Promise<GetGrossRevenuePointsRes> {
    return this.rpc.unary(
      CollectibleServiceGetGrossRevenuePointsDesc,
      GetGrossRevenuePointsReq.fromPartial(request),
      metadata,
    );
  }

  DeleteCollectible(request: DeepPartial<DeleteCollectibleByIdReq>, metadata?: grpc.Metadata): Promise<Empty> {
    return this.rpc.unary(
      CollectibleServiceDeleteCollectibleDesc,
      DeleteCollectibleByIdReq.fromPartial(request),
      metadata,
    );
  }
}

export const CollectibleServiceDesc = { serviceName: "CollectibleService" };

export const CollectibleServiceGetCollectiblesByOwnerDesc: UnaryMethodDefinitionish = {
  methodName: "GetCollectiblesByOwner",
  service: CollectibleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCollectiblesByOwnerReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCollectiblesByOwnerRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectibleServiceGetCollectiblesByCreatorDesc: UnaryMethodDefinitionish = {
  methodName: "GetCollectiblesByCreator",
  service: CollectibleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCollectiblesByCreatorReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCollectiblesByCreatorRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectibleServiceGetCollectiblesDesc: UnaryMethodDefinitionish = {
  methodName: "GetCollectibles",
  service: CollectibleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCollectiblesReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCollectiblesRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectibleServiceGetCollectiblesByContributorDesc: UnaryMethodDefinitionish = {
  methodName: "GetCollectiblesByContributor",
  service: CollectibleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCollectiblesByContributorReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCollectiblesByContributorRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectibleServiceGetSharedCollectiblesWithCreatorDesc: UnaryMethodDefinitionish = {
  methodName: "GetSharedCollectiblesWithCreator",
  service: CollectibleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSharedCollectiblesWithCreatorReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetSharedCollectiblesWithCreatorRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectibleServiceGetCurrentCollectiblesByOwnerDesc: UnaryMethodDefinitionish = {
  methodName: "GetCurrentCollectiblesByOwner",
  service: CollectibleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCurrentCollectiblesByOwnerReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCurrentCollectiblesByOwnerRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectibleServiceGetCollectibleByIdDesc: UnaryMethodDefinitionish = {
  methodName: "GetCollectibleById",
  service: CollectibleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetCollectibleByIdReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetCollectibleByIdRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectibleServiceMintTokenTransactionDesc: UnaryMethodDefinitionish = {
  methodName: "MintTokenTransaction",
  service: CollectibleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return MintTokenTransactionReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = MintTokenTransactionRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectibleServiceCreateCollectibleTransactionDesc: UnaryMethodDefinitionish = {
  methodName: "CreateCollectibleTransaction",
  service: CollectibleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateCollectibleTransactionReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateCollectibleTransactionRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectibleServiceGetAssetHistoryDesc: UnaryMethodDefinitionish = {
  methodName: "GetAssetHistory",
  service: CollectibleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetAssetHistoryReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetAssetHistoryRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectibleServiceGetHistoryDesc: UnaryMethodDefinitionish = {
  methodName: "GetHistory",
  service: CollectibleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetHistoryReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetHistoryRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectibleServiceGetSharedHistoryDesc: UnaryMethodDefinitionish = {
  methodName: "GetSharedHistory",
  service: CollectibleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetSharedHistoryReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetSharedHistoryRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectibleServiceGetGrossRevenuePointsDesc: UnaryMethodDefinitionish = {
  methodName: "GetGrossRevenuePoints",
  service: CollectibleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetGrossRevenuePointsReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetGrossRevenuePointsRes.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const CollectibleServiceDeleteCollectibleDesc: UnaryMethodDefinitionish = {
  methodName: "DeleteCollectible",
  service: CollectibleServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return DeleteCollectibleByIdReq.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = Empty.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare const self: any | undefined;
declare const window: any | undefined;
declare const global: any | undefined;
const tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1000;
  const nanos = (date.getTime() % 1000) * 1000000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = (t.seconds || 0) * 1000;
  millis += (t.nanos || 0) / 1000000;
  return new Date(millis);
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
