//import { captureException } from '@sentry/react';
import clsx from 'clsx';
import { Html5Qrcode } from 'html5-qrcode';
import React, { useEffect, useReducer } from 'react';

import css from './index.module.css';

interface Props {
  onScan: (data: string) => void;
}

const idQRScanner = 'QR_scanner';

export const QRScanner = ({ onScan }: Props) => {
  const [isLoading, toggleIsLoading] = useReducer(isLoading => !isLoading, true);
  const [isError, toggleIsError] = useReducer(isError => !isError, false);

  const qrCodeErrorCallback = (error: string) => {
    //captureException(error);
  };

  useEffect(() => {
    const html5QrCode = new Html5Qrcode(idQRScanner);

    html5QrCode
      .start(
        { facingMode: 'environment' },
        {
          fps: 10,
        },
        onScan,
        qrCodeErrorCallback,
      )
      .then(() => {
        toggleIsLoading();
      })
      .catch(error => {
        toggleIsError();
        //captureException(error);
      });

    return () => {
      html5QrCode.stop().then().catch(/*captureException*/);
    };
  }, [onScan]);

  return (
    <div className={css.container}>
      {isLoading && <p>Camera is connecting...</p>}
      {isError && <p>Camera not found</p>}
      <div id={idQRScanner} className={clsx(css.QRCodeCamera, isLoading ? css.loading : '')} />
    </div>
  );
};
