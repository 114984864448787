import React from 'react';

import { Loader } from 'assets/icons/Loader';

import css from './index.module.css';

interface Props {
  size?: number;
}

const LoadingSpinner = ({ size = 50 }: Props) => <Loader size={size} className={css.spinner} />;

export default LoadingSpinner;
