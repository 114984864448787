export enum AlertType {
  AddressCopied = 'address_copied',
  Avatar = 'avatar',
  BrandAmountLess = 'brand_amount_less',
  CancelTrade = 'cancel_trade',
  Disconnect = 'disconnect',
  ImagePicker = 'image_picker',
  InvalidAmount = 'invalid_amount',
  InvalidMnemonic = 'invalid_mnemonic',
  Logout = 'logout',
  SelectBrand = 'select_brand',
  SendTrade = 'send_trade',
  // TODO: fix spelling BOTH here and in the Forge repo
  SendTradeSuccessful = 'send_trade_successfull',
  SendTradeUnsuccessful = 'send_trade_unsuccessfull',
  SendTransaction = 'send_transaction',
  SessionRequestAbort = 'session_request_abort',
  TransactionFailed = 'transaction_failed',
  TransactionPending = 'transaction_pending',
  TransactionSuccessful = 'transaction_successful',
  SwitchChain = 'switch_chain',
  SwitchPayMethod = 'switch_pay_method',
  Session = 'session',
  Pairing = 'pairing',
  PinLock = 'pin_lock',
  PinForMnemonic = 'unlock_mnemonics',
  ApprovePersonalSign = 'approve_personal_sign',
  ApproveEthSignTypedData = 'approve_eth_sign_typed_data',
  PrivacyPolicyUpdate = 'privacy_policy_update',
}

export enum WalletErrorMessage {
  LOGIN_ROLE_VALIDATION_FAILED = 'login_role_validation_failed',
  LOGIN_APPLICATION_VALIDATION_FAILED = 'login_application_validation_failed',
  ALREADY_CONNECTED = 'already_connected',
}

export enum Path {
  Start = '/start',
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  Activate = '/activate/:accountId',

  Nfts = '/nfts',
  BeasyNftDetails = '/nfts/:tokenId',
  NftDetails = '/nfts/:contractAddress/:tokenId',
  BeasyNftHistory = '/nfts/:tokenId/history',
  NftHistory = '/nfts/:contractAddress/:tokenId/history',

  Transfers = '/transfers',
  TransferNft = '/transfers/nft',
  TransferCrypto = '/transfers/crypto',
  TransferCryptoConfirmation = '/transfers/crypto/details',
  TransferCryptoConfirmationDesktop = '/transfers/crypto/details/:recipientAddress',
  TransferNftConfirmation = '/transfers/confirm/:contractAddress/:tokenId/:recipientAddress',

  TransferDesktop = '/transfersDesktop',
  TransferDesktopType = '/transfer',
  TransferDesktopDetails = '/transfer/details/:type',
  TransferDesktopRecipientCrypto = '/transfer/recipient',
  TransferDesktopRecipient = '/transfer/recipient/:contractAddress/:tokenId/:tokenType',
  TransferDesktopConfirmation = '/transfers/confirm/:contractAddress/:tokenId/:recipientAddress',

  TxDetails = '/tx/:contractAddress/:hash',

  Friends = '/friends',
  FriendDetails = '/friends/:friendAddress',
  AddFriend = '/add-friend/:friendAddress',

  Profile = '/profile',
  Account = '/account',
  AccountDesktop = 'account',
  Balance = '/balance',
  BalanceTopUp = '/balance/top-up',
  Notifications = '/notifications',
  CustomerSupport = '/support',
  About = '/about',

}

export enum AuthSteps {
  CreateWallet = 'createWallet',
  CreateWalletReview = 'createWalletReview',
  MnemonicReview = 'mnemonicReview',
  MnemonicConfirmation = 'mnemonicConfirmation',

  RestoreWallet = 'restoreWallet',

  CreatePin = 'createPin',
  ConfirmPin = 'confirmPin',
}
