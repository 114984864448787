import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'redux/configStore';
import { selectorAccountInfo } from 'redux/reducers/accountInfo';

import { Path } from 'constants/enumTypes';

import css from './index.module.css';

export const PublicPage = () => {
  const { isLoggedIn } = useAppSelector(selectorAccountInfo);

  if (isLoggedIn) {
    return <Navigate to={Path.Nfts} />;
  }

  return (
    <div className={css.container}>
      <Outlet />
    </div>
  );
};
