import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { updateFlags } from 'redux/reducers/flags';
import { transferNft } from 'redux/reducers/transfer';
import { txPriceSelector, txPriceInDefaultCurrencySelector, isBalanceSufficientSelector } from 'redux/reducers/tx';
import { roundPrice, roundEth } from 'utils';

import { EthereumIcon } from 'assets/icons/Network/EthereumIcon';
import { PolygonIcon } from 'assets/icons/Network/PolygonIcon';
import checkCircleSuccess from 'assets/images/checkCircleSuccess.png';
import checkCircleSuccess2x from 'assets/images/checkCircleSuccess@2x.png';
import checkCircleSuccess3x from 'assets/images/checkCircleSuccess@3x.png';
import exclamation from 'assets/images/exclamation.png';
import exclamation2x from 'assets/images/exclamation@2x.png';
import exclamation3x from 'assets/images/exclamation@3x.png';

import Button, { ButtonColorScheme } from 'components/Button';
import { LandingHeader } from 'components/Header/LandingHeader';
import { InputErrorMessage } from 'components/Messages/InputErrorMessage';
import { SmallNftCard } from 'components/SmallNftCard';
import { TransferProgressBar } from 'components/TransferProgressBar';
import { UserInfo } from 'components/UserInfo';
import { TextAreaInputWithCopy } from 'components/inputs/TextAreaInputWithCopy';

import { Path } from 'constants/enumTypes';

import { useAlchemyBalance } from 'hooks/useBalanceQuery';
import { useNetwork } from 'hooks/useNetwork';

//import { checkAvailability } from 'services/MetaTransaction';
import { DEFAULT_CURRENCY } from 'services/currency';
import { getUserProfile, UserProfileQueryKey, getRoleNameMap } from 'services/userProfile';

import { PaymentMethodList } from './PaymentMethodList';
import css from './index.module.css';

const TABS = ['1. NFT/Crypto', '2. Transaction details', '3. Choose Recipient', '4. Review'];

export const TransferNftConfirmationPage = () => {
  const [checkMetaTx, setCheckMetaTx] = useState(false);
  const [selectedTabIndex] = useState(3);
  const listFTsBalance = useAlchemyBalance();
  const txPrice = useAppSelector(txPriceSelector);
  const [data, setData] = useState<any>(1);
  const { currentNetwork } = useNetwork();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { contractAddress = '', tokenId = '', recipientAddress = '' } = useParams();

  let txPriceInDefaultCurrency: any = useAppSelector(txPriceInDefaultCurrencySelector);

  const isBalanceSufficient = useAppSelector(isBalanceSufficientSelector);
  const friendQuery = useQuery([UserProfileQueryKey.getUserProfile, recipientAddress], () =>
    getUserProfile(recipientAddress),
  );

  const friendRole = friendQuery.data?.roles?.find(r => r.network === JSON.stringify(currentNetwork?.id));
  const getRoleNameMapQuery = useQuery(UserProfileQueryKey.getRoleNameMap, () => getRoleNameMap());
  const getRoleName = useCallback(
    (role: number) => {
      return getRoleNameMapQuery?.data![role];
    },
    [getRoleNameMapQuery?.data],
  );

  let dataPassedFromChild = 1;
  const childToParent = (childdata: any) => {
    dataPassedFromChild = childdata;
    setData(dataPassedFromChild);
  };

  const handleNormalTrade = useCallback(async () => {
    await dispatch(transferNft());
    navigate(Path.Transfers);
  }, [dispatch, navigate]);

  const cancel = () => {
    navigate(generatePath(Path.Nfts));
  };
/*
  useEffect(() => {
    checkAvailability(contractAddress).then((isAvailable: boolean) => {
      setCheckMetaTx(false);
    });
  });
*/
  useEffect(() => {
    dispatch(updateFlags({ isLoading: true }));
  }, [dispatch]);

  useEffect(() => {
    if (!friendQuery.isLoading) {
      dispatch(updateFlags({ isLoading: false }));
    }
  }, [dispatch, friendQuery.isLoading]);

  const isTransferPossible = () => {
    if (!isBalanceSufficient) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className={css.container}>
        <div className={css.containerHeader}>
          <LandingHeader isGoBack={true} />
          <h1 className="pageTitle">Confirm the Transfer</h1>
        </div>

        <div className={css.containerContent}>
          <SmallNftCard contractAddress={contractAddress} tokenId={tokenId} />
          <h2 className={css.tradeWith}>Transfer to</h2>
          <UserInfo avatarCid={friendQuery.data?.avatarCid ?? ''} nickname={friendQuery.data?.nickname ?? ''} />
          <TextAreaInputWithCopy label="Address" value={recipientAddress} successMessage="Address copied" />
        </div>

        <div className={css.containerFooter}>
          {!isBalanceSufficient || !checkMetaTx ? (
            <>
              <div className={css.insufficientFundsMessage}>
                <InputErrorMessage message="You have insufficient funds in your wallet" />
              </div>

              <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={() => navigate(-1)}>
                Back
              </Button>
            </>
          ) : (
            <>
              {checkMetaTx ? (
                <div className={css.chainList}>
                  <div className={css.chainNameContainer}>
                    <PaymentMethodList childToParent={childToParent} isModify={true} />
                  </div>
                </div>
              ) : (
                <div className={css.balanceCoins}>
                  {currentNetwork?.coin?.code === 'MATIC' ? <PolygonIcon /> : <EthereumIcon />}
                  <p>{currentNetwork?.coin?.code}</p>
                </div>
              )}
              {data === 1 && (
                <>
                  {txPrice && txPriceInDefaultCurrency && (
                    <>
                      <div className={css.totalPrice}>
                        Requires {roundPrice(txPrice, 10e4)} coinName / {roundPrice(txPriceInDefaultCurrency, 10e4)}{' '}
                        {DEFAULT_CURRENCY}
                      </div>
                    </>
                  )}
                </>
              )}

              <div className={css.previousButton}>
                <Button disabled={isTransferPossible()} onClick={handleNormalTrade}>
                  Transfer
                </Button>
              </div>
            </>
          )}
        </div>
      </div>

      <div className={css.containerDesktop}>
        <TransferProgressBar className={css.tabs} tabs={TABS} selectedTabIndex={selectedTabIndex} />
        {txPrice && txPriceInDefaultCurrency && (
          <>
            <div className={css.containerContent}>
              <div className={css.reviewNFT}>
                <SmallNftCard contractAddress={contractAddress} tokenId={tokenId} />
              </div>
              <div className={css.spacer}></div>
              <div className={css.reviewUser}>
                <UserInfo
                  avatarCid={friendQuery.data?.avatarCid ?? ''}
                  nickname={friendQuery.data?.nickname ?? ''}
                  business={friendQuery.data?.additionalData?.businessName ?? ''}
                  userRole={friendRole ? getRoleName(friendRole.role) : '-'}
                />
                <TextAreaInputWithCopy label="Address" value={recipientAddress} successMessage="Address copied" />
              </div>
              <div className={css.spacer}></div>
              <div className={css.reviewPay}>
                <h1 className="pageTitle">Order Summary</h1>
                <p className={css.text}>In order to transfer your NFT, you will need to pay the following fees:</p>
                <div className={css.price}>
                  <p className={css.textFee}>Transaction fee</p>

                  <p className={css.textPrice}>{roundPrice(txPrice, 10e4)} MATIC</p>
                </div>

                <hr className={css.solid}></hr>
                <div className={css.price}>
                  <p className={css.textResult}>Total </p>

                  <p className={css.textPrice}>{roundPrice(txPrice, 10e4)} MATIC</p>
                </div>
                <p className={css.payment}>Payment method</p>

                {checkMetaTx ? (
                  <div className={css.chainList}>
                    <div className={css.chainNameContainer}>
                      <PaymentMethodList childToParent={childToParent} isModify={true} />
                    </div>
                  </div>
                ) : (
                  <div className={css.balanceCoins}>
                    {currentNetwork?.coin?.code === 'MATIC' ? <PolygonIcon /> : <EthereumIcon />}
                    <p>{currentNetwork?.coin?.code}</p>
                  </div>
                )}

                <hr className={css.solid}></hr>
                {data === 1 && (
                  <div className={css.balanceCoins}>
                    {listFTsBalance.isSuccess &&
                      listFTsBalance.data.map(({ tokenBalance, symbol, logoURI, address }) => (
                        <div key={address} className={css.balanceRow}>
                          <div>
                            <span className={css.balanceValue}>
                              Balance: {roundEth(tokenBalance)} {symbol}
                              {isBalanceSufficient ? (
                                <img
                                  className={css.balanceStatusIcon}
                                  src={checkCircleSuccess}
                                  alt=""
                                  srcSet={`${checkCircleSuccess} 1x, ${checkCircleSuccess2x} 2x, ${checkCircleSuccess3x} 3x`}
                                />
                              ) : (
                                <img
                                  className={css.balanceStatusIcon}
                                  src={exclamation}
                                  alt="Insufficient funds"
                                  srcSet={`${exclamation} 1x, ${exclamation2x} 2x, ${exclamation3x} 3x`}
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
            <div className={css.footerContainer}>
              <div className={css.cancelButton}>
                <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={cancel}>
                  Cancel
                </Button>
              </div>
              <div className={css.continueButton}>
                <div className={css.previousButton}>
                  <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={() => navigate(-1)}>
                    Previous Step
                  </Button>
                </div>

                <div className={css.previousButton}>
                  <Button disabled={isTransferPossible()} onClick={handleNormalTrade}>
                    Transfer NFT
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
