//import { captureException } from '@sentry/react';
import { TransferHistoryItemFactory } from 'factories/TransferHistoryItemFactory';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAppSelector } from 'redux/configStore';
import { sentTxsSelector } from 'redux/reducers/tx';
import { filterNonEmpty } from 'utils';

import { TransferHistoryService } from 'services/TransferHistoryService';

import { Transfer } from 'types';

import { useLoading } from './useLoading';

export const useSharedTransferHistoryQuery = (friendAddress: string) => {
  const sentTxs = useAppSelector(sentTxsSelector);

  const query = useQuery(['sharedTransferHistory', friendAddress], async () => {
    const remoteTransfers = await TransferHistoryService.getSharedTransfers(friendAddress);

    const localTransfers: Transfer[] = filterNonEmpty(
      Object.keys(sentTxs).map(uuid => TransferHistoryItemFactory.makeTransferFromSentTx(uuid, sentTxs[uuid])),
    ).filter(
      transfer =>
        transfer.to.toLowerCase() === friendAddress.toLowerCase() ||
        transfer.from.toLowerCase() === friendAddress.toLowerCase(),
    );

    return filterNonEmpty([...remoteTransfers, ...localTransfers]).sort((a, b) => b.timestamp - a.timestamp);
  });

  useEffect(() => {
    query.refetch().catch(error => {
      //captureException(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentTxs]);

  useLoading(query.isFetching);

  return query;
};
