import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorAlert, updateAlertModal } from 'redux/reducers/alert';
import { handleGenericTx } from 'redux/reducers/transfer';
import {
  clearCurrentTx,
  isBalanceSufficientSelector,
  setInsufficientBalance,
  setTx,
  txPriceInDefaultCurrencySelector,
  txPriceSelector,
} from 'redux/reducers/tx';
import { walletService } from 'services';
import { roundPrice } from 'utils';

import Button, { ButtonColorScheme } from 'components/Button';
import { Modal } from 'components/Modal';

import { useNetwork } from 'hooks/useNetwork';

import { TxGenerator } from 'services/TxGenerator';

import { DEFAULT_CURRENCY } from 'services/currency';

import css from './index.module.css';

const getTitleFromTx = (tx: any) => {
  const methodSignature = tx.data.slice(0, 10);
  switch (methodSignature) {
    case '0xa22cb465':
      // setApprovalForAll
      return 'Do you approve this transaction?';
    case '0x23b872dd':
      // transferFrom
      return 'Do you approve this transfer?';
    case '0xa9059cbb':
      // transfer
      return 'Do you approve this transfer?';
    case '0x07f4f602':
      return 'Do you approve the creation of this collection?';
    case '0x0863feb3':
      return 'Do you approve the creation of this NFT?';
    case '0x8832e6e3':
      return 'Do you approve the minting of this NFT?';
    default:
      return 'Do you approve this transaction?';
  }
};
export const SendTransaction = () => {
  const dispatch = useAppDispatch();
  const { sessionData } = useAppSelector(selectorAlert);
  const state = useAppSelector(state => state);

  const { currentCoinCode } = useNetwork();
  const txPrice = useAppSelector(txPriceSelector);
  useEffect(() => {
    TxGenerator.makeGenericTx(sessionData.params.request.params[0])
      .then((tx:any) => {
        dispatch(setTx(tx));
      })
      .catch((error: any) => {
        if (error.code === 'INSUFFICIENT_FUNDS') {
          dispatch(setInsufficientBalance());
        } else {
          throw error;
        }
      });
  }, [dispatch, sessionData]);
  

  let txPriceInDefaultCurrency: any = useAppSelector(txPriceInDefaultCurrencySelector);

  const isBalanceSufficient = useAppSelector(isBalanceSufficientSelector);
  const isPrice = txPrice && txPriceInDefaultCurrency;

  const onPressRejectEventHandle = useCallback(() => {
    dispatch(
      updateAlertModal({
        isVisibleAlert: false,
      }),
    );
    dispatch(clearCurrentTx());
   // walletConnectService.rejectRequest(sessionData, 'transaction_rejected_by_user');
  }, [dispatch, sessionData]);

  const onPressAcceptEventHandle = useCallback(() => {
    dispatch(handleGenericTx(sessionData.params.request.params[0], sessionData.id, sessionData.topic));
    dispatch(
      updateAlertModal({
        isVisibleAlert: false,
      }),
    );
  }, [dispatch, sessionData.params.request.params[0]]);

  const alertMessage =
   getTitleFromTx(sessionData.params.request.params[0]);
  return (
    <Modal>
      <div className={css.container}>
        <h2 className={css.titleText}>Transaction request</h2>
        <p className={css.messageText}>{alertMessage}</p>
        <p className={css.txPrice}>
          {isPrice ? (
            <>
              {isBalanceSufficient ? (
                <>
                  Requires {roundPrice(txPrice, 10e4)} {currentCoinCode ? currentCoinCode : 'MATIC'} /{' '}
                  {roundPrice(txPriceInDefaultCurrency, 10e4)} {DEFAULT_CURRENCY}
                </>
              ) : (
                'You have insufficient funds. Please top up your balance.'
              )}
            </>
          ) : (
            'Calculating transaction cost...'
          )}
        </p>
        <div className={css.transactButtons}>
          <Button
            className={css.spaceButton}
            colorScheme={ButtonColorScheme.SECONDARY}
            onClick={onPressRejectEventHandle}
          >
            Reject
          </Button>
          <Button
            className={css.spaceButton}
            onClick={onPressAcceptEventHandle}
            disabled={!isBalanceSufficient || !isPrice}
          >
            Approve
          </Button>
        </div>
      </div>
    </Modal>
  );
};
