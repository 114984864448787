//import { captureException } from '@sentry/react';
import * as ethers from 'ethers';
import { assert } from 'utils';

import { environment } from 'environment';

import { WalletService } from './WalletService';

// See:
// https://mumbai.polygonscan.com/address/0x195fe6ee6639665cceb15bcceb9980fc445dfa0b
const erc20ContractAbi = [
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      { internalType: 'address', name: 'recipient', type: 'address' },
      { internalType: 'uint256', name: 'amount', type: 'uint256' },
    ],
    name: 'transfer',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

const erc721ContractAbi = [
  {
    inputs: [
      { internalType: 'address', name: 'from', type: 'address' },
      { internalType: 'address', name: 'to', type: 'address' },
      { internalType: 'uint256', name: 'tokenId', type: 'uint256' },
    ],
    name: 'safeTransferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

const erc1155ContractAbi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_from',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_to',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_id',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256',
      },
      {
        internalType: 'bytes',
        name: '_data',
        type: 'bytes',
      },
    ],
    name: 'safeTransferFrom',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export class WethService {
  constructor(private walletService: WalletService) {}

  async getBalance(contractAddress: string): Promise<number> {
    try {
      const address = this.walletService.getAddress();

      const contract = this.getContract('erc20', contractAddress);
      const balance = await contract.balanceOf(address);

      return Number(ethers.utils.formatEther(balance));
    } catch (error) {
      //captureException(error);
      throw error;
    }
  }

  async makeTransferTx(address: string, amount: string): Promise<ethers.PopulatedTransaction> {
    try {
      const contract = this.getContract('erc20');
      return await contract.populateTransaction.transfer(address, amount);
    } catch (error) {
      //captureException(error);
      throw error;
    }
  }

  async makeNftTransferTx(
    from: string,
    to: string,
    tokenId: string,
    contractAddress: string,
    tokenType: string,
  ): Promise<ethers.PopulatedTransaction> {
    try {
      const contract = this.getContract(tokenType, contractAddress);

      if (tokenType === 'erc1155') {
        return await contract.populateTransaction.safeTransferFrom(from, to, tokenId, 1, []);
      }
      return await contract.populateTransaction.safeTransferFrom(from, to, tokenId);
    } catch (error) {
      //captureException(error);
      throw error;
    }
  }

  private getContract(type: string, contractAddress = environment.contracts.weth.address): ethers.Contract {
    const wallet = this.walletService.getWallet();
    assert(wallet, 'Wallet is not initialized');
    switch (type) {
      case 'erc20':
        return new ethers.Contract(contractAddress, erc20ContractAbi, wallet);
      case 'erc721':
        return new ethers.Contract(contractAddress, erc721ContractAbi, wallet);
      case 'erc1155':
        return new ethers.Contract(contractAddress, erc1155ContractAbi, wallet);
      default:
        return new ethers.Contract(contractAddress, erc20ContractAbi, wallet);
    }
  }
}
