import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorAccountInfo } from 'redux/reducers/accountInfo';
import { updateAlertModal } from 'redux/reducers/alert';
import { updateFlags } from 'redux/reducers/flags';
import { selectorIsSessionConnected } from 'redux/reducers/flags';
import { shortenAddress } from 'utils';

import beasyLightText from 'assets/images/beasy-light-text.png';
import beasyLightText2x from 'assets/images/beasy-light-text@2x.png';
import beasyLightText3x from 'assets/images/beasy-light-text@3x.png';

import Button, { ButtonColorScheme } from 'components/Button';
import { ClipboardCopy } from 'components/clipboard';

import { AlertType } from 'constants/enumTypes';

import { ChainInfoWallet } from './ChainInfoWallet';
import css from './index.module.css';

export const WalletHeader = () => {
  const isSessionConnected = useAppSelector(selectorIsSessionConnected);

  const dispatch = useAppDispatch();
  const { address } = useAppSelector(selectorAccountInfo);

  const handleClick = useCallback(() => dispatch(updateFlags({ isVisibleScanner: true })), [dispatch]);
  const handlePairingClick = useCallback(() => {
    dispatch(updateAlertModal({ isVisibleAlert: true, alertType: AlertType.Pairing }));
  }, [dispatch]);
  const handleSessionClick = useCallback(() => {
    dispatch(updateAlertModal({ isVisibleAlert: true, alertType: AlertType.Session }));
  }, [dispatch]);
  const handleChainSwitch = useCallback(() => {
    dispatch(updateAlertModal({ isVisibleAlert: true, alertType: AlertType.SwitchChain }));
  }, [dispatch]);

  const handleDisconnect = useCallback(() => {
    dispatch(
      updateAlertModal({
        isVisibleAlert: true,
        alertType: AlertType.Disconnect,
      }),
    );
  }, [dispatch]);

  return (
    <div className={css.header}>
      <img
        src={beasyLightText}
        alt="beasyLogo"
        srcSet={`${beasyLightText} 1x, ${beasyLightText2x} 2x, ${beasyLightText3x} 3x`}
      />

      <div className={css.userInfo}>
        <div className={css.button}>
          {isSessionConnected ? (
            <Button colorScheme={ButtonColorScheme.ERROR} onClick={handleDisconnect}>
              Disconnect from Website
            </Button>
          ) : (
            <Button colorScheme={ButtonColorScheme.PRIMARY} onClick={handleClick}>
              Connect to Website
            </Button>
          )}
        </div>
        <div className={css.button}>
          <Button colorScheme={ButtonColorScheme.PRIMARY} onClick={handlePairingClick}>
            Pairings
          </Button>
        </div>
        <div className={css.button}>
          <Button colorScheme={ButtonColorScheme.PRIMARY} onClick={handleSessionClick}>
            Active Sessions
          </Button>
        </div>
        <div className={css.info}>
          <div>
            <span>Address:</span>
            <span>
              <ClipboardCopy text={address}>{shortenAddress(address)}</ClipboardCopy>
            </span>
          </div>
          <div>
            <span>Active network:</span>
            <div className={css.chainInfo}>
              <ChainInfoWallet onChainSwitch={handleChainSwitch} isModify={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
