import { useQuery, UseQueryResult } from 'react-query';

import { NftMetadataService } from 'services/NftMetadataService';

import { NftMetadata } from 'types';

export const useNftMetadataQuery = (uri: string): UseQueryResult<NftMetadata | null> => {
  return useQuery(['useNftMetadataQuery', uri], () => NftMetadataService.getNftMetadata(uri), {
    cacheTime: 3600e3 /* 1 hour */,
  });
};
