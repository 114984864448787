import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { providerService } from 'services';

import { Network } from 'protobuf/lib/network';

import { getNetworks } from 'services/apiServices/network';

export const useNetwork = () => {
  const getNetworksQuery = useQuery('getNetworks', () => getNetworks());
  const allNetworks = useMemo(() => getNetworksQuery?.data?.networks as Network[], [getNetworksQuery?.data?.networks]);
  const currentNetwork = allNetworks?.find(({ id }) => id === `${providerService.getChainId()}`);
  const currentCoinCode = currentNetwork?.coin?.code!;
  const currentNetworkName = currentNetwork?.name!;
  return useMemo(
    () => ({
      allNetworks,
      currentNetwork,
      currentCoinCode,
      currentNetworkName,
    }),
    [allNetworks, currentNetwork, currentCoinCode, currentNetworkName],
  );
};
