import { getSdkError } from '@walletconnect/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateAlertModal } from 'redux/reducers/alert';

import Button, { ButtonColorScheme } from 'components/Button';
import { Modal } from 'components/Modal';

import { web3wallet } from 'services/WalletConnectV2';

import SessionItem from './SessionItem';
import css from './index.module.css';

export const Sessions = () => {
  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    dispatch(updateAlertModal({ isVisibleAlert: false }));
  }, [dispatch]);
  const [sessions, setSessions] = useState(Object.values(web3wallet.getActiveSessions()));
  console.log("ACTIVE SESSIONS: ",sessions)
  return (
    <Modal>
      <div className={css.container}>
        <div className={css.title}>Active Sessions</div>
        <div className={css.list}>
          {sessions.length ? (
            sessions.map(session => {
              const { name, icons, url } = session.peer.metadata;

              return <SessionItem key={session.topic} topic={session.topic} name={name} logo={icons[0]} url={url} />;
            })
          ) : (
            <div className={css.title}>No Sessions</div>
          )}
        </div>
      </div>
      <Button color={ButtonColorScheme.SECONDARY} onClick={handleClose}>
        OK
      </Button>
    </Modal>
  );
};
