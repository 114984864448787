import React from 'react';

import { Rarity } from 'components/Rarity';
import { LoadingImage } from 'components/loading/Image';

import css from './NftCardContent.module.css';

interface Props {
  // Optional:
  imageSrc?: string;
  title?: string;
  description?: string;
  quantity?: number;
}

export const NftCardContent = ({ imageSrc, title, description, quantity }: Props) => {
  return (
    <div className={css.container}>
      <div className={css.thumbnail}>
        <LoadingImage src={imageSrc} className={css.image} />
      </div>
      <div className={css.info}>
        <h3 className={css.name} title={title}>
          {title || 'N/A'}
        </h3>

        <p className={css.description}>{description || 'N/A'}</p>

        {quantity && <Rarity quantity={quantity} />}
      </div>
    </div>
  );
};
