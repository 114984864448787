import React from 'react';
import { NavLink } from 'react-router-dom';

import { Path } from 'constants/enumTypes';

import css from './index.module.css';

const getNavLinkClassName = ({ isActive }: { isActive: boolean }) => (isActive ? css.active : '');

export const TransferTypeSwitch = () => {
  return (
    <ul className={css.container}>
      <li>
        <NavLink to={Path.TransferNft} className={getNavLinkClassName}>
          NFT
        </NavLink>
      </li>
      <li>
        <NavLink to={Path.TransferCrypto} className={getNavLinkClassName}>
          Crypto
        </NavLink>
      </li>
    </ul>
  );
};
