import css from './index.module.css';

interface Props {
  name: string;
  type: string;
}

export const CollectibleHeader = ({ name, type }: Props) => {
  const title =
    'From the NFT details page, you can view the media fields of your NFT. You will be able to transfer the NFT by pressing the "Transfer NFT" button. You will be able to verify the authenticity of your NFT by pressing the "Share QR Code" button. If a user scans the QR code that displays, they will be able to verify the ownership of your NFT.';
  return (
    <section className={css.container}>
      <h1 className={css.title} title={title}>
        {name}
      </h1>
      <p className={css.subTitle}>{type}</p>
    </section>
  );
};
