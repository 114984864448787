import React from 'react';

import Button, { ButtonColorScheme } from 'components/Button';
import { InputErrorMessage } from 'components/Messages/InputErrorMessage';
import { Modal } from 'components/Modal';

import { useLogout } from 'hooks/useLogout';

import css from './index.module.css';

interface Props {
  onCancel: () => void;
}

export const LogoutConfirmationDialog = ({ onCancel }: Props) => {
  const logout = useLogout();
  return (
    <Modal>
      <div className={css.wrapper}>
        <InputErrorMessage
          message="If you log out from your BEASY Wallet, all of your data will be lost. In order to recover this account and
          your data, please save your mnemonic phase."
        />
        <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={onCancel}>
          No, stay
        </Button>
        <Button onClick={logout}>OK</Button>
      </div>
    </Modal>
  );
};
