//import { captureException } from '@sentry/react';
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'redux/configStore';
import { selectorAccountInfo } from 'redux/reducers/accountInfo';
import { walletConnectSessionPersistenceService } from 'services';

import { LandingFooter } from 'components/Footer/LandingFooter';

import { Path } from 'constants/enumTypes';

import css from './index.module.css';

export const PrivatePage = ({ isFooterVisible = true }: { isFooterVisible?: boolean }) => {
  const { isLoggedIn } = useAppSelector(selectorAccountInfo);

  useEffect(() => {
    const initWallet = async () => {
      const walletSession = await walletConnectSessionPersistenceService.getWalletSession();
     /* if (walletSession) {
        await walletConnectService.initBySession(walletSession);
      }*/
    };

    initWallet().catch(/*captureException*/);
  }, []);

  if (!isLoggedIn) {
    return <Navigate to={Path.Start} />;
  }

  return (
    <div className={css.container}>
      <Outlet />
      {isFooterVisible && <LandingFooter />}
    </div>
  );
};
