import React from 'react';
import { render } from 'react-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { store } from 'redux/configStore';

import { AppModals } from 'components/AppModals';
import { Scanner } from 'components/Scanner';

import { App } from './App';
import './index.css';
import { queryClient } from './queryClient';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
const Buffer = require('buffer').Buffer;
global.Buffer = Buffer;

render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <SkeletonTheme baseColor="#4b5362" highlightColor="#9ea6b3" borderRadius="4px" duration={2}>
          <BrowserRouter>
            <App />
            <AppModals />
            <Scanner />
          </BrowserRouter>

          <ToastContainer
            autoClose={3000}
            limit={2}
            position="bottom-center"
            theme="dark"
            hideProgressBar={true}
            toastClassName="toastContainer"
          />
        </SkeletonTheme>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
