import React, { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'redux/configStore';
import { selectorAccountInfo } from 'redux/reducers/accountInfo';

import Button, { ButtonColorScheme } from 'components/Button';
import { LandingHeader } from 'components/Header/LandingHeader';
import { UserInfo } from 'components/UserInfo';
import { TextAreaInputWithCopy } from 'components/inputs/TextAreaInputWithCopy';

import { Path } from 'constants/enumTypes';

import { getCurrentUserProfile } from 'services/userProfile';

import css from './index.module.css';

export const BalanceTopUpPage = () => {
  const navigate = useNavigate();

  const { address } = useAppSelector(selectorAccountInfo);

  const getCurrentUserQuery = useQuery('getCurrentUserProfile', getCurrentUserProfile);

  const handleOkClick = useCallback(() => navigate(Path.Balance), [navigate]);

  return (
    <div className={css.container}>
      <LandingHeader isGoBack={true} />
      <h1 className="pageTitle">Top up the Balance</h1>

      <div className={css.contents}>
        <p className={css.subtitle}>Transfer cryptocurrencies to your wallet address</p>

        <p className={css.description}>
          Use an exchange platform to purchase crypto currencies and send them to your BEASY Wallet
        </p>

        <div className={css.userInfo}>
          {getCurrentUserQuery.isSuccess && (
            <UserInfo
              avatarCid={getCurrentUserQuery.data.avatarCid}
              nickname={getCurrentUserQuery.data.nickname}
              isEditable={true}
            />
          )}
        </div>

        <TextAreaInputWithCopy label="Address" value={address} successMessage="Address copied" />
      </div>

      <footer className={css.footer}>
        <Button colorScheme={ButtonColorScheme.SECONDARY} className={css.okButton} onClick={handleOkClick}>
          OK
        </Button>
      </footer>
    </div>
  );
};
