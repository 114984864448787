import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { mnemonicSelector } from 'redux/reducers/onboarding';
import { WalletFactory } from 'services';

import Button from 'components/Button';
import { OnboardingHeader } from 'components/Header/OnboardingHeader';
import { InputErrorMessage } from 'components/Messages/InputErrorMessage';
import { WebVersion } from 'components/NFTLogo';

import { getUserProfile } from 'services/userProfile';

import { Mnemonic } from './Mnemonic';
import { WordList } from './WordList';
import css from './index.module.css';
//import { captureException, Severity } from '@sentry/react';
import { isGrpcError } from 'services/grpc';
import { grpc } from '@improbable-eng/grpc-web';

interface Props {
  onNextStepAuth: () => void;
  onPrevStepAuth: () => void;
  onCreateAccount: () => void;
}

export const MnemonicConfirmation = ({ onNextStepAuth, onPrevStepAuth, onCreateAccount }: Props) => {
  const mnemonic = useSelector(mnemonicSelector);

  const [currentMnemonic, setCurrentMnemonic] = useState<string[]>([]);
  const [errorMnemonicValid, setErrorMnemonicValid] = useState(false);
  const isSubmitOff = errorMnemonicValid || currentMnemonic.length !== mnemonic.split(' ').length;

  const handleClear = useCallback(() => {
    setCurrentMnemonic([]);
  }, []);

  const handleRemove = useCallback((word: string) => {
    setCurrentMnemonic(value => value.filter(w => w !== word));
  }, []);

  const handleSelectWord = useCallback(
    (word: string) => {
      setCurrentMnemonic(value => [...value, word]);
    },
    [setCurrentMnemonic],
  );

  const submit = async () => {
    if (currentMnemonic.join(' ') === mnemonic) {
      const wallet = WalletFactory.fromMnemonic(mnemonic);

      try {
        const result = await getUserProfile(wallet.address);
        if (result !== undefined) {
          onNextStepAuth();
        }
      } catch (error) {
        //captureException(error, { level: Severity.Debug, extra: { mnemonic } });
        if (isGrpcError(error) && error.code === grpc.Code.NotFound) {
          onCreateAccount();
        }
      }
    } else {
      setErrorMnemonicValid(true);
    }
  };

  useEffect(() => {
    setErrorMnemonicValid(false);
  }, [currentMnemonic]);

  return (
    <section className={css.container}>
      <div className={css.container__info}>
        <OnboardingHeader goBack={onPrevStepAuth} />
        <article>
          <p className={css.createLabel}>Verify mnemonic phrase</p>
          <p>Tap the words to put them next to each other in the correct order</p>
        </article>
        <article>
          <label htmlFor="mnemonic">Mnemonic Phrase</label>
          <Mnemonic value={currentMnemonic} onClear={handleClear} onRemove={handleRemove} />
          <div className={css.errorField}>
            {errorMnemonicValid && <InputErrorMessage message="Incorrect mnemonic phrase. Please, try again." />}
          </div>
        </article>
        <WordList mnemonic={mnemonic} onSelectWord={handleSelectWord} currentMnemonic={currentMnemonic} />
        <Button disabled={isSubmitOff} onClick={submit}>
          Continue
        </Button>
      </div>
      <WebVersion />
    </section>
  );
};
