import { IpfsFile } from 'beasy-fe-commons';
import React from 'react';
//import ReactElasticCarousel from 'react-elastic-carousel';

import { GalleryItem } from '../GalleryItem';
import css from './index.module.css';

const ReactElasticCarousel: any = require('react-elastic-carousel').default;

interface Props {
  onOpenFullScreen: (uri: string) => void;
  media: IpfsFile[];
}

export const CarouselCollectible: React.FC<Props> = ({ onOpenFullScreen, media }) => {
  return (
    <div className={css.containerCarousel}>
      <ReactElasticCarousel className={css.carousel} isRTL={false} showArrows={false}>
        {media.map(mediaItem => (
          <GalleryItem key={mediaItem.cid} ipfsFile={mediaItem} onClick={onOpenFullScreen} />
        ))}
      </ReactElasticCarousel>
    </div>
  );
};
