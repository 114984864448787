import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorIsLoading, updateFlags } from 'redux/reducers/flags';
import { roundEth } from 'utils';

import ethLogo from 'assets/svg/eth-logo.svg';

import { useAlchemyBalance, useBalanceQuery } from 'hooks/useBalanceQuery';
import { useNetwork } from 'hooks/useNetwork';

import css from './index.module.css';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import { Path } from 'constants/enumTypes';

export const Payments = () => {
  const isLoading = useAppSelector(selectorIsLoading);
  const dispatch = useAppDispatch();
  const listFTsBalance = useAlchemyBalance();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading && listFTsBalance.isLoading) {
      dispatch(updateFlags({ isLoading: true }));
    }
    if (isLoading && !listFTsBalance.isLoading) {
      dispatch(updateFlags({ isLoading: false }));
    }
  }, [isLoading, listFTsBalance.isLoading, dispatch]);

  const { currentCoinCode } = useNetwork();
  const balanceQuery = useBalanceQuery();
  const handleTopUpClick = useCallback(() => navigate(Path.BalanceTopUp), [navigate]);
  return (
    <div className={css.wrapper}>
      <div className={css.title}>
        <h3>Your crypto balance:</h3>
      </div>

      {listFTsBalance.isSuccess && listFTsBalance.data.length
        ? listFTsBalance.data.map(({ tokenBalance, symbol, logoURI, address }) => (
            <div key={address} className={css.balanceRow}>
              <img className={css.logo} src={logoURI} alt="logo" />
              <h1 className={css.balanceValue}>
                {roundEth(tokenBalance)} {symbol}
              </h1>
            </div>
          ))
        : balanceQuery.isSuccess &&
          !balanceQuery.isStale && (
            <div className={css.balanceRow}>
              <div className={css.balance}>
                <div className={css.balanceValue}>
                  <img className={css.logo} src={ethLogo} alt="logo" />
                  {balanceQuery.data.toFixed(6)} {currentCoinCode}
                </div>
              </div>
            </div>
          )}
         
    </div>
  );
};
