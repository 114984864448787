import React, { useMemo } from 'react';

import s from './index.module.css';

interface Props {
  progress: number;
}

const ProgressBar = ({ progress }: Props) => {
  const style = useMemo(() => ({ width: `${100 * progress}%` }), [progress]);

  return (
    <div className={s.container}>
      <div className={s.bar} style={style}>
        <div className={s.barInner} />
      </div>
    </div>
  );
};

export default ProgressBar;
