import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthSteps, Path } from 'constants/enumTypes';

import { ConfirmPin } from '../ConfirmPin';
import { CreatePin } from '../CreatePin';
import { CreateWallet } from '../CreateWallet';
import { RestoreWallet } from '../RestoreWallet';

export const SignInPage = () => {
  const navigate = useNavigate();
  const [stepSignUp, setStepSignUp] = useState(AuthSteps.RestoreWallet);

  switch (stepSignUp) {
    case AuthSteps.CreatePin:
      return (
        <CreatePin
          onNextStepAuth={() => setStepSignUp(AuthSteps.ConfirmPin)}
          onPrevStepAuth={() => setStepSignUp(AuthSteps.RestoreWallet)}
        />
      );
    case AuthSteps.ConfirmPin:
      return (
        <ConfirmPin
          onNextStepAuth={() => navigate(Path.Nfts)}
          onPrevStepAuth={() => setStepSignUp(AuthSteps.CreatePin)}
        />
      );
    case AuthSteps.CreateWallet:
      return (
        <CreateWallet
          onNextStepAuth={() => setStepSignUp(AuthSteps.CreatePin)}
          onPrevStepAuth={() => setStepSignUp(AuthSteps.RestoreWallet)}
          externalAccount={true}
        />
      );

    default:
      return (
        <RestoreWallet
          onNextStepAuth={() => setStepSignUp(AuthSteps.CreatePin)}
          onPrevStepAuth={() => navigate(-1)}
          onCreateAccount={() => setStepSignUp(AuthSteps.CreateWallet)}
        />
      );
  }
};
