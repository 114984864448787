//import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { walletService } from 'services';
import { copyToClipboard } from 'utils';

import { showGenericErrorToast } from 'components/AppToasts';

import { createFriendSender } from 'services/apiServices/FriendService';
import { getCurrentUserProfile } from 'services/userProfile';

export const useFriendLink = () => {
  const [friendLink, setFriendLink] = useState('');

  // Loading user profile and creating friend sender in advance so that `copyToClipboard` would be synchronous.
  // Otherwise, it doesn't work in some mobile browsers.
  // It should be OK to call `createFriendSender` because it creates a record in the DB only once, on first call.
  useEffect(() => {
    const getFriendLink = async () => {
      const userProfile = await getCurrentUserProfile();
      if (userProfile.nickname) {
        const code = await createFriendSender(walletService.getAddress(), userProfile.nickname);
        const url = `${window.location.host}/add-friend/${code}`;
        setFriendLink(url);
      }
    };

    getFriendLink().catch(/*captureException*/);
  }, [setFriendLink]);

  return useCallback(() => {
    if (friendLink) {
      try {
        copyToClipboard({ data: friendLink, successMessage: 'Link copied' });
      } catch (error) {
        showGenericErrorToast('An error has occurred. Please try again later.');
      }
    }
  }, [friendLink]);
};
