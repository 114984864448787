import { CryptoTransferTxContext, GenericTxContext, NftTransferTxContext, TxContextType } from 'types';

export class TxContextFactory {
  static makeGenericTxContext(chainId: number): GenericTxContext {
    return {
      type: TxContextType.NftMinting,
      chainId,
    };
  }

  static makeNftTransferTxContext(
    tokenId: string,
    contractAddress: string,
    from: string,
    to: string,
    chainId: number,
  ): NftTransferTxContext {
    return {
      type: TxContextType.NftTransfer,
      from,
      to,
      tokenId,
      contractAddress,
      chainId,
    };
  }

  static makeCryptoTransferTxContext(
    amount: number,
    from: string,
    to: string,
    chainId: number,
    contractAddress: string,
    coinName: string,
  ): CryptoTransferTxContext {
    return {
      type: TxContextType.CryptoTransfer,
      from,
      to,
      amount,
      chainId,
      contractAddress,
      coinName,
    };
  }
}
