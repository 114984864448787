import React from 'react';

import errorImage from 'assets/svg/error_loading.svg';

import Button from 'components/Button';

import css from './index.module.css';

interface Props {
  message?: string;
  isVisibleImage?: boolean;
  onRetry?: () => void;
}
/**
 * Displays a warning about an error loading data.
 */
export const ErrorMessageQueryLoading = ({
  message = 'Something went wrong\nPlease try again',
  isVisibleImage = true,
  onRetry = () => {},
}: Props) => {
  return (
    <div className={css.container}>
      {isVisibleImage && <img className={css.image} src={errorImage} alt="Download error" />}
      <p className={css.message}>{message}</p>
      <Button className={css.buttonRetry} onClick={onRetry}>
        Retry
      </Button>
    </div>
  );
};
