import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { mnemonicSelector, setMnemonic } from 'redux/reducers/onboarding';

import lock from 'assets/images/lock.png';
import lock2x from 'assets/images/lock@2x.png';
import lock3x from 'assets/images/lock@3x.png';

import Button from 'components/Button';
import { Checkbox } from 'components/Checkbox';
import { OnboardingHeader } from 'components/Header/OnboardingHeader';
import { MessageWithIcon } from 'components/Messages/MessageWithIcon';
import { WebVersion } from 'components/NFTLogo';

import { getAccount, deleteAccount } from 'services/apiServices/accountService';

import { environment } from 'environment';

import css from './index.module.css';

interface Props {
  onNextStepAuth: () => void;
  onPrevStepAuth: () => void;
  isActivate?: boolean;
}

export const CreateWalletReview = ({ onPrevStepAuth, onNextStepAuth, isActivate }: Props) => {
  const [isChecked, setIsChecked] = useState(false);
  const dispatch = useDispatch();
  const { accountId = '' } = useParams();
  //Is used to check if there is a previous mnemonic, due the user has returned to this screen
  const mnemonic = useSelector(mnemonicSelector);
  const handleContinue = useCallback(async () => {
    try {
      //Get account
      const result = await getAccount(accountId);
      dispatch(setMnemonic(result.mnemonic));
      await deleteAccount(accountId);
    } catch (error) {
      console.log('Error: ', error);
      return;
    }
    onNextStepAuth();
  }, [accountId, dispatch, onNextStepAuth]);

  const handleAgreeCheckboxPress = useCallback(() => {
    setIsChecked(isChecked => !isChecked);
  }, [setIsChecked]);

  return (
    <section className={css.container}>
      <div className={css.container__info}>
        <OnboardingHeader goBack={onPrevStepAuth} />
        <article>
          <p className={css.createLabel}>{isActivate ? 'Create Your Account' : 'Create account'}</p>
          <p>
            On the next screen you will see 12 words. This is your Mnemonic Phrase, it's used to login to your account.
          </p>
        </article>
        <div className={css.centerInfo}>
          <article className={css.warning}>
            <MessageWithIcon
              message="Write down or copy these words in the right order and save them somewhere safe. If you lose
          it - you’ll lose access to&nbsp;your&nbsp;wallet."
            />
            <MessageWithIcon message="Never share this secret phrase with anyone." />
          </article>
          <img className={css.lock} src={lock} alt="lock" srcSet={`${lock} 1x, ${lock2x} 2x, ${lock3x} 3x`} />
        </div>
        <article className={css.agreement}>
          <Checkbox isChecked={isChecked} onClick={handleAgreeCheckboxPress} />
          {isActivate ? (
            <p>
              I agree to{' '}
              <a
                className={css.agreementLink}
                href={environment.externalLinks.termsOfService}
                target="_blank"
                rel="noreferrer"
              >
                Terms of Services
              </a>
              {' and '}
              <a
                className={css.agreementLink}
                href={environment.externalLinks.privacyPolicy}
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </p>
          ) : (
            <p>I understand that if I lose my mnemonic phrase, I will not be able to access my account.</p>
          )}
        </article>
        <Button disabled={!isChecked} onClick={isActivate && mnemonic === '' ? handleContinue : onNextStepAuth}>
          Continue
        </Button>
      </div>
      <WebVersion />
    </section>
  );
};
