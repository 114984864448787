import { environment } from 'environment';
import { GetNFTsRes_OwnedNft_TokenUri } from 'protobuf/lib/alchemy';

export const getCollectibleIdFromTokenId = (tokenId: string): string => {
  const l = 8;
  return tokenId.slice(0, -l) + '0'.repeat(l);
};

export const getUriIpfs = (uri = ''): string => {
  if (uri.startsWith('ipfs://')) {
    return uri.replace(/^(ipfs:\/\/)/, environment.ipfsGatewayUrl);
  }

  return uri;
};

export const getAlchemyUri = (tokenUri: GetNFTsRes_OwnedNft_TokenUri): string => {
  if (tokenUri.raw.startsWith(environment.ipfsGatewayUrl)) {
    return tokenUri.raw;
  }
  if (tokenUri.raw.startsWith(environment.ipfsApiUrl)) {
    return tokenUri.raw;
  }

  // Hack for OpenSea bug
  // See https://easybeasy.atlassian.net/browse/AT-979
  return tokenUri.gateway.replaceAll('/0x0x', '/0x');
};

export const isBeasyNFT = (uri: string): boolean => uri.startsWith(environment.ipfsGatewayUrl) || uri.startsWith(environment.ipfsApiUrl);
