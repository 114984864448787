import { FriendInfo } from 'pages/friends/FriendPage/FriendInfo';
import { filterList } from 'pages/friends/FriendPage/utils';
import { RecipientTypeSelector } from 'pages/transfers/RecipientTypeSelector';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/configStore';
import { selectorAccountInfo } from 'redux/reducers/accountInfo';
import { selectorIsLoading, updateFlags } from 'redux/reducers/flags';
import {
  transferSelector,
  setRecipientType,
  setRecipientAddress,
  setFriend,
  populateNftTransferTx,
  setTokenIdAndCollection,
  populateTx,
  setAmount,
} from 'redux/reducers/transfer';
import { isAddressValid } from 'utils';

import Button, { ButtonColorScheme } from 'components/Button';
import { EmptyListMessageText } from 'components/Messages/EmptyListMessage';
import { ErrorMessageQueryLoading } from 'components/Messages/ErrorMessageQueryLoading';
import { InputErrorMessage } from 'components/Messages/InputErrorMessage';
import { TransferProgressBar } from 'components/TransferProgressBar';
import { Search } from 'components/inputs/Search';
import { TextAreaInputWithPaste } from 'components/inputs/TextAreaInputWithPaste';

import { Path } from 'constants/enumTypes';

import { useFriendLink } from 'hooks/useFriendLink';

import { GetNFTsRes_OwnedNft } from 'protobuf/lib/alchemy';
import { Friend } from 'protobuf/lib/friend';

import { readFriends } from 'services/apiServices/FriendService';

import { RecipientType } from 'types';

import css from './index.module.css';
import { useNetwork } from 'hooks/useNetwork';

interface Props {
  value?: RecipientType;
  onChange?: (value: RecipientType) => void;
  onSelect?: (nft: GetNFTsRes_OwnedNft) => void;
  onNextStepAuth?: () => void;
  onPrevStepAuth?: () => void;
  onClickFriend?: (friend: Friend) => void;
  isVisibleFriendLink?: boolean;
}

const TABS = ['1. NFT/Crypto', '2. Transaction details', '3. Choose Recipient', '4. Review'];

export const TransferRecipient = ({ isVisibleFriendLink = false }: Props) => {
  const { currentNetwork } = useNetwork();
  const [selectedTabIndex] = useState(2);
  const [selected, setSelected] = useState(null);
  const [isSelectedAddressValid, setIsSelectedAddressValid] = useState(false);
  const [isChangeAddress, setIsChangeAddress] = useState(false);
  const { address } = useAppSelector(selectorAccountInfo);
  const { recipientType, recipientAddress } = useAppSelector(transferSelector);
  const { contractAddress, tokenId, tokenType } = useParams();
  const navigate = useNavigate();
  const handleChangeRecipientTypeSelector = (recipientType: RecipientType) => {
    setIsSelectedAddressValid(false);
    dispatch(setRecipientType(recipientType));
    setSelected(null);
    if (recipientType === RecipientType.Friend) {
      setIsChangeAddress(false);
    }
  };

  const handleSetRecipientAddress = (address: string) => {
    dispatch(setRecipientAddress(address));
    setIsChangeAddress(address.length > 0);
    if (isAddressValid(address)) {
      setIsSelectedAddressValid(true);
    } else {
      setIsSelectedAddressValid(false);
    }
  };

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectorIsLoading);
  const [searchInput, setSearchInput] = useState('');

  const friendsQuery = useQuery('readFriends', async () => {
    if (!address) {
      throw new Error('User address is empty');
    }

    return await readFriends(address ?? '');
  });

  const friendsToDisplay = useMemo(() => {
    if (friendsQuery.data?.length === 0) {
      return friendsQuery.data;
    }
    return filterList('name', searchInput, friendsQuery.data ?? []).sort((a, b) => a.name.localeCompare(b.name));
  }, [friendsQuery.data, searchInput]);

  const cancel = () => {
    navigate(generatePath(Path.Nfts));
  };

  const handleBack =  () => {
    dispatch(setAmount(0));
    navigate(-1);
  }

  const handleContinue = useCallback(async () => {
    if (tokenId && contractAddress && tokenType)
      dispatch(setTokenIdAndCollection({ tokenId, collection: contractAddress, chainId: Number(currentNetwork!.chainId!) }));
    await dispatch(populateNftTransferTx(tokenType));
    
    navigate(generatePath(Path.TransferDesktopConfirmation, {contractAddress: contractAddress === undefined ? '': contractAddress, tokenId: tokenId === undefined ? '': tokenId, recipientAddress }));
  }, [tokenId, contractAddress, tokenType, dispatch, currentNetwork, navigate, recipientAddress]);

  const continueMutation = useMutation(async () => {
    await dispatch(populateTx());
    navigate(generatePath(Path.TransferCryptoConfirmationDesktop, { recipientAddress }));
  });

  const handleSetFriend = useCallback(
    (friend: Friend, i:any) => {
      setSelected(i);
      dispatch(setFriend(friend));
      setIsSelectedAddressValid(true);
    },
    [dispatch, setIsSelectedAddressValid],
  );

  const handleFriendLink = useFriendLink();

  useEffect(() => {
    if (!isLoading && friendsQuery.isLoading) {
      dispatch(updateFlags({ isLoading: true }));
    }
    if (isLoading && !friendsQuery.isLoading) {
      dispatch(updateFlags({ isLoading: false }));
    }
  }, [isLoading, friendsQuery.isLoading, dispatch]);

  const listSearch = () => {
    const { isSuccess, isError, data, refetch } = friendsQuery;

    if (isError) {
      return <ErrorMessageQueryLoading onRetry={() => refetch()} />;
    }

    if (isSuccess && data?.length === 0) {
      return (
        <>
          <EmptyListMessageText message="At this moment you have no friends. Share the friend link to add friends" />
          {isVisibleFriendLink && (
            <div className={css.footer}>
              <Button onClick={handleFriendLink}>Friend Link</Button>
            </div>
          )}
        </>
      );
    } else {
    }

    if (friendsQuery.isSuccess) {
      return (
        <div className={css.ulContainer}>
          {friendsToDisplay.length > 0 ? (
            <ul className={css.listFriend}>
              {friendsToDisplay.map((friend, i) => (
                <li key={i} className={`${selected === i ? css.selectedElement : ''} ... `}>
                  <FriendInfo friend={friend} onClick={() => handleSetFriend(friend, i)} />
                </li>
              ))}
            </ul>
          ) : (
            <p className={css.noSuccess}>No match satisfying search condition</p>
          )}
        </div>
      );
    }
  };

  return (
    <div className={css.container}>
      <TransferProgressBar className={css.tabs} tabs={TABS} selectedTabIndex={selectedTabIndex} />
      <div className={css.mainItems}>
        <div className={css.recipientType}>
          <RecipientTypeSelector value={recipientType} onChange={handleChangeRecipientTypeSelector} />
        </div>
        {recipientType === RecipientType.Friend ? (
          <>
            <div className={css.searchBar}>
              <Search onChange={setSearchInput} />
            </div>
            <div className={css.containerList}>{listSearch()}</div>
          </>
        ) : (
          <div className={css.recipientAddress}>
            <TextAreaInputWithPaste label="Address" value={recipientAddress} onSetPaste={handleSetRecipientAddress} />
            {isChangeAddress && !isSelectedAddressValid && <InputErrorMessage message="Incorrect address" />}
          </div>
        )}
      </div>

      <div className={css.footerContainer}>
        <div className={css.cancelButton}>
          <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={cancel}>
            Cancel
          </Button>
        </div>

        <div className={css.continueButton}>
          <div className={css.previousButton}>
            <Button colorScheme={ButtonColorScheme.SECONDARY} onClick={handleBack}>
              Previous Step
            </Button>
          </div>

          {tokenId !== undefined ? (
            <div className={css.previousButton}>
              <Button disabled={selected === null && !isSelectedAddressValid} onClick={handleContinue}>
                Continue
              </Button>
            </div>
          ) : (
            <div className={css.previousButton}>
              <Button disabled={selected === null && !isSelectedAddressValid} onClick={() => continueMutation.mutate()}>
                Continue
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
