import React from 'react';

import { Chevron } from 'assets/icons/Chevron';
import beasyLightText2x from 'assets/images/beasy-light-text@2x.png';
import beasyLightText3x from 'assets/images/beasy-light-text@3x.png';
import beasyLightText from 'assets/images/beasy-light-text.png';

import css from './index.module.css';

export const OnboardingHeader = ({ goBack }: { goBack: () => void }) => {
  return (
    <nav className={css.container}>
      <button onClick={goBack}>
        <Chevron size={18} className={css.backIcon} />
      </button>
      <img
        src={beasyLightText}
        alt="beasyLogo"
        srcSet={`${beasyLightText} 1x, ${beasyLightText2x} 2x, ${beasyLightText3x} 3x`}
      />
    </nav>
  );
};
