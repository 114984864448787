import React from 'react';

import { useNftQuery } from 'hooks/useNftQuery';

import { NftCardContent } from './NftCardContent';

interface Props {
  contractAddress: string;
  tokenId: string;
}

export const SmallNftCard = ({ contractAddress, tokenId }: Props) => {
  const nftQuery = useNftQuery(contractAddress, tokenId);

  if (nftQuery.isSuccess && nftQuery.data) {
    const { metadata } = nftQuery.data;

    return (
      <NftCardContent
        description={metadata?.description}
        imageSrc={metadata?.image}
        title={metadata?.name}
        quantity={metadata?.beasy?.quantity}
      />
    );
  }

  return <NftCardContent />;
};
