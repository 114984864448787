import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { Chevron } from 'assets/icons';

import Button, { ButtonColorScheme } from 'components/Button';
import { ButtonGhost2 } from 'components/ButtonGhost2';
import { UserAvatar } from 'components/UserInfo/Avatar';

import { Path } from 'constants/enumTypes';
import { businessAgreementsId, nftGalleryId } from 'constants/onboarding';

import { getCurrentUserProfile, UserProfileQueryKey } from 'services/userProfile';

import css from './index.module.css';

export const WalletSidebar = () => {
  const navigate = useNavigate();

  const pathname = window.location.pathname;

  const getUserProfileQuery = useQuery(UserProfileQueryKey.getUserProfile, () => {
    return getCurrentUserProfile();
  });

  const handleTradeClick = useCallback(() => {
    navigate(Path.TransferDesktopType);
  }, [navigate]);
  return (
    <nav className={css.wrapper}>
      <ButtonGhost2 disabled={pathname === '/account'} className={pathname === '/account' ? css.profile : css.profile} onClick={() => navigate(Path.Account)}>
        <figure>
          <UserAvatar cid={getUserProfileQuery.data?.avatarCid} />
        </figure>
        <div>{getUserProfileQuery?.data?.nickname}</div>
        <Chevron size={13.5} />
      </ButtonGhost2>
      <div className={css.tradeButton}>
        <Button colorScheme={ButtonColorScheme.PRIMARY} onClick={handleTradeClick}>
          New Transfer
        </Button>
      </div>

      <ul className={css.listMenu}>
        <li className={pathname === '/nfts' ? css.active : ''}>
          <NavLink to={'/nfts'}>Tokens</NavLink>
        </li>
        <li id={nftGalleryId} className={pathname === '/transfers' ? css.active : ''}>
          <NavLink to={'/transfers'}>Transfers</NavLink>
        </li>
        <li id={businessAgreementsId} className={pathname === '/friends' ? css.active : ''}>
          <NavLink to={'/friends'}>Friends</NavLink>
        </li>
      </ul>

      <div className={css.copyright}>
        <span>Powered by Beasy</span>
      </div>
    </nav>
  );
};
